import {
    GET_EXAMBOARDSCATEGORY,
    GET_EXAMBOARDSCATEGORY_SUCCESS,
    ADD_NEW_EXAM_BOARDCATEGORY,
    ADD_NEW_EXAM_BOARDCATEGORY_SUCCESS,
    DELETE_EXAM_BOARDCATEGORY,
    DELETE_EXAM_BOARDCATEGORY_SUCCESS,
    RESET_STORE_EXAM_BOARDCATEGORY,
    GET_MASTERLIST,
    GET_MASTERLIST_SUCCESS,
    GETQA_MASTERLIST,
    GETQA_MASTERLIST_SUCCESS
   
  } from "./actionTypes"
  
  export const getExamBoardscategory = (data) => ({
    type: GET_EXAMBOARDSCATEGORY,
    payload: data
  })
  export const getExamBoardcategorySuccess = examboardscategory => ({
    type: GET_EXAMBOARDSCATEGORY_SUCCESS,
    payload: examboardscategory,
  })
  export const addNewExamBoardcategory = data => ({
    type: ADD_NEW_EXAM_BOARDCATEGORY,
    payload: data,
  })
  
  export const addNewExamBoardcategorySuccess = data => ({
    type:  ADD_NEW_EXAM_BOARDCATEGORY_SUCCESS,
    payload: data,
  })

  export const deleteExamBoardcategory = data => ({
    type: DELETE_EXAM_BOARDCATEGORY,
    payload: data,
  })
  
  export const deleteExamBoardcategorySuccess = data => ({
    type: DELETE_EXAM_BOARDCATEGORY_SUCCESS,
    payload: data,
  })

  export const resetStorecategory = data => ({
    type: RESET_STORE_EXAM_BOARDCATEGORY,
    payload: data,
  })

  export const getmasterlist = (data) => ({
    type: GET_MASTERLIST,
    payload: data
  })
  export const getmasterlistsuccess = data => ({
    type: GET_MASTERLIST_SUCCESS,
    payload: data,
  })

  export const getqamasterlist = (data) => ({
    type: GETQA_MASTERLIST,
    payload: data
  })
  export const getqamasterlistsuccess = data => ({
    type: GETQA_MASTERLIST_SUCCESS,
    payload: data,
  })

  