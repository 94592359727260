import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import BoardList from "pages/Masters/Board/list-boards";
import Usermaster from "pages/Masters/Board/usermaster";

import BoardCategoryList from "pages/Masters/Board/list-board-categories";
import ExamPaperList from "pages/Masters/ExamPapers/list-exampapers";
import SubjectList from "pages/Masters/Subject/list-subjects";
import SubjectCategoryList from "pages/Masters/Subject/list-subject-categories";
import Mapping from "pages/Masters/Configuration/mapping_screen";
import TopicList from "pages/Masters/Subject/list-topics";
import ConfigurationList from "pages/Masters/Configuration/list-conf";
import AddConfiguration from "pages/Masters/Configuration/add-conf";
import QuestionList from "pages/Questions/list-questions";
import QuestionApprovalList from "pages/Questions/list-question-approval";
import AddQuestions from "pages/Questions/add-ques";
import UserroleList from "pages/Masters/userrole/list-userrole";
import StudentMaster from "pages/Masters/StudentMaster/list-studentmaster";
import AddUserroleList from "pages/Masters/userrole/add-userrole";
import StudyMaterials from "pages/StudyMaterials/list-studymaterials";
import StudyMaterialsApproval from "pages/StudyMaterials/list-studymaterial-approval";
import AddStudyMaterials from "pages/StudyMaterials/add-materials";
import OnlineTest from "pages/onlineTest/onlineTest"
import GeneralSettings from "pages/GeneralSettings/GeneralSettings"
import * as constants from "../constants/layout"
import MockupTstList from "pages/Masters/MockupTest/list-mockup";
import AddMockupTest from "pages/Masters/MockupTest/add-mockup";
import AddMockupTopic from "pages/Masters/MockupTest/topic-mockup";
import ImportExcel from "pages/Questions/import-from-excel";
import LoginTransaction from "pages/Transactions/loginTransaction";


const authProtectedRoutes = [
  // { path: "/", exact: true,
  // menu_id: constants.SubmenuList.Dashboard,
  // component: <Login /> },
  {
    path: "/Dashboard", exact: true,
    menu_id: constants.SubmenuList.Dashboard,
    component: <Dashboard />
  },
  {
    path: "/questions",
    menu_id: constants.SubmenuList.QA,
    component: <QuestionList />
  },
  {
    path: "/list-question-approval",
    menu_id: constants.SubmenuList.QA,
    component: <QuestionApprovalList />
  },
  // //profile
  { path: "/profile", component: <UserProfile /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  {
    path: "/list-boards",
    exact: true,
    menu_id: constants.SubmenuList.ExamBoard,
    component: <BoardList setHeaderTitle={'Board'} />,
  },
  {
    path: "/list-board-categories",
    exact: true,
    menu_id: constants.SubmenuList.ExamBoardCategory,
    component: <BoardCategoryList />,
  },
  {
    path: "/list-exam-papers",
    exact: true,
    menu_id: constants.SubmenuList.ExamPapers,
    component: <ExamPaperList />,
  },
  {
    path: "/list-subjects",
    exact: true,
    menu_id: constants.SubmenuList.Subject,
    component: <SubjectList />,
  },
  {
    path: "/list-usermaster",
    exact: true,
    menu_id: constants.SubmenuList.UserMaster,
    component: <Usermaster />,
  },
  {
    path: "/list-StudentMaster",
    exact: true,
    menu_id: constants.SubmenuList.StudentMaster,
    component: <StudentMaster />,
  },

  {
    path: "/list-subject-categories",
    exact: true,
    menu_id: constants.SubmenuList.SubjectCategory,
    component: <SubjectCategoryList />,
  }
  ,
  {
    path: "/conf-map/:cid",
    exact: true,
    menu_id: constants.SubmenuList.ExamBluePrint,
    component: <Mapping />,
  }
  ,
  {
    path: "/list-topics",
    exact: true,
    menu_id: constants.SubmenuList.Topic,
    component: <TopicList />,
  }
  ,
  {
    path: "/list-configuration",
    exact: true,
    menu_id: constants.SubmenuList.ExamBluePrint,
    component: <ConfigurationList />,
  }
  ,
  {
    path: "/add-configuration/:cid",
    exact: true,
    menu_id: constants.SubmenuList.ExamBluePrint,
    component: <AddConfiguration />,
  }
  ,
  ,
  {
    path: "/list-mockuptest",
    exact: true,
    menu_id: constants.SubmenuList.MockupTest,
    component: <MockupTstList />,
  }
  ,
  {
    path: "/add-mockuptest/:mkid",
    exact: true,
    menu_id: constants.SubmenuList.MockupTest,
    component: <AddMockupTest />,
  },
  {
    path: "/add-mockuptest-topic/:mkid",
    exact: true,
    menu_id: constants.SubmenuList.MockupTest,
    component: <AddMockupTopic />,
  }
  ,
  {
    path: "/add-questions/:qid",
    exact: true,
    menu_id: constants.SubmenuList.QA,
    component: <AddQuestions />,
  },
  {
    path: "/list-userrole",
    exact: true,
    menu_id: constants.SubmenuList.UserRole,
    component: <UserroleList />,
  },
  {
    path: "/add-userrole",
    exact: true,
    menu_id: constants.SubmenuList.UserRole,
    component: <AddUserroleList />,
  },
  {
    path: "/list-studymaterial",
    exact: true,
    menu_id: constants.SubmenuList.StudyMaterial,
    component: <StudyMaterials />,
  },
  {
    path: "/list-studymaterial-approval",
    exact: true,
    menu_id: constants.SubmenuList.StudyMaterial,
    component: <StudyMaterialsApproval />,
  },
  {
    path: "/add-studymaterial/:stid",
    exact: true,
    menu_id: constants.SubmenuList.StudyMaterial,
    component: <AddStudyMaterials />,
  },
  {
    path: "/online-test",
    exact: true,
    menu_id: constants.SubmenuList.onlineTest,
    component: <OnlineTest />,
  },
  {
    path: "/general-settings",
    exact: true,
    menu_id: constants.SubmenuList.GeneralSettings,
    component: <GeneralSettings />,
  },
  {
    path: "/qa-import-excel",
    exact: true,
    menu_id: constants.SubmenuList.GeneralSettings,
    component: <ImportExcel />,
  },
  {
    path: "/login-transactions",
    exact: true,
    menu_id: constants.SubmenuList.GeneralSettings,
    component: <LoginTransaction />,
  }
];

const publicRoutes = [
  { path: "/", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
