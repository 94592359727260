import React, { useEffect } from "react"
import { Button, CardBody, Container, UncontrolledTooltip, Form, FormGroup, Input, InputGroup, Label, FormFeedback, CardFooter } from "reactstrap";
import { Row, Col, Card } from "reactstrap"
import './dashboard.scss'
import profileImg from "../../assets/images/profile-img.png"
import { Link } from "react-router-dom";
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import * as constants from "../../constants/layout"

const WelcomeComp = () => {
  const user = getLoggedInUser()

  const authUserString = sessionStorage.getItem('SESSION_KEY');
  let uName = "";
  if (authUserString) {
    const authUser = JSON.parse(authUserString);
    uName = authUser.u_name;
    uName = uName?.charAt(0)?.toUpperCase() + uName?.slice(1);
  }

  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Quick Links';
  }, []);

  return (
    <React.Fragment>
      {/* <Card className="overflow-hidden">
        <div className="bg-primary-subtle">
          <Row className="landing-img">
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back {uName}!</h5>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>

      </Card> */}

      {/* <Card className="overflow-hidden">
        <div className="bg-primary-subtle">
          <Row className="landing-img">
            <Col xs="12" md="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back {uName}!</h5>
              </div>
            </Col>
            <Col xs="12" md="5" className="d-flex justify-content-center">
              <img src={profileImg} alt="Profile" className="img-fluid" />
            </Col>
          </Row>
        </div>
      </Card> */}
      <Card style={{ padding: 16 }} className="overflow-hidden">
        {/* <Card style={{ padding: 16 }} className="overflow-hidden"> */}
        <CardBody>
          {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.Masters)?.urp_type === constants.menuTypes.View && (
            <h5 style={{
              fontWeight: 'bolder',
              fontSize: '19px'
            }}>Masters</h5>
          )}
          <Row>
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.ExamBoard)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-boards" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden bg-success-subtle" style={{ width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'darkmagenta'
                      }} className="mdi mdi-developer-board"></i></Row>
                      <div to="/list-boards" style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Exam Board</div >
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}

            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.ExamBoardCategory)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-board-categories" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden bg-danger-subtle" style={{ width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'blueviolet'
                      }} className="mdi mdi-hexagon-multiple"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Board Category</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}

            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.ExamPapers)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-exam-papers" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden" style={{ backgroundColor: 'gainsboro', width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'darkcyan'
                      }} className="mdi mdi-clipboard-text"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Exam Paper</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.Subject)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-subjects" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden" style={{ backgroundColor: 'antiquewhite', width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'coral'
                      }} className="mdi mdi-note-multiple"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Subject</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.SubjectCategory)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-subject-categories" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden" style={{ backgroundColor: 'burlywood', width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'aliceblue'
                      }} className="mdi mdi-file-tree"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Subcategory</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.Topic)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-topics" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden" style={{ backgroundColor: 'gainsboro', width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'brown'
                      }} className="mdi mdi-script-text"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Topic</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.UserMaster)?.urp_type === constants.menuTypes.View && (

              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-usermaster" style={{ textDecoration: 'none' }}>
                  <Card className="overflow-hidden" style={{ backgroundColor: 'darksalmon', width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'cornsilk'
                      }} className="mdi mdi-account-circle-outline"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >User</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.UserRole)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-userrole" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden" style={{ backgroundColor: 'darkgray', width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'darkslateblue'
                      }} className="mdi mdi-badge-account"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >User Role</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}
          </Row>
          <Row>
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.StudentMaster)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-StudentMaster" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden bg-success-subtle" style={{ width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'darkmagenta'
                      }} className="mdi mdi-developer-board"></i></Row>
                      <div to="/list-StudentMaster" style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Student Master</div >
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.GeneralSettings)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/login-transactions" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden bg-success-subtle" style={{ width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'darkmagenta'
                      }} className="mdi mdi-developer-board"></i></Row>
                      <div to="/login-transactions" style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Login Transactions</div >
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}


          </Row>
          {/* </CardBody> */}
          {/* </Card> */}
          {/* <Card style={{ padding: 16 }} className="overflow-hidden"> */}
          {/* <Row> */}
          {/* <Col lg={12}> */}
          <Row>
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.StudyMaterial)?.urp_type === constants.menuTypes.View && (
              <Col aria-colspan={1} lg={2}>
                <h5 style={{
                  fontWeight: 'bolder',
                  fontSize: '19px'
                }}>Study Material</h5>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.QA)?.urp_type === constants.menuTypes.View && (
              <Col aria-colspan={1} lg={1}>
                <h5 style={{
                  fontWeight: 'bolder',
                  fontSize: '19px'
                }}>Q&A</h5>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.Configuration)?.urp_type === constants.menuTypes.View && (
              <Col aria-colspan={2} lg={3}>
                <h5 style={{
                  fontWeight: 'bolder',
                  fontSize: '19px',
                  marginLeft: '5%'
                }}>Configuration</h5>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.ApprovalList && Number(p.urp_type) === constants.menuTypes.Permission1) && (
              <Col aria-colspan={2} lg={3}>
                <h5 style={{
                  fontWeight: 'bolder',
                  fontSize: '19px',
                  marginLeft: '5%'
                }}>Approval Process</h5>
              </Col>
            )}
          </Row>
          {/* <CardBody> */}
          {/* <h3>Quick Links</h3> */}
          <Row>

            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.StudyMaterial)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-studymaterial" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden bg-primary-subtle" style={{ width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'dodgerblue'
                      }} className="mdi mdi-book-open-page-variant"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Study Material</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.QA)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/questions" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden bg-warning-subtle" style={{ width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'chocolate'
                      }} className="mdi mdi-clipboard-list"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Q&A</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.ExamBluePrint)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-configuration" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden" style={{ backgroundColor: 'beige', width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'burlywood'
                      }} className="mdi mdi-file-table"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Exam Blue Print</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.MockupTest)?.urp_type === constants.menuTypes.View && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-mockuptest" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden" style={{ backgroundColor: 'cornsilk', width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'currentColor'
                      }} className="mdi mdi-clipboard-text-multiple"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Mockup Test</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.SMApproval && Number(p.urp_type) === constants.menuTypes.Permission1) && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-studymaterial-approval" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden" style={{ backgroundColor: 'cornsilk', width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'currentColor'
                      }} className="mdi mdi-book-check-outline"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >S&M Approval</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.QAApproval && Number(p.urp_type) === constants.menuTypes.Permission1) && (
              <Col lg={1} className="dashboard-me-6">
                <Link to="/list-question-approval" style={{ textDecoration: 'none' }}>

                  <Card className="overflow-hidden" style={{ backgroundColor: 'cornsilk', width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'currentColor'
                      }} className="mdi mdi-notebook-check"></i></Row>
                      <div style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Q&A Approval</div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )
            }          </Row>
          {/* </Col> */}
          {/* <Col lg={6}>
              <h5 style={{
                fontWeight: 'bolder',
                fontSize: '19px'
              }}>Configuration</h5>
              <Row>
                <Col lg={2} className="dashboard-me-6">
                  <Card className="overflow-hidden" style={{ backgroundColor: 'beige', width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'burlywood'
                      }} className="mdi mdi-file-table"></i></Row>
                      <Link to="/list-configuration" style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Exam Blue Print</Link>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={2} className="dashboard-me-6">
                  <Card className="overflow-hidden" style={{ backgroundColor: 'cornsilk', width: '134px', padding: '0px 2px 10px' }}>
                    <CardBody className="card-dashboard text-center">
                      <Row><i style={{
                        fontSize: 'xx-large',
                        color: 'currentColor'
                      }} className="mdi mdi-clipboard-text-multiple"></i></Row>
                      <Link to="/list-mockuptest" style={{
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '600'
                      }} >Mockup Test</Link>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col> */}
          {/* </Row> */}
          {/* </CardBody> */}
          {/* </Card> */}
          {/* <Card style={{ padding: 16 }} className="overflow-hidden"> */}
          {/* <h5 style={{
            fontWeight: 'bolder',
            fontSize: '19px'
          }}>Configuration</h5> */}
          {/* <CardBody> */}
          {/* <h3>Quick Links</h3> */}
          {/* <Row>
            <Col lg={1} className="dashboard-me-6">
              <Card className="overflow-hidden" style={{ backgroundColor: 'beige', width: '134px', padding: '0px 2px 10px' }}>
                <CardBody className="card-dashboard text-center">
                  <Row><i style={{
                    fontSize: 'xx-large',
                    color: 'burlywood'
                  }} className="mdi mdi-file-table"></i></Row>
                  <Link to="/list-configuration" style={{
                    color: 'black',
                    fontSize: '15px',
                    fontWeight: '600'
                  }} >Exam Blue Print</Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={1} className="dashboard-me-6">
              <Card className="overflow-hidden" style={{ backgroundColor: 'cornsilk', width: '134px', padding: '0px 2px 10px' }}>
                <CardBody className="card-dashboard text-center">
                  <Row><i style={{
                    fontSize: 'xx-large',
                    color: 'currentColor'
                  }} className="mdi mdi-clipboard-text-multiple"></i></Row>
                  <Link to="/list-mockuptest" style={{
                    color: 'black',
                    fontSize: '15px',
                    fontWeight: '600'
                  }} >Mockup Test</Link>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </CardBody>
        {/* </Card> */}
      </Card>
    </React.Fragment >
  )
}
export default WelcomeComp
