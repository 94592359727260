import {
    GET_EXAMBOARDSCATEGORY_SUCCESS,
    ADD_NEW_EXAM_BOARDCATEGORY_SUCCESS,
    DELETE_EXAM_BOARDCATEGORY_SUCCESS,
    GET_MASTERLIST_SUCCESS,
    RESET_STORE_EXAM_BOARDCATEGORY,
    GETQA_MASTERLIST_SUCCESS
  
  } from "./actionTypes"
  
  const INIT_STATE = {
    examBoardscategory: [],
    //masterqalist: [],
    masterlist:[],
    exboardcategory_statusFlag: 0
  }
  
  const ExamBoardCategory = (state = INIT_STATE, action) => {
    console.log(action.type, action.payload, 'reducer')
    switch (action.type) {
      case GET_EXAMBOARDSCATEGORY_SUCCESS:
        return {
          ...state,
          examBoardscategory: action.payload.examBoardscategory,
          exboardcategory_statusFlag: action.payload.statusFlag
        }
      case ADD_NEW_EXAM_BOARDCATEGORY_SUCCESS:
      return {
          ...state,
          exboardcategory_statusFlag: action.payload.statusFlag,
      };
      case  DELETE_EXAM_BOARDCATEGORY_SUCCESS:
        return {
            ...state,
            exboardcategory_statusFlag: action.payload.statusFlag,
        };
        case  GET_MASTERLIST_SUCCESS:
          return {
              ...state,
              masterlist: action.payload.masterlist,
              exboardcategory_statusFlag: action.payload.statusFlag
          };

            case GETQA_MASTERLIST_SUCCESS:
          return {
              ...state,
              masterlist: action.payload.masterlist,
              exboardcategory_statusFlag: action.payload.statusFlag
          };

          case RESET_STORE_EXAM_BOARDCATEGORY:
            return {
              ...state,
              exboardcategory_statusFlag: action.payload.statusFlag,
            };
        default:
            return state
        }
      }
      
      export default ExamBoardCategory