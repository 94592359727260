import PropTypes from "prop-types";
import React from "react";
import { Container, Row, Col } from "reactstrap";

import { withTranslation } from "react-i18next";
import WelcomeComp from "./WelcomeComp";


const Dashboard = props => {


  //meta title
  document.title = "Dashboard | ICE";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12" >
              <WelcomeComp />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
