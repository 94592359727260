import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_EXAMBOARDS, ADD_NEW_EXAM_BOARD, DELETE_EXAM_BOARD } from "./actionTypes";
import {
  getExamBoardSuccess, addNewExamBoardSuccess, deleteExamBoardSuccess
} from "./actions";

//Include Both Helper File with needed methods
import { getExamBoardsList, addNewExamBoard, deleteExamBoard, userValidation } from "helpers/fakebackend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchExamBoards({ payload: data}) {
  try {
    const response = yield call(getExamBoardsList, data)
    yield call(userValidation, response)
    yield put(getExamBoardSuccess(response))
  } catch (error) {
    yield put(getExamBoardSuccess({statusFlag: 2}))
  }
}

function* onAddNewExamBoard({ payload: data }) {
  try {
      const response = yield call(addNewExamBoard, data)
      yield call(userValidation, response)
      yield put(addNewExamBoardSuccess(response))
      if (response && response.statusFlag === 1) {
        toast.success(response.message, { autoClose: 2000 });
      } else {
        toast.error(response.message, { autoClose: 2000 });
      }      
  } catch (error) {
      yield put(addNewExamBoardSuccess({statusFlag: 2}))
      toast.error("Exam Board Added Failed", { autoClose: 2000 });
  }
}

function* onDeleteExamBoard({ payload: data }) {
  try {
      const response = yield call(deleteExamBoard, data)
      yield call(userValidation, response)
      yield put(deleteExamBoardSuccess(response))
      if (response && response.statusFlag === 1) {
        toast.success(response.message, { autoClose: 2000 });
      } else {
        toast.error(response.message, { autoClose: 2000 });
      }      
  } catch (error) {
      yield put(deleteExamBoardSuccess({statusFlag: 2}))
      toast.error("Exam Board Deleted Failed", { autoClose: 2000 });
  }
}

function* examboardSaga() {
  yield takeEvery(GET_EXAMBOARDS, fetchExamBoards)
  yield takeEvery(ADD_NEW_EXAM_BOARD, onAddNewExamBoard)
  yield takeEvery(DELETE_EXAM_BOARD, onDeleteExamBoard)

}

export default examboardSaga;
