import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postLogin,
} from "../../../helpers/fakebackend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const fireBaseBackend = getFirebaseBackend();
import { getRefreshToken, getLoggedInUser } from "../../../helpers/fakebackend_helper";
import * as constants from "constants/layout"
import axios from 'axios'
import * as url from "../../../helpers/url_helper";

const userLog = async (text) => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data1 = await response.json();

    const user = getLoggedInUser()
    const data = {
      user_id: Number(user.uid),
      ip_address: data1.ip,
      log_text: String(text),
      process_type: text === 'login' ? 'save' : 'update'
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(url.BASE_URL + 'user-master/adminUserLog', data, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
      if (refresh) {
        if (Number(refresh) === constants.invalidRefreshToken) {
          logoutUser()
        } else {
          userLog(text) // calling same function again getting refresh token
        }
      }
    }
    if (response1 && response1.data && response1.data.body) {
      if (response1.data.body.statusFlag === 1) {
        if (text === 'logout') {
          localStorage.removeItem("authUser");
          sessionStorage.removeItem("SESSION_KEY");
        }
      }
    } else {
      // return { statusFlag: 2, message: 'Something went wrong' }
    }
  } catch (error) {
    console.log(error.message);
  } finally {
    // setLoading(false);
  }
}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, user);
    console.log(response, 'login_response')
    if (response && response?.data?.body?.status === 200) {
      localStorage.setItem("authUser", JSON.stringify(response?.data?.body?.UserInfo));
      sessionStorage.setItem("SESSION_KEY", JSON.stringify(response?.data?.body?.UserInfo));
      yield put(loginSuccess(response?.data?.body?.UserInfo));
      toast.success(response?.data?.body?.message, { autoClose: 2000 });
      userLog('login')
      history('/Dashboard');
    } else if (response && response?.data?.body?.status === 401) {
      toast.error(response?.data?.body?.message, { autoClose: 2000 });
    } else {
      toast.error('Something went wrong', { autoClose: 2000 });
    }
    // history('/questions');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    userLog('logout')
    // Signal other tabs to close
    localStorage.setItem('logout', Date.now());
    history('/');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
