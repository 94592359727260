import {
    GET_EXAMPAPER_SUCCESS,
    ADD_NEW_EXAMPAPERS_SUCCESS,
    DELETE_EXAMPAPERS_SUCCESS,
    RESET_EXAMPAPERS_BOARDCATEGORY  
  } from "./actionTypes"
  
  const INIT_STATE = {
    examPapercategory: [],
    expapercategory_statusFlag: 0
  }
  
  const ExamPaperCategory = (state = INIT_STATE, action) => {
    console.log(action.type, action.payload, 'reducer')
    switch (action.type) {
      case GET_EXAMPAPER_SUCCESS:
        return {
          ...state,
          examPapercategory: action.payload.examPapercategory,
          expapercategory_statusFlag: action.payload.statusFlag
        }
      case ADD_NEW_EXAMPAPERS_SUCCESS:
      return {
          ...state,
          expapercategory_statusFlag: action.payload.statusFlag,
      };
      case  DELETE_EXAMPAPERS_SUCCESS:
        return {
            ...state,
            expapercategory_statusFlag: action.payload.statusFlag,
        };
  
          case RESET_EXAMPAPERS_BOARDCATEGORY:
            return {
              ...state,
              expapercategory_statusFlag: action.payload.statusFlag,
            };
        default:
            return state
        }
      }
      
      export default ExamPaperCategory