// Created by: Murugeswari Chandrabose
// Created At: 22 Jan 2024

// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import * as Yup from "yup";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import Select from "react-select";
import { getmasterlist, getusermaster, addusermaster, deleteusermaster, resetusermaster, resetpasswordmaster } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";

import * as constants from "../../../constants/layout"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";


const UsermasterList = () => {
    document.title = "Masters | User Master";

    const dispatch = useDispatch();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const store = useSelector(state => state.Usermaster)
    const masterstore = useSelector(state => state.ExamBoardCategory)
    const user = getLoggedInUser();
    const [inputFocus, setInputFocus] = useState(false);
    const inputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const [modal, setModal] = useState(false);
    const [submitbtn, setSubmitbtn] = useState('')
    const [deleteTrigger, setDeleteTrigger] = useState(false)
    const [modal1, setModal1] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [resetTrigger, setResetTrigger] = useState(false);
    const [boardCategory, setBoardCategory] = useState(null);
    const [board, setBoard] = useState();
    const [usermaster, setusermaster] = useState([])
    const [masterList, setmasterList] = useState([]);
    const [boardUid, setBoardUid] = useState(null);
    const [status, setStatus] = useState(true);
    const [userroleName, setuserroleName] = useState(null);
    const [canView, setCanView] = useState(false)
    const [canCreate, setCanCreate] = useState(false)
    const [canModify, setCanModify] = useState(false)
    const [canRemove, setCanRemove] = useState(false)
    const [newpassword, setnewpassword] = useState('')
    const [newpasswordError, SetnewpasswordError] = useState(false)
    const [resetpasswordError, setresetpasswordError] = useState('')
    const [userconfirmpassword, setuserconfirmpassword] = useState('')
    const [conformpasswordError, SetconformpasswordError] = useState('')
    const [conformpassword, setconformpassword] = useState(false)
    const password_validation = constants.errorMessages.PASSWORD_VALIDATION
    const [newpasswordShow, setNewPasswordShow] = useState(false);
    const [confmpasswordShow, setConfmPasswordShow] = useState(false);
    const [confmpasswordShow1, setConfmPasswordShow1] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);


    const selectedUrid = userroleName ? userroleName.value : null;
    const disableButton = () => {
        setButtonDisabled(true);
    };

    const enableButton = () => {
        setButtonDisabled(false);
    };
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            categoryID: (boardCategory && boardCategory.categoryID) || '',
            userdisplayName: (boardCategory && boardCategory.userdisplayName) || '',
            loginID: (boardCategory && boardCategory.loginID) || '',
            userroleName: (userroleName && userroleName.ur_name && {
                label: userroleName?.ur_name,
                value: userroleName?.u_urid,
            }) || null,
        },
        validationSchema: Yup.object({
            userdisplayName: Yup.string().required("Please Enter User Name").matches(/^[a-zA-Z. ]+$/, 'User name can only contain letters.'),
            loginID: Yup.string().required("Please Enter Login ID").matches(/^[a-zA-Z0-9]+$/, 'Login ID can only contain letters and numbers.'),
            userroleName: Yup.object().required("Please select user role"),
            password: Yup.string().matches(password_validation, constants.errorMessages.PASSWORD_VALIDATIONERROR),
            confirmPassword: Yup.string().matches(password_validation, constants.errorMessages.PASSWORD_VALIDATIONERROR)
        }),
        onSubmit: (values, { }) => {
            // setSubmitbtn(true); // Set form to submitting state
            setLoading(true);
            if (isEditing) { //update
                const newaddusermaster = {
                    user_id: Number(user.uid),
                    u_name: values.userdisplayName,
                    process_type: 'update',
                    u_loginid: values.loginID,
                    u_urid: Number(selectedUrid),
                    u_uid: board.uid,
                    u_status: status === true ? 1 : 2
                };
                disableButton()
                // update new Exam Board
                dispatch(addusermaster(newaddusermaster));

            } else { //add
                // Manual validation for password and confirmPassword
                if (!values.password || !values.confirmPassword) {
                    // Set errors and prevent form submission
                    validation.setFieldError('password', 'Password is required');
                    validation.setFieldError('confirmPassword', 'Confirm Password is required');
                    setLoading(false);
                    return;
                }

                // Check if passwords match
                if (values.password !== values.confirmPassword) {
                    validation.setFieldError('confirmPassword', 'Passwords must match');
                    setLoading(false);
                    return;
                }
                const newaddusermaster = {
                    user_id: Number(user.uid),
                    u_name: values.userdisplayName,
                    process_type: 'save',
                    u_loginid: values.loginID,
                    u_password: values.password,
                    u_urid: selectedUrid,
                };
                // save new Exam Board
                disableButton()
                dispatch(addusermaster(newaddusermaster));

            }

            // toggle();
        },
    });
    const [deleteModal, setDeleteModal] = useState(false);

    // After api call success stop loading
    useEffect(() => {
        enableButton()
        if (store.usermaster_statusFlag === 1) {
            if (submitbtn) { // add or update then refresh list               
                if (submitbtn !== 'savecontinue') { // If it not savecontinue close popup
                    setModal(false);

                }
                inputRef.current.focus();
                setInputFocus(true)
                setSubmitbtn('')
                getusermasterapi()
                validation.resetForm();
                // Manually trigger the onChange event for the Select component
                const event = {
                    target: {
                        name: 'userroleName', value: {
                            label: userroleName.label,
                            value: Number(userroleName.value),
                        }
                    }
                };
                validation.handleChange(event);
            }
            if (deleteTrigger) { // delete then refresh list
                setDeleteTrigger(false)
                getusermasterapi()
                setDeleteModal(false);
                setBoard('');
            }
            if (resetTrigger) {
                setResetTrigger(false);
                setNewPassword('')
                validation.resetForm();
                setModal1(false);
                // togglePasswordReset();   
                SetnewpasswordError(false)
                setresetpasswordError('')
                setconformpassword(false)
                SetconformpasswordError('')
                setBoardUid('');
                setuserconfirmpassword('')
                setnewpassword('')
            }
            setLoading(false)
            dispatch(resetusermaster({ statusFlag: 0 }));
        } else if (store.usermaster_statusFlag === 2) {
            if (submitbtn) {
                setSubmitbtn('')
            }
            if (deleteTrigger) {
                setDeleteTrigger(false)
                setDeleteModal(false);
                setBoard('');
            }
            if (resetTrigger) {
                setResetTrigger(false)
            }
            setLoading(false)
            dispatch(resetusermaster({ statusFlag: 0 }));
        }
    }, [store.usermaster_statusFlag])

    useEffect(() => {
        if (modal && inputRef.current && inputFocus) {
            inputRef.current.focus();
            setInputFocus(false);
        }
    }, [modal, inputFocus]);

    useEffect(() => {
        if (modal) {
            setInputFocus(true);
        }
    }, [modal]);

    const getusermasterapi = () => {
        setLoading(true)
        dispatch(getusermaster({ user_id: user && Number(user.uid), login_transaction: 0 }));
    }

    useEffect(() => {
        getusermasterapi()
        document.getElementById('headerTitle').innerHTML = 'User Master';
    }, [dispatch]);

    useEffect(() => {
        setusermaster(store.userList)
    }, [store.userList])

    const getmasterlistapi = () => {
        setLoading(true)
        dispatch(getmasterlist({ user_id: user && Number(user.uid) }));
    }

    useEffect(() => {
        getmasterlistapi()
    }, [dispatch]);

    useEffect(() => {
        if (masterstore.masterlist && masterstore.masterlist.userRoleList)
            setmasterList(masterstore.masterlist.userRoleList)
    }, [masterstore.masterlist])


    const onClickDelete = (userData) => {
        setBoard(userData);
        setDeleteModal(true);
    };


    const handleDeleteOrder = () => {
        setDeleteTrigger(true)
        setLoading(true)
        dispatch(deleteusermaster({ user_id: Number(user.uid), u_uid: Number(board.uid) }))
    };

    const handleSelectuserrolename = (selectedOption) => {
        setuserroleName(selectedOption);
    };
    const columns = useMemo(
        () => [
            {
                header: 'Actions',
                // accessorKey: 'action',
                // enableColumnFilter: false,
                // enableSorting: true,

                width: '8%',
                // width: (cell) => cell.value.length * 10, 
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            {user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.UserMaster && f.urp_type === constants.menuTypes.Modify) && (
                                    <div
                                        to="#"
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => {
                                            const userData = cellProps.row.original;
                                            handleEditClick(userData);
                                        }}
                                    >
                                        <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                            Edit
                                        </UncontrolledTooltip>
                                    </div>)}
                            {user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.UserMaster && f.urp_type === constants.menuTypes.Special) && (
                                    <div
                                        to="#"
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => {
                                            const userData = cellProps.row.original;
                                            onClickreset(userData);
                                        }}
                                    >
                                        <i className="mdi mdi-lock-reset" id="resettooltip" />
                                        <UncontrolledTooltip placement="top" target="resettooltip">
                                            Reset Password
                                        </UncontrolledTooltip>
                                    </div>)}
                            {user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.UserMaster && f.urp_type === constants.menuTypes.Remove) && Number(user.uid) !== Number(cellProps.row.original.uid) && (
                                    <div
                                        to="#"
                                        className="btn btn-sm btn-soft-danger"
                                        onClick={() => {
                                            const userData = cellProps.row.original; onClickDelete(userData);
                                        }}>
                                        <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                            Delete
                                        </UncontrolledTooltip>
                                    </div>)}
                        </div>
                    );
                }
            },
            {
                header: 'S.No.',
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Username ▼
                    </span>
                ),

                accessorKey: 'u_name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Login ID ▼
                    </span>
                ),

                accessorKey: 'u_loginid',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        User Role ▼
                    </span>
                ),

                accessorKey: 'ur_name',
                enableColumnFilter: false,
                enableSorting: true,

            },
            {
                header: 'Status',
                accessorKey: 'u_stsid',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const u_stsid = cellProps.row.original.u_stsid;

                    return (
                        <div className="d-flex align-items-center">
                            {u_stsid === "1" ? (
                                <i className="mdi mdi-check-circle text-success me-2" style={{ fontSize: '25px' }} />
                            ) : (
                                <i className="dripicons dripicons-wrong text-danger me-2" style={{ fontSize: '20px' }} />
                            )}
                        </div>
                    );
                },
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Created By ▼
                    </span>
                ),

                accessorKey: 'u_created',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Updated By ▼
                    </span>
                ),

                accessorKey: 'u_updated',
                // enableColumnFilter: false,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const mk_stsid = cellProps.row.original.u_updated;

                    return (
                        <div className="d-flex align-items-center">
                            {mk_stsid ? (
                                <span>{mk_stsid}</span>

                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    );
                },
            }


        ],
        []
    );

    const handleAddClick = () => {
        enableButton()
        validation.resetForm();
        setIsEditing(false)
        setBoard('')
        setuserroleName(null)
        toggle();
    };

    const handleEditClick = (userData) => {
        enableButton()
        validation.resetForm();
        setBoard(userData);
        toggle();
        setIsEditing(true);

        // Update this part to set the selected Subject and Subject Subcategory Name
        validation.setValues({
            userdisplayName: userData.u_name,
            loginID: userData.u_loginid,
            userroleName: {
                label: userData.ur_name,
                value: Number(userData.u_urid),
            }
        });
        setuserroleName({
            label: userData.ur_name,
            value: Number(userData.u_urid),
        });
        if (userData.u_stsid === '1') { //active status
            setStatus(true)
        } else {
            setStatus(false)
        }
        // Manually trigger the onChange event for the Select component
        const event = {
            target: {
                name: 'userroleName', value: {
                    label: userData.ur_name,
                    value: Number(userData.u_urid),
                }
            }
        };
        validation.handleChange(event);
    };

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    const togglePasswordReset = (uid) => {
        setModal1(!modal1);
        SetnewpasswordError(false)
        setresetpasswordError('')
        setconformpassword(false)
        SetconformpasswordError('')
        setBoardUid(uid);

    };

    const handleResetPasswordClick = () => {
        setIsEditing(true)
        if (newpassword === '') {
            SetnewpasswordError(true)
            setresetpasswordError(constants.errorMessages.NEWPASSWORD_ERROR)
        } else if (!password_validation.test(newpassword)) {
            SetnewpasswordError(true)
            setresetpasswordError(constants.errorMessages.PASSWORD_VALIDATIONERROR)

        } else {
            SetnewpasswordError(false)
            setresetpasswordError('')
        }

        if (userconfirmpassword === '') {
            setconformpassword(true)
            SetconformpasswordError(constants.errorMessages.CONFORMPASSWORD_ERROR)
        } else if (!password_validation.test(userconfirmpassword)) {
            setconformpassword(true)
            SetconformpasswordError(constants.errorMessages.PASSWORD_VALIDATIONERROR)
        } else if (newpassword !== userconfirmpassword) {
            setconformpassword(true)
            SetconformpasswordError(constants.errorMessages.EQUALCONFORMPASSWORD_ERROR)
        } else {
            setconformpassword(false)
            SetconformpasswordError('')
        }
        if (newpassword !== '' && userconfirmpassword !== '' && newpassword === userconfirmpassword && password_validation.test(newpassword)) {
            setResetTrigger(true);
            setIsEditing(true)
            dispatch(resetpasswordmaster({ user_id: Number(user.uid), reset_user_id: Number(boardUid), new_password: newpassword }));
        }

    };

    const onClickreset = (userData) => {
        setIsEditing(true)
        SetnewpasswordError(false)
        setresetpasswordError('')
        setconformpassword(false)
        SetconformpasswordError('')
        setBoardUid('');
        setuserconfirmpassword('')
        setnewpassword('')
        togglePasswordReset(userData.uid);

    };

    const toggleStatus = () => {
        setStatus(!status);
    };

    //meta title
    // document.title = "Masters | Exam Board Category";

    //USer role credentials
    useEffect(() => {
        if (user && user.user_previleges) {
            let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.UserMaster)
            if (page_menu_list) {
                setCanView(page_menu_list.find(f => f.urp_type === constants.menuTypes.View)?.urp_smid ? true : false)
                setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid ? true : false)
                setCanModify(page_menu_list.find(f => f.urp_type === constants.menuTypes.Modify)?.urp_smid ? true : false)
                setCanRemove(page_menu_list.find(f => f.urp_type === constants.menuTypes.Remove)?.urp_smid ? true : false)
            }
        }
    }, [user]);

    const onBlurnewpassword = () => {
        if (newpassword === '') {
            SetnewpasswordError(true)
            setresetpasswordError(constants.errorMessages.NEWPASSWORD_ERROR)
        } else if (!password_validation.test(newpassword)) {
            SetnewpasswordError(true)
            setresetpasswordError(constants.errorMessages.PASSWORD_VALIDATIONERROR)
        } else {
            SetnewpasswordError(false)
            setresetpasswordError('')
        }
    }

    const onBlurconforpassword = () => {
        if (userconfirmpassword === '') {
            setconformpassword(true)
            SetconformpasswordError(constants.errorMessages.CONFORMPASSWORD_ERROR)
        } else if (!password_validation.test(userconfirmpassword)) {
            setconformpassword(true)
            SetconformpasswordError(constants.errorMessages.PASSWORD_VALIDATIONERROR)
        } else if (newpassword !== userconfirmpassword) {
            setconformpassword(true)
            SetconformpasswordError(constants.errorMessages.EQUALCONFORMPASSWORD_ERROR)
        } else {
            setconformpassword(false)
            SetconformpasswordError('')
        }
    }

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content" style={{ height: '100%' }}>
                <div className="container-fluid">
                    {/* <Breadcrumbs title="Masters" breadcrumbItem="User Master" /> */}
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={usermaster || []}
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                isAddButton={canCreate}
                                                buttonName="Add"
                                                SearchPlaceholder="Search..."
                                                pagination="pagination"
                                                handleUserClick={handleAddClick}
                                                // isCustomPageSize={true}
                                                //  paginationWrapper='dataTables_paginate paging_simple_numbers'

                                                buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                theadClass="table-light"

                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                    <Modal isOpen={modal} toggle={toggle} backdrop="static">
                        <ModalHeader toggle={toggle} tag="h4">
                            {isEditing ? "Edit User Master" : "Add User"}
                        </ModalHeader>
                        <ModalBody>
                            <Form onSubmit={validation.handleSubmit}>
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label>User Role</Label>
                                            <Select
                                                value={userroleName}
                                                onChange={(selectedOption) => {

                                                    validation.setFieldValue("userroleName", selectedOption);
                                                    handleSelectuserrolename(selectedOption);
                                                }}
                                                options={masterList.map((board) => ({
                                                    label: board.ur_name,
                                                    value: board.urid,
                                                }))}
                                                ref={inputRef}
                                                validate={{
                                                    required: { value: true },
                                                }}

                                                className={`select2-selection ${validation.touched.userroleName && validation.errors.userroleName ? 'is-invalid' : ''}`}
                                            />
                                            {validation.touched.userroleName && validation.errors.userroleName ? (
                                                <div className="invalid-feedback">{validation.errors.userroleName}</div>
                                            ) : null}

                                        </div>
                                        <div className="mb-3">
                                            <Label>Name of the User</Label>
                                            <Input


                                                name="userdisplayName"
                                                type="text"
                                                maxLength={constants.textboxLength.u_name}
                                                placeholder="User Name"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.userdisplayName || ""}
                                                invalid={validation.touched.userdisplayName && validation.errors.userdisplayName}
                                            />
                                            {validation.touched.userdisplayName && validation.errors.userdisplayName ? (
                                                <FormFeedback type="invalid">{validation.errors.userdisplayName}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label>Login ID</Label>
                                            <Input
                                                name="loginID"
                                                type="text"
                                                placeholder="Login ID"
                                                maxLength={constants.textboxLength.u_loginid}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                disabled={isEditing}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.loginID || ""}
                                                invalid={validation.touched.loginID && validation.errors.loginID}
                                            />
                                            {validation.touched.loginID && validation.errors.loginID ? (
                                                <FormFeedback type="invalid">{validation.errors.loginID}</FormFeedback>
                                            ) : null}
                                        </div>
                                        {!isEditing && (
                                            <div>
                                                <div className="mb-3">
                                                    <Label>Password</Label>
                                                    <div className="input-group auth-pass-inputgroup">

                                                        <Input
                                                            name="password"
                                                            // type="password"
                                                            type={passwordShow ? "text" : "password"}
                                                            maxLength={constants.textboxLength.u_password}
                                                            placeholder="Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            invalid={validation.touched.password && validation.errors.password}
                                                        />
                                                        <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                                            <i className="mdi mdi-eye-outline"></i>
                                                        </button>
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <Label>Confirm Password</Label>
                                                    <div className="input-group auth-pass-inputgroup">

                                                        <Input
                                                            name="confirmPassword"
                                                            // type="password"
                                                            type={confmpasswordShow1 ? "text" : "password"}
                                                            maxLength={constants.textboxLength.u_password}
                                                            placeholder="Confirm Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.confirmPassword || ""}
                                                            invalid={validation.touched.confirmPassword && validation.errors.confirmPassword}
                                                        />
                                                        <button onClick={() => setConfmPasswordShow1(!confmpasswordShow1)} className="btn btn-light " type="button" id="password-addon">
                                                            <i className="mdi mdi-eye-outline"></i>
                                                        </button>
                                                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.confirmPassword}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {isEditing && (
                                            <div className="mb-3">
                                                <Label>Status</Label>
                                                <Col lg="2">
                                                    <div className="square-switch">
                                                        <input
                                                            type="checkbox"
                                                            id="statusMark"
                                                            className="switch switch-bool"
                                                            checked={status} // Reflect status state
                                                            onChange={toggleStatus} // Toggle status when switch changes
                                                        />
                                                        <label
                                                            htmlFor="statusMark"
                                                            data-on-label="active"
                                                            data-off-label="Inactive"
                                                        />
                                                    </div>
                                                </Col>
                                            </div>)}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button color="success" type="submit"
                                                disabled={isButtonDisabled}

                                                onClick={() => {
                                                    setSubmitbtn('save')
                                                }}>
                                                {isEditing ? "Update" : "Save"}
                                            </Button>
                                            {!isEditing && (
                                                <Button color="primary" type="submit" className="save-and-continue-user"
                                                    onClick={() => {
                                                        setSubmitbtn('savecontinue')
                                                    }}
                                                    disabled={isButtonDisabled}

                                                    style={{ marginLeft: '10px' }}>
                                                    Save and Continue
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>


                            </Form>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={modal1} toggle={togglePasswordReset} backdrop="static">
                        <ModalHeader toggle={togglePasswordReset} tag="h4">
                            Reset Password
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleResetPasswordClick();
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label>New Password</Label>
                                            <div className="input-group auth-pass-inputgroup">

                                                <Input
                                                    innerRef={passwordInputRef}

                                                    name="newPassword"
                                                    // type="password"
                                                    type={newpasswordShow ? "text" : "password"}
                                                    placeholder="New Password"
                                                    value={newpassword} onBlur={onBlurnewpassword} onChange={(e) => setnewpassword(e.target.value)}
                                                />
                                                <button onClick={() => setNewPasswordShow(!newpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                                                    <i className="mdi mdi-eye-outline"></i>
                                                </button>
                                            </div>
                                            <span style={{ color: 'red', fontSize: '10px' }}> {newpasswordError === true ? resetpasswordError : ''} </span>

                                        </div>

                                        <div className="mb-3">
                                            <Label>Confirm Password</Label>
                                            <div className="input-group auth-pass-inputgroup">

                                                <Input
                                                    name="confirmPassword"
                                                    // type="password"
                                                    type={confmpasswordShow ? "text" : "password"}
                                                    placeholder="Confirm Password"
                                                    value={userconfirmpassword} onBlur={onBlurconforpassword} onChange={(e) => setuserconfirmpassword(e.target.value)}
                                                />
                                                <button onClick={() => setConfmPasswordShow(!confmpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                                                    <i className="mdi mdi-eye-outline"></i>
                                                </button>
                                            </div>
                                            <span style={{ color: 'red', fontSize: '10px' }}> {conformpassword === true ? conformpasswordError : ''} </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button color="success" disabled={isButtonDisabled}
                                                type="submit" className="reset-password">
                                                Save
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>

                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
UsermasterList.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default UsermasterList;