/* EXAM BOARDS */
export const GET_USERROLE_LIST = "GET_USERROLE_LIST"
export const GET_USERROLE_LIST_SUCCESS = "GET_USERROLE_LIST_SUCCESS"
export const ADD_NEW_USER_ROLE = "ADD_NEW_USER_ROLE"
export const ADD_NEW_USER_ROLE_SUCCESS = "ADD_NEW_USER_ROLE_SUCCESS"
export const RESET_STORE_USER_ROLE = "RESET_STORE_USER_ROLE"
export const DELETE_USER_ROLE = "DELETE_USER_ROLE"
export const DELETE_USER_ROLE_SUCCESS = "DELETE_USER_ROLE_SUCCESS"
export const GET_USER_ROLE_DETAILS = "GET_USER_ROLE_DETAILS"
export const GET_USER_ROLE_DETAILS_SUCCESS = "GET_USER_ROLE_DETAILS_SUCCESS"
export const EDIT_USER_ROLE = "EDIT_USER_ROLE"

