//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import { getqamasterlist } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../components/Common/TableContainer';
import * as constants from "../../../constants/layout"
import { editloadMock, addNewMockuptest, resetStoremockuptest } from "../../../store/mockuptest/actions";
import "../../../../node_modules/@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { Card, Button, CardBody, Col, Container, UncontrolledTooltip, Form, FormGroup, Input, InputGroup, Label, Row, FormFeedback, CardFooter, Badge } from "reactstrap";
import "./mockup.scss"
import Spinners from "../../../components/Common/Spinner";
import * as url from "../../../helpers/url_helper";
import axios from 'axios'

const AddMockupTest = () => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const { mkid } = useParams();
    const store = useSelector(state => state.MockupList)
    const dispatch = useDispatch();
    const user = getLoggedInUser()
    const navigate = useNavigate();
    const masterstore = useSelector(state => state.ExamBoardCategory)
    const [configuration, setConfiguration] = useState(null);
    const [masterList, setmasterList] = useState([]);
    const [boardcategory, setboardcategory] = useState([]);
    const [allBoardcategory, setAllBoardcategory] = useState([]);
    const [submitbtn, setSubmitbtn] = useState(false);
    const [initialSubmitState, setInitialSubmitState] = useState(false);
    const [c_subjects, setC_subjects] = useState([]);
    const [deleteTrigger, setDeleteTrigger] = useState(false);
    var isClone = localStorage.getItem('isClone');
    const [selectedCount, setselectedCount] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [selBoard, setSelBoard] = useState("")

    const [subjectsubcategory, setsubjectsubcategory] = useState([]);
    const [allSubjectsubcategory, setAllSubjectsubcategory] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedBoardCategory, setselectedBoardCategory] = useState(null);
    const [editingRow, setEditingRow] = useState(null);
    const [status, setstatus] = useState(false);
    const [optionTopicName, setOptionTopicName] = useState([]);
    const [optionAllTopicName, setOptionAllTopicName] = useState([]);
    const [allSubjectList, setAllSubjectList] = useState([]);
    const [allSubjectCatList, setAllSubjectSubcatList] = useState([]);
    const [selectedPaper, setselectedPaper] = useState(null);
    const [paperList, setPaperList] = useState([])
    const [SelectedsubjectRadio, setselectedsubjectRadio] = useState('')
    const paperRef = useRef(null);
    const [paperAllList, setAllPaperList] = useState([]);
    const [optionLanguageName, setOptionLanguageName] = useState([]);
    const [optionAllLanguageName, setOptionAllLanguageName] = useState([]);
    const [selectedLanguage, setselectedLanguage] = useState(null);
    const [selectpaper, setSelectPaper] = useState("")
    const [selectboard, setSelectBoard] = useState("")
    const [edit12, setEdit] = useState(false)
    const [inputFocus, setInputFocus] = useState(false);
    const boardref = useRef(null);
    const [subjectID, setSubjectId] = useState();
    const testref = useRef(null);
    const [subject1, setsubject] = useState([]);
    const [qCount, setQ_count] = useState([]);


    const enableButton = () => {
        setButtonDisabled(false);
    };
    useEffect(() => {
        if (handleClose) {
            setInputFocus(false);
        } else {
            setInputFocus(true)
        }

    }, [])


    const handleEditRow = (row) => {
        setIsEditing(true);
        setEditingRow(row);
        // Set form fields with the data from the row being edited


        var filtered_topic_option = optionAllTopicName.filter(e => e.subjectid == mks_sid && e.subcategoryid == mks_sscid);
        setOptionTopicName(filtered_topic_option);

        let subcatID = row.mks_sscid ? row.mks_sscid : 0


        var filtered_subjects = allSubjectList.filter(e => Number(e.expid) == Number(row.ex_paper_id))
        setsubject(filtered_subjects)
        validation.setFieldValue("topics", { label: row.cs_tname, value: row.mks_tid });
        validation.setFieldValue("subcat", { label: row.subCategory, value: row.mks_sscid });
        validation.setFieldValue("subjectQuestions", row.totalQuestions);
        validation.setFieldValue("markPerQuestion", row.mark);
    };

    const handlechangeQuestion = (e) => {

        var output = document.getElementById('input2')

        validation.setFieldValue('totalMarks', "")
        output.value = validation.values.markPerQuestion * e.target.value

        validation.setFieldValue('totalMarks', output.value)
        if (output && output.value) {
            disableButton()
        } else {
            enableButton()
        }
    }


    const handleClearData = () => {
        validation.setFieldValue("topics", "");
        validation.setFieldValue("subcat", "");
        validation.setFieldValue("subjectSubcategory", "");
        validation.setFieldValue("selectedTopicName", "");
        validation.setFieldValue("subjectQuestions", "");
        validation.setFieldValue("markPerQuestion", "");
        setIsEditing(false);
        setEditingRow('');
    }

    const handlechangeMark = (e) => {

        var output = document.getElementById('input2')

        output.value = validation.values.totalQuestions * e.target.value
        validation.setFieldValue('totalMarks', output.value)

        if (output && output.value) {
            disableButton()
        } else {
            enableButton()
        }
    }
    const handleClose = () => {
        navigate("/list-mockuptest");
    }

    useEffect(() => {
        if (c_subjects) {
            disableButton()
        } else {
            enableButton()
        }
    }, [c_subjects])

    function handleSelectSubjectName(varselectedSubjectName) {


        const [sid, sscid] = varselectedSubjectName.value.split('-');

        const data = {
            "user_id": Number(user.uid),
            "q_sid": Number(sid),
            "q_sscid": Number(sscid) || 0
        }
        getData1(data)
        var filter_topic = optionAllTopicName.filter(e =>
            Number(e.subjectid) == Number(sid)
        );
        // var filtered_topic_option = optionAllTopicName.filter(e =>
        //     e.cs_expid == Number(selectpaper) && e.exctid == Number(selectboard)
        // );
        setOptionTopicName(filter_topic);

        validation.setFieldValue("selectedTopicName", null);
        validation.setFieldValue("subcat", null)
        setselectedCount("")
    }

    const handleAddOrUpdateRow = () => {

        // Validate input values before adding to the table
        if (
            validation.values.subjectName &&
            // validation.values.selectedTopicName &&
            validation.values.subjectQuestions &&
            validation.values.markPerQuestion
        ) {
            var sscid;

            const subjectQuestions = parseInt(validation.values.subjectQuestions);
            const markPerQuestion = parseFloat(validation.values.markPerQuestion);
            const product = subjectQuestions * markPerQuestion;
            // const [sid, sscid] = validation.values.subjectName.value.split('-');
            const s_name = validation.values.subjectName.label;
            const sid = validation.values.subjectName.sid

            // const sscid = validation?.values?.subcat?.value || 0
            const ssc_name = validation?.values?.subcat?.label || 0
            const tid = validation?.values?.topics?.value || 0
            const tname = validation?.values?.topics?.label || 0
            if (mkid == 0) {

                sscid = validation?.values?.subcat?.value
            } else if (mkid != 0) {
                sscid = validation?.values?.subcat?.sscid
            }

            var duplicateArray = []
            if (!isEditing) {

                duplicateArray = dataTable.filter(e => e.mks_tid === Number(validation.values.topics.value))
            } else {
                duplicateArray = dataTable.filter(e => e.sno != editingRow.sno && e.mks_tid === Number(validation.values.topics.value))

            }

            if (duplicateArray.length > 0) {
                toast.error("Selected Topic is already added.");
                return false
            }


            if (!isEditing) {
                var proposedCumulativeMarks = dataTable.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.product), 0)
                var proposedCumulativeQuestions = dataTable.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.totalQuestions), 0)
                proposedCumulativeQuestions = proposedCumulativeQuestions + parseInt(validation.values.subjectQuestions)
                proposedCumulativeMarks = proposedCumulativeMarks + parseFloat(product);
            }
            else {
                var temp_dataTable = dataTable.filter(e => e.sno != editingRow.sno)
                var proposedCumulativeMarks = temp_dataTable.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.product), 0)
                var proposedCumulativeQuestions = temp_dataTable.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.totalQuestions), 0)
                proposedCumulativeQuestions = proposedCumulativeQuestions + parseInt(validation.values.subjectQuestions)
                proposedCumulativeMarks = proposedCumulativeMarks + parseFloat(product);

            }


            // Check if adding the new row will exceed the total marks
            if (proposedCumulativeMarks <= parseInt(validation.values.totalMarks)) {
                // Check if adding the new row will exceed the total questions
                if (proposedCumulativeQuestions <= parseInt(validation.values.totalQuestions)) {
                    const newRow = {
                        sno: isEditing ? editingRow.sno : dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
                        subjectName: s_name,
                        subCategory: ssc_name || "0",
                        totalQuestions: subjectQuestions,
                        mark: markPerQuestion,
                        product: product, // Store product value in the newRow object

                        mks_sid: Number(sid) || 0,
                        s_name: s_name,
                        mks_sscid: Number(sscid) || 0,
                        ssc_name: ssc_name,
                        mks_questioncount: subjectQuestions.toString() || "0",
                        mks_marks: markPerQuestion.toString() || "0",
                        mks_tid: Number(tid) || 0,
                        cs_tname: tname || "0",
                        ex_paper_id: Number(validation.values.selectedPaper.value),
                        ex_paper_name: validation.values.selectedPaper.label

                    };
                    setselectedCount("")

                    if (isEditing) {

                        // Update the row in the data table
                        setDataTable((prev) =>
                            prev.map((row) => (row.sno === editingRow.sno ? { ...row, ...newRow } : row))
                        );
                        var editRow = {
                            mks_sid: Number(sid) || 0,
                            s_name: s_name,
                            mks_sscid: Number(sscid),
                            ssc_name: ssc_name,
                            mks_questioncount: subjectQuestions.toString() || "0",
                            mks_marks: markPerQuestion.toString() || "0",
                            mks_tid: Number(validation.values.topics.value),
                            cs_tname: validation.values.topics.label,
                            ex_paper_id: Number(validation.values.selectedPaper.value),
                            ex_paper_name: validation.values.selectedPaper.label
                        }
                        setC_subjects((prev) =>

                            prev.map((row) => (row.sno === editingRow.sno ? { ...row, ...editRow } : row))
                        )
                        setIsEditing(false);
                        setEditingRow(null);
                    } else {
                        // Add a new row to the data table
                        setDataTable([...dataTable, newRow]);

                        // Push the new row into the c_subjects array
                        setC_subjects((prev) => [
                            ...prev,
                            {
                                sno: isEditing ? editingRow.sno : dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
                                subjectName: s_name,
                                subCategory: ssc_name,
                                totalQuestions: subjectQuestions,
                                mark: markPerQuestion,
                                product: product,
                                mks_sid: Number(sid),
                                s_name: s_name,
                                mks_sscid: Number(sscid),
                                ssc_name: ssc_name,
                                mks_questioncount: subjectQuestions.toString() || "0",
                                mks_marks: markPerQuestion.toString() || "0",
                                mks_tid: Number(validation?.values?.topics?.value) || 0,
                                cs_tname: validation?.values?.topics?.label || 0,
                                ex_paper_id: Number(validation.values.selectedPaper.value),
                                ex_paper_name: validation.values.selectedPaper.label
                            },
                        ]);

                    }
                    // Clear the form fields after adding or updating a row

                    if (dataTable && dataTable.length > 0) {
                        setButtonDisabled(true);
                    }

                    validation.setFieldValue("subjectQuestions", "");
                    validation.setFieldValue("subcat", "");
                    validation.setFieldValue("topics", "");
                    validation.setFieldValue("markPerQuestion", "");

                    // Display success toast
                    toast.success("Row successfully added");
                } else {
                    // Display an error toast 
                    toast.error("Total questions exceeded. Please adjust the values.");
                }
            } else {
                // Display an error toast 
                toast.error("Total marks exceeded. Please adjust the values.");
            }
        } else {
            // Display an error toast 
            toast.error("Please fill all the required fields");
        }

    };


    const handleDeleteRow = (row) => {
        try {

            setDataTable(prevData => {
                // Filter out the row to be deleted based on some identifier (e.g., id)
                const updatedData = prevData.filter(item => item.sno !== row.sno);
                // Return the updated data
                return updatedData;
            });
            setC_subjects(prevData => {
                // Filter out the row to be deleted based on some identifier (e.g., id)
                const updatedData = prevData.filter(item => item.sno !== row.sno);
                // Return the updated data
                return updatedData;
            });

        } catch (error) {
            console.error("Error deleting row:", error);
        }
    };


    const getmasterlistapi = () => {

        dispatch(getqamasterlist({ user_id: Number(user.uid) }));
    }

    useEffect(() => {

        // if (masterstore.masterlist && masterstore.masterlist.conf_subject_subcategoryList) {

        //     setAllSubjectList(masterstore.masterlist.conf_subject_subcategoryList)
        //     // setsubject(masterstore.masterlist.conf_subject_subcategoryList)
        // }

        if (masterstore.masterlist && masterstore.masterlist.examBoardList)
            setmasterList(masterstore.masterlist.examBoardList)
        // if (masterstore.masterlist && masterstore.masterlist.board_categoryList) {
        //     var boardList = masterstore.masterlist.board_categoryList.filter(e => e.c_exctid != 0)

        //     // var board_category_unused = masterstore.masterlist.board_categoryList

        //     setboardcategory(boardList)
        //     setAllBoardcategory(boardList)
        // }

        if (masterstore.masterlist && masterstore.masterlist.conf_subject_subcategoryList) {
            if (masterstore.masterlist && masterstore.masterlist.conf_subject_subcategoryList.sscid != '0') {
                var filter_subcat = masterstore.masterlist.conf_subject_subcategoryList.filter(e => e.sscid != '0')

                setsubjectsubcategory(filter_subcat)
                setAllSubjectSubcatList(filter_subcat)
                if (mkid != 0) {

                    if (store && store.mockupTestData) {
                        setTimeout(() => {

                            if (store && store.mockupTestData && store.mockupTestData.statusFlag == 1) {

                                var data = store.mockupTestData.mockupTestData[0]
                                var data1 = masterstore.masterlist.topicList
                                editload_subcatBind(masterstore.masterlist.conf_subject_subcategoryList.filter(e => e.sscid != '0'), data, data1)
                            }
                        }, 2000);
                    }
                }

            }



        }

        if (masterstore.masterlist && masterstore.masterlist.topicList) {
            setOptionAllTopicName(masterstore.masterlist.topicList)
            setOptionTopicName(masterstore.masterlist.topicList)
            if (mkid != 0) {
                // editload_
                editload_topicBind(masterstore.masterlist.topicList)
            }
        }
        // if (masterstore.masterlist && masterstore.masterlist.examPaperList) {
        //     // setPaperList(masterstore.masterlist.examPaperList)
        //     setAllPaperList(masterstore.masterlist.examPaperList)
        // }

        if (masterstore.masterlist && masterstore.masterlist.languageList) {
            setOptionLanguageName(masterstore.masterlist.languageList)
            setOptionAllLanguageName(masterstore.masterlist.languageList)
        }

    }, [masterstore.masterlist])

    const editload_topicBind = (row) => {

        if (store && store.mockupTestData && store.mockupTestData.mockupTestData && store.mockupTestData.mockupTestData.length > 0) {
            const data = store.mockupTestData.mockupTestData[0]
            const data1 = row
            var filter_topics = data1.filter(e =>
                Number(e.subjectid) == data.mk_sid)
            setOptionTopicName(filter_topics)
            setOptionAllTopicName(filter_topics)
        }
    }
    const editload_subcatBind = (row, data, data1) => {

        if (row && row.length > 0) {

            var filtersubcat = row.filter(e => e.sid == Number(data.mk_sid))
            setsubjectsubcategory(filtersubcat)
            setAllSubjectSubcatList(filtersubcat)



        }


    }
    const getMasterApi = async () => {
        console.log('===============>')
        const data = { user_id: Number(user.uid) }
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'master/getMaster', data, config)
        if (response1 && response1.data && response1.data && response1.data.status === 200) {
            console.log('res[ppos', response1?.data?.body)

            setboardcategory(response1?.data?.body?.board_categoryList)
            setAllBoardcategory(response1?.data?.body?.board_categoryList)
            setAllPaperList(response1?.data?.body?.examPaperList)
            setPaperList(response1?.data?.body?.examPaperList)
            setAllSubjectList(response1?.data?.body?.subjectList)
            setsubject(response1?.data?.body?.subjectList)

        }

    }
    useEffect(() => {
        setselectedsubjectRadio('subjectwise')
        getmasterlistapi()
        getMasterApi()
    }, [dispatch]);

    const geteditloadapi = () => {
        setLoading(true)
        setEdit(true)
        dispatch(editloadMock({ user_id: Number(user.uid), mkid: Number(mkid) }));
    }

    const disableButton = () => {
        setButtonDisabled(true);
    };


    function handleSelectBoardName(varselectedBoardName) {

        const [exctid, exct_exid] = varselectedBoardName.value.split('-')
        setSelectBoard(exctid)
        // var filtered_exampaper_option = paperAllList.map(e => {
        //     var exctid_arr = e?.exctid?.split(',');
        //     if (exctid_arr?.length > 0) {
        //         if (exctid_arr?.includes(exctid)) {
        //             return e;
        //         }
        //     }

        // })
        // filtered_exampaper_option = filtered_exampaper_option.filter(e => e != undefined);
        // setPaperList(filtered_exampaper_option)
        setselectedPaper(null)

        validation.setFieldValue("subjectSubcategory", "");
        validation.setFieldValue("subjectName", "")
        validation.setFieldValue("selectedPaper", "")
        validation.setFieldValue("selectedTopicName", "")
        setselectedCount(null)
        validation.setFieldValue("topics", null)
        validation.setFieldValue("subcat", null)

    }


    const handleSelectPaper = (selectedOption) => {
        setSelectPaper(selectedOption.value)
        var filtered_subject = allSubjectList.filter(e => e.expid == selectedOption?.value)
        setsubject(filtered_subject)

        // var filtered_subject = allSubjectList.filter(e => e.)
        // const [exctid, exid] = selectedBoardCategory.value.split('-')
        // var filtered_subject_option = allSubjectList.map(e => {
        //     var exctid_arr = e?.exctid?.split(',');
        //     if (exctid_arr?.length > 0) {

        //         if (e.s_expid == selectedOption?.value) {
        //             return e;
        //         }


        //     }

        // })

        // filtered_subject_option = filtered_subject_option.filter(e => e != undefined && (e.sscid == 0));
        // if (filtered_subject_option && filtered_subject_option.length > 0) {
        //     setsubject(filtered_subject_option)

        // }
        // else {

        //     setsubject(filtered_subject_option)

        // }
        // var filtered_topic_option = optionAllTopicName.filter(e => e.exctid == selectedOption?.value);
        // filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
        // setOptionAllTopicName(filtered_topic_option)
        validation.setFieldValue("subjectSubcategory", "");
        validation.setFieldValue("subjectName", "")
        validation.setFieldValue("selectedTopicName", "")
        setselectedCount(null)
        validation.setFieldValue("topics", null)
        validation.setFieldValue("subcat", null)

    };



    const handleSelectTopics = (selectedOption) => {
        var filteredcount = optionAllTopicName.filter(e => e.topicid == selectedOption.value)
        setselectedCount(filteredcount)
    }




    useEffect(() => {
        document.getElementById('headerTitle').innerHTML = 'Subject wise Mockup Test';
        if (mkid != 0) {
            geteditloadapi()

        }
    }, [dispatch, mkid]);

    useEffect(() => {
        if (store && store.mockuptest_statusFlag && store.mockuptest_statusFlag === 4) {
            navigate(`/list-mockuptest`);
        } else if (store && store.mockuptest_statusFlag && store.mockuptest_statusFlag === 1) {
            if (store.mockupTestData && store.mockupTestData && store.mockupTestData &&
                store.mockupTestData.mockupTestData && store.mockupTestData.mockupTestData.length > 0) {
                const editData = store.mockupTestData.mockupTestData[0];
                const editDataSub = store.mockupTestData.mockupTestSubjects[0];
                setSubjectId(editDataSub)

                if (editData.mk_type == 1) {
                    navigate(`/add-mockuptest/${editData.mkid}`)
                    setButtonDisabled(true)
                    setselectedsubjectRadio('subjectwise')
                }
                else if (editData.mk_type == 2) {
                    setselectedsubjectRadio('topicwise')
                    //     navigate(`/add-mockuptest-topic/${editData.mkid}`)
                }

                validation.setFieldValue("configurationID", editData.mkid);
                validation.setFieldValue("configurationName", isClone === '1' ? "" : editData.mk_testname);
                const subcatvalue = {
                    label: editDataSub.ssc_name,
                    value: editDataSub.mks_sscid
                }

                // Set value for selectedBoardCategory
                const boardCategoryValue = {
                    label: editData.ex_name + "-" + editData.exct_name,
                    value: editData.mk_exctid + "-" + editData.mk_exid,
                };
                const papervalue = {
                    label: editData.exp_name,
                    value: editData.mk_expid
                };

                const subjectValue = {
                    label: editData.s_name,
                    value: editData.mk_sid
                }
                const topicvalue = {
                    label: editDataSub.t_name,
                    value: editDataSub.mks_tid
                }
                // const langaugevalue = {
                //     label: editData && editData.mk_lid && editData.mk_lid == 1 ? "English" : "Regional",
                //     value: editData.mk_lid,

                // }

                // setselectedLanguage(langaugevalue)
                // validation.setFieldValue("selectedLanguage", langaugevalue)
                var filtered_subjects = allSubjectsubcategory.filter(e => Number(e.ssc_sid) == Number(editData.mk_sid))
                setAllSubjectSubcatList(filtered_subjects)

                validation.setFieldValue("selectedBoardCategory", boardCategoryValue);
                setselectedBoardCategory(boardCategoryValue);
                validation.setFieldValue("selectedPaper", papervalue);
                setselectedPaper(papervalue);
                validation.setFieldValue("subjectName", subjectValue);
                validation.setFieldValue("markPerQuestion", store.mockupTestData.mockupTestSubjects[0]?.mks_marks)
                // validation.setFieldValue("subcat", subcatvalue);
                validation.setFieldValue("selectedTopicName", topicvalue);


                validation.setFieldValue("totalMarks", Number(editData.mk_totalmarks));
                validation.setFieldValue("totalQuestions", Number(editData.mk_totalquestions));
                validation.setFieldValue("duration", Number(editData.mk_duration));
                // Set data table with the data for subjects
                const subjectsData = store.mockupTestData.mockupTestSubjects.map((subject, index) => ({
                    sno: index + 1,
                    subjectName: editData?.s_name,
                    subCategory: subject.ssc_name,
                    totalQuestions: subject.mks_questioncount,
                    mark: subject.mks_marks,
                    product: Number(subject.mks_questioncount) * Number(subject.mks_marks),
                    mks_sid: subject.mks_sid || 0,
                    mks_sscid: Number(subject.mks_sscid) || 0,
                    mks_questioncount: subject.mks_questioncount,
                    mks_marks: subject.mks_marks,
                    mks_tid: Number(subject.mks_tid),
                    cs_tname: subject.t_name,
                    ex_paper_id: Number(editData.mk_exid),
                    ex_paper_name: subject.exampaper
                }));
                // Set subjects data
                setDataTable(subjectsData);
                // Set c_subjects array
                setC_subjects(subjectsData);

                // Set status toggle only during update

                if (editData.mk_stsid == "1")
                    setstatus(true)
                else setstatus(false)


            }

        }

    }, [store.mockupTestData]);

    const [columns, setColumns] = useState(
        () => [
            {
                header: 'Actions',
                width: '8%',
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="btn btn-sm btn-soft-info"
                                onClick={() => handleEditRow(cellProps.row.original)}
                            >
                                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>

                            <div
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    handleDeleteRow(cellProps.row.original);
                                }}
                            >
                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    );
                }
            },
            {
                header: 'S.No.',
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
            },



            {

                header: (
                    <span style={{ cursor: 'pointer' }}>
                        SubCategory ▼
                    </span>
                ),

                accessorKey: 'subCategory',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'txt-center', // Keep this if you want to center the content vertically
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3" style={{ cursor: 'pointer' }}>
                            {cellProps?.row?.original?.subCategory && Number(cellProps?.row?.original?.subCategory) !== 0 ? (
                                // <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                //   const subjectData = cellProps.row.original;
                                //   getTopicList(subjectData);
                                // }}>
                                <div>
                                    {cellProps?.row?.original?.subCategory}
                                </div>
                            )
                                : (
                                    <div style={{ justifyContent: "center" }}>-</div>
                                )}
                        </div>
                    );
                }

            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Topic ▼
                    </span>
                ),

                accessorKey: 'cs_tname',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        No.of Questions ▼
                    </span>
                ),

                accessorKey: 'totalQuestions',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'txt-right'
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Mark/Question ▼
                    </span>
                ),

                accessorKey: 'mark',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'txt-right'

            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Marks ▼
                    </span>
                ),

                accessorKey: 'product',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'txt-center'

            }
        ],
        []
    );
    function handleselecttopic(varselectedtopic) {
        var filteredcount = optionAllTopicName.filter(e => e.topicid == varselectedtopic.value)
        setselectedCount(filteredcount)
    }
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            configurationID: (configuration && configuration.configurationID) || "",
            configurationName: (configuration && configuration.configurationName) || "",
            totalMarks: (configuration && configuration.totalMarks) || "",
            totalQuestions: (configuration && configuration.totalQuestions) || "",
            duration: (configuration && configuration.duration) || "",
            selectedBoardCategory: (configuration && configuration.selectedBoardCategory) || null,
            // selectedLanguage: (configuration && configuration.selectedLanguage) || null
        },
        validationSchema: Yup.object({
            configurationName: Yup.string().required("Please Enter Test Name"),
            totalMarks: Yup.number().required("Please Enter Total Marks"),
            totalQuestions: Yup.number().required("Please Enter Total Questions"),
            duration: Yup.number().required("Please Enter Duration"),
            selectedBoardCategory: Yup.object().required("Please select Exam Board Category"),
            // selectedLanguage: Yup.object().required("Please select Language")
        }),
        onSubmit: (values) => {
            const arr = [];
            setSubmitbtn(true);
            setLoading(true);

            const [mk_exctid, mk_exid] = selectedBoardCategory.value.split('-');
            // if (validation.values && validation.values.subjectName && validation.values.subjectName.label != "" && validation.values.subjectName.label != null && validation.values.subjectName.value != undefined && typeof validation.values.subjectName.value === 'string') {

            //     var [s_name, subcategory] = validation?.values?.subjectName?.label?.split('-') || ""
            // } else {
            //     var s_name = validation?.values?.subjectName?.label
            // }

            var isClone = localStorage.getItem('isClone');

            const markPerQuestion = parseFloat(validation.values.markPerQuestion);
            const product = validation.values.totalQuestions * markPerQuestion;
            // if(validation.)
            // if (validation.values && validation.values.subjectName && validation.values.subjectName.value != "" && validation.values.subjectName.value != null && validation.values.subjectName.value != undefined && typeof validation.values.subjectName.value === 'string') {
            //     console.log('first')
            //     let value = validation.values.subjectName.value.trim(); // Trim to handle leading/trailing whitespace

            //     if (value && value.includes('-')) {
            //         console.log('second')
            //         var [sid, sscid] = validation?.values?.subjectName?.value?.split('-');
            //     }
            // }
            // else {
            //     console.log('third')
            //     var sid = validation?.values?.subjectName?.value
            // }
            const s_name = validation.values.subjectName.label;
            const sid = validation.values.subjectName.value;
            const newRow = {
                sno: isEditing ? editingRow.sno : dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
                subjectName: s_name,
                subCategory: 0,
                totalQuestions: validation.values.totalQuestions,
                mark: markPerQuestion,
                product: product, // Store product value in the newRow object
                mks_sid: validation?.values?.subjectName?.value || 0,
                s_name: s_name || 0,
                mks_sscid: 0,
                ssc_name: 0,
                mks_questioncount: validation.values.totalQuestions,
                mks_marks: markPerQuestion.toString() || "0",
                mks_tid: (validation?.values?.selectedTopicName?.value !== null && validation?.values?.selectedTopicName?.value !== undefined) ? Number(validation?.values?.selectedTopicName?.value) : 0,
                cs_tname:
                    (validation.values.selectedTopicName?.label !== null && validation.values.selectedTopicName?.label !== undefined) ? (validation.values.selectedTopicName.label) : "",
                ex_paper_id: Number(validation.values.selectedPaper.value),
                ex_paper_name: validation.values.selectedPaper.label

            };
            if (newRow) {

                setDataTable([...dataTable, newRow]);
                const data = {
                    sno: isEditing ? editingRow.sno : dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
                    subjectName: s_name,
                    subCategory: 0,
                    totalQuestions: validation.values.totalQuestions,
                    mark: markPerQuestion,
                    product: product,
                    mks_sid: Number(sid) || 0,
                    s_name: s_name,
                    mks_sscid: 0,
                    ssc_name: 0,
                    mks_questioncount: validation.values.totalQuestions || "0",
                    mks_marks: markPerQuestion.toString() || "0",
                    mks_tid: (validation?.values?.selectedTopicName?.value !== null && validation?.values?.selectedTopicName?.value !== undefined) ? Number(validation?.values?.selectedTopicName?.value) : 0,
                    cs_tname: (validation.values.selectedTopicName?.label !== null && validation.values.selectedTopicName?.label !== undefined) ? validation.values.selectedTopicName.label : "",
                    ex_paper_id: Number(validation.values.selectedPaper.value),
                    ex_paper_name: validation.values.selectedPaper.label
                }
                arr.push(data)
            }

            const newblueprint = {
                user_id: Number(user.uid),
                mk_testname: values.configurationName,
                mk_exid: Number(mk_exid),
                mk_exctid: Number(mk_exctid),
                mk_totalmarks: Number(values.totalMarks),
                mk_totalquestions: Number(values.totalQuestions),
                mk_duration: Number(values.duration),
                mk_subjects: arr,
                mk_type: (validation?.values?.selectedTopicName?.label !== null && validation?.values?.selectedTopicName?.label !== undefined) ? 2 : 1,
                mk_expid: Number(values.selectedPaper.value),
                mkid: isClone == '1' ? '0' : (mkid),
                mk_stsid: status ? 1 : 2,
                mk_sid: Number(sid) || 0,
                mk_lid: 0,
                process_type: mkid == 0 || isClone == '1' ? 'save' : 'update',
            };
            // Dispatch the action
            dispatch(addNewMockuptest(newblueprint))
                .then(() => {

                    navigate("/list-mockuptest");
                    // Reset the form and state if needed

                })
                .catch((error) => {
                    // Handle error if needed
                    console.error("Error:", error);
                });
        },
    });


    useEffect(() => {
        setDataTable([])
        setC_subjects([])
    }, []);

    const getData1 = async (req) => {

        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(url.BASE_URL + 'master/getSubjectwiseQcount', req, config);
            if (response && response.statusFlag === 3) {
                toast.error(response.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                sessionStorage.removeItem("SESSION_KEY")
                window.location.reload();
                return;
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser();
                    } else {
                        await getData();
                    }
                }
            }

            if (response?.data?.body?.count) {
                setQ_count(response?.data?.body?.count)
                // setReportEntryList(response.data.body.reportEntryList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("Report Entry API Error:", error);
        }
    }



    //After api call success stop loading
    useEffect(() => {
        if (store.mockuptest_statusFlag === 1) {
            if (submitbtn) { // add or update then refresh list
                setSubmitbtn(false)

                validation.resetForm();
                setInitialSubmitState(true);
                navigate("/list-mockuptest");
            }
            if (deleteTrigger) { // delete then refresh list
                setDeleteTrigger(false)

                setDeleteModal(false);
                setBoard('');
            }
            setLoading(false)
            dispatch(resetStoremockuptest({ statusFlag: 0 }));
        } else if (store.mockuptest_statusFlag === 2) {
            if (submitbtn) {
                setSubmitbtn(false)
            }
            if (deleteTrigger) {
                setDeleteTrigger(false)
            }
            setLoading(false)
            dispatch(resetStoremockuptest({ statusFlag: 0 }));
        } else if (store.mockuptest_statusFlag === 3) {
            navigate(`/list-mockuptest`);
        }
    }, [store.mockuptest_statusFlag])

    const customStyles = {
        option: (styles, state, data) => { // fixed
            return {
                ...styles,
                backgroundColor: state.isDisabled
                    ? undefined
                    : state.isSelected
                        ? '#a6b0cf57'
                        : state.isFocused
                            ? '#556ee6'
                            : 'white',

                color: state.isDisabled
                    ? '#ccc'
                    : state.isSelected
                        ? 'black'
                        : state.isFocused
                            ? 'white'
                            : 'black',

                cursor: state.isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled
                        ? state.isSelected
                            ? '#556ee6'
                            : '#556ee6'
                        : undefined,
                },
                fontWeight: state.value && Number(state.value.split('-')[1]) !== 0 ? '' : '600'
            };
        }
    };

    //meta title
    document.title = "Mockup Test | Create Mockup Test"

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <Container fluid={true}>
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>

                                <Col lg="12">
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                        }}
                                    >
                                        <Card className="crd-min-ht">
                                            <CardBody>

                                                <form>
                                                    <Row>
                                                        {edit12 ?
                                                            // (
                                                            //     SelectedsubjectRadio === 'subjectwise' ? (
                                                            //         <h2>Subject wise</h2>
                                                            //     ) : (
                                                            //         <h2>Topic wise</h2>
                                                            //     )
                                                            // )
                                                            // :


                                                            <Row>
                                                                <Col lg="12">

                                                                </Col>
                                                                {/* 
                                                                <Col lg="7">
                                                                    <label>          <input
                                                                        type="radio"
                                                                        name="options"
                                                                        value="subjectwise"
                                                                        defaultChecked
                                                                        onChange={(selectedOption) => {
                                                                            validation.setFieldValue("SelectedtopicRadio", selectedOption.target.checked);
                                                                            setselectedsubjectRadio(selectedOption.target.value);
                                                                        }}
                                                                    /> Subjectwise
                                                                    </label>
                                                                    <label style={{
                                                                        "margin-left": '34px',
                                                                    }}>          <input
                                                                            type="radio"
                                                                            name="options"
                                                                            value="topicwise"


                                                                            onChange={(selectedOption) => {
                                                                                validation.setFieldValue("SelectedtopicRadio", selectedOption.target.checked);
                                                                                setselectedsubjectRadio(selectedOption.target.value);
                                                                            }}
                                                                        /> Topicwise
                                                                    </label>
                                                                </Col>
                                                                <hr></hr> */}
                                                                <Col lg="2">

                                                                </Col>

                                                            </Row> : ""}

                                                    </Row>

                                                    <Row>

                                                        <Col lg="6">



                                                            <Col lg="12">

                                                                <Row>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>Test Name<span style={{ color: 'red' }}>*</span></Label>
                                                                            <Input
                                                                                tabIndex="1"
                                                                                name="configurationName"
                                                                                type="text"
                                                                                maxLength={constants.textboxLength.test_name}
                                                                                placeholder="Test Name"
                                                                                validate={{
                                                                                    required: { value: true },
                                                                                }}
                                                                                value={validation.values.configurationName}
                                                                                onChange={validation.handleChange}
                                                                                onBlur={validation.handleBlur}
                                                                                invalid={
                                                                                    validation.touched.configurationName && validation.errors.configurationName ? true : false
                                                                                }
                                                                                innerRef={testref}
                                                                            />
                                                                            {validation.touched.configurationName && validation.errors.configurationName ? (
                                                                                <FormFeedback type="invalid">{validation.errors.configurationName}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>

                                                                    <Col lg="6">

                                                                        <div className="mb-3">
                                                                            <Label>Exam Paper<span style={{ color: 'red' }}>*</span></Label>
                                                                            <Select
                                                                                tabIndex="4"
                                                                                // isDisabled={c_subjects && c_subjects.length > 0}
                                                                                ref={paperRef}
                                                                                value={validation.values.selectedPaper}
                                                                                onChange={(selectedOption) => {
                                                                                    handleSelectPaper(selectedOption);
                                                                                    validation.setFieldValue("selectedPaper", selectedOption)
                                                                                }}
                                                                                options={paperList.map((paper) => ({
                                                                                    label: paper.exp_name,
                                                                                    value: paper.expid,
                                                                                }))}
                                                                                menuPlacement="bottom"
                                                                                menuPosition="absolute"
                                                                                menuPortalTarget={document.body} // This is important for positioning the menu correctly in some cases
                                                                                menuStyle={{ maxHeight: '200px', overflowY: 'auto' }}
                                                                                styles={customStyles}
                                                                                className={`select2-selection ${validation.touched.selectedPaper && validation.errors.selectedPaper ? 'is-invalid' : ''}`}
                                                                            />
                                                                            {validation.touched.selectedPaper && validation.errors.selectedPaper ? (
                                                                                <FormFeedback type="invalid">{validation.errors.selectedPaper}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>

                                                                </Row>

                                                                <Row>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>Exam Board Category<span style={{ color: 'red' }}>*</span></Label>
                                                                            <Select
                                                                                tabIndex="2" isDisabled={c_subjects && c_subjects.length > 0}
                                                                                value={validation.values.selectedBoardCategory}
                                                                                onChange={(selectedOption) => {
                                                                                    validation.setFieldValue("selectedBoardCategory", selectedOption);
                                                                                    setselectedBoardCategory(selectedOption);
                                                                                    handleSelectBoardName(selectedOption);

                                                                                }}
                                                                                options={boardcategory.map((board) => ({
                                                                                    label: board.categoryname,
                                                                                    value: board.exctid + "-" + board.exct_exid,
                                                                                }))}
                                                                                className={`select2-selection ${validation.touched.selectedBoardCategory && validation.errors.selectedBoardCategory ? 'is-invalid' : ''}`}
                                                                            />
                                                                            {validation.touched.selectedBoardCategory && validation.errors.selectedBoardCategory ? (
                                                                                <div className="invalid-feedback">{validation.errors.selectedBoardCategory}</div>
                                                                            ) : null}
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg="6">

                                                                        <div className="mb-3">
                                                                            <Label>Subject
                                                                                <span style={{ color: 'red' }}>*</span>
                                                                            </Label>
                                                                            <Select
                                                                                // isDisabled={c_subjects && c_subjects.length > 0}
                                                                                // tabIndex="5" isDisabled={c_subjects && c_subjects.length > 0}
                                                                                value={validation.values.subjectName}
                                                                                onChange={(selectedOption) => {
                                                                                    validation.setFieldValue("subjectName", selectedOption);
                                                                                    handleSelectSubjectName(selectedOption);
                                                                                }}
                                                                                options={subject1.map((option) => ({
                                                                                    label: option.subjectname,
                                                                                    value: option.subjectid,
                                                                                }))}
                                                                                menuPlacement="bottom"
                                                                                menuPosition="absolute"
                                                                                menuPortalTarget={document.body} // This is important for positioning the menu correctly in some cases
                                                                                menuStyle={{ maxHeight: '200px', overflowY: 'auto' }}
                                                                                styles={customStyles}
                                                                                className={`select2-selection ${validation.touched.subjectName && validation.errors.subjectName ? 'is-invalid' : ''}`}
                                                                            />
                                                                            {validation.touched.subjectName && validation.errors.subjectName ? (
                                                                                <div className="invalid-feedback">{validation.errors.subjectName}</div>
                                                                            ) : null}
                                                                            {SelectedsubjectRadio == 'subjectwise' && qCount && qCount.length > 0 ?
                                                                                <Badge pill color="success" className="me-1 qount">
                                                                                    Available Questions :  <b>{qCount && qCount.length > 0 ? (qCount[0].qcount || "") : ""}</b>
                                                                                </Badge> : ""}

                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Row>
                                                                <Col lg='6'>
                                                                    <div className="mb-3">
                                                                        <Label>Duration(Mins)<span style={{ color: 'red' }}>*</span></Label>
                                                                        <Input
                                                                            className="rightAlignText"
                                                                            tabIndex="3"
                                                                            name="duration"
                                                                            type="number"
                                                                            min="1"
                                                                            max="999"
                                                                            onChange={(e) => {
                                                                                let value = e.target.value;
                                                                                if (value.length > 3) {
                                                                                    value = value.slice(0, 3);
                                                                                }
                                                                                if (parseInt(value) > 999) {
                                                                                    value = "999";
                                                                                }
                                                                                e.target.value = value;
                                                                                validation.handleChange(e);
                                                                            }}
                                                                            onBlur={validation.handleBlur}
                                                                            onKeyPress={(e) => {
                                                                                if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                            onPaste={(e) => {
                                                                                e.preventDefault();
                                                                                const pastedText = e.clipboardData.getData('text');
                                                                                let pastedValue = parseFloat(pastedText);
                                                                                if (isNaN(pastedValue) || pastedValue < 0) {
                                                                                    pastedValue = "";
                                                                                } else if (pastedValue > 999) {
                                                                                    pastedValue = 999;
                                                                                }
                                                                                e.target.value = pastedValue.toString().slice(0, 3);
                                                                                validation.handleChange(e);
                                                                            }}
                                                                            value={validation.values.duration || ""}
                                                                            invalid={validation.touched.duration && validation.errors.duration ? true : false}
                                                                        />
                                                                        {validation.touched.duration && validation.errors.duration ? (
                                                                            <FormFeedback type="invalid">{validation.errors.duration}</FormFeedback>
                                                                        ) : null}
                                                                    </div>

                                                                </Col>
                                                                <Col lg='6'>
                                                                    {
                                                                        SelectedsubjectRadio == 'topicwise' ?

                                                                            <div className="mb-3">
                                                                                <Label>Topic<span style={{ color: 'red' }}>*</span></Label>
                                                                                <Select
                                                                                    tabIndex="6" isDisabled={c_subjects && c_subjects.length > 0}
                                                                                    value={validation.values.selectedTopicName}
                                                                                    onChange={(selectedOption) => {
                                                                                        validation.setFieldValue("selectedTopicName", selectedOption);
                                                                                        handleselecttopic(selectedOption)
                                                                                    }}
                                                                                    options={validation && validation.values ? optionTopicName.map((option) => ({
                                                                                        label: option.topicname,
                                                                                        value: option.topicid,
                                                                                    })) : []}
                                                                                    validate={{
                                                                                        required: { value: true },
                                                                                    }}
                                                                                    className={`select2-selection ${validation.touched.selectedTopicName && validation.errors.selectedTopicName ? 'is-invalid' : ''}`}
                                                                                    menuPlacement="bottom"
                                                                                    menuPosition="absolute"
                                                                                    menuPortalTarget={document.body} // This is important for positioning the menu correctly in some cases
                                                                                    menuStyle={{ maxHeight: '200px', overflowY: 'auto' }} // Set the max height for the dropdown list
                                                                                />
                                                                                {SelectedsubjectRadio == 'topicwise' && selectedCount && selectedCount.length > 0 ?
                                                                                    <Badge pill color="success" className="me-1 qount">
                                                                                        Available Questions :  <b>{selectedCount && selectedCount.length > 0 ? (selectedCount[0].qcount || "") : ""}</b>
                                                                                    </Badge> : ""}
                                                                                {validation.touched.selectedTopicName && validation.errors.selectedTopicName ? (
                                                                                    <div className="invalid-feedback">{validation.errors.selectedTopicName}</div>
                                                                                ) : null}
                                                                            </div> : ""
                                                                    }

                                                                </Col>

                                                            </Row>
                                                            <Row>

                                                                <Col lg='6'>

                                                                    {mkid > 0 && isClone === '0' && (
                                                                        <div className="mb-3">
                                                                            <Label>Status</Label>
                                                                            <div className="square-switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id="statusToggle"
                                                                                    className="switch switch-info"
                                                                                    checked={status}
                                                                                    onClick={() => setstatus(!status)}
                                                                                />
                                                                                <label htmlFor="statusToggle" data-on-label="Active" data-off-label="Inactive" />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Col>

                                                            </Row>


                                                        </Col>
                                                        <Col lg="6">
                                                            <Col lg="12">
                                                                <Col lg="6">
                                                                    <Row>
                                                                        <Col lg="6">
                                                                            <div className="mb-3">
                                                                                <Label>Total Questions<span style={{ color: 'red' }}>*</span></Label>
                                                                                <Input
                                                                                    className="rightAlignText"
                                                                                    tabIndex="7"
                                                                                    name="totalQuestions"
                                                                                    type="number"
                                                                                    min="1"
                                                                                    max="999"
                                                                                    onChange={(e) => {
                                                                                        let value = e.target.value;
                                                                                        if (value.length > 3) {
                                                                                            value = value.slice(0, 3);
                                                                                        }
                                                                                        if (parseInt(value) > 999) {
                                                                                            value = "999";
                                                                                        }
                                                                                        e.target.value = value;
                                                                                        handlechangeQuestion(e)
                                                                                        validation.handleChange(e);
                                                                                    }}
                                                                                    onBlur={validation.handleBlur}
                                                                                    onKeyPress={(e) => {
                                                                                        if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    onPaste={(e) => {
                                                                                        e.preventDefault();
                                                                                        const pastedText = e.clipboardData.getData('text');
                                                                                        let pastedValue = parseFloat(pastedText);
                                                                                        if (isNaN(pastedValue) || pastedValue < 0) {
                                                                                            pastedValue = "";
                                                                                        } else if (pastedValue > 999) {
                                                                                            pastedValue = 999;
                                                                                        }
                                                                                        e.target.value = pastedValue.toString().slice(0, 3);
                                                                                        validation.handleChange(e);
                                                                                    }}
                                                                                    value={validation.values.totalQuestions || ""}
                                                                                    invalid={validation.touched.totalQuestions && validation.errors.totalQuestions ? true : false}
                                                                                />
                                                                                {validation.touched.totalQuestions && validation.errors.totalQuestions ? (
                                                                                    <FormFeedback type="invalid">{validation.errors.totalQuestions}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg="6">
                                                                            <Label>Mark/Question<span style={{ color: 'red' }}>*</span></Label>
                                                                            <Input
                                                                                tabIndex="8"
                                                                                className="rightAlignText"

                                                                                name="markPerQuestion"
                                                                                type="number"
                                                                                min="1"
                                                                                validate={{ required: { value: true } }}
                                                                                max="999"
                                                                                onChange={(e) => {
                                                                                    let value = e.target.value;
                                                                                    if (value.length > 3) {
                                                                                        value = value.slice(0, 3);
                                                                                    }
                                                                                    if (parseInt(value) > 999) {
                                                                                        value = "999";
                                                                                    }
                                                                                    e.target.value = value;
                                                                                    handlechangeMark(e)
                                                                                    validation.handleChange(e);
                                                                                }}

                                                                                onBlur={validation.handleBlur}
                                                                                onKeyPress={(e) => {
                                                                                    if (e.key === '-' || e.key === 'e') {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}
                                                                                onPaste={(e) => {
                                                                                    e.preventDefault();
                                                                                    const pastedText = e.clipboardData.getData('text');
                                                                                    let pastedValue = parseFloat(pastedText);
                                                                                    if (isNaN(pastedValue) || pastedValue < 0) {
                                                                                        pastedValue = "";
                                                                                    } else if (pastedValue > 999) {
                                                                                        pastedValue = 999;
                                                                                    }
                                                                                    e.target.value = pastedValue.toString().slice(0, 4);
                                                                                    validation.handleChange(e);
                                                                                }}
                                                                                value={validation.values.markPerQuestion || ""}
                                                                                invalid={validation.touched.markPerQuestion && validation.errors.markPerQuestion ? true : false}
                                                                            />
                                                                            {validation.touched.markPerQuestion && validation.errors.markPerQuestion ? (
                                                                                <FormFeedback type="invalid">{validation.errors.markPerQuestion}</FormFeedback>
                                                                            ) : null}

                                                                        </Col>
                                                                    </Row>
                                                                    <Row style={{ "margin-top": '18px' }}>
                                                                        <Col lg="6">
                                                                            <div>
                                                                                <Label>Total Marks</Label>
                                                                                <Input
                                                                                    className="rightAlignText"
                                                                                    disabled={isButtonDisabled}
                                                                                    name="totalMarks"
                                                                                    type="number"
                                                                                    min="1"
                                                                                    max="999"
                                                                                    id="input2"
                                                                                    onChange={(e) => {
                                                                                        let value = e.target.value;
                                                                                        if (value.length > 3) {
                                                                                            value = value.slice(0, 3);
                                                                                        }
                                                                                        if (parseInt(value) > 999) {
                                                                                            value = "999";
                                                                                        }
                                                                                        e.target.value = value;
                                                                                        validation.handleChange(e);
                                                                                    }}
                                                                                    onBlur={validation.handleBlur}
                                                                                    onKeyPress={(e) => {
                                                                                        if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    onPaste={(e) => {
                                                                                        e.preventDefault();
                                                                                        const pastedText = e.clipboardData.getData('text');
                                                                                        let pastedValue = parseFloat(pastedText);
                                                                                        if (isNaN(pastedValue) || pastedValue < 0) {
                                                                                            pastedValue = "";
                                                                                        } else if (pastedValue > 999) {
                                                                                            pastedValue = 999;
                                                                                        }
                                                                                        e.target.value = pastedValue.toString().slice(0, 3);
                                                                                        validation.handleChange(e);
                                                                                    }}
                                                                                    value={validation.values.totalMarks || ""}
                                                                                    invalid={validation.touched.totalMarks && validation.errors.totalMarks ? true : false}
                                                                                />

                                                                                {validation.touched.totalMarks && validation.errors.totalMarks ? (
                                                                                    <FormFeedback type="invalid">{validation.errors.totalMarks}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Col>
                                                        </Col>


                                                    </Row>

                                                </form>

                                            </CardBody>
                                            <CardFooter>

                                                <div className="text-end">
                                                    <Button color="success"
                                                        tabIndex="15"
                                                        type="submit"
                                                        className="save-user"
                                                    >
                                                        {mkid > 0 && isClone === '0' ? 'Update' : 'Save'}
                                                    </Button>
                                                    <button
                                                        tabIndex="16"
                                                        type="button" style={{ marginLeft: "10px" }}
                                                        onClick={handleClose}
                                                        className="btn btn-danger">Close</button>
                                                </div>

                                            </CardFooter>

                                        </Card>
                                    </Form>
                                </Col>
                            </Row>
                    }

                </Container>
            </div >
        </React.Fragment >
    );
};

export default AddMockupTest;
