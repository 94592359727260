export const GET_USERMASTER = "GET_USERMASTER"
export const GET_USERMASTER_SUCCESS = "GET_USERMASTER_SUCCESS"
export const ADD_USERMASTER = " ADD_USERMASTER"
export const ADD_USERMASTER_SUCCESS = " ADD_USERMASTER_SUCCESS"
export const RESET_STORE_USERMASTER = "RESET_STORE_USERMASTER"
export const DELETE_USERMASTER = "DELETE_USERMASTER"
export const DELETE_USERMASTER_SUCCESS = "DELETE_USERMASTER_SUCCESS"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"

