import {
    GET_SUBJECTS_SUCCESS,
    ADD_SUBJECT_SUCCESS,
    DELETE_SUBJECT_SUCCESS,
    RESET_STORE_SUBJECT,
} from "./actionTypes"

  const INIT_STATE = {
    subjectList: [],
    subject_statusFlag: 0
  }
  
  const Subject= (state = INIT_STATE, action) => {
    console.log(action.type, action.payload, 'reducer')
    switch (action.type) {
      case  GET_SUBJECTS_SUCCESS:
        return {
          ...state,
          subjectList: action.payload.subjectList,
          subject_statusFlag: action.payload.statusFlag
        }
      case ADD_SUBJECT_SUCCESS:
      return {
          ...state,
          subject_statusFlag: action.payload.statusFlag,
      };
      case   DELETE_SUBJECT_SUCCESS:
        return {
            ...state,
            subject_statusFlag: action.payload.statusFlag,
        };
       case  RESET_STORE_SUBJECT:
            return {
              ...state,
              subject_statusFlag: action.payload.statusFlag,
            };
        default:
            return state
        }
      }
      
      export default Subject