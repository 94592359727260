import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { ADD_GENERALSETTINGS, EDITLOAD_GENERALSETTINGS } from "./actionTypes";
import {
    addgeneralsettingsSuccess, editloadgeneralSettingsSuccess
} from "./actions";

//Include Both Helper File with needed methods
import { deleteblueprint, addGeneralSettings, editloadblueprint, userValidation, editloadgeneralSettings} from "helpers/fakebackend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



function* onAddgeneralsettings({ payload: data }) {
  try {
      const response = yield call(addGeneralSettings, data)
      yield call(userValidation, response)
      yield put(addgeneralsettingsSuccess(response))
      if (response && response.statusFlag === 1) {
        toast.success(response.message, { autoClose: 2000 });
      } else {
        toast.error(response.message, { autoClose: 2000 });
      }      
  } catch (error) {
      yield put(addgeneralsettingsSuccess({statusFlag: 2}))
      toast.error("General Settings Added Failed", { autoClose: 2000 });
  }
}

function* oneditgeneralsettings({ payload: data }) {
  try {
      const response = yield call(editloadgeneralSettings, data)
      yield call(userValidation, response)
      yield put(editloadgeneralSettingsSuccess(response))
      if (response && response.statusFlag === 1) {
        toast.success(response.message, { autoClose: 2000 });
      } else {
        toast.error(response.message, { autoClose: 2000 });
      }      
  } catch (error) {
      yield put(editloadgeneralSettingsSuccess({statusFlag: 2}))
      toast.error("Exam Board Deleted Failed", { autoClose: 2000 });
  }
}

function* generalSettingsSaga() {
  yield takeEvery(ADD_GENERALSETTINGS, onAddgeneralsettings)
  yield takeEvery(EDITLOAD_GENERALSETTINGS, oneditgeneralsettings)

}

export default generalSettingsSaga;
