import PropTypes from 'prop-types'
import React, { useMemo } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import TableContainer from '../../components/Common/TableContainer';

const subjectModal = ({ show, subjectList, subjectDetails, onCloseClick }) => {
    const columns = useMemo(
        () => [          
          {
            header: 'S.No.',
            accessorKey: 'sno',
            width: '8%',
            enableColumnFilter: false,
            enableSorting: true,
            className: 'pl-25',
            cell: ({ row, table }) =>
              (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1   
          },
          {
            header: (
              <span style={{ cursor: 'pointer' }}>
                Subject Name ▼
              </span>
            ),
    
            accessorKey: 'subjectname',
            enableColumnFilter: false,
            enableSorting: true,
          },    
    
        ],
        []
      );
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick}>
        <ModalHeader toggle={onCloseClick} tag="h4" style={{background: '#556ee630'}}>
             {subjectDetails?.exp_name}
            </ModalHeader>
        <ModalBody>       
        <TableContainer
            columns={columns}
            data={subjectList || []}
            isPagination={true}
            pagination="pagination"
            buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
        />

        <div className="hstack gap-2 mb-0" style={{float: 'right', marginTop: '4%'}}>
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Close</button>
            </div>
        </ModalBody>     
    </Modal>
    )
}

subjectModal.propTypes = {
  onCloseClick: PropTypes.func,
  subjectList: PropTypes.any,
  show: PropTypes.any
}

export default subjectModal
