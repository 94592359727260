import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import ExamBoardSaga from "./examboard/saga";
import ExamBoardcategorySaga from "./examboardcategory/saga";
import Subject from "./subject/saga";
import SubjectSubcategory from "./subjectsubcategory/saga";
import Topic from "./topic/saga";
import Usermaster from "./usermaster/saga";
import Userrole from "./userrole/saga";
import Blueprint from "./blueprint/saga";
import exampaperSaga from "./exampapers/saga";
import mockupTest from "./mockuptest/saga";
import Studymaterial from "./studymaterial/saga";
import generalSettingsSaga from "./generalsettings/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ExamBoardSaga),
    fork(ExamBoardcategorySaga),
    fork(Subject),
    fork(SubjectSubcategory),
    fork(Topic),
    fork(Usermaster),
    fork(Userrole),
    fork(Blueprint),
    fork(exampaperSaga),
    fork(mockupTest),
    fork(Studymaterial),
    fork(generalSettingsSaga)

  ]);
}
