import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_USERROLE_LIST, ADD_NEW_USER_ROLE, DELETE_USER_ROLE, GET_USER_ROLE_DETAILS } from "./actionTypes";
import {
  getUserroleListuccess, addNewUserroleSuccess, deleteUserroleSuccess, getUserRoleDetailsSuccess
} from "./actions";

//Include Both Helper File with needed methods
import { getUserroleList, addNewUserrole, deleteUserRole, getUserroleDetails, userValidation } from "helpers/fakebackend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchUserRoleList({ payload: data}) {
  try {
    const response = yield call(getUserroleList, data)
    yield call(userValidation, response)
    yield put(getUserroleListuccess(response))
  } catch (error) {
    yield put(getUserroleListuccess({statusFlag: 2}))
  }
}

function* onaddNewUserrole({ payload: data }) {
  try {
      const response = yield call(addNewUserrole, data)
      yield call(userValidation, response)
      yield put(addNewUserroleSuccess(response))
      if (response && response.statusFlag === 1) {
        toast.success(response.message, { autoClose: 2000 });
      } else {
        toast.error(response.message, { autoClose: 2000 });
      }      
  } catch (error) {
      yield put(addNewUserroleSuccess({statusFlag: 2}))
      toast.error("User role Added Failed", { autoClose: 2000 });
  }
}

function* ondeleteUserRole({ payload: data }) {
  try {
      const response = yield call(deleteUserRole, data)
      yield call(userValidation, response)
      yield put(deleteUserroleSuccess(response))
      if (response && response.statusFlag === 1) {
        toast.success(response.message, { autoClose: 2000 });
      } else {
        toast.error(response.message, { autoClose: 2000 });
      }      
  } catch (error) {
      yield put(deleteUserroleSuccess({statusFlag: 2}))
      toast.error("User role Deleted Failed", { autoClose: 2000 });
  }
}

function* onGetUserRoleDetails({ payload: data }) {
  try {
      const response = yield call(getUserroleDetails, data)
      yield call(userValidation, response)
      yield put(getUserRoleDetailsSuccess(response))
      if (response && response.statusFlag === 1) {
        toast.success(response.message, { autoClose: 2000 });
      } else {
        toast.error(response.message, { autoClose: 2000 });
      }      
  } catch (error) {
      yield put(getUserRoleDetailsSuccess({statusFlag: 2}))
      toast.error("Get user role details Failed", { autoClose: 2000 });
  }
}

function* examboardSaga() {
  yield takeEvery(GET_USERROLE_LIST, fetchUserRoleList)
  yield takeEvery(ADD_NEW_USER_ROLE, onaddNewUserrole)
  yield takeEvery(GET_USER_ROLE_DETAILS, onGetUserRoleDetails)
  yield takeEvery(DELETE_USER_ROLE, ondeleteUserRole)

}

export default examboardSaga;
