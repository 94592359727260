import {
  GET_TOPIC_SUCCESS,
  ADD_TOPIC_SUCCESS,
  DELETE_TOPIC_SUCCESS,
  RESET_STORE_TOPIC,
} from "./actionTypes"

const INIT_STATE = {
  topicList: [],
  topic_statusFlag: 0
}

const Topic = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TOPIC_SUCCESS:
      return {
        ...state,
        topicList: action.payload.topicList,
        topic_statusFlag: action.payload.statusFlag
      }
    case ADD_TOPIC_SUCCESS:
      return {
        ...state,
        topic_statusFlag: action.payload.statusFlag,
      };
    case DELETE_TOPIC_SUCCESS:
      return {
        ...state,
        topic_statusFlag: action.payload.statusFlag,
      };
    case RESET_STORE_TOPIC:
      return {
        ...state,
        topic_statusFlag: action.payload.statusFlag,
      };
    default:
      return state
  }
}

export default Topic