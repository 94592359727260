import {
    GET_BLUEPRINT,
    GET_BLUEPRINT_SUCCESS,
    ADD_BLUEPRINT,
    ADD_BLUEPRINT_SUCCESS,
    DELETE_BLUEPRINT,
    DELETE_BLUEPRINT_SUCCESS,
    RESET_STORE_BLUEPRINT,
    EDITLOAD_BLUEPRINT,
    EDITLOAD_BLUEPRINT_SUCCESS,
  } from "./actionTypes"
  
  export const getblueprint = (data) => ({
    type: GET_BLUEPRINT,
    payload: data
  })
  
  export const getblueprintSuccess = bluePrintList => ({
    type: GET_BLUEPRINT_SUCCESS,
    payload: bluePrintList,
  })
  export const addblueprint = data => ({
    type: ADD_BLUEPRINT,
    payload: data,
  })
  
  export const addblueprintSuccess = data => ({
    type: ADD_BLUEPRINT_SUCCESS,
    payload: data,
  })
  
  export const deleteblueprint = data => ({
    type: DELETE_BLUEPRINT,
    payload: data,
  })
  
  export const deleteblueprintSuccess = data => ({
    type: DELETE_BLUEPRINT_SUCCESS,
    payload: data,
  })

  export const editload = data => ({
    type: EDITLOAD_BLUEPRINT,
    payload: data,
  })
  
  export const editloadSuccess = bluePrintData  => ({
    type: EDITLOAD_BLUEPRINT_SUCCESS,
    payload: bluePrintData ,
  })
  
  export const resetStoreblueprint = data => ({
    type: RESET_STORE_BLUEPRINT,
    payload: data,
  })