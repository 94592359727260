import {
  GET_MOCKUPTEST_SUCCESS,
  ADD_NEW_MOCKUPTEST_SUCCESS,
  EDITLOAD_MOCKUPTEST_SUCCESS,
  DELETE_MOCKUPTEST_SUCCESS,
  RESET_STORE_MOCKUPTEST
} from "./actionTypes"

const INIT_STATE = {
  mockupList: [],
  mockuptest_statusFlag: 0,
  mockupTestData: []
}

const MockupList = (state = INIT_STATE, action) => {
  console.log(action.type, action.payload, 'reducer')
  switch (action.type) {
    case GET_MOCKUPTEST_SUCCESS:
      return {
        ...state,
        mockupList: action.payload.mockupList,
        mockuptest_statusFlag: action.payload.statusFlag
      }
    case ADD_NEW_MOCKUPTEST_SUCCESS:
      return {
        ...state,
        mockuptest_statusFlag: action.payload.statusFlag,
      };
    case EDITLOAD_MOCKUPTEST_SUCCESS:
      return {
        ...state,
        mockupTestData: action.payload.mockupTestData,
        mockuptest_statusFlag: action.payload.statusFlag,
      };
    case DELETE_MOCKUPTEST_SUCCESS:
      return {
        ...state,
        mockuptest_statusFlag: action.payload.statusFlag,
      };
    case RESET_STORE_MOCKUPTEST:
      return {
        ...state,
        mockuptest_statusFlag: action.payload.statusFlag,
      };
    default:
      return state
  }
}

export default MockupList