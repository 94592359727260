import {
    GET_USERMASTER,
    GET_USERMASTER_SUCCESS,
    ADD_USERMASTER,
    ADD_USERMASTER_SUCCESS,
    DELETE_USERMASTER,
    DELETE_USERMASTER_SUCCESS,
    RESET_STORE_USERMASTER,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS
} from "./actionTypes"
  
  export const getusermaster = (data) => ({
    type: GET_USERMASTER,
    payload: data
  })
  export const getusermasterSuccess = userList => ({
    type: GET_USERMASTER_SUCCESS,
    payload: userList,
  })
  export const addusermaster = data => ({
    type: ADD_USERMASTER,
    payload: data,
  })
  
  export const addusermasterSuccess = data => ({
    type: ADD_USERMASTER_SUCCESS,
    payload: data,
  })

  export const deleteusermaster = data => ({
    type: DELETE_USERMASTER,
    payload: data,
  })
  
  export const deleteusermasterSuccess = data => ({
    type: DELETE_USERMASTER_SUCCESS,
    payload: data,
  })

  export const resetpasswordmaster = data => ({
    type: RESET_PASSWORD,
    payload: data,
  })

  export const resetpasswordmasterSuccess = data => ({
    type: RESET_PASSWORD_SUCCESS ,
    payload: data,
  })

  export const changepasswordmaster = data => ({
    type: CHANGE_PASSWORD,
    payload: data,
  })

  export const changepasswordmasterSuccess = data => ({
    type: CHANGE_PASSWORD_SUCCESS ,
    payload: data,
  })

  export const resetusermaster = data => ({
    type:RESET_STORE_USERMASTER,
    payload: data,
  })

 

  