import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Routes, Route, useNavigate } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";
import * as url from "../src/helpers/url_helper";


// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { getLoggedInUser } from "./helpers/fakebackend_helper"
import * as constants from "./constants/layout"
// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from "react-redux";
import { logoutUser } from "./store/actions";
import axios from 'axios';

// import SessionManager from 'components/Common/SessionManager';

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);


const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = ({ onIdle }) => {

  const history = useNavigate();
  const dispatch = useDispatch();
  const pageLoadedRef = useRef(false);
  const selectLayoutState = (state) => state.Layout;
  const LayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutType: layout.layoutType,
    })
  );

  const {
    layoutType
  } = useSelector(LayoutProperties);

  const Layout = getLayout(layoutType);
  const user = getLoggedInUser()

  const handleIdle = () => {
    // localStorage.removeItem("authUser");
    // sessionStorage.removeItem("SESSION_KEY")
    dispatch(logoutUser(history));
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log('hai');
      console.log('s-storage', sessionStorage.getItem('SESSION_KEY'));

      const user = sessionStorage.getItem('SESSION_KEY');
      localStorage.setItem('authUser', user)
    };

    // Attach event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      // Check if the logout event was triggered
      if (event.key === 'logout') {
        // Redirect to the login page or perform any other action
        // window.location.href = '/';
      }
    };

    // Attach the event listener
    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Configure the idle timer
  useIdleTimer({
    timeout: 1000 * 60 * 5, // 5 minutes in milliseconds
    onIdle: handleIdle,
    debounce: 500 // Debounce time to wait before calling onIdle
  });

  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Call getLogout to send data when the tab/window is closing
      getLogout();

      // Optionally, set a custom message (note that modern browsers might ignore this)
      setTimeout(() => {

        event.returnValue = 'Are you sure you want to leave?';
      }, 5000);
    };

    // Attach the event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // const getLogout = async () => {
  //   try {
  //     console.log('---------------------------------------->')
  //     var data = {
  //       "user_id": Number(user.uid),
  //     }
  //     // return false;
  //     const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  //     const response1 = await axios.post(url.BASE_URL + 'master/saveLogout', data, config)
  //     console.log('eee', response1.rows)

  //   } catch (error) {
  //     // Handle error
  //     console.error(error);
  //   }
  // }
  const getLogout = () => {
    // Create the payload to send to the server
    // const payload = JSON.stringify({ user_id: Number(user.uid) });
    const BASE_URL = 'http://localhost:8081/api/'; // Replace with your actual base URL

    // Use navigator.sendBeacon to send the data
    const endpoint = 'master/saveLogout';
    const url1 = new URL(endpoint, BASE_URL);
    console.log('--------', url1)

    const success = navigator.sendBeacon(url1);
    console.log('Beacon sent:', success); // Debugging statement
    // navigator.sendBeacon(url.BASE_URL + 'master/saveLogout');
  };




  useEffect(() => {
    const checkSession = () => {
      const sessionData = JSON.parse(sessionStorage.getItem('SESSION_KEY'));
      console.log('session Storage', JSON.parse(sessionStorage.getItem('SESSION_KEY')));
      console.log('local Storage', JSON.parse(localStorage.getItem('authUser')));
      console.log('sessionData', sessionData)
      if (!sessionData) {
        // Redirect to login page if session is invalid
        // navigate('/');
      }
    };

    checkSession();
  }, [navigate]);

  return (
    <React.Fragment>
      {/* <SessionManager /> */}
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                {(route.menu_id === 11 || route.menu_id === 12 || route.menu_id === 13 || route.menu_id === 15 || route.menu_id === 16 || route.menu_id === 14 || (user && user.user_previleges && user.user_previleges.find(f =>
                  Number(f.urp_smid) === route.menu_id && f.urp_type === constants.menuTypes.View))) && (
                    <Layout>
                      {route.component}
                    </Layout>
                  )}
              </Authmiddleware>}
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;