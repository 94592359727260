// Created by: Murugeswari Chandrabose
// Created At: 22 Jan 2024

// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import Select from "react-select";
import { resetUserRoleStore, deleteUserrole } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import * as constants from "../../../constants/layout"
import axios from "axios";
import { useDispatch } from "react-redux";

import * as url from "../../../../src/helpers/url_helper";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";

const StudentMaster = () => {
    document.title = "Masters | Student Master";

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const user = getLoggedInUser()
    const [canView, setCanView] = useState(false)
    const [canCreate, setCanCreate] = useState(false)
    const [canModify, setCanModify] = useState(false)
    const [canRemove, setCanRemove] = useState(false)
    const [studentmasterlist, setstudentmasterlist] = useState([])
    const [studentdetails, setstudentdetails] = useState([])
    const [details, setdetails] = useState([])
    const [stuname, setstuname] = useState([])
    const [status, setstatus] = useState([])
    const [stu_mail, setstu_mail] = useState([])
    const [stu_mobileno, setstu_mobileno] = useState([])
    const [preferences, setpreferences] = useState("");
    const [interested, setinterested] = useState("");
    const [preferencevalue, setpreferencevalue] = useState("");
    const [interestedvalue, setinterestedvalue] = useState("");
    const [username, setusername] = useState("");

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: true
        },
        validationSchema: Yup.object({
            userdisplayName: Yup.string().required("Please Enter User display Name"),
            loginID: Yup.string().required("Please Enter Login ID"),
            password: Yup.string().required("Please Enter Password"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Please confirm your password'),
        }),
        onSubmit: (values) => {

            toggle();
        },
    });

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    const onClickview = (studentdetails) => {
        setdetails(studentdetails)
        setModal(true);
        getstudentdetailslist(studentdetails)

    };

    useEffect(() => {

        const authUser = JSON.parse(sessionStorage.getItem('SESSION_KEY'));
        if (authUser && authUser.u_name) {

            setusername(authUser.u_name.charAt(0).toUpperCase());
        }
    }, []);


    //   useEffect(() => {

    //     getstudentdetailslist()
    //     }, [details]);

    const getstudentdetailslist = async (studentdetails) => {

        const data = {
            user_id: Number(user.uid),
            stid: Number(studentdetails.stid)
        };

        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(url.BASE_URL + 'master/getStudentDetailsList', data, config);
            if (response && response.statusFlag === 3) {
                toast.error(response.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                sessionStorage.removeItem("SESSION_KEY")
                window.location.reload();
                return;
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser();
                    } else {
                        await handlestudentmasterlist();
                    }
                }
            }

            if (response?.data?.body?.stu_personal_details) {
                setstudentdetails(response.data.body.stu_personal_details);
                setstuname(response.data.body.stu_personal_details[0].st_name);
                setstu_mail(response.data.body.stu_personal_details[0].st_email);
                setstu_mobileno(response.data.body.stu_personal_details[0].st_mobileno);
                setpreferences(response.data.body.stu_preferences);
                setstatus(response.data.body.stu_personal_details[0].sts_name)
                // setpreferencevalue(response.data.body.stu_preferences[0].exctid);
                setinterested(response.data.body.stu_interested);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("student detailsAPI getsubjects_topicslist Error:", error);
        }
    };

    const columns = useMemo(
        () => [
            {
                header: 'Actions',
                width: '8%',

                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">


                            {user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.StudentMaster && f.urp_type === constants.menuTypes.Remove) && (
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-danger"
                                        onClick={() => {
                                            const studentdetails = cellProps.row.original;
                                            onClickview(studentdetails);
                                        }}>
                                        <i className="mdi mdi-eye" id="deletetooltip" />
                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                            View
                                        </UncontrolledTooltip>
                                    </Link>)}

                        </div>
                    );
                }
            },
            {
                header: 'S.No.',
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1

            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Student Name ▼
                    </span>
                ),

                accessorKey: 'st_name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Email ID ▼
                    </span>
                ),

                accessorKey: 'st_email',
                enableColumnFilter: false,
                enableSorting: true,

            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Status ▼
                    </span>
                ),

                accessorKey: 'st_stsid',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const st_stsid = cellProps.row.original.st_stsid;

                    let icon;
                    switch (st_stsid) {
                        case "1":
                            icon = <span>
                                <i className="mdi mdi-check-circle text-success me-2" id="activetooltip" style={{ fontSize: '25px' }} />
                                <UncontrolledTooltip placement="top" target="activetooltip">
                                    Active
                                </UncontrolledTooltip>
                            </span>
                            break;
                        case "2":
                            icon =
                                <span>

                                    <i className="dripicons dripicons-wrong text-danger me-2" id="inactivetooltip" style={{ fontSize: '20px' }} />
                                    <UncontrolledTooltip placement="top" target="inactivetooltip">
                                        InActive
                                    </UncontrolledTooltip>
                                </span>
                            break;
                        case "3":
                            icon =
                                <span>

                                    <i className="mdi mdi-book-clock-outline text-warning me-2" id="pendingtooltip" style={{ fontSize: '25px' }} />
                                    <UncontrolledTooltip placement="top" target="pendingtooltip">
                                        Pending
                                    </UncontrolledTooltip>
                                </span>
                            break;

                    }

                    return (
                        <div className="d-flex align-items-center">
                            {icon}
                        </div>
                    );
                },
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Created At ▼
                    </span>
                ),

                accessorKey: 'u_created',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Created By ▼
                    </span>
                ),

                accessorKey: 'u_createdby',
                enableColumnFilter: false,
                enableSorting: true,
            },
        ],
        []
    );





    //Initial List API calling
    useEffect(() => {

        document.getElementById('headerTitle').innerHTML = 'Student Master';
    }, []);

    useEffect(() => {

        handlestudentmasterlist();
    }, []);

    const handlestudentmasterlist = async () => {

        const data = {
            user_id: Number(user.uid),
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(url.BASE_URL + 'master/getmasterlist', data, config);
            if (response && response.statusFlag === 3) {
                toast.error(response.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                sessionStorage.removeItem("SESSION_KEY")
                window.location.reload();
                return;
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser();
                    } else {
                        await handlestudentmasterlist();
                    }
                }
            }

            if (response?.data?.body?.masterList) {
                setstudentmasterlist(response.data.body.masterList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("student detailsAPI getsubjects_topicslist Error:", error);
        }
    };

    //USer role credentials
    useEffect(() => {
        if (user && user.user_previleges) {
            let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.StudentMaster)
            if (page_menu_list) {
                setCanView(page_menu_list.find(f => f.urp_type === constants.menuTypes.View)?.urp_smid ? true : false)
                setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid ? true : false)
                setCanModify(page_menu_list.find(f => f.urp_type === constants.menuTypes.Modify)?.urp_smid ? true : false)
                setCanRemove(page_menu_list.find(f => f.urp_type === constants.menuTypes.Remove)?.urp_smid ? true : false)
            }
        }
    }, [user]);

    return (
        <React.Fragment>

            <div className="page-content" style={{ height: '100%' }}>
                <div className="container-fluid">
                    {/* <Breadcrumbs title="Masters" breadcrumbItem="User Role" /> */}
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={studentmasterlist || []}
                                                isGlobalFilter={true}
                                                isPagination={true}

                                                buttonName="Add"
                                                SearchPlaceholder="Search..."
                                                pagination="pagination"



                                                buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                theadClass="table-light"

                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                    <Modal isOpen={modal} toggle={toggle} backdrop="static">
                        <ModalHeader toggle={toggle} tag="h4">
                            {"Student Details"}
                        </ModalHeader>
                        <ModalBody>
                            <div className="landing-card">
                                <Row>
                                    <Col xs="12" style={{ background: '#f0f8ff' }}>
                                        <div className="text-center" style={{ marginTop: '15px' }}>
                                            <div className="rounded-circle header-profile-user" style={{ width: '40px', height: '40px', background: '#007bff', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', marginLeft: "45%" }}>
                                                {username}
                                            </div>

                                        </div>
                                        <h5 className="mt-3 mb-1 " style={{
                                            // marginLeft: "39%" 
                                            textAlign: 'center'
                                        }} >{stuname}{" "}{status == "Active" ?
                                            <span>

                                                <i className="mdi mdi-check-circle text-success me-2" id="activetooltip" style={{ fontSize: '15px' }} />
                                                <UncontrolledTooltip placement="top" target="activetooltip">
                                                    Active
                                                </UncontrolledTooltip>
                                            </span>
                                            : status == 'Inactive' ?
                                                <span>

                                                    <i className="dripicons dripicons-wrong text-danger me-2" id="inactivetooltip" style={{ fontSize: '15px' }} />
                                                    <UncontrolledTooltip placement="top" target="inactivetooltip">
                                                        Inactive
                                                    </UncontrolledTooltip>
                                                </span>
                                                : status == 'Pending' ? <span><i className="mdi mdi-book-clock-outline text-warning me-2" id="pendingtooltip" style={{ fontSize: '15px' }} />
                                                    <UncontrolledTooltip placement="top" target="pendingtooltip">
                                                        Pending
                                                    </UncontrolledTooltip>
                                                </span>
                                                    :
                                                    ""}</h5>
                                        <p className="text-muted mb-0 " style={{
                                            // marginLeft: "38%" 
                                            textAlign: 'center'
                                        }} >{stu_mail}</p>
                                        <ul className="list-unstyled mt-4 " style={{ margin: '5%' }}>
                                            <li>
                                                <div className="d-flex">
                                                    <i className="bx bx-phone text-primary fs-4"></i>
                                                    <div className="ms-3">
                                                        <h6 className="fs-14 mb-2">Phone</h6>
                                                        <p className="text-muted fs-14 mb-0">{stu_mobileno}</p>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="mt-3">
                                                <div className="d-flex" >
                                                    <i className="bx bx-package text-primary fs-4"></i>
                                                    <div className="ms-3">
                                                        <h6 className="fs-14 mb-2">Preferences</h6>
                                                        <p className="text-muted fs-14 text-break mb-0">
                                                            {preferences.length > 0 ? (
                                                                preferences.map((preference, index) => (
                                                                    <span key={index} className="badge badge-soft-warning fs-6 mx-1">
                                                                        {preference.exboard_excategory}
                                                                    </span>
                                                                ))
                                                            ) : (
                                                                <span>No preferences available</span>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="mt-3">
                                                <div className="d-flex" >
                                                    <i className="bx bx-star text-primary fs-4"></i>
                                                    <div className="ms-3">
                                                        <h6 className="fs-14 mb-2">Looking For</h6>
                                                        <p className="text-muted fs-14 text-break mb-0">
                                                            {interested.length > 0 ? (
                                                                interested.map((interest, index) => (
                                                                    <span key={index} className="badge badge-soft-warning fs-6 mx-1">
                                                                        {interest.mst_displaytext}
                                                                    </span>
                                                                ))
                                                            ) : (
                                                                <span>No interested available</span>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
StudentMaster.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default StudentMaster;