import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_TOPIC, ADD_TOPIC, DELETE_TOPIC } from "./actionTypes";
import { gettopicSuccess, addtopicSuccess, deletetopicSuccess } from "./actions";

//Include Both Helper File with needed methods
import { gettopicList, addtopiclist, deletetopiclist, userValidation } from "helpers/fakebackend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchtopic({ payload: data }) {
  try {
    const response = yield call(gettopicList, data)
    yield call(userValidation, response)
    yield put(gettopicSuccess(response))
  } catch (error) {
    yield put(gettopicSuccess({ statusFlag: 2 }))
  }
}

function* onAddtopic({ payload: data }) {
  try {
    const response = yield call(addtopiclist, data)
    yield call(userValidation, response)
    yield put(addtopicSuccess(response))
    if (response && response.statusFlag === 1) {
      toast.success(response.message, { autoClose: 2000 });
    } else {
      toast.error(response.message, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(addtopicSuccess({ statusFlag: 2 }))
    toast.error("Subject subcategory added failed!!", { autoClose: 2000 });
  }
}
function* onDeletetopic({ payload: data }) {
  try {
    const response = yield call(deletetopiclist, data)
    yield call(userValidation, response)
    yield put(deletetopicSuccess(response))
    if (response && response.statusFlag === 1) {
      toast.success(response.message, { autoClose: 2000 });
    } else {
      toast.error(response.message, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(deletetopicSuccess({ statusFlag: 2 }))
    toast.error("subjectsubcategory Deleted Failed", { autoClose: 2000 });
  }
}

function* topicSaga() {
  yield takeEvery(GET_TOPIC, fetchtopic)
  yield takeEvery(ADD_TOPIC, onAddtopic)
  yield takeEvery(DELETE_TOPIC, onDeletetopic)
}

export default topicSaga;
