// Created by: Murugeswari Chandrabose
// Created At: 22 Jan 2024

// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import * as Yup from "yup";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import Select from "react-select";
import { gettopic, getmasterlist, addtopic, deletetopic, resettopic } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import * as constants from "../../../constants/layout"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";

const TopicList = () => {
  document.title = "Masters | Topics";

  const dispatch = useDispatch();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [disabledsubcat, setDisabledsubcat] = useState(false);
  const store = useSelector(state => state.Topic)
  const masterstore = useSelector(state => state.ExamBoardCategory)
  const user = getLoggedInUser()
  const [submitbtn, setSubmitbtn] = useState('')
  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef(null);
  const [deleteTrigger, setDeleteTrigger] = useState(false)
  const [masterList, setmasterList] = useState([]);
  const [modal, setModal] = useState(false);
  const [categoryflag, setCategoryFlag] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [board, setBoard] = useState();
  const [isLoading, setLoading] = useState(false);
  const [topic, setTopic] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [topiclist, settopiclist] = useState([])
  const [selectedSubject, setselectedSubject] = useState([]);
  const [selectedSubjectSubcat, setselectedSubjectSubcat] = useState([]);
  const [canView, setCanView] = useState(false)
  const [canCreate, setCanCreate] = useState(false)
  const [canModify, setCanModify] = useState(false)
  const [canRemove, setCanRemove] = useState(false)
  const [selectedPaper, setselectedPaper] = useState(null);
  const [paperList, setPaperList] = useState([])
  const [allSubjectList, setAllSubjectList] = useState([]);
  const [allSubjectSubcatList, setAllSubjectSubcatList] = useState([]);
  const [allSubjectSubcatListMaster, setAllSubjectSubcatListMaster] = useState([]);
  const inputtopRef = useRef(null);

  const [topicEdit, setTopicEdit] = useState();
  const disableButton = () => {
    setButtonDisabled(true);
  };

  const enableButton = () => {
    setButtonDisabled(false);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      topicID: (topic && topic.topicID) || '',
      topicName: (topic && topic.subCategory) || '',
      selectedPaper: (topic && topic.selectedPaper) || null,
      selectedSubject: (topic && topic.selectedSubject) || null,

    },
    validationSchema: Yup.object({
      topicName: Yup.string().required("Please Enter Topic Name"),
      selectedSubject: Yup.object().required("Please select Subject"),
      selectedPaper: Yup.object().required("Please select exam  paper"),
    }),
    onSubmit: (values) => {
      // setSubmitbtn(true)
      setLoading(true)
      // disabled={isEditing}

      if (isEditing) { //update


        console.log('sele', selectedSubject)
        console.log('sele', selectedSubjectSubcat)

        const t_sid = selectedSubject != null ? selectedSubject?.value : 0;
        const t_sscid = selectedSubjectSubcat != null && selectedSubjectSubcat != "" && selectedSubjectSubcat != undefined ? selectedSubjectSubcat?.value : 0;
        const newaddtopic = {
          user_id: Number(user.uid),
          t_name: values.topicName,
          process_type: 'update',
          t_sid: Number(t_sid),
          t_sscid: Number(t_sscid),
          t_id: board.tid,
          ex_paper_id: Number(selectedPaper?.value),
        };
        // update new Exam Board
        disableButton()
        dispatch(addtopic(newaddtopic));
      } else { //add
        const t_sid = selectedSubject != null ? selectedSubject?.value : 0;
        const t_sscid = selectedSubjectSubcat != null ? selectedSubjectSubcat?.value : 0;
        const newaddtopic = {
          user_id: Number(user.uid),
          t_name: values.topicName,
          process_type: 'save',
          t_sid: Number(t_sid),
          t_sscid: Number(t_sscid) || 0,
          ex_paper_id: Number(selectedPaper?.value),
        };
        // save new Exam Board
        disableButton()
        dispatch(addtopic(newaddtopic));
      }

      // toggle();
    },
  });

  const customStyles = {
    option: (styles, state, data) => { // fixed
      return {
        ...styles,
        backgroundColor: state.isDisabled
          ? undefined
          : state.isSelected
            ? '#a6b0cf57'
            : state.isFocused
              ? '#556ee6'
              : 'white',

        color: state.isDisabled
          ? '#ccc'
          : state.isSelected
            ? 'black'
            : state.isFocused
              ? 'white'
              : 'black',

        cursor: state.isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !state.isDisabled
            ? state.isSelected
              ? '#556ee6'
              : '#556ee6'
            : undefined,
        },
        fontWeight: state.value && Number(state.value.split('-')[1]) !== 0 ? '' : '600'
      };
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  // After api call success stop loading
  useEffect(() => {
    enableButton()
    if (store.topic_statusFlag === 1) {
      if (submitbtn) { // add or update then refresh list       
        if (submitbtn !== 'savecontinue') { // If it not savecontinue close popup
          setModal(false);

        }
        setSubmitbtn('')
        gettopList()
        validation.resetForm();

        const event = {
          target: {
            name: 'selectedPaper', value: {
              label: selectedPaper.label,
              value: selectedPaper.value,
            }
          }
        };
        validation.handleChange(event);
        const subject = {
          target: {
            name: 'selectedSubject', value: {
              label: selectedSubject?.label,
              value: selectedSubject?.value,
            }
          }
        };
        validation.handleChange(subject);
        inputtopRef.current.focus()

        // setselectedPaper(null)
        // setselectedSubject(null)
      }
      if (deleteTrigger) { // delete then refresh list
        setDeleteTrigger(false)
        gettopList()
        setDeleteModal(false);
        setBoard('');
      }
      setLoading(false)
      dispatch(resettopic({ statusFlag: 0 }));
    } else if (store.topic_statusFlag === 2) {
      if (submitbtn) {
        setSubmitbtn('')
      }
      if (deleteTrigger) {
        setDeleteTrigger(false)
        setDeleteModal(false);
        setBoard('');
      }
      setLoading(false)
      dispatch(resettopic({ statusFlag: 0 }));
    }
  }, [store.topic_statusFlag])

  useEffect(() => {
    if (modal && inputRef.current && inputFocus) {
      inputRef.current.focus();
      setInputFocus(false);
    }
  }, [modal, inputFocus]);

  useEffect(() => {
    if (modal) {
      setInputFocus(true);
    }
  }, [modal]);

  const gettopList = () => {
    setLoading(true)
    dispatch(gettopic({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    gettopList()
  }, [dispatch]);

  useEffect(() => {
    settopiclist(store.topicList);
  }, [store.topicList]);

  const getmasterlistapi = () => {

    dispatch(getmasterlist({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    getmasterlistapi()
    document.getElementById('headerTitle').innerHTML = 'Topic';
  }, [dispatch]);

  useEffect(() => {
    if (masterstore.masterlist && masterstore.masterlist.subjectList) {
      console.log('master------------------', masterstore?.masterlist)
      setAllSubjectList(masterstore.masterlist.subjectList)
    }
    if (masterstore.masterlist && masterstore.masterlist.subCategryList) {
      setAllSubjectSubcatList(masterstore.masterlist.subCategryList)
      setAllSubjectSubcatListMaster(masterstore.masterlist.subCategryList)
    }
    if (masterstore.masterlist && masterstore.masterlist.examPaperList)
      setPaperList(masterstore.masterlist.examPaperList)

    if (topicEdit) // bind the data after call the master api
      bindEditData()

  }, [masterstore.masterlist])

  const handleSelectCategory = (selectedOption) => {
    setselectedSubject(selectedOption);
    console.log('select-------------------', selectedOption)
    console.log('--=--', allSubjectSubcatListMaster)
    const data = allSubjectSubcatListMaster.filter(e => e.ssc_sid == selectedOption?.value)

    setAllSubjectSubcatList(data)
    setselectedSubjectSubcat("")
    validation.setFieldValue("selectedSubjectSubcat", "")
    console.log('=--=--=-=-', data)
    console.log('data', data?.length)
    if (data?.length === 0) {
      console.log('=-=-==-=-==-=')
      setDisabledsubcat(true)
    } else {
      console.log('=-=-=-==-=====================')
      setDisabledsubcat(false)
    }
    console.log('dfata', data)

  };
  const handleSelectSubCategory = (selectedOption) => {
    setselectedSubjectSubcat(selectedOption);
    // console.log('select-------------------', selectedOption)
    // console.log('--=--', allSubjectSubcatList)
    // const data = allSubjectSubcatList.filter(e => e.ssc_sid == selectedOption?.value)
    // setAllSubjectSubcatList(data)
    // console.log('dfata', data)

  };

  const onClickDelete = (topicData) => {
    setBoard(topicData);
    setDeleteModal(true);
  };
  const handleSelectPaper = (selectedOption) => {
    console.log('selecte', selectedOption)
    console.log('alll-====0', allSubjectList)
    var filtered_subjects = allSubjectList.filter(e => Number(e.expid) == Number(selectedOption.value))
    setmasterList(filtered_subjects)
    setselectedPaper(selectedOption);
    setselectedSubject(null)
  };
  const handleDeleteOrder = () => {
    setDeleteTrigger(true)
    setLoading(true)
    dispatch(deletetopic({ user_id: Number(user.uid), t_id: Number(board.tid) }))
  };

  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        // accessorKey: 'action',
        // enableColumnFilter: false,
        // enableSorting: true,

        width: '8%',
        // width: (cell) => cell.value.length * 10, 
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.Topic && f.urp_type === constants.menuTypes.Modify) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const topicData = cellProps.row.original;
                      handleEditClick(topicData);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>)}
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.Topic && f.urp_type === constants.menuTypes.Remove) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      const topicData = cellProps.row.original; onClickDelete(topicData);
                    }}>
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </div>)}
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        accessorKey: 'sno',
        width: '8%',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'pl-25',
        cell: ({ row, table }) =>
          (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
      },

      {
        header: (

          <span style={{ cursor: 'pointer' }}>
            Topic Name ▼
          </span>

        ),

        accessorKey: 'topicname',

        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <div style={{ width: "100%", "text-wrap": 'balance' }}>
              {cellProps.row.original.topicname}
            </div>
          );
        }
      },
      // {
      //   header: (
      //     <span style={{ cursor: 'pointer' }}>
      //       Topic Name ▼
      //     </span>
      //   ),
      //   width: '30%',
      //   accessorKey: 'topicname',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps) => {
      //     return (
      //         <div style={{width:"100%",  "text-wrap": 'balance' }}>
      //             {cellProps.row.original.topicname}
      //         </div>
      //     );
      // }
      // },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Exam Paper ▼
          </span>
        ),

        accessorKey: 'exampaper',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Subject ▼
          </span>
        ),

        accessorKey: 'subjectname',
        enableColumnFilter: false,
        enableSorting: true,
        //   cell: (cellProps) => {
        //     return (
        //         <div style={{width:"100%",  "text-wrap": 'balance' }}>
        //             {cellProps.row.original.subjectname}
        //         </div>
        //     );
        // }
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Subject Subcategory ▼
          </span>
        ),

        accessorKey: 'subcategoryname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            No.of Questions ▼
          </span>
        ),

        accessorKey: 'questioncount',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'txt-center'
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Created By ▼
          </span>
        ),

        accessorKey: 'createdat',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Updated By ▼
          </span>
        ),

        accessorKey: 'updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const mk_stsid = cellProps.row.original.updated;

          return (
            <div className="d-flex align-items-center">
              {mk_stsid ? (
                <span>{mk_stsid}</span>

              ) : (
                <span>-</span>
              )}
            </div>
          );
        },
      }

    ],
    []
  );

  const handleAddClick = () => {
    enableButton()
    setTopicEdit(null)
    validation.resetForm();
    setIsEditing(false)
    setBoard('')
    setDisabledsubcat(false)
    setselectedPaper(null)
    setselectedSubjectSubcat(null)
    setselectedSubject(null)
    toggle();
  };

  const handleEditClick = (topicData) => {
    enableButton()
    getmasterlistapi() // Call the master api
    setTopicEdit(topicData) // bind the data for accessing after the api call

  };

  const bindEditData = () => {



    validation.resetForm();
    setBoard(topicEdit);
    toggle();
    setIsEditing(true);


    var filtered_subjects = allSubjectList.filter(e => Number(e.expid) == Number(topicEdit?.expid))
    if (filtered_subjects)
      setmasterList(filtered_subjects)
    console.log('=-=-=--topicedit', topicEdit)
    if (topicEdit?.sscid == 0) {
      setDisabledsubcat(true)
    }
    // Update this part to set the selected Subject and Subject Subcategory Name
    validation.setValues({
      topicName: topicEdit?.topicname,
      selectedPaper: {
        label: topicEdit?.exampaper,
        value: topicEdit?.expid

      },
      selectedSubject: {
        label: topicEdit?.subjectname,
        value: topicEdit?.sid,
      },
      selectedSubjectSubcat: {
        label: topicEdit?.subcategoryname,
        value: topicEdit?.sscid
      }
    });

    setselectedSubject({
      label: topicEdit?.subjectname,
      value: topicEdit?.sid,
    });
    setselectedSubjectSubcat({
      label: topicEdit?.subcategoryname,
      value: topicEdit?.sscid,
    });
    setselectedPaper({
      label: topicEdit?.exampaper,
      value: topicEdit?.expid

    })
    // Manually trigger the onChange event for the Select component
    // const event = { target: { name: 'selectedSubject', value: validation.values.selectedSubject } };
    // validation.handleChange(event);
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setDisabledsubcat(false)

    } else {
      setModal(true);
    }
  };
  //meta title
  // document.title = "Masters | Topics";

  //USer role credentials
  useEffect(() => {
    if (user && user.user_previleges) {
      let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.Topic)
      if (page_menu_list) {
        setCanView(page_menu_list.find(f => f.urp_type === constants.menuTypes.View)?.urp_smid ? true : false)
        setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid ? true : false)
        setCanModify(page_menu_list.find(f => f.urp_type === constants.menuTypes.Modify)?.urp_smid ? true : false)
        setCanRemove(page_menu_list.find(f => f.urp_type === constants.menuTypes.Remove)?.urp_smid ? true : false)
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Topic" /> */}
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={topiclist || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        isAddButton={canCreate}
                        buttonName="Add"
                        SearchPlaceholder="Search..."
                        pagination="pagination"
                        handleUserClick={handleAddClick}
                        // isCustomPageSize={true}
                        //  paginationWrapper='dataTables_paginate paging_simple_numbers'

                        buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                        theadClass="table-light"

                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>
          <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEditing ? "Edit Topic Name" : "Add Topic Name"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Exam Paper<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selectedPaper}
                        onChange={(selectedOption) => {
                          handleSelectPaper(selectedOption);
                          validation.setFieldValue("selectedPaper", selectedOption)
                        }}
                        options={paperList.map((paper) => ({
                          label: paper.exp_name,
                          value: paper.expid,
                        }))}
                        ref={inputRef}
                        validate={{
                          required: { value: true },
                        }}

                        className={`select2-selection ${validation.touched.selectedPaper && validation.errors.selectedPaper ? 'is-invalid' : ''}`}


                        invalid={
                          validation.touched.selectedPaper && validation.errors.selectedPaper ? true : false
                        }
                      />
                      {validation.touched.selectedPaper && validation.errors.selectedPaper ? (
                        <FormFeedback type="invalid">{validation.errors.selectedPaper}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Subject<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selectedSubject}
                        onChange={(selectedOption) => {
                          handleSelectCategory(selectedOption);
                          validation.setFieldValue("selectedSubject", selectedOption)
                        }}
                        options={masterList.map((board) => ({
                          label: board.subjectname,
                          value: board.subjectid,
                        }))}
                        styles={customStyles}
                        validate={{
                          required: { value: true },
                        }}

                        className={`select2-selection ${validation.touched.selectedSubject && validation.errors.selectedSubject ? 'is-invalid' : ''}`}

                        invalid={
                          validation.touched.selectedSubject && validation.errors.selectedSubject ? true : false
                        }

                      />
                      {validation.touched.selectedSubject && validation.errors.selectedSubject ? (
                        <FormFeedback type="invalid">{validation.errors.selectedSubject}</FormFeedback>
                      ) : null}

                    </div>
                    <div className="mb-3">
                      <Label>Subcategory
                        {
                          !disabledsubcat ?

                            <span style={{ color: 'red' }}>*</span>
                            : ""}
                      </Label>
                      <Select
                        value={selectedSubjectSubcat}
                        onChange={(selectedOption) => {
                          handleSelectSubCategory(selectedOption);
                          validation.setFieldValue("selectedSubjectSubcat", selectedOption)
                        }}
                        isDisabled={disabledsubcat}
                        options={allSubjectSubcatList.map((board) => ({
                          label: board.ssc_name,
                          value: board.sscid,
                        }))}
                        styles={customStyles}
                        validate={{
                          required: { value: true },
                        }}

                        className={`select2-selection ${validation.touched.selectedSubjectSubcat && validation.errors.selectedSubjectSubcat ? 'is-invalid' : ''}`}

                        invalid={
                          validation.touched.selectedSubjectSubcat && validation.errors.selectedSubjectSubcat ? true : false
                        }

                      />
                      {validation.touched.selectedSubjectSubcat && validation.errors.selectedSubjectSubcat ? (
                        <FormFeedback type="invalid">{validation.errors.selectedSubjectSubcat}</FormFeedback>
                      ) : null}

                    </div>

                    <div className="mb-3">
                      <Label>Topic Name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        name="topicName"
                        type="text"
                        maxLength={constants.textboxLength.t_name}
                        placeholder="Topic"
                        validate={{
                          required: { value: true },
                        }}
                        innerRef={inputtopRef}

                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.topicName || ""}
                        invalid={
                          validation.touched.topicName && validation.errors.topicName ? true : false
                        }
                      />
                      {validation.touched.topicName && validation.errors.topicName ? (
                        <FormFeedback type="invalid">{validation.errors.topicName}</FormFeedback>
                      ) : null}
                    </div>



                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button color="success"
                        type="submit"
                        className="save-user"
                        disabled={isButtonDisabled}
                        onClick={() => {
                          setSubmitbtn('save')
                        }}
                      >
                        {!!isEditing ? "Update" : "Save"}
                      </Button>
                      {!isEditing && (
                        <Button color="primary" type="submit" className="save-and-continue-user"
                          onClick={() => {
                            setSubmitbtn('savecontinue')
                          }}
                          disabled={isButtonDisabled}
                          style={{ marginLeft: '10px' }}>
                          Save and Continue
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
TopicList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default TopicList;