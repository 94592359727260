import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              {/* {new Date().getFullYear()} © Skote. */}
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Powered by Trio-S
                {/* <a onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a> */}
              </div>


            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
