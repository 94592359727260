import { call, put, takeEvery } from "redux-saga/effects";
import { GET_EXAMPAPER, ADD_NEW_EXAMPAPERS, DELETE_EXAMPAPERS } from "./actionTypes";
import {
  getExamPaperSuccess, deleteExamPaperSuccess, addNewExamPaperSuccess
} from "./actions";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { getExamPaperList, addExamPaper, deleteExamPaper, userValidation } from "helpers/fakebackend_helper";

function* fetchExamPapers({ payload: data }) {
  try {
    const response = yield call(getExamPaperList, data)
    yield call(userValidation, response)
    yield put(getExamPaperSuccess(response))
  } catch (error) {
    yield put(getExamPaperSuccess({ statusFlag: 2 }))
  }
}

function* onAddNewExamPaper({ payload: data }) {
  try {
    const response = yield call(addExamPaper, data)
    yield call(userValidation, response)
    yield put(addNewExamPaperSuccess(response))
    if (response && response.statusFlag === 1) {
      toast.success(response.message, { autoClose: 2000 });
    } else {
      toast.error(response.message, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(addNewExamPaperSuccess({ statusFlag: 2 }))
    toast.error("Exam Paper Added Failed", { autoClose: 2000 });
  }
}

function* onDeleteExamPaper({ payload: data }) {
  try {
    const response = yield call(deleteExamPaper, data)
    yield call(userValidation, response)
    yield put(deleteExamPaperSuccess(response))
    if (response && response.statusFlag === 1) {
      toast.success(response.message, { autoClose: 2000 });
    } else {
      toast.error(response.message, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(deleteExamPaperSuccess({ statusFlag: 2 }))
    toast.error("Exam Board Deleted Failed", { autoClose: 2000 });
  }
}

function* exampaperSaga() {
  yield takeEvery(GET_EXAMPAPER, fetchExamPapers)
  yield takeEvery(ADD_NEW_EXAMPAPERS, onAddNewExamPaper)
  yield takeEvery(DELETE_EXAMPAPERS, onDeleteExamPaper)

}

export default exampaperSaga;
