import {
    GET_TOPIC,
    GET_TOPIC_SUCCESS,
    ADD_TOPIC,
    ADD_TOPIC_SUCCESS,
    DELETE_TOPIC,
    DELETE_TOPIC_SUCCESS,
    RESET_STORE_TOPIC,
} from "./actionTypes"
  
  export const gettopic = (data) => ({
    type: GET_TOPIC,
    payload: data
  })
  export const gettopicSuccess = topicList => ({
    type: GET_TOPIC_SUCCESS,
    payload: topicList,
  })
  export const addtopic = data => ({
    type: ADD_TOPIC,
    payload: data,
  })
  
  export const addtopicSuccess = data => ({
    type: ADD_TOPIC_SUCCESS,
    payload: data,
  })

  export const deletetopic = data => ({
    type: DELETE_TOPIC,
    payload: data,
  })
  
  export const deletetopicSuccess = data => ({
    type: DELETE_TOPIC_SUCCESS,
    payload: data,
  })

  export const resettopic = data => ({
    type:RESET_STORE_TOPIC,
    payload: data,
  })

 

  