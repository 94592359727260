import {
    GET_STUDYMATERIAL,
    GET_STUDYMATERIAL_SUCCESS,
    DELETE_STUDYMATERIAL,
    DELETE_STUDYMATERIAL_SUCCESS,
    RESET_STORE_STUDYMATERIAL,
} from "./actionTypes"
  
  export const getstudymaterial = (data) => ({
    type: GET_STUDYMATERIAL,
    payload: data
  })
  export const getstudymaterialSuccess = subjectList => ({
    type: GET_STUDYMATERIAL_SUCCESS,
    payload: subjectList,
  })
   export const deletestudymaterial = data => ({
    type: DELETE_STUDYMATERIAL,
    payload: data,
  })
  
  export const deletestudymaterialSuccess = data => ({
    type: DELETE_STUDYMATERIAL_SUCCESS,
    payload: data,
  })

  export const resetstudymaterial = data => ({
    type:RESET_STORE_STUDYMATERIAL,
    payload: data,
  })

 

  