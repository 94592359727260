import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {GET_STUDYMATERIAL, DELETE_STUDYMATERIAL} from "./actionTypes";
import {getstudymaterialSuccess, deletestudymaterialSuccess} from "./actions";

//Include Both Helper File with needed methods
import { getstudymaterialList, deletestudymaterial, userValidation} from "helpers/fakebackend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchstudymaterial({ payload: data}) {
  try {
    const response = yield call(getstudymaterialList, data)
    yield call(userValidation, response)
    yield put(getstudymaterialSuccess(response))
  } catch (error) {
    yield put(getstudymaterialSuccess({statusFlag: 2}))
  }
}

function* onDeletestudymaterial({ payload: data }) {
    try {
        const response = yield call(deletestudymaterial, data)
        yield call(userValidation, response)
        yield put(deletestudymaterialSuccess(response))
        console.log(response, 'response')
        if (response && response.statusFlag === 1) {
          toast.success(response.message, { autoClose: 2000 });
        } else {
          toast.error(response.message, { autoClose: 2000 });
        }      
    } catch (error) {
        yield put(deletestudymaterialSuccess({statusFlag: 2}))
        toast.error("subject Deleted Failed", { autoClose: 2000 });
    }
  }

function* studymaterialSaga() {
  yield takeEvery(GET_STUDYMATERIAL, fetchstudymaterial)
  yield takeEvery(DELETE_STUDYMATERIAL, onDeletestudymaterial)
 }

export default studymaterialSaga;
