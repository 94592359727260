import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import { getExamBoards, addNewExamBoard, resetStore, deleteExamBoard } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import * as constants from "../../../constants/layout"
import { useSelector, useDispatch } from "react-redux";
//import { getHeader   } from "../../../components/VerticalLayout/Header"
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";

const BoardList = () => {
  document.title = "Masters | Exam Board";
  const dispatch = useDispatch();
  const store = useSelector(state => state.ExamBoard);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef(null);
  const [board, setBoard] = useState(null);
  const [examBoards, setexamBoards] = useState([]);
  const [submitbtn, setSubmitbtn] = useState('');
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const user = getLoggedInUser();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      boardName: (board && board.boardName) || ''
    },
    validationSchema: Yup.object({
      boardName: Yup.string().required("Please Enter Board Name")
    }),
    onSubmit: (values) => {
      setLoading(true);
      const newExamBoard = {
        user_id: Number(user.uid),
        examBoardName: values.boardName,
        process_type: isEdit ? 'update' : 'save',
        ex_board_id: isEdit ? board.exid : undefined
      };
      disableButton();
      dispatch(addNewExamBoard(newExamBoard));
    },
  });
  const disableButton = () => {
    setButtonDisabled(true);
  };

  const enableButton = () => {
    setButtonDisabled(false);
  };
  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        // accessorKey: 'action',
        // enableColumnFilter: false,
        // enableSorting: true,

        width: '8%',
        // width: (cell) => cell.value.length * 10, 
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.ExamBoard && f.urp_type === constants.menuTypes.Modify) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const boardData = cellProps.row.original;
                      onEditClick(boardData);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>)}
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.ExamBoard && f.urp_type === constants.menuTypes.Remove) && (
                  
                  <div
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const boardData = cellProps.row.original; onClickDelete(boardData);
                  }}>
                  
                
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </div>)}
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        accessorKey: 'sno',
        width: '8%',
        enableColumnFilter: false,
        className: 'pl-25',
        cell: ({ row, table }) =>
          (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1

      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Exam Board Name ▼
          </span>
        ),

        accessorKey: 'ex_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Created By ▼
          </span>
        ),

        accessorKey: 'ex_created',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
          Updated By ▼
        </span>
        ),
        
        accessorKey: 'ex_updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const mk_stsid = cellProps.row.original.ex_updated;

          return (
            <div className="d-flex align-items-center">
              {mk_stsid  ? (
                <span>{mk_stsid}</span>
              ) : (
              <span>-</span>
              )}
            </div>
          );
        },
      }
    ],
    []
  );


  const handleAddClick = () => {
    enableButton()
    validation.resetForm();
    setIsEdit(false);
    setBoard('');
    toggle();
  };

  const toggle = () => {
    setModal(prev => !prev);
  };

  useEffect(() => {
    if (modal && inputRef.current && inputFocus) {
      inputRef.current.focus();
      setInputFocus(false);
    }
  }, [modal, inputFocus]);

  useEffect(() => {
    if (modal) {
      setInputFocus(true);
    }
  }, [modal]);

  const onEditClick = (boardData) => {
    enableButton()
    validation.resetForm();
    setBoard(boardData);
    toggle();
    setIsEdit(true);
    validation.setValues({ "boardName": boardData.ex_name });
  }

  const onClickDelete = (boardData) => {
    setBoard(boardData);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    setDeleteTrigger(true);
    setLoading(true);
    dispatch(deleteExamBoard({ user_id: Number(user.uid), ex_board_id: Number(board.exid) }))
  };

  const getExamBoardList = () => {
    setLoading(true);
    dispatch(getExamBoards({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    getExamBoardList()
    document.getElementById('headerTitle').innerHTML = 'Exam Board';
  }, [dispatch]);

  useEffect(() => {
    setexamBoards(store.examBoards);
  }, [store.examBoards])

  useEffect(() => {
    enableButton()

    if (store.exboard_statusFlag === 1) {
      if (submitbtn) {
        if (submitbtn !== 'savecontinue') {
          setModal(false);
          
        }
        validation.resetForm();
        setSubmitbtn('');
        // inputRef.current.focus();
        // setInputFocus(false);
        getExamBoardList();
      }
      if (deleteTrigger) {
        setDeleteTrigger(false);
        getExamBoardList();
        setDeleteModal(false);
        setBoard('');
      }
      setLoading(false);
      dispatch(resetStore({statusFlag: 0}));
       // Set autofocus after value reset
       if (modal && inputRef.current) {
        inputRef.current.focus();
      }
    } else if (store.exboard_statusFlag === 2) {
      if (submitbtn) {
        setSubmitbtn('');
      }
      if (deleteTrigger) {
        setDeleteTrigger(false);
        setDeleteModal(false);
        setBoard('');
      }
      setLoading(false);
      dispatch(resetStore({ statusFlag: 0 }));
    }
  }, [store.exboard_statusFlag]);



  useEffect(() => {
    if (user && user.user_previleges) {
      let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.ExamBoard)
      if (page_menu_list) {
        setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid ? true : false)
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Board" /> */}
          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} />
              :
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={examBoards || []}
                      isGlobalFilter={true}
                      isPagination={true}
                      isAddButton={canCreate}
                      buttonName="Add"
                      SearchPlaceholder="Search..."
                      pagination="pagination"
                      handleUserClick={handleAddClick}
                      // isCustomPageSize={true}
                      buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            }
          </Row>
          <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Exam Board" : "Add Exam Board"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Exam Board Name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        name="boardName"
                        type="text"
                        placeholder="Exam Board Name"
                        validate={{ required: { value: true } }}
                        maxLength={constants.textboxLength.ex_name}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.boardName || ""}
                        invalid={validation.touched.boardName && validation.errors.boardName}
                        innerRef={inputRef}

                      />
                      {validation.touched.boardName && validation.errors.boardName ? (
                        <FormFeedback type="invalid">{validation.errors.boardName}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="success"
                        type="submit"
                        className="save-user"
                        onClick={() => setSubmitbtn('savebtn')}
                        disabled={isButtonDisabled}

                      >
                        {!!isEdit ? "Update" : "Save"}
                      </Button>
                      {!isEdit && (
                        <Button
                          color="primary"
                          type="submit"
                          className="save-and-continue-user"
                          onClick={() => setSubmitbtn('savecontinue')}
                          style={{ marginLeft: '10px' }}
                          disabled={isButtonDisabled}

                        >
                          Save and Continue
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}

export default BoardList;