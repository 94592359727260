export const GET_EXAMBOARDSCATEGORY = "GET_EXAMBOARDSCATEGORY"
export const GET_EXAMBOARDSCATEGORY_SUCCESS = "GET_EXAMBOARDSCATEGORY_SUCCESS"
export const ADD_NEW_EXAM_BOARDCATEGORY = "ADD_NEW_EXAM_BOARDCATEGORY"
export const ADD_NEW_EXAM_BOARDCATEGORY_SUCCESS = "ADD_NEW_EXAM_BOARDCATEGORY_SUCCESS"
export const RESET_STORE_EXAM_BOARDCATEGORY = "RESET_STORE_EXAM_BOARDCATEGORY"
export const DELETE_EXAM_BOARDCATEGORY = " DELETE_EXAM_BOARDCATEGORY"
export const DELETE_EXAM_BOARDCATEGORY_SUCCESS = "DELETE_EXAM_BOARDCATEGORY_SUCCESS"
export const GET_MASTERLIST = "GET_MASTERLIST"
export const  GET_MASTERLIST_SUCCESS = " GET_MASTERLIST_SUCCESS"
export const GETQA_MASTERLIST = "GETQA_MASTERLIST"
export const  GETQA_MASTERLIST_SUCCESS = " GETQA_MASTERLIST_SUCCESS"

