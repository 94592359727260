import {
    GET_SUBJECTS,
    GET_SUBJECTS_SUCCESS,
    ADD_SUBJECT,
    ADD_SUBJECT_SUCCESS,
    DELETE_SUBJECT,
    DELETE_SUBJECT_SUCCESS,
    RESET_STORE_SUBJECT,
} from "./actionTypes"
  
  export const getsubject = (data) => ({
    type: GET_SUBJECTS,
    payload: data
  })
  export const getsubjectSuccess = subjectList => ({
    type: GET_SUBJECTS_SUCCESS,
    payload: subjectList,
  })
  export const addsubject = data => ({
    type: ADD_SUBJECT,
    payload: data,
  })
  
  export const addsubjectSuccess = data => ({
    type: ADD_SUBJECT_SUCCESS,
    payload: data,
  })

  export const deletesubject = data => ({
    type: DELETE_SUBJECT,
    payload: data,
  })
  
  export const deletesubjectSuccess = data => ({
    type: DELETE_SUBJECT_SUCCESS,
    payload: data,
  })

  export const resetsubject = data => ({
    type:RESET_STORE_SUBJECT,
    payload: data,
  })

 

  