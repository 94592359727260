
import {
    ADD_GENERALSETTINGS,
    ADD_GENERALSETTINGS_SUCCESS,
    EDITLOAD_GENERALSETTINGS,
    EDITLOAD_GENERALSETTINGS_SUCCESS,
  } from "./actionTypes"
  
export const addgeneralsettings = data => ({
    type: ADD_GENERALSETTINGS,
    payload: data,
  })
  
  export const addgeneralsettingsSuccess = data => ({
    type: ADD_GENERALSETTINGS_SUCCESS,
    payload: data,
  })
export const editloadGeneralSettings = data => ({
    type: EDITLOAD_GENERALSETTINGS,
    payload: data,
  })
  
  export const editloadgeneralSettingsSuccess = blueprintdata  => ({
    type: EDITLOAD_GENERALSETTINGS_SUCCESS,
    payload: blueprintdata ,
  })