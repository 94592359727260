import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import withRouter from "components/Common/withRouter";
import ChangePasswordModal from "../../../components/Common/changepasswordmodal";
import { ToastContainer } from "react-toastify";
import LogoutModal from "components/Common/LogoutModal";
import { useNavigate } from "react-router-dom";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("");
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    if (sessionStorage.getItem('SESSION_KEY')) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(sessionStorage.getItem('SESSION_KEY'));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(sessionStorage.getItem('SESSION_KEY'));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  useEffect(() => {
    // Fetch the user's name from localStorage
    const authUser = JSON.parse(sessionStorage.getItem('SESSION_KEY'));
    if (authUser && authUser.u_name) {
      // Extract the first letter of the name
      setusername(authUser.u_name.charAt(0).toUpperCase());
    }
  }, []);

  const openChangePasswordModal = () => {
    setChangePasswordModal(true);
  };

  const closeChangePasswordModal = () => {
    setChangePasswordModal(false);
  };
  const navigateLogout = () => {
    navigate('/logout')
  }

  return (
    <React.Fragment>
      <ChangePasswordModal
        isOpen={changePasswordModal}
        toggle={closeChangePasswordModal}
      />
      <LogoutModal
        show={logoutModal}
        onDeleteClick={navigateLogout}
        onCloseClick={() => setLogoutModal(false)}
      />
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >

          {/* Display the first letter of the username as the profile image */}
          <div className="rounded-circle header-profile-user" style={{ width: '40px', height: '40px', background: '#007bff', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}>
            {username}
          </div>

        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" onClick={openChangePasswordModal}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Change Password")}{" "}
          </DropdownItem>

          <div className="dropdown-divider" />
          <div className="dropdown-item pointer" onClick={() => setLogoutModal(true)}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </div>
          {/* <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link> */}
        </DropdownMenu>
      </Dropdown>
      <ToastContainer />
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(ProfileMenu));
