import {
  GET_EXAMBOARDS_SUCCESS,
  ADD_NEW_EXAM_BOARD_SUCCESS,
  RESET_STORE_EXAM_BOARD,
  DELETE_EXAM_BOARD_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  examBoards: [],
  exboard_statusFlag: 0
}

const ExamBoard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EXAMBOARDS_SUCCESS:
      return {
        ...state,
        examBoards: action.payload.examBoards,
        exboard_statusFlag: action.payload.statusFlag
      }

    case ADD_NEW_EXAM_BOARD_SUCCESS:
      return {
          ...state,
          exboard_statusFlag: action.payload.statusFlag,
      };
    
    case RESET_STORE_EXAM_BOARD:
      return {
        ...state,
        exboard_statusFlag: action.payload.statusFlag,
      };

      case DELETE_EXAM_BOARD_SUCCESS:
        return {
            ...state,
            exboard_statusFlag: action.payload.statusFlag,
        };

    default:
      return state
  }
}

export default ExamBoard
