import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Container, Button, Card, Row, Label, Col, Input, CardBody, Form, Collapse, Badge } from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";
import Spinners from '../../components/Common/Spinner';
import Select from "react-select";
import { getmasterlist } from "../../store/actions";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import JobFilter from './JobFilter';
import QuestionData from './QuestionData';
import { getqamasterlist } from "../../store/actions";
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import * as constants from "../../constants/layout"
import axios from "axios";
import * as url from "../../../src/helpers/url_helper";
import { logoutUser } from "store/auth/login/actions";
import { getRefreshToken } from "../../../src/helpers/fakebackend_helper";
import { UncontrolledTooltip } from "reactstrap";
import TableContainer from 'components/Common/TableContainer';
import PaginationTableContainer from 'components/Common/PaginationTableContainer';
const ExcelJS = require('exceljs');
import DeleteModal from "../../components/Common/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import QuestionModal from "../../components/Common/questionModal"

const QuestionList = () => {
  const navigate = useNavigate();
  document.title = "Question & Answers";

  const [board, setBoard] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const store = useSelector(state => state.ExamBoardCategory)
  const masterstore = useSelector(state => state.ExamBoardCategory)

  const user = getLoggedInUser()
  const [isLoading, setLoading] = useState(false);
  const flatpickrRef = React.useRef(null);

  const [optionBoardName, setOptionBoardName] = useState([]);
  const [optionSubjectName, setOptionSubjectName] = useState([]);
  const [optionAllSubjectName, setOptionAllSubjectName] = useState([]);
  const [optionExamPaper, setOptionExamPaper] = useState([]);
  const [optionAllExamPaper, setOptionAllExamPaper] = useState([]);

  const [optionSubject, setOptionSubject] = useState([]);
  const [optionSubCategory, setOptionSubCategory] = useState([]);
  const [optionLanguage, setOptionLanguage] = useState([]);
  const [optionLanguageAll, setOptionLanguageAll] = useState([]);
  const [optionSubjectAll, setOptionSubjectAll] = useState([]);
  const [optionSubCategoryAll, setOptionSubCategoryAll] = useState([]);

  const [optionTopicName, setOptionTopicName] = useState([]);
  const [optionAllTopicName, setOptionAllTopicName] = useState([]);
  const [optionStatus, setOptionStatus] = useState([]);
  const [approveStatus, setApproveStatus] = useState([]);
  const [questionData1, setQuestionData1] = useState([])

  const [examLevel, setExamLevel] = useState([{
    label: "All",
    value: 0
  }])
  const [topicModal, setTopicModal] = useState(false);

  const [examLevelOptions, setExamLevelOptions] = useState([])
  const [examLevelOptionsAll, setExamLevelOptionsAll] = useState([])
  const [selectedBoardName, setselectedBoardName] = useState({
    label: "All",
    value: 0
  });
  const [selectedTopicName, setselectedTopicName] = useState({
    label: "All",
    value: 0
  });
  const [selectedSubjectName, setselectedSubjectName] = useState({
    label: "All",
    value: 0
  });
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState({
    label: "All",
    value: 0
  });
  const [selectedExamPaper, setselectedExamPaper] = useState({
    label: "All",
    value: 0
  });

  const [selectedLanguage, setSelectedLanguage] = useState({
    label: "All",
    value: 0
  });

  const [selectedStatus, setselectedStatus] = useState({
    label: "All",
    value: 0
  });
  const [selectedApproveStatus, setselectedApproveStatus] = useState({
    label: "All",
    value: 0
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    selectedBoardName: 0,
    selectedSubjectName: 0 - 0,
    selectedTopicName: 0,
    selectedStatus: 0,
    selectedApproveStatus: 0,
    selectedExamPaper: 0
  });
  const toggleTopic = () => {
    setTopicModal(!topicModal);
  };
  const [searchVal, setSearchVal] = useState('');
  const [searchData, setSearchData] = useState('');
  const customStyles = {
    option: (styles, state, data) => { // fixed
      return {
        ...styles,
        backgroundColor: state.isDisabled
          ? undefined
          : state.isSelected
            ? '#a6b0cf57'
            : state.isFocused
              ? '#556ee6'
              : 'white',

        color: state.isDisabled
          ? '#ccc'
          : state.isSelected
            ? 'black'
            : state.isFocused
              ? 'white'
              : 'black',

        cursor: state.isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !state.isDisabled
            ? state.isSelected
              ? '#556ee6'
              : '#556ee6'
            : undefined,
        },
        fontWeight: state.value && Number(state?.value?.split('-')[1]) !== 0 ? '' : '600'
      };
    }
  };
  // useEffect(() => {
  //   const disableBackButton = (event) => {
  //     window.history.pushState(null, document.title, window.location.href);
  //     event.preventDefault();
  //   };

  //   window.addEventListener('popstate', disableBackButton);

  //   return () => {
  //     window.removeEventListener('popstate', disableBackButton);
  //   };
  // }, []);


  const getQuestion = (question) => {
    console.log('-------')
    console.log('-----------', question)
    setTopicModal(true)
    setQuestionData1(question)
  }


  // const [questionData,setQuestionData] = useState([]);
  const toggleFilter = () => setIsFilterOpen(!isFilterOpen);
  function handleSelectBoardName(selectedBoardName) {
    setselectedBoardName(selectedBoardName);

    if (selectedBoardName?.value == '0' || selectedBoardName == null || selectedBoardName == undefined) {
      setOptionExamPaper(optionAllExamPaper)
    }
    else {
      var filtered_exampaper_option = optionAllExamPaper.map(e => {
        var exctid_arr = e?.exctid?.split(',');
        if (exctid_arr?.length > 0) {
          if (exctid_arr?.includes(selectedBoardName.value)) {
            return e;
          }
        }

      })
      filtered_exampaper_option = filtered_exampaper_option.filter(e => e != undefined);
      setOptionExamPaper(filtered_exampaper_option)

    }
    setselectedExamPaper({
      label: "All",
      value: 0
    })

  }
  const handleSelectPaper = (varselectedPaper) => {
    setselectedExamPaper(varselectedPaper)
    if (varselectedPaper?.value == '0' || varselectedPaper == null || varselectedPaper == undefined) { // if select all option for board, need to checko only exam paper 
      setOptionSubject(optionSubjectAll)
      setOptionSubCategory(optionSubCategoryAll)
      setOptionLanguage(optionLanguageAll)
      setOptionTopicName(optionAllTopicName)
      setselectedSubjectName({
        label: "All",
        value: 0
      })
      setSelectedSubCategoryName({
        label: "All",
        value: 0
      })
      setselectedTopicName({
        label: "All",
        value: 0
      })
      setSelectedLanguage({
        label: "All",
        value: 0
      })
    }
    else {
      setselectedSubjectName({
        label: "All",
        value: 0
      })
      setSelectedSubCategoryName({
        label: "All",
        value: 0
      })
      setselectedTopicName({
        label: "All",
        value: 0
      })
      var filter_sub = optionSubjectAll.filter(e => Number(e.expid) == varselectedPaper?.value)
      setOptionSubject([{ subjectname: "All", subjectid: "0" }, ...filter_sub])
      var filter_lang = optionAllExamPaper.filter(e => Number(e.expid) == varselectedPaper?.value)
      filter_lang.map((row) => {
        if (row.lang === '1') {
          setSelectedLanguage({ label: 'English', value: row.lang })
          setOptionLanguage([{ l_name: "All", lid: "0" }, { l_name: 'English', lid: row.lang }])
        }
        else if (row.lang === '2') {
          setSelectedLanguage({ label: 'Regional', value: row.lang })
          setOptionLanguage([{ l_name: "All", lid: "0" }, { l_name: 'Regional', lid: row.lang }])
        }
        if (row.lang !== '1' && row.lang !== '2') {
          setSelectedLanguage({ label: 'All', value: '0' })
          setOptionLanguage(optionLanguageAll)
        }
      })
    }
  };
  function filter_exampaper_subject(varselectedPaper) {
    var filtered_subject_option = optionAllSubjectName.map(e => {
      var exctid_arr = e?.exctid?.split(',');

      if (exctid_arr?.length > 0) {
        if (selectedBoardName?.value == '0' || selectedBoardName == null || selectedBoardName == undefined) { // if select all option for board, need to checko only exam paper 
          if (e.s_expid == varselectedPaper?.value) {
            return e;
          }
        }
        else {
          if (exctid_arr?.includes(selectedBoardName?.value) && e.s_expid == varselectedPaper.value) {
            return e;
          }
        }
      }

    })
    filtered_subject_option = filtered_subject_option.filter(e => e != undefined);
    setOptionSubjectName([{ s_name: "All", sid: "0", sscid: "0", exctid: "0" }, ...filtered_subject_option])

  }
  function filter_exampaper_topic(varselectedPaper) {
    var filtered_topic_option = optionAllTopicName.filter(e => e.t_expid == varselectedPaper.value);
    filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
    setOptionTopicName([{ topicname: "All", topicid: "0" }, ...filtered_topic_option])
  }

  function handleSelectTopicName(selectedTopicName) {
    setselectedTopicName(selectedTopicName);
  }

  // function handleSelectSubjectName(varselectedSubjectName) {

  //   if (varselectedSubjectName?.label != 'All' && varselectedSubjectName != null && varselectedSubjectName != undefined) {// if select all option for board, need to checko only exam paper 
  //     handleSelectPaper(selectedExamPaper)
  //     const [sid, sscid] = varselectedSubjectName.value;
  //     var filtered_topic_option = optionAllTopicName.filter(e => e.subjectid == sid && e.subcategoryid == sscid);
  //     filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
  //     setOptionTopicName([{ topicname: "All", topicid: "0" }, ...filtered_topic_option])
  //   }
  //   else {

  //     handleSelectPaper(selectedExamPaper)
  //   }

  //   setselectedSubjectName(varselectedSubjectName);
  //   setselectedTopicName({
  //     label: "All",
  //     value: 0
  //   })

  // }



  function handleSelectStatus(selectedStatus) {
    setselectedStatus(selectedStatus);
  }
  function handleSelectApprove(selectedStatus) {
    setselectedApproveStatus(selectedStatus);
  }

  const getqamasterlistapi = () => {
    setLoading(true)
    dispatch(getqamasterlist({ user_id: Number(user.uid) }));
    dispatch(getmasterlist({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Q&A';
    setFilterData({
      selectedBoardName: 0,
      selectedTopicName: 0,
      selectedSubjectName: 0 - 0,
      selectedStatus: 0,
      selectedApproveStatus: 0,
      selectedExamPaper: 0

    })
    setSearchData('')
    getqamasterlistapi()
    localStorage.removeItem('selectedSubjectName')
    localStorage.removeItem('selectedExamPaper')
    localStorage.removeItem('selectedSubCategoryName')
    localStorage.removeItem('selectedTopicName')
    localStorage.removeItem('applyButton')
    localStorage.removeItem('selectedStatus')
    localStorage.removeItem('selectedLanguage')
  }, [dispatch]);

  const handlesearch = (e) => {
    setSearchVal(e.target.value);
  };

  const onSearch = () => {
    setSearchData(searchVal)
  }

  useEffect(() => {

    if (store.masterlist && store.masterlist.board_categoryList)
      setOptionBoardName([{ categoryname: "All", exctid: "0" }, ...store.masterlist.board_categoryList])
    if (store.masterlist && store.masterlist.conf_subject_subcategoryList) {
      setOptionSubjectName([{ s_name: "All", sid: "0", sscid: "0", exctid: "0" }, ...store.masterlist.conf_subject_subcategoryList])
      setOptionAllSubjectName([{ s_name: "All", sid: "0", sscid: "0", exctid: "0" }, ...store.masterlist.conf_subject_subcategoryList])
    }

    if (store.masterlist && store.masterlist.topicList) {
      setOptionTopicName([{ topicname: "All", topicid: "0" }])
      setOptionAllTopicName([{ topicname: "All", topicid: "0" }, ...store.masterlist.topicList])
    }

    if (store.masterlist && store.masterlist.examPaperList) {
      setOptionExamPaper([{ exp_name: "All", expid: "0" }, ...store.masterlist.examPaperList])
      setOptionAllExamPaper([{ exp_name: "All", expid: "0" }, ...store.masterlist.examPaperList])
    }

    if (masterstore?.masterlist && masterstore?.masterlist?.subjectList) {
      setOptionSubject([{ subjectname: "All", subjectid: "0" }])
      setOptionSubjectAll([{ subjectname: "All", subjectid: "0" }, ...masterstore?.masterlist?.subjectList])
    }

    if (masterstore?.masterlist && masterstore?.masterlist?.subCategryList) {
      setOptionSubCategory([{ ssc_name: "All", sscid: "0" }])
      setOptionSubCategoryAll([{ ssc_name: "All", sscid: "0" }, ...masterstore?.masterlist?.subCategryList])
    }

    if (masterstore?.masterlist && masterstore?.masterlist?.examLevelList) {
      setExamLevelOptions([{ levelid: "0", levelname: "All" }, ...masterstore?.masterlist?.examLevelList])
      setExamLevelOptionsAll([{ levelname: "All", levelid: "0" }, ...masterstore?.masterlist?.examLevelList])
    }

    if (store.masterlist && store.masterlist?.languageList) {
      setOptionLanguage([{ l_name: "All", lid: "0" }, ...store.masterlist?.languageList])
      setOptionLanguageAll([{ l_name: "All", lid: "0" }, ...store.masterlist?.languageList])
    }

    if (store.masterlist && store.masterlist?.verification_statusList) {
      setApproveStatus([{ statusname: "All", statusid: "0" }, ...store.masterlist?.verification_statusList])
    }

    if (store.masterlist && store.masterlist.statusList)
      setOptionStatus([{ statusname: "All", statusid: "0" }, ...store.masterlist.statusList])

  }, [store.masterlist])

  const [generalsetting, setGeneralSetting] = useState("");
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    if (store.masterlist != undefined) {
      if (store.masterlist && store.masterlist.generalSettings) {
        setGeneralSetting(store.masterlist.generalSettings)
      }
    }
  }, [store.masterlist])


  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        // accessorKey: 'action',
        // enableColumnFilter: false,
        // enableSorting: true,

        width: '8%',
        // width: (cell) => cell.value.length * 10, 
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.QA && f.urp_type === constants.menuTypes.Modify || f.urp_type === constants.menuTypes.Special) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      localStorage.setItem('isClone', '0')
                      navigate(
                        `/add-questions/${cellProps.row.original.qid}`
                      )
                    }
                    }

                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>
                )}
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.QA && f.urp_type === constants.menuTypes.Remove) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      onClickDelete(cellProps.row.original.qid);
                    }}>
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </div>
                )}
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        accessorKey: 'sno',
        width: '8%',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'pl-25',
        cell: (cellProps) => {
          var data = cellProps.row.original.rno
          return (
            <div className="d-flex align-items-center">
              <span>{data}</span>
            </div>
          );


        }
        // (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Question Text ▼
          </span>
        ),

        accessorKey: 'question',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          var question_text = '';
          const question = cellProps.row.original.question;
          const created = cellProps.row.original.qid;

          if (question.length > 50) {
            var question_text = question.substring(0, 50 - 3) + '...';
          }

          return (

            <div className="d-flex gap-3 text-align-right" style={{ cursor: 'pointer' }}
              // onClick={() => {

              //   // const question = cellProps.row.original.question
              //   getQuestion(question)
              // }
              // } 
              id={`question${created}`}
            >
              {question_text !== '' ? question_text : question}
              {
                question !== '' ? <UncontrolledTooltip placement="bottom" target={`question${created}`} className="tooltip-inner"
                >
                  {question}
                </UncontrolledTooltip> : ''
              }
              {/* {
                cellProps?.row?.original?.topicscount && Number(cellProps?.row?.original?.topicscount) !== 0 ? (
                  <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                    const subjectData = cellProps.row.original;
                    getTopicList(subjectData);
                  }}>
                    {cellProps?.row?.original?.topicscount}
                  </div>
                ) : (
                  <div>{cellProps?.row?.original?.topicscount}</div>
                )
              } */}
            </div >
            // <div className="d-flex align-items-center" id={`question${created}`}>
            //   {question_text !== '' ? question_text : question}
            //   {question !== '' ? <UncontrolledTooltip placement="bottom" target={`question${created}`} className="tooltip-inner"
            //   >
            //     {question}
            //   </UncontrolledTooltip> : ''}
            // </div>
          );
        },
      },
      // {
      //   header: (
      //     <span style={{ cursor: 'pointer' }}>
      //       Topic ▼
      //     </span>
      //   ),

      //   accessorKey: 't_name',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Exam Level ▼
          </span>
        ),

        accessorKey: 'exl_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Languages ▼
          </span>
        ),

        accessorKey: 'l_name',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const langname = cellProps.row.original.langname;
          const aggregated_values = cellProps.row.original.aggregated_values;

          console.log('langname', langname);
          console.log('aggregated_values', aggregated_values);

          // Convert comma-separated strings into arrays
          const langnameArray = langname?.split(',').map(value => value.trim());
          const aggregatedValuesArray = aggregated_values?.split(',').map(value => value.trim());

          // Find common values
          const commonValues = langnameArray?.filter(value => aggregatedValuesArray?.includes(value));

          // Find different values
          // const differentInLangname = langnameArray.filter(value => !aggregatedValuesArray.includes(value));
          const differentValues = aggregatedValuesArray?.filter(value => !langnameArray?.includes(value));


          if (langname !== null) {

            return (
              <div className="d-flex align-items-center" >
                {
                  commonValues?.map(value => {
                    return (
                      <Badge color="success" className="me-2" key={value}>
                        {value}
                      </Badge>
                    )
                  })
                }
                {
                  differentValues?.map(value => {
                    return (
                      <Badge color="danger" className="me-2" key={value}>
                        {value}
                      </Badge>
                    )
                  })
                }
              </div >
            );
          }
          else {
            return (
              <div className="d-flex align-items-center" >
                {
                  aggregatedValuesArray?.map(value => {
                    return (
                      <Badge color="danger" className="me-2" key={value}>
                        {value}
                      </Badge>
                    )
                  })
                }
              </div>
            )
          }
        },
      },

      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Created By ▼
          </span>
        ),

        accessorKey: 'created',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Status',
        accessorKey: 'q_vstatus',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const c_stsid = cellProps.row.original.q_vstatus;
          // const status = cellProps.row.original.statuscode;

          return (
            <div className="d-flex align-items-center">
              {c_stsid == 1 ? (
                <i className="mdi mdi-check-circle text-success me-2" style={{ fontSize: '25px' }} />
              ) : c_stsid == 2 ? (
                <i className="bx bx-x
 text-danger me-2" style={{ fontSize: '20px' }} />
              ) : c_stsid == "3" ? (
                <i className="mdi mdi-book-clock-outline text-warning me-2" style={{ fontSize: '25px' }} />
              ) :
                c_stsid == "4" ? (
                  <i className="mdi mdi-check-circle text-success me-2" style={{ fontSize: '25px' }} />
                ) :

                  c_stsid == "5" ? (
                    <i className="dripicons dripicons-wrong text-danger me-2" style={{ fontSize: '20px' }} />
                  ) :



                    ""}
            </div>
          );
        },
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Updated By ▼
          </span>
        ),

        accessorKey: 'updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const mk_stsid = cellProps.row.original.updated;

          return (
            <div className="d-flex align-items-center">
              {mk_stsid ? (
                <span>{mk_stsid}</span>
              ) : (
                <span>-</span>
              )}
            </div>
          );
        },
      }

    ],
    []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPageData = 10;
  const indexOfLast = currentPage * perPageData;
  const [qaList, setQAList] = useState([]);

  useEffect(() => {
    var page_indexOfLast = currentPage * perPageData;
    var offset = page_indexOfLast - perPageData;
    GetQAList(offset);
  }, [filterData, currentPage, searchData]);

  useEffect(() => {
    if (current) {
      GetQAList(current)
    }
    setCurrent(0)
  }, [current])

  async function GetQAList(offset) {
    const user = getLoggedInUser()

    const filter_user_id = user?.user_previleges.find(f =>
      Number(f.urp_mid) === constants.mainMenuList.QA && f.urp_type === constants.menuTypes.Permission1);


    const data = {
      "user_id": Number(user.uid),
      "q_sscid": Number(selectedSubCategoryName?.value),
      "q_tid": Number(selectedTopicName?.value),
      "q_stsid": Number(selectedStatus?.value),
      "q_exctid": Number(selectedBoardName?.value),
      "q_sid": Number(selectedSubjectName?.value),
      "q_ex_paper_id": Number(selectedExamPaper?.value),
      "q_approvetests": 0,
      "lang_id": Number(selectedLanguage?.value),
      "q_vstatus": Number(selectedApproveStatus?.value),
      "offset": Number(current) != 0 ? Number(current - 1) : 0,
      "searchData": searchData || "",
      "filter_user_id": filter_user_id?.uid ? 0 : Number(user?.uid),
      "exam_level_id": Number(examLevel?.value === undefined ? 0 : examLevel?.value),
      "limit": 10
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(url.BASE_URL + 'qa/getQAList', data, config)
    if (response1 && response1.statusFlag === 3) { // user validation
      toast.error(response1.message, { autoClose: 2000 });
      localStorage.removeItem("authUser");
      sessionStorage.removeItem("SESSION_KEY")
      window.location.reload()
      return
    }
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
      if (refresh) {
        if (Number(refresh) === constants.invalidRefreshToken) {
          logoutUser()
        } else {
          GetQAList(offset) // calling same function again getting refresh token
        }
      }
    }
    if (response1 && response1.data && response1.data.body) {
      setQAList(response1.data.body.QAList)
      setTotalCount(response1.data.body.QACount)
      return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
    } else {
      return { statusFlag: 2, message: 'Something went wrong' }
    }
  }

  const exportToExcel = async () => {

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Q&A List')

    // Add headers with styling
    const headerRow = worksheet.addRow([
      'S.No.',
      'Question Text',
      'Exam Level',
      'Languages',
      'Created By',
      'Status',
      'Updated By'
    ])

    headerRow.font = { bold: true }
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'c0d5e8' } // Background color
    }

    // Add data
    qaList.forEach((item, index) => {
      worksheet.addRow([
        index + 1,
        item.question,
        item.exl_name,
        item.l_name,
        item.created,
        item.status,
        item.updated
      ])
    })

    // Save the workbook
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `Q&A List.xlsx`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  function handleSelectSubjectName(varselectedSubjectName) {
    setselectedSubjectName(varselectedSubjectName)
    if (varselectedSubjectName?.value === '0') {
      setOptionSubCategory(optionSubCategoryAll)
      setOptionTopicName(optionAllTopicName)
      setSelectedSubCategoryName({ label: "All", value: "0" })
      setselectedTopicName({ label: "All", value: "0" })
    } else {
      setSelectedSubCategoryName({
        label: "All",
        value: 0
      })
      setselectedTopicName({
        label: "All",
        value: 0
      })
      var filter_subcat = optionSubCategoryAll.filter(e => Number(e.ssc_sid) == varselectedSubjectName?.subjectid)
      setOptionSubCategory([{ ssc_name: "All", sscid: "0" }, ...filter_subcat])
      var filter_topic = optionAllTopicName.filter(e => Number(e.subjectid) == varselectedSubjectName?.subjectid)
      setOptionTopicName([{ topicname: "All", topicid: "0" }, ...filter_topic])
    }
  }

  function handleSelectSubCategoryName(varselectedSubjectName) {
    setSelectedSubCategoryName(varselectedSubjectName)
    if (varselectedSubjectName?.value === '0') {
      setOptionTopicName(optionAllTopicName)
      setselectedTopicName({ label: "All", value: "0" })
    } else {
      var filter_subcat = optionAllTopicName.filter(e => Number(e.subcategoryid) == varselectedSubjectName?.sscid)
      setOptionTopicName([{ topicname: "All", topicid: "0" }, ...filter_subcat])
    }
  }

  const onClickDelete = (qid) => {

    setBoard({ qid });
    setDeleteModal(true);
  };

  const handleDeleteOrder = async () => {
    setLoading(true);

    const data = {
      user_id: Number(user.uid),
      qid: Number(board.qid)
    };

    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'qa/deleteQA', data, config);
      if (response && response.statusFlag === 3) {
        toast.error(response.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        sessionStorage.removeItem("SESSION_KEY")
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await handleDeleteOrder();
          }
        }
      }

      if (response?.data?.body?.statusFlag === 1) {
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      console.error(error);
    } finally {
      setLoading(false);
      setDeleteModal(false);


      await GetQAList(0);
    }
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />

      <QuestionModal
        show={topicModal}
        questionData={questionData1}
        // subjectDetails={subjectDetails}
        // from={'subcategory'}
        onCloseClick={() => setTopicModal(false)}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Q&A" breadcrumbItem="Q&A" /> */}


          <Row>
            <Col lg={12}>
              <Card className="job-filter">
                <CardBody>
                  <Form>

                    <Row className="g-3">
                      <Col lg={4} >
                        <div className='hstack gap-3' style={{ justifyContent: 'end' }}>
                          <Input
                            name="search"
                            type="text"
                            placeholder="Search..."
                            onChange={handlesearch}
                            value={searchVal || ""}
                            autoFocus

                          />
                          <a href="#" onClick={onSearch} className="btn btn-secondary h-100">
                            <i className="bx bx-search-alt align-middle"></i></a>
                        </div>
                      </Col>
                      <Col lg={8} className="text-end">

                        <Row className="text-end">

                          <div className='hstack gap-3' style={{ justifyContent: 'end' }}>
                            {user && user.user_previleges && user.user_previleges.find(f =>
                              Number(f.urp_mid) === constants.mainMenuList.QA && f.urp_type === constants.menuTypes.Create) && (
                                <Button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    localStorage.setItem('isClone', '0')
                                    navigate(
                                      `/add-questions/0`
                                    )
                                  }
                                  }
                                >
                                  + Add
                                </Button>
                              )}

                            <a href="#" onClick={toggleFilter} className="btn btn-secondary h-100">
                              <i className="bx bx-filter-alt align-middle"></i></a>
                          </div>
                          {/* <div className="position-relative h-100 hstack gap-3">
                          <a href="#" onClick={toggleFilter} className="btn btn-secondary h-100">
                            <i className="bx bx-filter-alt align-middle"></i></a>
                        </div> */}
                        </Row>
                      </Col>


                      <Collapse isOpen={isFilterOpen} id="collapseExample">
                        <Row>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Exam Paper</Label>
                              <Select
                                value={selectedExamPaper}
                                onChange={(selectedOption) => {
                                  handleSelectPaper(selectedOption);
                                }}
                                options={optionExamPaper.map((option) => ({
                                  label: option.exp_name,
                                  value: option.expid,
                                }))}

                                className="select2-selection"

                              />
                            </div>
                          </Col>

                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Language</Label>
                              <Select
                                value={selectedLanguage}
                                onChange={(selectedOption) => {
                                  setSelectedLanguage(selectedOption);
                                }}
                                options={optionLanguage.map((option) => ({
                                  label: option.l_name,
                                  value: option.lid,
                                }))}

                                className="select2-selection"

                              />
                            </div>
                          </Col>

                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Subject</Label>
                              <Select
                                value={selectedSubjectName}
                                onChange={handleSelectSubjectName}
                                options={optionSubject.map((option) => ({
                                  ...option,
                                  label: option.subjectname,
                                  value: option.subjectid,
                                }))}
                                styles={customStyles}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Subcategory</Label>
                              <Select
                                value={selectedSubCategoryName}
                                onChange={handleSelectSubCategoryName}
                                options={optionSubCategory.map((option) => ({
                                  ...option,
                                  label: option.ssc_name,
                                  value: option.sscid,
                                }))}
                                styles={customStyles}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Topic</Label>
                              <Select
                                value={selectedTopicName}
                                onChange={handleSelectTopicName}
                                options={optionTopicName.map(option => ({
                                  label: option.topicname,
                                  value: option.topicid,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>Exam Level</Label>
                              <Select
                                value={examLevel}
                                onChange={(selectedOption) => {
                                  setExamLevel(selectedOption);
                                }}
                                options={examLevelOptions.map((option) => ({
                                  label: option.levelname,
                                  value: option.levelid,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>Status</Label>
                              <Select
                                value={selectedStatus}
                                onChange={(selectedOption) => {
                                  handleSelectStatus(selectedOption);
                                }}
                                options={optionStatus.map((option) => ({
                                  label: option.statusname,
                                  value: option.statusid,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>Approve</Label>
                              <Select
                                value={selectedApproveStatus}
                                onChange={(selectedOption) => {
                                  handleSelectApprove(selectedOption);
                                }}
                                options={approveStatus.map((option) => ({
                                  label: option.statusname,
                                  value: option.statusid,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg={3} >
                            <div className="d-flex flex-wrap gap-2 justify-content-start">
                              <Button
                                type="button"
                                style={{ marginTop: 25 }}
                                className="btn btn-soft-info"
                                onClick={() => setFilterData({
                                  selectedBoardName: selectedBoardName ? selectedBoardName.value : 0,
                                  selectedTopicName: selectedTopicName ? selectedTopicName.value : 0,
                                  selectedSubjectName: selectedSubjectName ? selectedSubjectName.value : 0 - 0,
                                  selectedStatus: selectedStatus ? selectedStatus.value : 0,
                                  selectedApproveStatus: selectedApproveStatus ? selectedApproveStatus.value : 0,
                                  selectedExamPaper: selectedExamPaper ? selectedExamPaper.value : 0

                                })}
                              >
                                Apply
                              </Button>
                              <Button
                                type="button"
                                style={{ marginTop: 25 }}
                                className="btn btn-soft-danger"
                                onClick={() => {
                                  setFilterData({
                                    selectedBoardName: 0,
                                    selectedTopicName: 0,
                                    selectedSubjectName: 0 - 0,
                                    selectedStatus: 0,
                                    selectedApproveStatus: 0,
                                    selectedExamPaper: 0
                                  })
                                  setOptionSubject(optionSubjectAll)
                                  setOptionSubCategory(optionSubCategoryAll)
                                  setOptionTopicName(optionAllTopicName)
                                  setOptionExamPaper(optionAllExamPaper)
                                  setOptionLanguage(optionLanguageAll)
                                  setExamLevelOptions(examLevelOptionsAll)

                                  setselectedBoardName({
                                    label: "All",
                                    value: 0
                                  })
                                  setselectedTopicName({
                                    label: "All",
                                    value: 0
                                  })
                                  setselectedSubjectName({
                                    label: "All",
                                    value: 0
                                  })
                                  setSelectedSubCategoryName({
                                    label: "All",
                                    value: 0
                                  })
                                  setSelectedLanguage({
                                    label: "All",
                                    value: 0
                                  })
                                  setselectedStatus({
                                    label: "All",
                                    value: 0
                                  })
                                  setselectedApproveStatus({
                                    label: "All",
                                    value: 0
                                  })
                                  setselectedExamPaper({
                                    label: "All",
                                    value: 0
                                  })
                                  setExamLevel({
                                    label: "All",
                                    value: 0
                                  })
                                }
                                }
                              >
                                Clear
                              </Button>
                              <div className="text-end">
                                {/* <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-success me-2"
                                  onClick={() => {
                                    navigate('/qa-import-excel')
                                  }
                                  }
                                >
                                  Import from excel
                                </Button> */}

                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-warning"
                                  onClick={() => exportToExcel()}
                                >
                                  Export excel
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Collapse>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>


          {isLoading ?
            <Spinners setLoading={setLoading} />
            :
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationTableContainer
                    columns={columns}
                    data={qaList || []}
                    isPagination={true}
                    // isGlobalFilter={true}
                    perPageData={perPageData}
                    totalLength={totalCount}
                    currentPage={currentPage}
                    setCurrent={setCurrent}
                    // SearchPlaceholder="Search..."
                    // setCurrentPage={setCurrentPage}
                    pagination="pagination"
                    // isCustomPageSize={true}
                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                  />
                </CardBody>
              </Card>
            </Col>
          }
        </Container>
      </div >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment >
  );
}

export default QuestionList;