import {
    GET_BLUEPRINT_SUCCESS,
    ADD_BLUEPRINT_SUCCESS,
    RESET_STORE_BLUEPRINT,
    DELETE_BLUEPRINT_SUCCESS,
    EDITLOAD_BLUEPRINT_SUCCESS,
  } from "./actionTypes"
  
  const INIT_STATE = {
    bluePrintList: [],
    bluePrintData :[],
    blueprint_statusFlag: 0
  }
  
  const Blueprint = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_BLUEPRINT_SUCCESS:
        return {
          ...state,
          bluePrintList: action.payload.bluePrintList,
          blueprint_statusFlag: action.payload.statusFlag
        }
  
      case ADD_BLUEPRINT_SUCCESS:
        return {
            ...state,
            blueprint_statusFlag: action.payload.statusFlag,
        };
      
      case RESET_STORE_BLUEPRINT:
        return {
          ...state,
          blueprint_statusFlag: action.payload.statusFlag,
        };
  
        case DELETE_BLUEPRINT_SUCCESS:
          return {
              ...state,
              blueprint_statusFlag: action.payload.statusFlag,
          };

          case EDITLOAD_BLUEPRINT_SUCCESS:
            return {
                ...state,
                bluePrintData : action.payload.bluePrintData ,
                blueprint_statusFlag: action.payload.statusFlag,
            };
  
      default:
        return state
    }
  }
  
  export default Blueprint
  