//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import { getqamasterlist } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import * as constants from "../../../constants/layout"
import { editloadMock, addNewMockuptest, resetStoremockuptest } from "../../../store/mockuptest/actions";
import "../../../../node_modules/@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { Card, Button, CardBody, Col, Container, UncontrolledTooltip, Form, FormGroup, Input, InputGroup, Label, Row, FormFeedback, CardFooter, Badge } from "reactstrap";
import './mockup.scss'

const AddMockupTest = () => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const { mkid } = useParams();
    const store = useSelector(state => state.MockupList)
    const dispatch = useDispatch();
    const user = getLoggedInUser()
    const navigate = useNavigate();
    const masterstore = useSelector(state => state.ExamBoardCategory)
    const [isLoading, setLoading] = useState(false);
    const [configuration, setConfiguration] = useState(null);
    const [masterList, setmasterList] = useState([]);
    const [boardcategory, setboardcategory] = useState([]);
    const [allBoardcategory, setAllBoardcategory] = useState([]);
    const [subject1, setsubject] = useState([]);
    const [submitbtn, setSubmitbtn] = useState(false);
    const [initialSubmitState, setInitialSubmitState] = useState(false);
    const [c_subjects, setC_subjects] = useState([]);
    const [deleteTrigger, setDeleteTrigger] = useState(false);
    var isClone = localStorage.getItem('isClone');

    const [subjectsubcategory, setsubjectsubcategory] = useState([]);
    const [allSubjectsubcategory, setAllSubjectsubcategory] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedBoardCategory, setselectedBoardCategory] = useState(null);
    const [editingRow, setEditingRow] = useState(null);
    const [status, setstatus] = useState(false);
    const [optionTopicName, setOptionTopicName] = useState([]);
    const [optionAllTopicName, setOptionAllTopicName] = useState([]);
    const [paperAllList, setAllPaperList] = useState([]);
    const [allSubjectList, setAllSubjectList] = useState([]);
    const [selectedPaper, setselectedPaper] = useState(null);
    const [paperList, setPaperList] = useState([])
    const [SelectedsubjectRadio, setselectedsubjectRadio] = useState('')
    const [selectedCount, setselectedCount] = useState([]);
    const paperRef = useRef(null);
    const [selectedLanguage, setselectedLanguage] = useState(null);
    const [optionLanguageName, setOptionLanguageName] = useState([]);
    const [optionAllLanguageName, setOptionAllLanguageName] = useState([]);
    const [edit12, setEdit] = useState(false)
    const [inputFocus, setInputFocus] = useState(false);
    const testref = useRef(null);
    const [selBoard, setSelBoard] = useState("")
    const [expid, setExpid] = useState()


    // Navigation
    if (SelectedsubjectRadio === 'topicwise') {
        navigate("/add-mockuptest-topic/0");

    } else if (SelectedsubjectRadio === 'subjectwise') {
        navigate("/add-mockuptest/0")
    }

    useEffect(() => {
        disableButton()
    }, [dispatch]);


    const disableButton = () => {
        setButtonDisabled(true);
    };
    const enableButton = () => {
        setButtonDisabled(false);
    };

    // Topic Changes
    function handleselecttopic(varselectedtopic) {
        var filteredcount = optionAllTopicName.filter(e => e.topicid == varselectedtopic.value)
        setselectedCount(filteredcount)
    }

    // Board Change
    function handleSelectBoardName(varselectedBoardName) {
        const [exctid, exct_exid] = varselectedBoardName.value.split('-')
        setSelBoard(exctid)
        var filtered_exampaper_option = paperAllList.map(e => {
            var exctid_arr = e?.exctid?.split(',');
            if (exctid_arr?.length > 0) {
                if (exctid_arr?.includes(exctid)) {
                    return e;
                }
            }

        })
        filtered_exampaper_option = filtered_exampaper_option.filter(e => e != undefined);
        setPaperList(filtered_exampaper_option)
        setselectedPaper(null)

        validation.setFieldValue("subjectSubcategory", "");
        validation.setFieldValue("subjectName", "")
        validation.setFieldValue("selectedPaper", "")
        validation.setFieldValue("selectedTopicName", "")
        setselectedCount("")

    }

    // Initial load for inputbox focus
    useEffect(() => {
        if (handleClose) {
            setInputFocus(false);
        } else {
            setInputFocus(true)
        }

    }, [])

    // Paper change
    const handleSelectPaper = (selectedOption) => {
        setselectedPaper(selectedOption)
        var filtered_subject_option = allSubjectList.map(e => {
            var exctid_arr = e?.exctid?.split(',');
            if (exctid_arr?.length > 0) {
                return e;
            }

        })

        filtered_subject_option = filtered_subject_option.filter(e => e != undefined && e.s_expid == selectedOption.value);
        setsubject(filtered_subject_option)

        var filtered_topic_option = optionAllTopicName.filter(e => e.cs_expid == selectedOption?.value && e.exctid == selBoard);
        filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
        setOptionTopicName(filtered_topic_option)
        validation.setFieldValue("subjectSubcategory", "");
        validation.setFieldValue("subjectName", "")
        validation.setFieldValue("selectedTopicName", "")
        setselectedCount("")
    };
    // markperquestion calculation
    const handlechangeMark = (e) => {

        var output = document.getElementById('input2')

        output.value = validation.values.totalQuestions * e.target.value
        validation.setFieldValue('totalMarks', output.value)

        if (output && output.value) {
            disableButton()
        } else {
            enableButton()
        }
    }
    // question calculation
    const handlechangeQuestion = (e) => {

        var output = document.getElementById('input2')

        validation.setFieldValue('totalMarks', "")
        output.value = validation.values.markPerQuestion * e.target.value

        validation.setFieldValue('totalMarks', output.value)
        if (output && output.value) {
            disableButton()
        } else {
            enableButton()
        }
    }

    // close Button navigation
    const handleClose = () => {
        navigate("/list-mockuptest");
    }

    // Masterapi function
    const getmasterlistapi = () => {

        dispatch(getqamasterlist({ user_id: Number(user.uid) }));
    }
    // Masterapi calling
    useEffect(() => {
        getmasterlistapi()
    }, [dispatch]);

    // Editload Api calling
    const geteditloadapi = () => {
        setLoading(true)
        setEdit(true)

        dispatch(editloadMock({ user_id: Number(user.uid), mkid: Number(mkid) }));
    }

    // subject changes
    function handleSelectSubjectName(varselectedSubjectName) {

        const [sid, sscid] = varselectedSubjectName.value.split('-');
        var filtered_topic_option = optionAllTopicName.filter(e => e.subjectid == sid && e.subcategoryid == sscid
            && e.exctid == selBoard);
        setOptionTopicName(filtered_topic_option);

        validation.setFieldValue("selectedTopicName", null);
        validation.setFieldValue("subcat", null)
        setselectedCount("")
    }

    useEffect(() => {
        document.getElementById('headerTitle').innerHTML = 'MockupTest';
        if (mkid != 0) {
            geteditloadapi()

        }
    }, [dispatch, mkid]);

    useEffect(() => {
        if (store && store.mockuptest_statusFlag && store.mockuptest_statusFlag === 4) {
            navigate(`/list-mockuptest`);
        } else if (store && store.mockuptest_statusFlag && store.mockuptest_statusFlag === 1) {
            if (store.mockupTestData && store.mockupTestData.mockupTestData && store.mockupTestData.mockupTestData.length > 0) {
                const editData = store.mockupTestData.mockupTestData[0];
                const editDataSub = store.mockupTestData.mockupTestSubjects[0];
                setExpid(editData.mk_expid)

                validation.setFieldValue("configurationID", editData.mkid);
                validation.setFieldValue("configurationName", isClone === '1' ? "" : editData.mk_testname);


                // Set value for selectedBoardCategory
                const boardCategoryValue = {
                    label: editData.ex_name + "-" + editData.exct_name,
                    value: editData.mk_exctid + "-" + editData.mk_exid,
                };

                const papervalue = {
                    label: editData.exp_name,
                    value: editData.mk_expid
                };
                if (editDataSub && editDataSub.mks_sscid > 0) {

                    const subjectValue = {
                        label: editData.s_name + "-" + editDataSub.ssc_name,
                        value: editDataSub.mks_sscid
                    }
                    validation.setFieldValue("subjectName", subjectValue);

                } else {
                    const subjectValue = {
                        label: editData.s_name,
                        value: editDataSub.mks_sscid
                    }
                    validation.setFieldValue("subjectName", subjectValue);
                }
                const topicvalue = {
                    label: editDataSub.t_name,
                    value: editDataSub.mks_tid
                }

                // const langaugevalue = {
                //     label: editData && editData.mk_lid && editData.mk_lid == 1 ? "English" : "Regional",
                //     value: editData.mk_lid,

                // }

                // setselectedLanguage(langaugevalue)
                // validation.setFieldValue("selectedLanguage", isClone === '1' ? langaugevalue : langaugevalue)



                validation.setFieldValue("selectedBoardCategory", boardCategoryValue);
                setselectedBoardCategory(boardCategoryValue);
                validation.setFieldValue("selectedPaper", papervalue);
                setselectedPaper(papervalue);
                // validation.setFieldValue("subjectName", subjectValue);
                validation.setFieldValue("selectedTopicName", topicvalue);

                validation.setFieldValue("totalMarks", Number(editData.mk_totalmarks));
                validation.setFieldValue("markPerQuestion", Number(editDataSub.mks_marks))
                validation.setFieldValue("totalQuestions", Number(editData.mk_totalquestions));
                validation.setFieldValue("duration", Number(editData.mk_duration));

                // Set data table with the data for subjects
                const subjectsData = store.mockupTestData.mockupTestSubjects.map((subject, index) => ({
                    sno: index + 1,
                    subjectName: subject.s_name,
                    subCategory: subject.ssc_name,
                    totalQuestions: subject.mks_questioncount,
                    mark: subject.mks_marks,
                    product: Number(subject.mks_questioncount) * Number(subject.mks_marks),
                    mks_sid: subject.mks_sid,
                    mks_sscid: subject.mks_sscid,
                    mks_questioncount: subject.mks_questioncount,
                    mks_marks: subject.mks_marks,
                    mks_tid: Number(subject.mks_tid),
                    cs_tname: subject.t_name,
                    ex_paper_id: Number(subject.expid),
                    ex_paper_name: subject.exampaper
                }));

                // Set subjects data
                setDataTable(subjectsData);
                // Set c_subjects array
                setC_subjects(subjectsData);

                // Set status toggle only during update

                if (editData.mk_stsid == "1")
                    setstatus(true)
                else setstatus(false)


            }

        }

    }, [store.mockupTestData]);

    useEffect(() => {
        if (masterstore.masterlist && masterstore.masterlist.conf_subject_subcategoryList) {
            setAllSubjectList(masterstore.masterlist.conf_subject_subcategoryList)
            setsubject(masterstore.masterlist.conf_subject_subcategoryList)
            if (mkid != 0) {
                editload_subcatgorybind()
            }
        }

        if (masterstore.masterlist && masterstore.masterlist.examBoardList)
            setmasterList(masterstore.masterlist.examBoardList)
        if (masterstore.masterlist && masterstore.masterlist.board_categoryList) {
            var board_category_unused = masterstore.masterlist.board_categoryList.filter(e => e.c_exctid != 0)

            setboardcategory(board_category_unused)
            setAllBoardcategory(board_category_unused)
        }

        if (masterstore.masterlist && masterstore.masterlist.subCategryList) {
            setsubjectsubcategory(masterstore.masterlist.subCategryList)
            setAllSubjectsubcategory(masterstore.masterlist.subCategryList)
        }

        if (masterstore.masterlist && masterstore.masterlist.topicList) {
            setOptionAllTopicName(masterstore.masterlist.topicList)
            setOptionTopicName(masterstore.masterlist.topicList)
            if (mkid != 0) {
                editloadTopic_bind(masterstore.masterlist.topicList)


            }
        }
        if (masterstore.masterlist && masterstore.masterlist.examPaperList) {
            setAllPaperList(masterstore.masterlist.examPaperList)
            setPaperList(masterstore.masterlist.examPaperList)
            if (mkid != 0) {
                var data1 = store.mockupTestData.mockupTestData[0]
                var data = masterstore.masterlist.examPaperList
                var value = data.filter(e => e.exctid == Number(data1.mk_exctid))
                var filtered_exampaper_option = data.map(e => {
                    var exctid_arr = e?.exctid?.split(',');
                    if (exctid_arr?.length > 0) {
                        if (exctid_arr?.includes(data1.mk_exctid)) {
                            return e;
                        }
                    }

                })
                filtered_exampaper_option = filtered_exampaper_option.filter(e => e != undefined);
                setPaperList(filtered_exampaper_option)

            }
        }
        if (masterstore.masterlist && masterstore.masterlist.languageList) {
            setOptionLanguageName(masterstore.masterlist.languageList)
            setOptionAllLanguageName(masterstore.masterlist.languageList)
        }

    }, [masterstore.masterlist])

    const editloadTopic_bind = (data11) => {
        if (store && store.mockupTestData && store.mockupTestData.mockupTestData && store.mockupTestData.mockupTestData.length > 0) {

            const data1 = store.mockupTestData.mockupTestData[0]
            const value = data11.filter(e => Number(e.t_expid) == data1.mk_expid && Number(e.exctid) == Number(data1.mk_exctid))
            setOptionTopicName(value)
        }
    }
    const editload_subcatgorybind = () => {
        if (store && store.mockupTestData && store.mockupTestData.mockupTestData && store.mockupTestData.mockupTestData.length > 0) {

            var data1 = store.mockupTestData.mockupTestData[0]
            var data = masterstore.masterlist.conf_subject_subcategoryList
            var value = data.filter(e => e.exctid == Number(data1.mk_exctid))
            var filtered_exampaper_option = data.map(e => {
                var exctid_arr = e?.exctid?.split(',');
                if (exctid_arr?.length > 0) {
                    if (exctid_arr?.includes(data1.mk_exctid)) {
                        return e;
                    }
                }

            })
            filtered_exampaper_option = filtered_exampaper_option.filter(e => e != undefined);
            setsubject(filtered_exampaper_option)
        }
    }


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            configurationID: (configuration && configuration.configurationID) || "",
            configurationName: (configuration && configuration.configurationName) || "",
            totalMarks: (configuration && configuration.totalMarks) || "",
            totalQuestions: (configuration && configuration.totalQuestions) || "",
            duration: (configuration && configuration.duration) || "",
            selectedBoardCategory: (configuration && configuration.selectedBoardCategory) || null,
            // selectedLanguage: (configuration && configuration.selectedLanguage) || null
        },
        validationSchema: Yup.object({
            configurationName: Yup.string().required("Please Enter Test Name"),
            totalMarks: Yup.number().required("Please Enter Total Marks"),
            totalQuestions: Yup.number().required("Please Enter Total Questions"),
            duration: Yup.number().required("Please Enter Duration"),
            selectedBoardCategory: Yup.object().required("Please select Exam Board Category"),
            // selectedLanguage: Yup.object().required("Please select Language")
        }),
        onSubmit: (values) => {
            const arr = [];
            setSubmitbtn(true);
            setLoading(true);

            const [mk_exctid, mk_exid] = selectedBoardCategory.value.split('-');
            if (validation.values && validation.values.subjectName && validation.values.subjectName.label != "" && validation.values.subjectName.label != null && validation.values.subjectName.value != undefined) {

                var [s_name, subcategory] = validation.values.subjectName.label.split('-') || ""
            }


            var isClone = localStorage.getItem('isClone');

            const markPerQuestion = parseFloat(validation.values.markPerQuestion);
            const product = validation.values.totalQuestions * markPerQuestion;
            // if(validation.)
            if (validation.values && validation.values.subjectName && validation.values.subjectName.value != "" && validation.values.subjectName.value != null && validation.values.subjectName.value != undefined) {
                var [sid, sscid] = validation.values.subjectName.value.split('-');
            }
            const ssc_name = validation.values.subjectName.label;
            const newRow = {
                sno: isEditing ? editingRow.sno : dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
                subjectName: 0,
                subCategory: ssc_name,
                totalQuestions: validation.values.totalQuestions,
                mark: markPerQuestion,
                product: product, // Store product value in the newRow object
                mks_sid: Number(sid) || 0,
                s_name: s_name || 0,
                mks_sscid: Number(sscid) || 0,
                ssc_name: ssc_name,
                mks_questioncount: validation.values.totalQuestions,
                mks_marks: markPerQuestion.toString() || "0",
                mks_tid: Number(validation.values.selectedTopicName.value),
                cs_tname: validation.values.selectedTopicName.label,
                ex_paper_id: Number(validation.values.selectedPaper.value),
                ex_paper_name: validation.values.selectedPaper.label

            };
            if (newRow) {

                setDataTable([...dataTable, newRow]);
                const data = {
                    sno: isEditing ? editingRow.sno : dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
                    subjectName: 0,
                    subCategory: ssc_name,
                    totalQuestions: validation.values.totalQuestions,
                    mark: markPerQuestion,
                    product: product,
                    mks_sid: Number(sid),
                    s_name: s_name,
                    mks_sscid: Number(sscid),
                    ssc_name: ssc_name,
                    mks_questioncount: validation.values.totalQuestions || "0",
                    mks_marks: markPerQuestion.toString() || "0",
                    mks_tid: Number(validation.values.selectedTopicName.value),
                    cs_tname: validation.values.selectedTopicName.label,
                    ex_paper_id: Number(validation.values.selectedPaper.value),
                    ex_paper_name: validation.values.selectedPaper.label
                }
                arr.push(data)

            }


            const newblueprint = {
                user_id: Number(user.uid),
                mk_testname: values.configurationName,
                mk_exid: Number(mk_exid),
                mk_exctid: Number(mk_exctid),
                mk_totalmarks: Number(values.totalMarks),
                mk_totalquestions: Number(values.totalQuestions),
                mk_duration: Number(values.duration),
                mk_subjects: arr,
                mk_type: 2,
                mk_expid: Number(values.selectedPaper.value),
                mkid: isClone == '1' ? '0' : (mkid),
                mk_stsid: status ? 1 : 2,
                mk_sid: Number(sid) || 0,
                mk_lid: 0,
                process_type: mkid == 0 || isClone == '1' ? 'save' : 'update',
            };
            dispatch(addNewMockuptest(newblueprint))

                // Dispatch the action
                .then(() => {

                    navigate("/list-mockuptest");
                    // Reset the form and state if needed

                })
                .catch((error) => {
                    // Handle error if needed
                    console.error("Error:", error);
                });
        },
    });


    useEffect(() => {
        setDataTable([])
        setC_subjects([])
    }, []);

    //After api call success stop loading
    useEffect(() => {
        if (store.mockuptest_statusFlag === 1) {
            if (submitbtn) { // add or update then refresh list
                setSubmitbtn(false)

                validation.resetForm();
                setInitialSubmitState(true);
                navigate("/list-mockuptest");
            }
            if (deleteTrigger) { // delete then refresh list
                setDeleteTrigger(false)

                setDeleteModal(false);
                setBoard('');
            }
            setLoading(false)
            dispatch(resetStoremockuptest({ statusFlag: 0 }));
        } else if (store.mockuptest_statusFlag === 2) {
            if (submitbtn) {
                setSubmitbtn(false)
            }
            if (deleteTrigger) {
                setDeleteTrigger(false)
            }
            setLoading(false)
            dispatch(resetStoremockuptest({ statusFlag: 0 }));
        } else if (store.mockuptest_statusFlag === 3) {
            navigate(`/list-mockuptest`);
        }
    }, [store.mockuptest_statusFlag])

    const customStyles = {
        option: (styles, state, data) => { // fixed
            return {
                ...styles,
                backgroundColor: state.isDisabled
                    ? undefined
                    : state.isSelected
                        ? '#a6b0cf57'
                        : state.isFocused
                            ? '#556ee6'
                            : 'white',

                color: state.isDisabled
                    ? '#ccc'
                    : state.isSelected
                        ? 'black'
                        : state.isFocused
                            ? 'white'
                            : 'black',

                cursor: state.isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled
                        ? state.isSelected
                            ? '#556ee6'
                            : '#556ee6'
                        : undefined,
                },
                fontWeight: state.value && Number(state.value.split('-')[1]) !== 0 ? '' : '600'
            };
        }
    };

    //meta title
    document.title = "Mockup Test | Create Mockup Test"
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <Container fluid={true}>
                    <Row>
                        <Col lg="12">
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                }}
                            >
                                <Card className="crd-min-ht">
                                    <CardBody>
                                        {edit12 ?
                                            <h2> Topic wise</h2>
                                            :
                                            <Row>
                                                <Col lg="6">
                                                    <label style={{
                                                    }}>
                                                        <input
                                                            type="radio"
                                                            name="options"
                                                            value="subjectwise"
                                                            onChange={(selectedOption) => {
                                                                validation.setFieldValue("SelectedtopicRadio", selectedOption.target.checked);
                                                                setselectedsubjectRadio(selectedOption.target.value);
                                                            }}

                                                        /> Subjectwise
                                                    </label>
                                                    <label style={{
                                                        "margin-left": '34px'
                                                    }}>        <input
                                                            type="radio"
                                                            name="options"
                                                            value="topicwise"
                                                            defaultChecked
                                                            onChange={(selectedOption) => {
                                                                validation.setFieldValue("SelectedtopicRadio", selectedOption.target.checked);
                                                                setselectedsubjectRadio(selectedOption.target.value);
                                                            }}
                                                        /> Topicwise
                                                    </label>
                                                </Col>
                                                <hr></hr>
                                            </Row>
                                        }

                                        <form>
                                            <Row>

                                                <Col lg="6">



                                                    <Col lg="12">

                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>Test Name<span style={{ color: 'red' }}>*</span></Label>
                                                                    <Input
                                                                        tabIndex="1"
                                                                        name="configurationName"
                                                                        type="text"
                                                                        maxLength={constants.textboxLength.test_name}
                                                                        placeholder="Test Name"
                                                                        validate={{
                                                                            required: { value: true },
                                                                        }}
                                                                        value={validation.values.configurationName}
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.configurationName && validation.errors.configurationName ? true : false
                                                                        }
                                                                        innerRef={testref}
                                                                    />
                                                                    {validation.touched.configurationName && validation.errors.configurationName ? (
                                                                        <FormFeedback type="invalid">{validation.errors.configurationName}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>

                                                            <Col lg="6">

                                                                <div className="mb-3">
                                                                    <Label>Exam Paper<span style={{ color: 'red' }}>*</span></Label>
                                                                    <Select
                                                                        tabIndex="4"  isDisabled={c_subjects && c_subjects.length > 0}
                                                                        ref={paperRef}
                                                                        value={validation.values.selectedPaper}
                                                                        onChange={(selectedOption) => {
                                                                            handleSelectPaper(selectedOption);
                                                                            validation.setFieldValue("selectedPaper", selectedOption)
                                                                        }}
                                                                        options={paperList.map((paper) => ({
                                                                            label: paper.exp_name,
                                                                            value: paper.expid,
                                                                        }))}
                                                                        menuPlacement="bottom"
                                                                        menuPosition="absolute"
                                                                        menuPortalTarget={document.body} // This is important for positioning the menu correctly in some cases
                                                                        menuStyle={{ maxHeight: '200px', overflowY: 'auto' }}
                                                                        styles={customStyles}
                                                                        className={`select2-selection ${validation.touched.selectedPaper && validation.errors.selectedPaper ? 'is-invalid' : ''}`}
                                                                    />
                                                                    {validation.touched.selectedPaper && validation.errors.selectedPaper ? (
                                                                        <FormFeedback type="invalid">{validation.errors.selectedPaper}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>Exam Board Category<span style={{ color: 'red' }}>*</span></Label>
                                                                    <Select
                                                                        tabIndex="2" isDisabled={c_subjects && c_subjects.length > 0}
                                                                        value={validation.values.selectedBoardCategory}
                                                                        onChange={(selectedOption) => {
                                                                            validation.setFieldValue("selectedBoardCategory", selectedOption);
                                                                            setselectedBoardCategory(selectedOption);
                                                                            handleSelectBoardName(selectedOption);

                                                                        }}
                                                                        options={boardcategory.map((board) => ({
                                                                            label: board.categoryname,
                                                                            value: board.exctid + "-" + board.exct_exid,
                                                                        }))}
                                                                        className={`select2-selection ${validation.touched.selectedBoardCategory && validation.errors.selectedBoardCategory ? 'is-invalid' : ''}`}
                                                                    />
                                                                    {validation.touched.selectedBoardCategory && validation.errors.selectedBoardCategory ? (
                                                                        <div className="invalid-feedback">{validation.errors.selectedBoardCategory}</div>
                                                                    ) : null}
                                                                </div>

                                                            </Col>
                                                            <Col lg="6">

                                                                <div className="mb-3">
                                                                    <Label>Subject & Subcategory</Label>
                                                                    <Select
                                                                        tabIndex="5" isDisabled={c_subjects && c_subjects.length > 0}
                                                                        value={validation.values.subjectName}
                                                                        onChange={(selectedOption) => {
                                                                            validation.setFieldValue("subjectName", selectedOption);
                                                                            handleSelectSubjectName(selectedOption);
                                                                        }}
                                                                        options={subject1.map((option) => ({
                                                                            label: option.s_name,
                                                                            value: option.sid + "-" + option.sscid,
                                                                        }))}
                                                                        menuPlacement="bottom"
                                                                        menuPosition="absolute"
                                                                        menuPortalTarget={document.body} // This is important for positioning the menu correctly in some cases
                                                                        menuStyle={{ maxHeight: '200px', overflowY: 'auto' }}
                                                                        styles={customStyles}
                                                                        className={`select2-selection ${validation.touched.subjectName && validation.errors.subjectName ? 'is-invalid' : ''}`}
                                                                    />
                                                                    {validation.touched.subjectName && validation.errors.subjectName ? (
                                                                        <div className="invalid-feedback">{validation.errors.subjectName}</div>
                                                                    ) : null}

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Row>
                                                        <Col lg='6'>
                                                            <div className="mb-3">
                                                                <Label>Duration(Mins)<span style={{ color: 'red' }}>*</span></Label>
                                                                <Input
                                                                    className="rightAlignText"
                                                                    tabIndex="3"
                                                                    name="duration"
                                                                    type="number"
                                                                    min="1"
                                                                    max="999"
                                                                    onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        if (value.length > 3) {
                                                                            value = value.slice(0, 3);
                                                                        }
                                                                        if (parseInt(value) > 999) {
                                                                            value = "999";
                                                                        }
                                                                        e.target.value = value;
                                                                        validation.handleChange(e);
                                                                    }}
                                                                    onBlur={validation.handleBlur}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault();
                                                                        const pastedText = e.clipboardData.getData('text');
                                                                        let pastedValue = parseFloat(pastedText);
                                                                        if (isNaN(pastedValue) || pastedValue < 0) {
                                                                            pastedValue = "";
                                                                        } else if (pastedValue > 999) {
                                                                            pastedValue = 999;
                                                                        }
                                                                        e.target.value = pastedValue.toString().slice(0, 3);
                                                                        validation.handleChange(e);
                                                                    }}
                                                                    value={validation.values.duration || ""}
                                                                    invalid={validation.touched.duration && validation.errors.duration ? true : false}
                                                                />
                                                                {validation.touched.duration && validation.errors.duration ? (
                                                                    <FormFeedback type="invalid">{validation.errors.duration}</FormFeedback>
                                                                ) : null}
                                                            </div>

                                                        </Col>
                                                        <Col lg='6'>
                                                            <div className="mb-3">
                                                                <Label>Topic<span style={{ color: 'red' }}>*</span></Label>
                                                                <Select
                                                                    tabIndex="6" isDisabled={c_subjects && c_subjects.length > 0}
                                                                    value={validation.values.selectedTopicName}
                                                                    onChange={(selectedOption) => {
                                                                        validation.setFieldValue("selectedTopicName", selectedOption);
                                                                        handleselecttopic(selectedOption)
                                                                    }}
                                                                    options={validation && validation.values ? optionTopicName.map((option) => ({
                                                                        label: option.topicname,
                                                                        value: option.topicid,
                                                                    })) : []}
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    className={`select2-selection ${validation.touched.selectedTopicName && validation.errors.selectedTopicName ? 'is-invalid' : ''}`}
                                                                    menuPlacement="bottom"
                                                                    menuPosition="absolute"
                                                                    menuPortalTarget={document.body} // This is important for positioning the menu correctly in some cases
                                                                    menuStyle={{ maxHeight: '200px', overflowY: 'auto' }} // Set the max height for the dropdown list
                                                                />
                                                                {selectedCount && selectedCount.length > 0 ?
                                                                    <Badge pill color="success" className="me-1 qount">
                                                                        Available Questions :  <b>{selectedCount && selectedCount.length > 0 ? (selectedCount[0].qcount || "") : ""}</b>
                                                                    </Badge> : ""}
                                                                {validation.touched.selectedTopicName && validation.errors.selectedTopicName ? (
                                                                    <div className="invalid-feedback">{validation.errors.selectedTopicName}</div>
                                                                ) : null}
                                                            </div>

                                                            {/* <div className="mb-3">
                                                                <Label>Topic<span style={{ color: 'red' }}>*</span></Label>
                                                                <Select styles={{maxHeight: '1px'}}
                                                                    tabIndex="6"
                                                                    value={validation.values.selectedTopicName}
                                                                    onChange={(selectedOption) => {
                                                                        validation.setFieldValue("selectedTopicName", selectedOption);
                                                                        handleselecttopic(selectedOption)
                                                                    }}
                                                                    options={validation && validation.values ? optionTopicName.map((option) => ({
                                                                        label: option.topicname,
                                                                        value: option.topicid,
                                                                    })) : []}
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}

                                                                    className={`select2-selection ${validation.touched.selectedTopicName && validation.errors.selectedTopicName ? 'is-invalid' : ''}`}
                                                                />
                                                                {selectedCount && selectedCount.length > 0 ?
                                                                    <Badge pill color="success" className="me-1 qount">
                                                                        Available Questions :  <b>{selectedCount && selectedCount.length > 0 ? (selectedCount[0].qcount || "") : ""}</b>
                                                                    </Badge> : ""}
                                                                {validation.touched.selectedTopicName && validation.errors.selectedTopicName ? (
                                                                    <div className="invalid-feedback">{validation.errors.selectedTopicName}</div>
                                                                ) : null}

                                                            </div> */}

                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        {/* <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label>Languages<span style={{ color: 'red' }}>*</span></Label>
                                                                <Select
                                                                    isDisabled={mkid > 0 && isClone === '0'}
                                                                    value={validation.values.selectedLanguage}
                                                                    onChange={(selectedOption) => {
                                                                        validation.setFieldValue("selectedLanguage", selectedOption);
                                                                        setselectedLanguage(selectedOption);
                                                                    }}
                                                                    options={
                                                                        optionLanguageName.map((board) => ({
                                                                            label: board.l_name,
                                                                            value: board.lid,
                                                                        }))}
                                                                    className={`select2-selection ${validation.touched.selectedLanguage && validation.errors.selectedLanguage ? 'is-invalid' : ''}`}
                                                                />
                                                                {validation.touched.selectedLanguage && validation.errors.selectedLanguage ? (
                                                                    <div className="invalid-feedback">{validation.errors.selectedLanguage}</div>
                                                                ) : null}
                                                            </div>
                                                        </Col> */}


                                                        <Col lg='6'>

                                                            {mkid > 0 && isClone === '0' && (
                                                                <div className="mb-3">
                                                                    <Label>Status</Label>
                                                                    <div className="square-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="statusToggle"
                                                                            className="switch switch-info"
                                                                            checked={status}
                                                                            onClick={() => setstatus(!status)}
                                                                        />
                                                                        <label htmlFor="statusToggle" data-on-label="Active" data-off-label="Inactive" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Col>

                                                    </Row>


                                                </Col>
                                                <Col lg="6">
                                                    <Col lg="12">
                                                        <Col lg="6">
                                                            <Row>
                                                                <Col lg="6">
                                                                    <div className="mb-3">
                                                                        <Label>Total Questions<span style={{ color: 'red' }}>*</span></Label>
                                                                        <Input
                                                                            className="rightAlignText"
                                                                            tabIndex="7"
                                                                            name="totalQuestions"
                                                                            type="number"
                                                                            min="1"
                                                                            max="999"
                                                                            onChange={(e) => {
                                                                                let value = e.target.value;
                                                                                if (value.length > 3) {
                                                                                    value = value.slice(0, 3);
                                                                                }
                                                                                if (parseInt(value) > 999) {
                                                                                    value = "999";
                                                                                }
                                                                                e.target.value = value;
                                                                                handlechangeQuestion(e)
                                                                                validation.handleChange(e);
                                                                            }}
                                                                            onBlur={validation.handleBlur}
                                                                            onKeyPress={(e) => {
                                                                                if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                            onPaste={(e) => {
                                                                                e.preventDefault();
                                                                                const pastedText = e.clipboardData.getData('text');
                                                                                let pastedValue = parseFloat(pastedText);
                                                                                if (isNaN(pastedValue) || pastedValue < 0) {
                                                                                    pastedValue = "";
                                                                                } else if (pastedValue > 999) {
                                                                                    pastedValue = 999;
                                                                                }
                                                                                e.target.value = pastedValue.toString().slice(0, 3);
                                                                                validation.handleChange(e);
                                                                            }}
                                                                            value={validation.values.totalQuestions || ""}
                                                                            invalid={validation.touched.totalQuestions && validation.errors.totalQuestions ? true : false}
                                                                        />
                                                                        {validation.touched.totalQuestions && validation.errors.totalQuestions ? (
                                                                            <FormFeedback type="invalid">{validation.errors.totalQuestions}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <Label>Mark/Question<span style={{ color: 'red' }}>*</span></Label>
                                                                    <Input
                                                                        tabIndex="8"
                                                                        className="rightAlignText"

                                                                        name="markPerQuestion"
                                                                        type="number"
                                                                        min="1"
                                                                        validate={{ required: { value: true } }}
                                                                        max="999"
                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            if (value.length > 3) {
                                                                                value = value.slice(0, 3);
                                                                            }
                                                                            if (parseInt(value) > 999) {
                                                                                value = "999";
                                                                            }
                                                                            e.target.value = value;
                                                                            handlechangeMark(e)
                                                                            validation.handleChange(e);
                                                                        }}

                                                                        onBlur={validation.handleBlur}
                                                                        onKeyPress={(e) => {
                                                                            if (e.key === '-' || e.key === 'e') {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        onPaste={(e) => {
                                                                            e.preventDefault();
                                                                            const pastedText = e.clipboardData.getData('text');
                                                                            let pastedValue = parseFloat(pastedText);
                                                                            if (isNaN(pastedValue) || pastedValue < 0) {
                                                                                pastedValue = "";
                                                                            } else if (pastedValue > 999) {
                                                                                pastedValue = 999;
                                                                            }
                                                                            e.target.value = pastedValue.toString().slice(0, 4);
                                                                            validation.handleChange(e);
                                                                        }}
                                                                        value={validation.values.markPerQuestion || ""}
                                                                        invalid={validation.touched.markPerQuestion && validation.errors.markPerQuestion ? true : false}
                                                                    />
                                                                    {validation.touched.markPerQuestion && validation.errors.markPerQuestion ? (
                                                                        <FormFeedback type="invalid">{validation.errors.markPerQuestion}</FormFeedback>
                                                                    ) : null}

                                                                </Col>
                                                            </Row>
                                                            <Row style={{ "margin-top": '18px' }}>
                                                                <Col lg="6">
                                                                    <div>
                                                                        <Label>Total Marks</Label>
                                                                        <Input
                                                                            className="rightAlignText"
                                                                            disabled={isButtonDisabled}
                                                                            name="totalMarks"
                                                                            type="number"
                                                                            min="1"
                                                                            max="999"
                                                                            id="input2"
                                                                            onChange={(e) => {
                                                                                let value = e.target.value;
                                                                                if (value.length > 3) {
                                                                                    value = value.slice(0, 3);
                                                                                }
                                                                                if (parseInt(value) > 999) {
                                                                                    value = "999";
                                                                                }
                                                                                e.target.value = value;
                                                                                validation.handleChange(e);
                                                                            }}
                                                                            onBlur={validation.handleBlur}
                                                                            onKeyPress={(e) => {
                                                                                if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                            onPaste={(e) => {
                                                                                e.preventDefault();
                                                                                const pastedText = e.clipboardData.getData('text');
                                                                                let pastedValue = parseFloat(pastedText);
                                                                                if (isNaN(pastedValue) || pastedValue < 0) {
                                                                                    pastedValue = "";
                                                                                } else if (pastedValue > 999) {
                                                                                    pastedValue = 999;
                                                                                }
                                                                                e.target.value = pastedValue.toString().slice(0, 3);
                                                                                validation.handleChange(e);
                                                                            }}
                                                                            value={validation.values.totalMarks || ""}
                                                                            invalid={validation.touched.totalMarks && validation.errors.totalMarks ? true : false}
                                                                        />

                                                                        {validation.touched.totalMarks && validation.errors.totalMarks ? (
                                                                            <FormFeedback type="invalid">{validation.errors.totalMarks}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Col>
                                                </Col>


                                            </Row>
                                        </form>

                                    </CardBody>
                                    <CardFooter>

                                        <div className="text-end">
                                            <Button color="success"
                                                type="submit"
                                                className="save-user"
                                                tabIndex="9"
                                            >
                                                {mkid > 0 && isClone === '0' ? 'Update' : 'Save'}
                                            </Button>
                                            <button type="button" style={{ marginLeft: "10px" }}
                                                onClick={handleClose}
                                                tabIndex="10"
                                                className="btn btn-danger">Close</button>
                                        </div>

                                    </CardFooter>

                                </Card>
                            </Form>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddMockupTest;
