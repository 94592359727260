import React, { useState, useMemo, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import { addNewExamPaper, deleteExamPaper, getExamPaper, resetStorePaper, getmasterlist } from "../../../store/actions";
import * as constants from "../../../constants/layout"
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Row, Modal, UncontrolledTooltip, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody } from "reactstrap";
import { getLoggedInUser } from "helpers/fakebackend_helper";
import axios from "axios";
import Select from "react-select";
import * as url from "../../../helpers/url_helper";
import SubjectModal from "../../../components/Common/SubjectModal"
import SubjectSubExamPaperModel from "../../../components/Common/subject_sub_exampaper_Modal"
import TopicExamList from "../../../components/Common/Topic_exampaper_Modal"


const ExamPaperList = () => {
  document.title = "Masters | Exam Papers";
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [examPaper, setExamPaper] = useState([]);
  const [canCreate, setCanCreate] = useState(false);
  const [modal, setModal] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector(state => state.ExamPaperCategory);
  const masterstore = useSelector(state => state.ExamBoardCategory)
  const [isEdit, setIsEdit] = useState(false);
  const [masterList, setmasterList] = useState([]);
  const inputRef = useRef(null);
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const [exampapr, setExam] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const user = getLoggedInUser();
  const [submitbtn, setSubmitbtn] = useState('');
  const [subjectModal, setsubjectModal] = useState(false);
  const [selectedlanguage, setselectedlanguage] = useState([])
  const [subjectSubCatList, setSubjectSubCatList] = useState([])
  const [subjectList, setSubjectList] = useState([])
  const [subjectDetails, setSubjectDetails] = useState('')
  const [subjectSubDetails, setSubjectSubDetails] = useState('')
  const [subjectSubCatModal, setSubjectSubCatModal] = useState(false);
  const [topicModal, setTopicModal] = useState(false);
  const [topicList, setTopicList] = useState([])
  const [selectedLanguage, setselectedLanguage] = useState([]);

  const [restrictedLanguage, setRestrictedLanguage] = useState([]);

  const disableButton = () => {
    setButtonDisabled(true);
  };

  const enableButton = () => {
    setButtonDisabled(false);
  };
  // Get Exam paper List Api
  const getExampaperListApi = () => {
    setLoading(true);
    dispatch(getExamPaper({ user_id: Number(user.uid) }));
  }

  // Initial call
  useEffect(() => {
    getExampaperListApi()
    document.getElementById('headerTitle').innerHTML = 'Exam Paper';
  }, [dispatch]);

  useEffect(() => {
    setExamPaper(store.examPapercategory);
  }, [store.examPapercategory])

  // Delete Action
  const onClickDelete = (exam) => {
    setExam(exam);
    setDeleteModal(true);
  };

  // Input box Focus
  useEffect(() => {
    if (modal && inputRef.current && inputFocus) {
      inputRef.current.focus();
      setInputFocus(false);
    }
  }, [modal, inputFocus]);

  useEffect(() => {
    if (modal) {
      setInputFocus(true);
    }
  }, [modal]);

  const getmasterlistapi = () => {
    setLoading(true)
    dispatch(getmasterlist({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    getmasterlistapi()
    document.getElementById('headerTitle').innerHTML = 'Exam Paper';
  }, [dispatch]);

  useEffect(() => {
    if (masterstore.masterlist && masterstore.masterlist.languageList)
      setmasterList(masterstore.masterlist.languageList)

  }, [masterstore.masterlist])

  useEffect(() => {
    enableButton()
    if (store.expapercategory_statusFlag === 1) {
      if (submitbtn) {
        if (submitbtn !== 'savecontinue') {
          setModal(false);
        }
        inputRef.current.focus();
        setInputFocus(true)
        setselectedLanguage(null)
        setRestrictedLanguage([])
        validation.resetForm();
        setSubmitbtn('');
        getExampaperListApi();
      }
      if (deleteTrigger) {
        setDeleteTrigger(false);
        getExampaperListApi();
        setDeleteModal(false);
        setExam('');
      }
      setLoading(false);
      dispatch(resetStorePaper({ statusFlag: 0 }));
    } else if (store.expapercategory_statusFlag === 2) {
      if (submitbtn) {
        setSubmitbtn('');
      }
      if (deleteTrigger) {
        setDeleteTrigger(false);
        setDeleteModal(false);
        setExam('');
      }
      setLoading(false);
      dispatch(resetStorePaper({ statusFlag: 0 }));
    }
  }, [store.expapercategory_statusFlag]);

  // Validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      papername: (exampapr && exampapr.papername) || '',
      selectedLanguage: (exampapr && exampapr.selectedLanguage) || []
    },
    validationSchema: Yup.object({
      papername: Yup.string().required("Please Enter Paper Name"),
      selectedLanguage: Yup.array().min(1, "Please select the language")
        .required("Please select the language"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const NewExamPaper = {
        user_id: Number(user.uid),
        exampaperName: values.papername,
        process_type: isEdit ? 'update' : 'save',
        ex_paper_id: isEdit ? exampapr.expid : undefined,
        lang_id: selectedLanguage,
      };
      disableButton()
      dispatch(addNewExamPaper(NewExamPaper));
    },
  });

  const handleSelectCategory = (selectedOption) => {
    setselectedLanguage(selectedOption);
  };
  const onEditClick = (subjectData) => {
    enableButton()
    validation.resetForm();
    setExam(subjectData);
    toggle();
    setIsEdit(true);

    // Update this part to set the selected Subject and languages
    validation.setValues({
      language_ids: subjectData.language_ids,
      languagename: subjectData.languagename,
    });

    // Split the languages string into an array
    const selectedLanguages = subjectData.languages_ids.split(',');


    let arr = []
    selectedLanguages.map((lang) => {
      const split_options = lang.split('_');
      arr.push({ label: split_options[0], value: Number(split_options[1]) })
    });

    // Set the selected languages in the state
    setselectedLanguage(arr);



    // Split the restricted string into an array
    const restricted_languages = subjectData.stlang?.split(',');


    let restricted_arr = []
    restricted_languages?.map((lang) => {
      const res_split_options = lang.split('_');
      restricted_arr.push({ label: res_split_options[0], value: Number(res_split_options[1]) })
    });

    // Set the restricted languages in the state
    setRestrictedLanguage(restricted_arr);



    validation.setValues({ "papername": subjectData.exp_name });
    // Manually trigger the onChange event for the Select component
    const event = { target: { name: 'selectedLanguage', value: arr } };
    validation.handleChange(event);
  };

  useEffect(() => {
    if (user && user.user_previleges) {
      let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.ExamPapers)
      if (page_menu_list) {
        setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid ? true : false)
      }
    }
  }, [user]);

  const toggle = () => {
    setRestrictedLanguage([])
    setModal(prev => !prev);
  };

  const handleAddClick = () => {
    enableButton()
    validation.resetForm();
    setIsEdit(false);
    setselectedLanguage(null)
    setExam('');
    toggle();
  };

  const handleDeleteOrder = () => {
    setDeleteTrigger(true);
    setLoading(true);
    dispatch(deleteExamPaper({ user_id: Number(user.uid), ex_paper_id: Number(exampapr.expid) }))
  };

  const toggleSubject = () => {
    setsubjectModal(!subjectModal)
  }

  const toggleSubjectSubCategory = () => {
    setSubjectSubCatModal(!subjectSubCatModal)
  }

  const toggleTopic = () => {
    setTopicModal(!topicModal);
  };

  // SubjectCount List API
  async function getSubjectList(data) {
    try {
      // setLoading(true)
      var req_data = {
        user_id: Number(user.uid),
        expid: Number(data.expid)
      }
      const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
      const response1 = await axios.post(url.BASE_URL + 'master/getSubjectExampaper', req_data, config)
      if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
        const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser()
          } else {
            await getSubjectList() // calling same function again getting refresh token
          }
        }
      }

      if (response1 && response1.data && response1?.data?.body?.SubjectExampaper) {
        toggleSubject();
        setSubjectList(response1?.data?.body?.SubjectExampaper)
        setSubjectDetails(data)
      } else {
        setSubjectList([])
        setSubjectDetails('')
      }
      // setLoading(false)

    } catch (error) {
      console.error('Error in Subjectcount List API', error)
    }
  }

  // Topic Count List API
  async function getTopicList(data) {
    try {

      // setLoading(true)
      var req_data = {
        user_id: Number(user.uid),
        expid: Number(data.expid)
      }
      const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
      const response1 = await axios.post(url.BASE_URL + 'master/getTopicExampaper', req_data, config)
      if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
        const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser()
          } else {
            await getTopicList() // calling same function again getting refresh token
          }
        }
      }
      if (response1 && response1.data && response1?.data?.body?.SubjectExampaper) {
        toggleTopic();
        setTopicList(response1?.data?.body?.SubjectExampaper)
        setSubjectDetails(data)
      } else {
        setTopicList([])
        setSubjectDetails('')
      }
      // setLoading(false)
    } catch (error) {
      console.error('Error in TopiclistCountApi', error)
    }
  }

  // SubjectSubCategoryCount List API
  async function getSubjectSub(data) {
    try {
      // setLoading(true)
      var req_data = {
        user_id: Number(user.uid),
        expid: Number(data.expid)
      }
      const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
      const response1 = await axios.post(url.BASE_URL + 'master/getSubcategoryExampaper', req_data, config)
      if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
        const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser()
          } else {
            await getSubjectSub() // calling same function again getting refresh token
          }
        }
      }

      if (response1 && response1.data && response1?.data?.body?.SubjectExampaper) {
        toggleSubjectSubCategory();
        setSubjectSubCatList(response1?.data?.body?.SubjectExampaper)
        setSubjectSubDetails(data)
      } else {
        setSubjectSubCatList([])
        setSubjectSubDetails('')
      }
      // setLoading(false)
    } catch (error) {
      console.error('Error in SubjectSubcategoryListAPI', error)
    }
  }

  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        width: '8%',
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.ExamPapers && f.urp_type === constants.menuTypes.Modify) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const exam = cellProps.row.original;
                      onEditClick(exam);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>
                )}
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.ExamPapers && f.urp_type === constants.menuTypes.Remove) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      const exam = cellProps.row.original; onClickDelete(exam);
                    }}>
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </div>
                )}
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        accessorKey: 'sno',
        width: '8%',
        enableColumnFilter: false,
        className: 'pl-25',
        cell: ({ row, table }) =>
          (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1

      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Exam Paper ▼
          </span>
        ),

        accessorKey: 'exp_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Languages ▼
          </span>
        ),

        accessorKey: 'languagename',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            No. of Subjects ▼
          </span>
        ),
        accessorKey: 'subject_count',
        className: 'txt-center',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3 justify-content-end" style={{ cursor: 'pointer' }}>
              {cellProps?.row?.original?.subject_count && Number(cellProps?.row?.original?.subject_count) !== 0 ? (
                <div style={{ textDecoration: 'underline' }} onClick={() => {
                  const subjectData = cellProps.row.original;
                  getSubjectList(subjectData);
                }}>
                  {cellProps?.row?.original?.subject_count}
                </div>
              ) : (
                <div>{cellProps?.row?.original?.subject_count}</div>
              )}
            </div>
          );
        }
      },
      {
        header: (
          <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column' }}>
            <span>No. of Subjects</span>
            <span>Subcategories ▼</span>
          </div>
        ),
        accessorKey: 'subjectsub_count',
        className: 'txt-center',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3 justify-content-end" style={{ cursor: 'pointer' }}>
              {cellProps?.row?.original?.subjectsub_count && Number(cellProps?.row?.original?.subjectsub_count) !== 0 ? (
                <div style={{ textDecoration: 'underline' }} onClick={() => {
                  const subjectData = cellProps.row.original;
                  getSubjectSub(subjectData);
                }}>
                  {cellProps?.row?.original?.subjectsub_count}
                </div>
              ) : (
                <div>{cellProps?.row?.original?.subjectsub_count}</div>
              )}
            </div>
          );
        }
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            No. of topics ▼
          </span>
        ),
        accessorKey: 'topic_count',
        className: 'txt-center',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3 justify-content-end" style={{ cursor: 'pointer' }}>
              {cellProps?.row?.original?.topic_count && Number(cellProps?.row?.original?.topic_count) !== 0 ? (
                <div style={{ textDecoration: 'underline' }} onClick={() => {
                  const subjectData = cellProps.row.original;
                  getTopicList(subjectData);
                }}>
                  {cellProps?.row?.original?.topic_count}
                </div>
              ) : (
                <div>{cellProps?.row?.original?.topic_count}</div>
              )}
            </div>
          );
        }
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Created By ▼
          </span>
        ),

        accessorKey: 'exp_created',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Updated By ▼
          </span>
        ),

        accessorKey: 'ex_updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const mk_stsid = cellProps.row.original.ex_updated;

          return (
            <div className="d-flex align-items-center">
              {mk_stsid ? (
                <span>{mk_stsid}</span>
              ) : (
                <span>-</span>
              )}
            </div>
          );
        },
      }

    ],
    []
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SubjectModal
        show={subjectModal}
        subjectList={subjectList}
        subjectDetails={subjectDetails}
        onCloseClick={() => setsubjectModal(false)}
      />
      <SubjectSubExamPaperModel
        show={subjectSubCatModal}
        subjectSubCatList={subjectSubCatList}
        subjectSubDetails={subjectSubDetails}
        onCloseClick={() => setSubjectSubCatModal(false)}
      />
      <TopicExamList
        show={topicModal}
        topicList={topicList}
        subjectDetails={subjectDetails}
        from={'subcategory'}
        onCloseClick={() => setTopicModal(false)}
      />
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Paper" /> */}
          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} />
              :
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={examPaper || []}
                      isGlobalFilter={true}
                      isPagination={true}
                      isAddButton={canCreate}
                      buttonName="Add"
                      SearchPlaceholder="Search..."
                      pagination="pagination"
                      handleUserClick={handleAddClick}
                      // isCustomPageSize={true}
                      buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            }
          </Row>
          <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Exam Paper" : "Add Exam Paper"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Exam Paper Name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        name="papername"
                        type="text"
                        placeholder="Exam Paper Name"
                        validate={{ required: { value: true } }}
                        maxLength={constants.textboxLength.exp_name}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.papername || ""}
                        invalid={validation.touched.papername && validation.errors.papername}
                        innerRef={inputRef}

                      />
                      {validation.touched.papername && validation.errors.papername ? (
                        <FormFeedback type="invalid">{validation.errors.papername}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Languages<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selectedLanguage}
                        onChange={(selectedOption) => {

                          selectedOption = [...restrictedLanguage, ...selectedOption]

                          var langOptions = selectedOption.filter((obj, index, self) =>
                            index === self.findIndex((o) => (
                              o.value === obj.value
                            ))
                          );


                          validation.setFieldValue("selectedLanguage", langOptions);

                          handleSelectCategory(langOptions);
                        }}
                        options={masterList.map((board) => ({
                          label: board.l_name,
                          value: board.lid,
                        }))}
                        validate={{
                          required: { value: true },
                        }}

                        className={`select2-selection ${validation.touched.selectedLanguage && validation.errors.selectedLanguage ? 'is-invalid' : ''}`}

                        isMulti
                        invalid={
                          validation.touched.selectedLanguage && validation.errors.selectedLanguage ? true : false
                        }
                      />
                      {validation.touched.selectedLanguage && validation.errors.selectedLanguage ? (
                        <FormFeedback type="invalid">{validation.errors.selectedLanguage}</FormFeedback>
                      ) : null}
                    </div>
                    {/* <div className="mb-3">
                      <Label>Languages<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selectedlanguage}
                        onChange={(selectedOption) => {
                   
                          validation.setFieldValue("selectedlanguage", selectedOption);
  
                          handleSelectCategory(selectedOption);
                        }}
                        options={masterList.map((board) => ({
                          label: board.l_name,
                          value: board.lid,
                        }))}
                        ref={inputRef} 
                        validate={{
                          required: { value: true },
                      }}

                        className={`select2-selection ${validation.touched.selectedlanguage && validation.errors.selectedlanguage ? 'is-invalid' : ''}`}
                                                            
                        isMulti
                        invalid={
                          validation.touched.selectedlanguage && validation.errors.selectedlanguage ? true : false
                        }
                      />
                       {validation.touched.selectedlanguage && validation.errors.selectedlanguage ? (
                        <FormFeedback type="invalid">{validation.errors.selectedlanguage}</FormFeedback>
                      ) : null}
                    </div> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        id="btn"
                        color="success"
                        type="submit"
                        className="save-user"
                        disabled={isButtonDisabled}
                        onClick={() => setSubmitbtn('savebtn')}
                      >
                        {!!isEdit ? "Update" : "Save"}
                      </Button>
                      {!isEdit && (
                        <Button
                          color="primary"
                          type="submit"
                          className="save-and-continue-user"
                          disabled={isButtonDisabled}

                          onClick={() => setSubmitbtn('savecontinue')}
                          style={{ marginLeft: '10px' }}
                        >
                          Save & Continue
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}
export default ExamPaperList;