import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Container, Form, Row, Input, Label, FormFeedback } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
// import images
import logodark from "../../assets/images/trios-logo.jpg";
import logolight from "../../assets/images/trios-logo.jpg";
import CarouselPage from "./CarouselPage";
import { loginUser, socialLogin } from "../../store/actions";
import withRouter from "components/Common/withRouter";
import { ToastContainer } from "react-toastify";
import * as constants from "../../constants/layout"
import './auth.scss'
const Login = props => {
  const [configuration, setConfiguration] = useState(null);
  const [passwordShow, setPasswordShow] = useState(false);

  const dispatch = useDispatch();
  //meta title
  document.title = "Login  | TNPSC";
  useEffect(() => {
    localStorage.removeItem("authUser")
  }, [])

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: (configuration && configuration.username) || "",
      password: (configuration && configuration.password) || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Login ID"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
      console.log('values', values);
    }
  });
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4" style={{ backgroundColor: '#FFFFFF' }}>
                <div className="w-100">
                  <div className="d-flex flex-column h-153">
                    <div className="d-flex mt-5 justify-content-center align-items-center">
                      <img
                        src={logolight}
                        alt=""
                        height="45"
                        width="85"
                        className="logo-dark-element"
                      />
                    </div>

                    {/* <div className="d-flex mt-5 justify-content-center align-items-center">
                      <img
                        src={logolight}
                        alt=""
                        height="45"
                        width="85"
                        className="logo-dark-element"
                      />
                    </div> */}

                    <div className="my-auto mt-5">
                      <div>
                        <h5 className="text-primary text-center" >Welcome!</h5>

                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                          }}

                        >
                          <div className="mb-3">
                            <Label className="form-label">Login ID</Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Enter Login ID"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username && validation.errors.username ? true : false
                              }
                            />
                            {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">

                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                maxLength={constants.textboxLength.u_password}
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                              />
                              <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i>
                              </button>
                              {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>

                        </Form>

                      </div>
                    </div>

                    <div className="text-center footer-val" >
                      <p className="mb-0">
                        © {new Date().getFullYear()}. Powered
                        by
                        <a onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a>
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
