import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_SUBJECTSSUBCATEGORY, ADD_SUBJECTSUBCATEGORY, DELETE_SUBJECTSUBCATEGORY } from "./actionTypes";
import { getsubjectsubcategorySuccess, addsubjectsubcategorySuccess, deletesubjectsubcategorySuccess } from "./actions";

//Include Both Helper File with needed methods
import { getsubjectsubcategoryList, addsubjectsubcategorylist, deletesubjectsubcategory, userValidation } from "helpers/fakebackend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchsubjectsubcategory({ payload: data }) {
  try {
    const response = yield call(getsubjectsubcategoryList, data)
    yield call(userValidation, response)
    yield put(getsubjectsubcategorySuccess(response))
  } catch (error) {
    yield put(getsubjectsubcategorySuccess({ statusFlag: 2 }))
  }
}

function* onAddsubjectsubcategory({ payload: data }) {
  try {
    const response = yield call(addsubjectsubcategorylist, data)
    yield call(userValidation, response)
    yield put(addsubjectsubcategorySuccess(response))
    if (response && response.statusFlag === 1) {
      toast.success(response.message, { autoClose: 2000 });
    } else {
      toast.error(response.message, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(addsubjectsubcategorySuccess({ statusFlag: 2 }))
    toast.error("Subject subcategory added failed!!", { autoClose: 2000 });
  }
}
function* onDeletesubjectsubcategory({ payload: data }) {
  try {
    const response = yield call(deletesubjectsubcategory, data)
    yield call(userValidation, response)
    yield put(deletesubjectsubcategorySuccess(response))
    if (response && response.statusFlag === 1) {
      toast.success(response.message, { autoClose: 2000 });
    } else {
      toast.error(response.message, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(deletesubjectsubcategorySuccess({ statusFlag: 2 }))
    toast.error("subjectsubcategory Deleted Failed", { autoClose: 2000 });
  }
}

function* subjectsubcategorySaga() {
  yield takeEvery(GET_SUBJECTSSUBCATEGORY, fetchsubjectsubcategory)
  yield takeEvery(ADD_SUBJECTSUBCATEGORY, onAddsubjectsubcategory)
  yield takeEvery(DELETE_SUBJECTSUBCATEGORY, onDeletesubjectsubcategory)
}

export default subjectsubcategorySaga;
