import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import ExamBoard from "./examboard/reducer";
import ExamBoardCategory from "./examboardcategory/reducer";
import Subject from "./subject/reducer";
import Subjectsubcategory from "./subjectsubcategory/reducer";
import Topic from "./topic/reducer";
import Usermaster from "./usermaster/reducer";
import Userrole from "./userrole/reducer";
import Blueprint from "./blueprint/reducer";
import ExamPaperCategory from "./exampapers/reducer";
import MockupList from "./mockuptest/reducer";
import GeneralSettings from "./generalsettings/reducer"
import Studymaterial from "./studymaterial/reducer";
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ExamBoard,
  ExamBoardCategory,
  Subject,
  Subjectsubcategory,
  Topic,
  Usermaster,
  Userrole,
  Blueprint,
  ExamPaperCategory,
  MockupList,  
  Studymaterial,
  GeneralSettings
});

export default rootReducer;
