import {
  GET_MOCKUPTEST,
  GET_MOCKUPTEST_SUCCESS,
  ADD_NEW_MOCKUPTEST,
  ADD_NEW_MOCKUPTEST_SUCCESS,
  EDITLOAD_MOCKUPTEST,
  EDITLOAD_MOCKUPTEST_SUCCESS,
  DELETE_MOCKUPTEST,
  DELETE_MOCKUPTEST_SUCCESS,
  RESET_STORE_MOCKUPTEST
} from "./actionTypes"

export const getMockupTest = (data) => ({
  type: GET_MOCKUPTEST,
  payload: data
})

export const getMockupTestSuccess = examboards => ({
  type: GET_MOCKUPTEST_SUCCESS,
  payload: examboards,
})
export const addNewMockuptest = data => ({
  type: ADD_NEW_MOCKUPTEST,
  payload: data,
})

export const addNewMockuptestSuccess = data => ({
  type: ADD_NEW_MOCKUPTEST_SUCCESS,
  payload: data,
})
export const editloadMock = data => ({
  type: EDITLOAD_MOCKUPTEST,
  payload: data,
})

export const editloadMockSuccess = mockupTestData => ({
  type: EDITLOAD_MOCKUPTEST_SUCCESS,
  payload: mockupTestData,
})
export const deleteMockupTest = data => ({
  type: DELETE_MOCKUPTEST,
  payload: data,
})

export const deleteMockupTestSuccess = data => ({
  type: DELETE_MOCKUPTEST_SUCCESS,
  payload: data,
})
export const resetStoremockuptest = data => ({
  type: RESET_STORE_MOCKUPTEST,
  payload: data,
})