import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {GET_SUBJECTS, ADD_SUBJECT, DELETE_SUBJECT} from "./actionTypes";
import {getsubjectSuccess, addsubjectSuccess, deletesubjectSuccess} from "./actions";

//Include Both Helper File with needed methods
import { getsubjectList, addsubjectlist, deletesubjectlist, userValidation} from "helpers/fakebackend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchsubject({ payload: data}) {
  try {
    const response = yield call(getsubjectList, data)
    yield call(userValidation, response)
    yield put(getsubjectSuccess(response))
  } catch (error) {
    yield put(getsubjectSuccess({statusFlag: 2}))
  }
}

function* onAddsubject({ payload: data }) {
    try {
        const response = yield call(addsubjectlist, data)
        yield call(userValidation, response)
        yield put(addsubjectSuccess(response))
        console.log(response, 'response')
        if (response && response.statusFlag === 1) {
          toast.success(response.message, { autoClose: 2000 });
        } else {
          toast.error(response.message, { autoClose: 2000 });
        }      
    } catch (error) {
        yield put(addsubjectSuccess({statusFlag: 2}))
        toast.error("subject Added Failed", { autoClose: 2000 });
    }
  }
  function* onDeletesubject({ payload: data }) {
    try {
        const response = yield call(deletesubjectlist, data)
        yield call(userValidation, response)
        yield put(deletesubjectSuccess(response))
        console.log(response, 'response')
        if (response && response.statusFlag === 1) {
          toast.success(response.message, { autoClose: 2000 });
        } else {
          toast.error(response.message, { autoClose: 2000 });
        }      
    } catch (error) {
        yield put(deletesubjectSuccess({statusFlag: 2}))
        toast.error("subject Deleted Failed", { autoClose: 2000 });
    }
  }

function* subjectSaga() {
  yield takeEvery(GET_SUBJECTS, fetchsubject)
  yield takeEvery(ADD_SUBJECT, onAddsubject)
  yield takeEvery(DELETE_SUBJECT, onDeletesubject)
 }

export default subjectSaga;
