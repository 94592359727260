import {
  GET_EXAMBOARDS,
  GET_EXAMBOARDS_SUCCESS,
  ADD_NEW_EXAM_BOARD,
  ADD_NEW_EXAM_BOARD_SUCCESS,
  DELETE_EXAM_BOARD,
  DELETE_EXAM_BOARD_SUCCESS,
  RESET_STORE_EXAM_BOARD
} from "./actionTypes"

export const getExamBoards = (data) => ({
  type: GET_EXAMBOARDS,
  payload: data
})

export const getExamBoardSuccess = examboards => ({
  type: GET_EXAMBOARDS_SUCCESS,
  payload: examboards,
})
export const addNewExamBoard = data => ({
  type: ADD_NEW_EXAM_BOARD,
  payload: data,
})

export const addNewExamBoardSuccess = data => ({
  type: ADD_NEW_EXAM_BOARD_SUCCESS,
  payload: data,
})

export const deleteExamBoard = data => ({
  type: DELETE_EXAM_BOARD,
  payload: data,
})

export const deleteExamBoardSuccess = data => ({
  type: DELETE_EXAM_BOARD_SUCCESS,
  payload: data,
})


export const resetStore = data => ({
  type: RESET_STORE_EXAM_BOARD,
  payload: data,
})