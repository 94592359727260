import React, { useMemo, useState, useEffect, useRef } from "react";
import { Card, Button, CardBody, Col, Container, UncontrolledTooltip, Label, Row, FormFeedback, CardFooter, Badge } from "reactstrap";
import { useFormik } from "formik";
import "../../../../node_modules/@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import * as Yup from "yup";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getmasterlist, resetStoreblueprint, editload, logoutUser } from "../../../store/actions";
import { getLoggedInUser, getRefreshToken } from "../../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../components/Common/TableContainer';
import "./conf.scss"
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import * as url from "../../../../src/helpers/url_helper";
import axios from "axios";
import * as constants from "../../../constants/layout"

var GLOBAL_SUBJECT_LIST = [];
var GLOBAL_TOPIC_LIST = [];
const Mapping = () => {

    const [tableData, setTableData] = useState([])
    // const tableData = [
    //     { id: 1, colorClass: 'table-light', columns: ['General studies', 'Current events', '70%', '10%', '10%', '10%'] },
    //     { id: 2, colorClass: 'table-success', columns: ['General tamil', 'Dev subject', '70%', '10%', '10%', '10%'] },
    //     { id: 3, colorClass: 'table-info', columns: ['Physics', 'Laws', '70%', '10%', '10%', '10%'] },
    //     { id: 4, colorClass: 'table-warning', columns: ['Geography', 'Earth', '70%', '10%', '10%', '10%'] },
    //     { id: 5, colorClass: 'table-danger', columns: ['History', 'Empire', '70%', '10%', '10%', '10%'] }
    // ];
    // const tableData = 
    // [
    //     { id: 1, columns: ['Paper 1', 'Math', 5, 10, 3, 2], colorClass: 'row-class' },
    //     { id: 2, columns: ['Paper 2', 'Science', 6, 12, 4, 1], colorClass: 'row-class' },
    // ];
    // const levels = ['Low', 'Medium', 'High', 'Advanced'];
    const [levels, setLevels] = useState(["Low", "Medium", "High", "Advanced"]);

    const [values, setValues] = useState(new Array(levels.length).fill(0));

    const { cid } = useParams();
    const store = useSelector(state => state.Blueprint)
    const dispatch = useDispatch();
    const user = getLoggedInUser()
    const navigate = useNavigate();
    const confmasterstore = useSelector(state => state.ExamBoardCategory)
    const [isLoading, setLoading] = useState(false);
    const [masterList, setmasterList] = useState([]);
    const [boardcategory, setboardcategory] = useState([]);
    const [allBoardcategory, setAllBoardcategory] = useState([]);
    const [subject, setsubject] = useState([]);
    const [subjectCountArr, setSubjectCount] = useState([]);
    const [submitbtn, setSubmitbtn] = useState(false);
    const [initialSubmitState, setInitialSubmitState] = useState(false);
    const [c_subjects, setC_subjects] = useState([]);
    const [deleteTrigger, setDeleteTrigger] = useState(false);
    var isClone = localStorage.getItem('isClone');
    const [arr1, setArr1] = useState([]);

    const [subjectsubcategory, setsubjectsubcategory] = useState([]);
    const [allSubjectsubcategory, setAllSubjectsubcategory] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedoptionName, setselectedoptionName] = useState(null);

    const [selectedBoardCategory, setselectedBoardCategory] = useState(null);
    const [selectedLanguage, setselectedLanguage] = useState(null);
    const [totalQuestions, setTotalQuestions] = useState(null);
    const [Language, setLanguage] = useState(null);
    const [editingRow, setEditingRow] = useState(null);
    const [negativeMark, setNegativeMark] = useState(false);
    const [status, setstatus] = useState(false);
    const [optionTopicName, setOptionTopicName] = useState([]);
    const [optionLanguageName, setOptionLanguageName] = useState([]);
    const [optionAllLanguageName, setOptionAllLanguageName] = useState([]);
    const [optionAllTopicName, setOptionAllTopicName] = useState([]);
    const [selectedPaper, setselectedPaper] = useState(null);
    const [examboardCat, setExamBoardCat] = useState(null);
    const [paperList, setPaperList] = useState([])
    const [allpaperlist, setAllpaperlist] = useState([])
    const [selectedCount, setselectedCount] = useState(0);
    const [selectedLevelCount, setselectedLevelCount] = useState([]);
    const [selectedRequiredLowCount, setRequiredLowCount] = useState(0);
    const [selectedRequiredMedCount, setRequiredMedCount] = useState(0);
    const [selectedRequiredHighCount, setRequiredHighCount] = useState(0);
    const [selectedRequiredAdvCount, setRequiredAdvCount] = useState(0);
    const [totalValues, setTotalValues] = useState(0);
    const [selectedLowCount, setselectedLowCount] = useState(null);
    const [selectedMediumCount, setselectedMediumCount] = useState(null);
    const [selectedHighCount, setselectedHighCount] = useState(null);
    const [selectedAdvancedCount, setselectedAdvancedCount] = useState(null);
    const [inputFocus, setInputFocus] = useState(false);
    const [editClick, setEditClick] = useState(false)
    const [editLoadCalling, setEditLoadCalling] = useState(false)
    const [subjectMapList, setSubjectMapList] = useState([])
    const [subjectAllMapList, setSubjectAllMapList] = useState([])
    const [paperAllMapList, setPaperAllMapList] = useState([])
    var subjectCount;

    const paperRef = useRef(null);
    const subjectRef = useRef(null);
    useEffect(() => {
        if (handleClose) {
            setInputFocus(false);
        } else {
            setInputFocus(true)
        }

    }, [])

    const handleChangeLevel = (index, newValue) => {
        let value, data;
        if (index === 0) {
            value = selectedLevelCount[0]?.questioncount * newValue;
            data = value / 100;
            setRequiredLowCount(!isNaN(data) ? Math.round(data) : 0);
        } else if (index === 1) {
            value = selectedLevelCount[1]?.questioncount * newValue;
            data = value / 100;
            setRequiredMedCount(!isNaN(data) ? Math.round(data) : 0);
        } else if (index === 2) {
            value = selectedLevelCount[2]?.questioncount * newValue;
            data = value / 100;
            setRequiredHighCount(!isNaN(data) ? Math.round(data) : 0);
        } else if (index === 3) {
            value = selectedLevelCount[3]?.questioncount * newValue;
            data = value / 100;
            setRequiredAdvCount(!isNaN(data) ? Math.round(data) : 0);
        }

        // Update the value in the array
        const updatedValues = [...values];
        updatedValues[index] = newValue;
        setValues(updatedValues);
        const total = updatedValues
            .map(value => Number(value)) // Convert values to numbers
            .filter(num => !isNaN(num)) // Filter out NaN values
            .reduce((acc, curr) => acc + curr, 0);
        // const total = updatedValues.reduce((acc, curr) => acc + curr, 0);

        setTotalValues(total)
    };



    useEffect(() => {

        document.getElementById('headerTitle').innerHTML = 'Mapping Blue print';
        geteditloadapi()
        getSubjectCount()
    }, [dispatch, cid]);


    const handleEditRow = (row) => {
        const editData = row
        setEditClick(true)
        const filter_subject = subjectCount.filter(e => e?.cs_sid == row?.sid)
        const totalQuestionCount = filter_subject.reduce((accumulator, item) => {
            return accumulator + parseInt(item.questioncount, 10);
        }, 0);
        var updatedLevelCount = [];

        subjectCount.map((row) => {

            if (row.q_exlid && row.cs_expid == editData?.expid && row.cs_sid == editData?.sid) {
                updatedLevelCount.push({
                    key: row.q_exlid,
                    questioncount: row.questioncount
                })
            }
        })

        setselectedLevelCount(updatedLevelCount);


        const explevel = row.explevel;

        subjectCount.map((row) => {
            explevel.map((row1) => {
                if (row.q_exlid == 1 && row1.key == 1) {
                    var req = ((row1.value * row.questioncount) / 100).toFixed();
                    req > 0 ? setRequiredLowCount(req) : ''
                }
                if (row.q_exlid == 2 && row1.key == 2) {
                    var req = ((row1.value * row.questioncount) / 100).toFixed();
                    req > 0 ? setRequiredMedCount(req) : ''
                }
                if (row.q_exlid == 3 && row1.key == 3) {
                    var req = ((row1.value * row.questioncount) / 100).toFixed();
                    req > 0 ? setRequiredHighCount(req) : ''
                }
                if (row.q_exlid == 4 && row1.key == 4) {
                    var req = ((row1.value * row.questioncount) / 100).toFixed();
                    req > 0 ? setRequiredAdvCount(req) : ''
                }
            })
        })


        // Sum the values in the explevel array
        const total = explevel
            .map(item => parseFloat(item.value)) // Convert each value to a number
            .filter(num => !isNaN(num)) // Filter out NaN values
            .reduce((acc, curr) => acc + curr, 0); // Sum up the values

        setTotalValues(total)

        setselectedCount(totalQuestionCount)
        const data = row?.explevel;

        const updatedValues = new Array(levels.length).fill(0);
        // Convert `key` values to integers, sort by these integer values
        const sortedData = data.sort((a, b) => {
            // Compare based on numeric value of `key`
            return parseInt(a.key, 10) - parseInt(b.key, 10);
        });



        if (sortedData) {
            sortedData.forEach((item, index) => {
                if (index < updatedValues.length) {
                    updatedValues[index] = item?.value;
                }
            });
        }
        setValues(updatedValues);
        setIsEditing(true);
        setEditingRow(row);

        setselectedPaper({
            label: row.exp_name,
            value: row.expid
        });

        setsubject({
            sno: row?.sno,
            subjectName: row?.s_name,
            cs_sid: row?.sid,
            cs_sscid: "0",
            cs_questioncount: "5",
            cs_mark: 10,
            cs_tid: 0,
            cs_tname: null,
            ex_paper_id: row?.expid
        });

        validation.setFieldValue("subjectName", {
            label: row.s_name,
            value: row.sid
        });

        validation.setFieldValue("selectedPaper", {
            label: row.exp_name,
            value: row.expid
        });
    };


    const geteditloadapi = () => {
        setLoading(true)
        dispatch(editload({ user_id: Number(user.uid), cid: Number(cid) }));
    }

    const handleClose = () => {
        validation.resetForm();
        setInitialSubmitState(true);
        navigate("/list-configuration");
    }
    const getSubjectCount = async () => {
        try {

            const data = {
                "cid": Number(cid),
                "user_id": Number(user.uid)
            }
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
            const response1 = await axios.post(url.BASE_URL + 'blueprint/getSubjectCount', data, config)
            if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser();
                    } else {
                        await getSubjectCount();
                    }
                }
            }
            if (response1 && response1.data && response1.data && response1.data.status === 200) {
                subjectCount = response1?.data?.body?.SubjectCount
                setSubjectCount(response1?.data?.body?.SubjectCount)

            } else {
                toast.error(response1.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (e) {
            console.log("error in subject count api", e)
        }

    }
    const handleAddOrUpdateRow = () => {
        const numberArray = values
            .map(str => parseFloat(str))
            .filter(num => !isNaN(num));

        const total = numberArray.reduce((acc, curr) => acc + curr, 0);

        // Check if the total is greater than 100%
        if (total > 100) {
            toast.error("The sum of all values must be 100%", { autoClose: 2000 });
            return; // Prevent saving if validation fails
        }

        // Create new row data
        const newRow = {
            expid: selectedPaper?.value,
            exp_name: selectedPaper?.label,
            s_name: !isEditing ? subject?.label : subject?.subjectName,
            sid: !isEditing ? subject?.value : subject?.cs_sid,
            explevel: levels.map((level, index) => ({
                key: index + 1,
                value: values[index] || 0,
            })),
        };

        // Check if the row already exists in the data table
        const isDuplicate = tableData.some(row =>
            row.expid === newRow.expid && row.sid === newRow.sid
        );

        if (isDuplicate && !isEditing) {
            toast.error("The combination of exam paper and subject already exists", { autoClose: 2000 });
            return;
        } else if (!isDuplicate && !isEditing && editLoadCalling) {
            toast.error("The combination of exam paper and subject already exists", { autoClose: 2000 });
            return;

        }

        // Update or add new row
        if (selectedPaper && subject) {
            if (isEditing) {
                setTableData(prev =>
                    prev.map(row =>
                        row.expid === editingRow.expid && row.sid === editingRow.sid
                            ? { ...newRow, expid: row.expid, sid: row.sid }
                            : row
                    )
                );
                setArr1(prev =>
                    prev.map(row =>
                        row.expid === editingRow.expid && row.sid === editingRow.sid
                            ? { ...newRow, expid: row.expid, sid: row.sid }
                            : row
                    )
                );
            } else {
                setArr1(prevArr1 => [...prevArr1, newRow]);
                setTableData(prevArr1 => [...prevArr1, newRow]);
            }

            // Reset fields after adding or editing
            setselectedPaper(null);
            setsubject(null);
            setValues(new Array(levels.length).fill(0));
            setIsEditing(false);
            setEditingRow(null);
            setTotalValues(0);
            setRequiredLowCount(0);
            setRequiredHighCount(0);
            setRequiredMedCount(0);
            setRequiredAdvCount(0);
            setselectedCount(0);
            setEditClick(false);
            setEditLoadCalling(false);
            setselectedLevelCount([0, 0, 0, 0]);
            validation.setFieldValue("selectedPaper", "");
            validation.setFieldValue("subjectName", "");
        } else {
            toast.error("Please select the values", { autoClose: 2000 });
        }
    };

    const handleClear = () => {

        setselectedPaper(null);
        setsubject(null);
        setValues(new Array(levels.length).fill(0));
        setIsEditing(false);
        setEditingRow(null);
        setTotalValues(0);
        setRequiredLowCount(0);
        setRequiredHighCount(0);
        setRequiredMedCount(0);
        setRequiredAdvCount(0);
        setselectedCount(0);
        setEditClick(false);
        setEditLoadCalling(false);
        setselectedLevelCount([0, 0, 0, 0]);
        validation.setFieldValue("selectedPaper", "");
        validation.setFieldValue("subjectName", "");
    }



    const handleSaveClick = async () => {


        if (arr1 && arr1.length > 0) {
            if (store.bluePrintData?.editloadMapping.length > 0) {

                var request_data = {
                    "cid": Number(cid),
                    "user_id": Number(user.uid),
                    "mapping": arr1,
                    "process_type": "update"
                }
            } else {
                var request_data = {
                    "cid": Number(cid),
                    "user_id": Number(user.uid),
                    "mapping": arr1,
                    "process_type": "save"
                }

            }
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
            const response1 = await axios.post(url.BASE_URL + 'blueprint/mappingConf', request_data, config)
            if (response1 && response1.statusFlag === 3) {
                toast.error(response1.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                window.location.reload()
                return
            }
            if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
                const refresh = await getRefreshToken()
                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser()
                    } else {
                        handleSaveClick()
                    }
                }
            }


            if (response1 && response1.data && response1.data.body && response1?.data?.body?.statusFlag == 1) {

                toast.success(response1.data.body.message, { autoClose: 2000 });
                setTimeout(() => {
                    navigate(
                        `/list-configuration`
                    )
                }, 1000);

            } else {
                toast.error(response1.data.message, { autoClose: 2000 });

            }

        } else {
            toast.error("Please Select the values", { autoClose: 2000 });

        }


    }

    const handleSelectPaper = (selectedOption) => {
        var filter_subject = dataTable.filter(e => Number(e.ex_paper_id) == Number(selectedOption?.value))
        setSubjectAllMapList(filter_subject)
        setselectedCount(0)
        setselectedLevelCount([])
        setTotalValues(0)
        var filtered_subjects = GLOBAL_SUBJECT_LIST.filter(e => Number(e.expid) == Number(selectedOption.value))
        setsubject(filtered_subjects)
        setselectedPaper(selectedOption);
        validation.setFieldValue("subjectName", null);

        var filtered_topic_option = GLOBAL_TOPIC_LIST.filter(e => e.t_expid == Number(selectedOption.value));
        setOptionTopicName(filtered_topic_option);
        validation.setFieldValue("selectedTopicName", null);
    };


    const handleDeleteRow = (row) => {
        try {
            setTableData(prevData => {
                const updatedData = prevData.filter(item => item.sid !== row.sid || item.expid !== row.expid);
                return updatedData;
            });
            setArr1(prevData => {
                const updatedData = prevData.filter(item => item.sid !== row.sid || item.expid !== row.expid);
                return updatedData;
            });

        } catch (error) {
            console.error("Error deleting row:", error);
        }
    };

    const getmasterlistapi = () => {
        setLoading(true)
        dispatch(getmasterlist({ user_id: Number(user.uid) }));
    }

    useEffect(() => {

        getmasterlistapi()
    }, []);


    function handleSelectSubjectName(varselectedSubjectName) {
        console.log('=-=-=-=', subjectCountArr)
        var filter_data = subjectCountArr.filter(e => e.cs_sid == varselectedSubjectName?.value)
        const sum = filter_data.reduce((accumulator, item) => accumulator + Number(item.questioncount), 0);
        setselectedLevelCount(filter_data)

        var data;
        setselectedCount(sum)

        setsubject(varselectedSubjectName)

    }

    useEffect(() => {

        document.getElementById('headerTitle').innerHTML = 'Mapping';
    }, [dispatch, cid]);



    useEffect(() => {

        if (store.bluePrintData && store.bluePrintData.bluePrintData && store.bluePrintData.bluePrintData.length > 0) {
            const editData = store.bluePrintData.bluePrintData[0];
            const editLoadMapping = store.bluePrintData?.editloadMapping;

            if (editLoadMapping.length > 0) {
                setEditLoadCalling(true)
                setTableData(editLoadMapping)
                console.log(';editLoadMapping', editLoadMapping);

                setArr1(editLoadMapping)
            } else {
                setTableData([])
            }

            validation.setFieldValue("configurationID", editData.cid);
            validation.setFieldValue("configurationName", isClone === '1' ? "" : editData.c_name);
            setExamBoardCat(editData.ex_name + "-" + editData.exct_name)

            const boardCategoryValue = {
                label: editData.ex_name + "-" + editData.exct_name,
                value: editData.c_exctid + "-" + editData.c_exid,
            };
            const langaugevalue = {
                label: editData && editData.c_lid && editData.c_lid == 1 ? "English" : "Regional",
                value: editData.c_lid,

            }
            setselectedLanguage(langaugevalue)
            setLanguage(editData && editData.c_lid && editData.c_lid == 1 ? "English" : "Regional")
            validation.setFieldValue("selectedLanguage", isClone === '1' ? langaugevalue : langaugevalue)

            validation.setFieldValue("selectedBoardCategory", isClone === '1' ? null : boardCategoryValue);
            setselectedBoardCategory(isClone === '1' ? null : boardCategoryValue);


            validation.setFieldValue("negativeMark", editData.c_negativemark === 1);

            validation.setFieldValue("totalMarks", editData.c_totalmarks);
            validation.setFieldValue("totalQuestions", editData.c_totalquestions);
            setTotalQuestions(editData?.c_totalquestions)
            validation.setFieldValue("selectedoptionName", {
                label: editData.c_optionscount.toString(),
                value: editData.c_optionscount,
            });
            setselectedoptionName({
                label: editData.c_optionscount.toString(),
                value: editData.c_optionscount,
            });
            validation.setFieldValue("duration", editData.c_duration);

            const subjectsData = store.bluePrintData.bluePrintSubjects.map((subject, index) => ({
                sno: index + 1,
                subjectName: subject.s_name,
                subCategory: subject.ssc_name,
                totalQuestions: subject.cs_questioncount,
                mark: subject.cs_mark,
                product: Number(subject.cs_questioncount) * Number(subject.cs_mark),
                cs_sid: subject.cs_sid,
                cs_sscid: subject.cs_sscid,
                cs_questioncount: subject.cs_questioncount,
                cs_mark: subject.cs_mark,
                cs_tid: Number(subject.cs_tid),
                cs_tname: subject.t_name,
                ex_paper_id: Number(subject.expid),
                ex_paper_name: subject.exampaper
            }));

            for (let i = 0; i < subjectsData?.length; i++) {
            }
            console.log('sss', subjectsData)
            const groupedData = subjectsData.reduce((acc, item) => {
                // Create a unique key based on ex_paper_id and ex_paper_name
                const key = `${item.ex_paper_id}-${item.ex_paper_name}`;

                if (!acc[key]) {
                    acc[key] = {
                        ex_paper_id: item.ex_paper_id,
                        ex_paper_name: item.ex_paper_name,
                        subjects: []
                    };
                }

                acc[key].subjects.push({
                    sno: item.sno,
                    subjectName: item.subjectName,
                    subCategory: item.subCategory,
                    totalQuestions: item.totalQuestions,
                    mark: item.mark,
                    product: item.product,
                    cs_sid: item.cs_sid,
                    cs_sscid: item.cs_sscid,
                    cs_questioncount: item.cs_questioncount,
                    cs_mark: item.cs_mark,
                    cs_tid: item.cs_tid,
                    cs_tname: item.cs_tname
                });

                return acc;
            }, {});

            // Convert the grouped data into an array
            const result = Object.values(groupedData);

            console.log('-====================', result);
            setPaperAllMapList(result)


            setDataTable(subjectsData);

            // Set c_subjects array
            setC_subjects(subjectsData);

            // Set status toggle only during update

            if (editData.c_stsid == "1")
                setstatus(true)
            else setstatus(false)
            if (editData.c_negativemark == 1) setNegativeMark(true)
            else setNegativeMark(false)

        }
    }, [store.bluePrintData]);
    useEffect(() => {

        console.log(optionAllTopicName, "optionAllTopicName")
    }, [optionAllTopicName])

    useEffect(() => {

        if (confmasterstore.masterlist && confmasterstore.masterlist.examBoardList)
            setmasterList(confmasterstore.masterlist.examBoardList)
        if (confmasterstore.masterlist && confmasterstore.masterlist.board_categoryList) {
            if (!isEditing) {
                var filtered_board = confmasterstore.masterlist.board_categoryList.filter(e => e.flag != 0)
                setboardcategory(filtered_board)

                setAllBoardcategory(filtered_board)
            } else {
                setboardcategory(confmasterstore.masterlist.board_categoryList)
                setAllBoardcategory(confmasterstore.masterlist.board_categoryList)
            }
        }


        if (confmasterstore.masterlist && confmasterstore.masterlist.subCategryList) {
            setsubjectsubcategory(confmasterstore.masterlist.subCategryList)
            setAllSubjectsubcategory(confmasterstore.masterlist.subCategryList)
        }

        if (confmasterstore.masterlist && confmasterstore.masterlist.topicList) {
            setOptionTopicName(confmasterstore.masterlist.topicList)
            GLOBAL_TOPIC_LIST = confmasterstore.masterlist.topicList
        }


        if (confmasterstore.masterlist && confmasterstore.masterlist.subjectList) {
            GLOBAL_SUBJECT_LIST = confmasterstore.masterlist.subjectList;
            setsubject(confmasterstore.masterlist.subjectList)
        }
        if (confmasterstore.masterlist && confmasterstore.masterlist.examPaperList)
            setPaperList(confmasterstore.masterlist.examPaperList)
        setAllpaperlist(confmasterstore.masterlist.examPaperList)

        if (confmasterstore.masterlist && confmasterstore.masterlist.languageList) {
            setOptionLanguageName(confmasterstore.masterlist.languageList)
            setOptionAllLanguageName(confmasterstore.masterlist.languageList)
        }

    }, [confmasterstore.masterlist])

    const columns = useMemo(
        () => [
            {
                header: 'Actions',
                width: '8%',
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="btn btn-sm btn-soft-info"
                                onClick={() => { handleEditRow(cellProps.row.original) }}
                            >
                                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>

                            <div
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    handleDeleteRow(cellProps.row.original);
                                }}
                            >
                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    );
                }
            },
            {
                header: '#',
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Exam Paper ▼
                    </span>
                ),

                accessorKey: 'ex_paper_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            {cellProps.row.original.exp_name}

                        </div>
                    );
                }
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Subject ▼
                    </span>
                ),

                accessorKey: 'subjectName',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            {cellProps.row.original.s_name}

                        </div>
                    );
                }
            },

            {
                header: (
                    <div style={{ textAlign: 'right' }}>
                        <span style={{ cursor: 'pointer' }}>
                            Low
                        </span>
                    </div>
                ),

                accessorKey: 'totalQuestions',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,

                cell: (cellProps) => {

                    const matchedItem = cellProps.row.original.explevel.filter(item => item.key == 1);

                    return (
                        <div className="text-right">
                            {matchedItem ? `${matchedItem[0]?.value}%` : 'N/A'}
                        </div>
                    );

                }
            },
            {
                header: (
                    <div style={{ textAlign: 'right' }}>

                        <span style={{ cursor: 'pointer', textAlign: 'right' }}>
                            Medium
                        </span>
                    </div>
                ),

                accessorKey: 'mark',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {

                    const matchedItem = cellProps?.row?.original?.explevel?.filter(item => item?.key == 2);

                    return (
                        <div className="text-right">
                            {matchedItem ? `${matchedItem[0]?.value}%` : 'N/A'}
                        </div>
                    );

                }
            },
            {
                header: (
                    <div style={{ textAlign: 'right' }}>

                        <span style={{ cursor: 'pointer' }}>
                            High
                        </span >
                    </div>
                ),

                accessorKey: 'product1',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {

                    const matchedItem = cellProps?.row?.original?.explevel?.filter(item => item?.key == 3);

                    return (
                        <div className="text-right">
                            {matchedItem ? `${matchedItem[0]?.value}%` : 'N/A'}
                        </div>
                    );

                }
            },
            {
                header: (
                    <div style={{ textAlign: 'right' }}>

                        <span style={{ cursor: 'pointer' }}>
                            Advanced
                        </span>
                    </div>
                ),

                accessorKey: 'product2',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const matchedItem = cellProps?.row?.original?.explevel?.filter(item => item?.key == 4);

                    return (
                        <div className="text-right">
                            {matchedItem ? `${matchedItem[0]?.value}%` : 'N/A'}
                        </div>
                    );

                }
            },
        ],
        []
    );

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {


        },
        validationSchema: Yup.object({

        }),
        onSubmit: (values) => {

        },
    });


    useEffect(() => {
        // return () => {
        validation.resetForm();
        setDataTable([])
        setC_subjects([])
        // };
    }, []);

    //After api call success stop loading
    useEffect(() => {
        if (store.blueprint_statusFlag === 1) {
            if (submitbtn) { // add or update then refresh list
                setSubmitbtn(false)

                validation.resetForm();
                setInitialSubmitState(true);
                navigate("/list-configuration");
            }
            if (deleteTrigger) { // delete then refresh list
                setDeleteTrigger(false)

                setDeleteModal(false);
                setBoard('');
            }
            setLoading(false)
            dispatch(resetStoreblueprint({ statusFlag: 0 }));
        } else if (store.blueprint_statusFlag === 2) {
            if (submitbtn) {
                setSubmitbtn(false)
            }
            if (deleteTrigger) {
                setDeleteTrigger(false)
            }
            setLoading(false)
            dispatch(resetStoreblueprint({ statusFlag: 0 }));
        }
    }, [store.blueprint_statusFlag])

    const customStyles = {
        option: (styles, state, data) => { // fixed
            return {
                ...styles,
                backgroundColor: state.isDisabled
                    ? undefined
                    : state.isSelected
                        ? '#a6b0cf57'
                        : state.isFocused
                            ? '#556ee6'
                            : 'white',

                color: state.isDisabled
                    ? '#ccc'
                    : state.isSelected
                        ? 'black'
                        : state.isFocused
                            ? 'white'
                            : 'black',

                cursor: state.isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled
                        ? state.isSelected
                            ? '#556ee6'
                            : '#556ee6'
                        : undefined,
                },
            };
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <Container fluid={true}>
                    <Card>

                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <Row className="mb-3">
                                        <Col lg={4}>
                                            <h6 className="mb-1">Exam Board Category {" "}: {" "}<strong>{examboardCat}
                                            </strong> </h6>
                                        </Col>
                                        <Col lg={4}>
                                            <h6 className="mb-1">Language{" "}: {" "}<strong>{Language} </strong></h6>
                                        </Col>
                                        <Col lg={4}>
                                            <h6 className="mb-1">Total Questions{" "}: {" "}<strong>{totalQuestions}</strong></h6>
                                        </Col>
                                    </Row></Col>
                                <Col lg={4}>
                                    <Card>
                                        <CardBody>
                                            <div>


                                                <Row className="align-items-center">
                                                    {/* Exam Paper Dropdown */}
                                                    <Col md={6} className="mb-2">
                                                        <Label style={{ fontSize: '12px' }}>Exam Paper</Label>
                                                        <Select
                                                            ref={paperRef}
                                                            value={validation.values.selectedPaper}
                                                            isDisabled={isEditing}
                                                            onChange={(selectedOption) => {
                                                                handleSelectPaper(selectedOption);
                                                                validation.setFieldValue("selectedPaper", selectedOption);
                                                            }}
                                                            options={paperAllMapList.map((paper) => ({
                                                                label: paper.ex_paper_name,
                                                                value: paper.ex_paper_id,
                                                            }))}
                                                            styles={customStyles}
                                                            className={`select2-selection ${validation.touched.selectedPaper && validation.errors.selectedPaper ? 'is-invalid' : ''}`}
                                                        />
                                                        {validation.touched.selectedPaper && validation.errors.selectedPaper ? (
                                                            <FormFeedback type="invalid">{validation.errors.selectedPaper}</FormFeedback>
                                                        ) : null}
                                                    </Col>

                                                    {/* Subject Dropdown */}
                                                    <Col md={6} className="mb-2">
                                                        <Label style={{ fontSize: '12px' }}>Subject</Label>
                                                        <Select
                                                            isDisabled={isEditing}
                                                            ref={subjectRef}
                                                            value={validation.values.subjectName}
                                                            onChange={(selectedOption) => {
                                                                validation.setFieldValue("subjectName", selectedOption);
                                                                handleSelectSubjectName(selectedOption);
                                                            }}
                                                            options={subjectAllMapList.map((option) => ({
                                                                label: option.subjectName,
                                                                value: option.cs_sid,
                                                            }))}
                                                            validate={{ required: { value: true } }}
                                                            styles={customStyles}
                                                            className={`select2-selection ${validation.touched.subjectName && validation.errors.subjectName ? 'is-invalid' : ''}`}
                                                        />
                                                        {validation.touched.subjectName && validation.errors.subjectName ? (
                                                            <div className="invalid-feedback">{validation.errors.subjectName}</div>
                                                        ) : null}
                                                        {/* {selectedCount != 0 && (
                                                            <Badge pill color="success" className="me-1">
                                                                Available Questions: <b>{selectedCount ? (selectedCount || "") : ""}</b>
                                                            </Badge>
                                                        )} */}
                                                    </Col>

                                                    {/* Badge displayed under Subject dropdown */}
                                                    <Col md={6} className="mb-2">
                                                        {/* {selectedCount != 0 && (
                                                            <Badge pill color="success" className="me-1">
                                                                Available Questions: <b>{selectedCount ? (selectedCount || "") : ""}</b>
                                                            </Badge>
                                                        )} */}
                                                    </Col>
                                                    <Col md={6} className="mb-2">
                                                        {selectedCount != 0 && (
                                                            <Badge pill color="success" className="me-1">
                                                                Available Questions: <b>{selectedCount ? (selectedCount || "") : ""}</b>
                                                            </Badge>
                                                        )}
                                                    </Col>
                                                </Row>                                          <Row>
                                                    <Col md={10} className="mb-4">
                                                        <>
                                                            {levels.map((level, index) => (
                                                                <Row key={index}>
                                                                    <div className="p-3">
                                                                        <h5 className="font-size-14 mb-3 mt-0">
                                                                            {level}

                                                                            {selectedLevelCount && (
                                                                                <Badge pill color="success" className="me-1 ms-2">
                                                                                    Avl. Questions: <b>{selectedLevelCount[index]?.questioncount || 0}</b>
                                                                                </Badge>
                                                                            )}
                                                                            {/* 
                                                                            {(index === 0 && selectedRequiredLowCount) && (
                                                                                <Badge pill className="me-1 badge-orange ms-2">
                                                                                    Req. Questions: <b>{selectedRequiredLowCount || 0}</b>
                                                                                </Badge>
                                                                            )}
                                                                            {(index === 1 && selectedRequiredMedCount) && (
                                                                                <Badge pill className="me-1 badge-orange ms-2">
                                                                                    Req. Questions: <b>{selectedRequiredMedCount || 0}</b>
                                                                                </Badge>
                                                                            )}
                                                                            {(index === 2 && selectedRequiredHighCount) && (
                                                                                <Badge pill className="me-1 badge-orange ms-2">
                                                                                    Req. Questions: <b>{selectedRequiredHighCount || 0}</b>
                                                                                </Badge>
                                                                            )}
                                                                            {(index === 3 && selectedRequiredAdvCount) && (
                                                                                <Badge pill className="me-1 badge-orange ms-2">
                                                                                    Req. Questions: <b>{selectedRequiredAdvCount || 0}</b>
                                                                                </Badge>
                                                                            )} */}
                                                                        </h5>
                                                                        <span>

                                                                            <Slider
                                                                                value={values[index]}
                                                                                orientation="horizontal"
                                                                                onChange={value => handleChangeLevel(index, value)}
                                                                                disabled={true}
                                                                            />
                                                                            <p className="percent">{values[index] + "%"}</p>
                                                                        </span>
                                                                    </div>
                                                                </Row>
                                                            ))}
                                                            {/* <Badge pill color="success" className="me-1 totalvaluesBadge" > */}
                                                            <Badge pill className="me-1 badge-primary totalvaluesBadge">

                                                                <b> {totalValues}%</b>
                                                            </Badge>
                                                        </>


                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>

                                </Col>
                                <Col lg={1} className="button-align">

                                    {/* <Col md={2} className="text-center"> */}
                                    <Button
                                        color="success" // Use Bootstrap's success color
                                        type="button"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#28a745",
                                            border: "none",
                                            padding: "0px",
                                            fontSize: "25px",
                                            height: "40px",
                                            width: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                        onClick={handleAddOrUpdateRow}
                                    >
                                        <i
                                            className={`mdi mdi-greater-than text-white`}
                                            style={{ fontSize: "inherit" }}
                                        />
                                    </Button>
                                    <Button
                                        color="danger" // Use Bootstrap's success color
                                        type="button"
                                        style={{
                                            color: "white",
                                            backgroundColor: "danger",
                                            border: "none",
                                            padding: "0px",
                                            fontSize: "25px",
                                            height: "40px",
                                            width: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                        onClick={handleClear}
                                    >
                                        <i className="mdi mdi-close text-white"></i>
                                    </Button>
                                    {/* </Col> */}
                                </Col>
                                <Col lg={7}>
                                    <Card>
                                        <CardBody>
                                            <div>

                                                <TableContainer
                                                    columns={columns}
                                                    data={tableData || []}
                                                    isPagination={true}
                                                    buttonName="Add"
                                                    pagination="pagination"
                                                    buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                                                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>

                        <CardFooter>
                            <div className="text-end">
                                <Button color="success"
                                    type="submit"
                                    className="save-user"
                                    disabled={initialSubmitState}
                                    onClick={handleSaveClick}
                                >
                                    update                                </Button>
                                <button type="button" style={{ marginLeft: "10px" }}
                                    onClick={handleClose}
                                    className="btn btn-danger">Close</button>
                            </div>

                        </CardFooter>
                    </Card>
                </Container>
            </div >
        </React.Fragment >
    );
};

export default Mapping;
