import { call, put, takeEvery } from "redux-saga/effects";
import { GET_MOCKUPTEST, ADD_NEW_MOCKUPTEST, EDITLOAD_MOCKUPTEST, DELETE_MOCKUPTEST } from "./actionTypes";
import {
  getMockupTestSuccess, addNewMockuptestSuccess, editloadMockSuccess, deleteMockupTestSuccess
} from "./actions";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { getMockupTestList, userValidation, addMockup, editloadMockupTest, deleteMockupTest } from "helpers/fakebackend_helper";


function* fetchMockupTest({ payload: data }) {
  try {
    const response = yield call(getMockupTestList, data)
    yield call(userValidation, response)
    yield put(getMockupTestSuccess(response))
  } catch (error) {
    yield put(getMockupTestSuccess({ statusFlag: 2 }))
  }
}

function* onAddNewExamPaper({ payload: data }) {
  try {
    const response = yield call(addMockup, data)
    yield call(userValidation, response)
    yield put(addNewMockuptestSuccess(response))
    if (response && response.statusFlag === 1) {
      toast.success(response.message, { autoClose: 2000 });
    } else {
      toast.error(response.message, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(addNewMockuptestSuccess({ statusFlag: 2 }))
    toast.error("MockupTest Added Failed", { autoClose: 2000 });
  }
}
function* oneditMockupTest({ payload: data }) {
  try {
    const response = yield call(editloadMockupTest, data)
    yield call(userValidation, response)
    yield put(editloadMockSuccess(response))
    if (response && response.statusFlag === 1) {
      toast.success(response.message, { autoClose: 2000 });
    } else {
      toast.error(response.message, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(editloadMockSuccess({ statusFlag: 2 }))
    toast.error("Mockup Deleted Failed", { autoClose: 2000 });
  }
}

function* onDeleteMockupTest({ payload: data }) {
  try {
    const response = yield call(deleteMockupTest, data)
    yield call(userValidation, response)
    yield put(deleteMockupTestSuccess(response))
    if (response && response.statusFlag === 1) {
      toast.success(response.message, { autoClose: 2000 });
    } else {
      toast.error(response.message, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(deleteMockupTestSuccess({ statusFlag: 2 }))
    toast.error("MOCKUPTEST Deleted Failed", { autoClose: 2000 });
  }
}


function* mockupTest() {
  yield takeEvery(GET_MOCKUPTEST, fetchMockupTest)
  yield takeEvery(ADD_NEW_MOCKUPTEST, onAddNewExamPaper)
  yield takeEvery(EDITLOAD_MOCKUPTEST, oneditMockupTest)
  yield takeEvery(DELETE_MOCKUPTEST, onDeleteMockupTest)
}

export default mockupTest;
