// Created by: Murugeswari Chandrabose
// Created At: 22 Jan 2024

// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { ToastContainer } from "react-toastify";
import Spinners from "../../components/Common/Spinner";


import { useSelector, useDispatch } from "react-redux";

import './test.css'
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";


const OnlineTest = () => {
  const store = useSelector(state => state.Blueprint)
  const [isLoading, setLoading] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(0)
  const [selectedAnswer, setSelectedAnswer] = useState('')
  const [showResult, setShowResult] = useState(false)
  const dispatch = useDispatch();
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null)
  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  })
  const quiz = {
    topic: 'Javascript',
    level: 'Beginner',
    totalQuestions: 4,
    perQuestionScore: 5,
    questions: [
      {
        question: 'Which function is used to serialize an object into a JSON string in Javascript?',
        choices: ['stringify()', 'parse()', 'convert()', 'None of the above'],
        type: 'MCQs',
        correctAnswer: 'stringify()',
      },
      {
        question: 'Which of the following keywords is used to define a variable in Javascript?',
        choices: ['var', 'let', 'var and let', 'None of the above'],
        type: 'MCQs',
        correctAnswer: 'var and let',
      },
      {
        question:
          'Which of the following methods can be used to display data in some form using Javascript?',
        choices: ['document.write()', 'console.log()', 'window.alert', 'All of the above'],
        type: 'MCQs',
        correctAnswer: 'All of the above',
      },
      {
        question: 'How can a datatype be declared to be a constant type?',
        choices: ['const', 'var', 'let', 'constant'],
        type: 'MCQs',
        correctAnswer: 'const',
      },
      {
        question: 'Which function is used to serialize an object into a JSON string in Javascript?',
        choices: ['stringify()', 'parse()', 'convert()', 'None of the above'],
        type: 'MCQs',
        correctAnswer: 'stringify()',
      },
      {
        question: 'Which of the following keywords is used to define a variable in Javascript?',
        choices: ['var', 'let', 'var and let', 'None of the above'],
        type: 'MCQs',
        correctAnswer: 'var and let',
      },
      {
        question:
          'Which of the following methods can be used to display data in some form using Javascript?',
        choices: ['document.write()', 'console.log()', 'window.alert', 'All of the above'],
        type: 'MCQs',
        correctAnswer: 'All of the above',
      },
      {
        question: 'How can a datatype be declared to be a constant type?',
        choices: ['const', 'var', 'let', 'constant'],
        type: 'MCQs',
        correctAnswer: 'const',
      },
      {
        question: 'Which function is used to serialize an object into a JSON string in Javascript?',
        choices: ['stringify()', 'parse()', 'convert()', 'None of the above'],
        type: 'MCQs',
        correctAnswer: 'stringify()',
      },
      {
        question: 'Which of the following keywords is used to define a variable in Javascript?',
        choices: ['var', 'let', 'var and let', 'None of the above'],
        type: 'MCQs',
        correctAnswer: 'var and let',
      },
      {
        question:
          'Which of the following methods can be used to display data in some form using Javascript?',
        choices: ['document.write()', 'console.log()', 'window.alert', 'All of the above'],
        type: 'MCQs',
        correctAnswer: 'All of the above',
      },
      {
        question: 'How can a datatype be declared to be a constant type?',
        choices: ['const', 'var', 'let', 'constant'],
        type: 'MCQs',
        correctAnswer: 'const',
      },
      {
        question: 'Which function is used to serialize an object into a JSON string in Javascript?',
        choices: ['stringify()', 'parse()', 'convert()', 'None of the above'],
        type: 'MCQs',
        correctAnswer: 'stringify()',
      },
      {
        question: 'Which of the following keywords is used to define a variable in Javascript?',
        choices: ['var', 'let', 'var and let', 'None of the above'],
        type: 'MCQs',
        correctAnswer: 'var and let',
      },
      {
        question:
          'Which of the following methods can be used to display data in some form using Javascript?',
        choices: ['document.write()', 'console.log()', 'window.alert', 'All of the above'],
        type: 'MCQs',
        correctAnswer: 'All of the above',
      },
      {
        question: 'How can a datatype be declared to be a constant type?',
        choices: ['const', 'var', 'let', 'constant'],
        type: 'MCQs',
        correctAnswer: 'const',
      },
      {
        question: 'Which function is used to serialize an object into a JSON string in Javascript?',
        choices: ['stringify()', 'parse()', 'convert()', 'None of the above'],
        type: 'MCQs',
        correctAnswer: 'stringify()',
      },
      {
        question: 'Which of the following keywords is used to define a variable in Javascript?',
        choices: ['var', 'let', 'var and let', 'None of the above'],
        type: 'MCQs',
        correctAnswer: 'var and let',
      },
      {
        question:
          'Which of the following methods can be used to display data in some form using Javascript?',
        choices: ['document.write()', 'console.log()', 'window.alert', 'All of the above'],
        type: 'MCQs',
        correctAnswer: 'All of the above',
      },
      {
        question: 'How can a datatype be declared to be a constant type?',
        choices: ['const', 'var', 'let', 'constant'],
        type: 'MCQs',
        correctAnswer: 'const',
      },
      
    ],
  }

  useEffect(() => {
   document.getElementById('headerTitle').innerHTML='Online Test';
}, [dispatch]);

  const { questions } = quiz
  const { question, choices, correctAnswer } = questions[activeQuestion]

  const onClickNext = () => {
    setSelectedAnswerIndex(null)
    setResult((prev) =>
      selectedAnswer
        ? {
            ...prev,
            score: prev.score + 5,
            correctAnswers: prev.correctAnswers + 1,
          }
        : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }
    )
    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion((prev) => prev + 1)
    } else {
      setActiveQuestion(0)
      setShowResult(true)
    }
  }

  const onAnswerSelected = (answer, index) => {
    setSelectedAnswerIndex(index)
    if (answer === correctAnswer) {
      setSelectedAnswer(true)
    } else {
      setSelectedAnswer(false)
    }
  }

  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`)

  //meta title
  // document.title = "Masters | Online Test";

 
  return (
    <React.Fragment>
     
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Online Test" /> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                  <Col lg="8"> <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5 className="mb-1">Exam Name : General science and Constitution of India</h5>
                      </div>
                    </div></Col>
                  <Col lg="4">
                  <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5 className="mb-1" style={{textAlign: 'right'}}>Time Remaining:- 15:12</h5>
                      </div>
                    </div>
                  </Col>
                  </Row>
                </CardBody>
            </Card>
            </Col>
            </Row>
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col xs="12">
                  <Card>
                    <CardBody style={{padding:'30px 40px'}}>                                         
                      <Row>
                        <Col xs="8">
                          <Row>
                            <Col xs="12">
                              <Row>
                                <Col xs="4">
                                <Card className="mini-stats-wid">
                                    <CardBody style={{padding: '10px', background: '#556ee617'}}>
                                  <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">
                                      Total Questions
                                    </p>
                                    <h4 className="mb-0">20</h4>
                                  </div>
                                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                    <span className="avatar-title rounded-circle bg-primary">
                                      <i className={"bx bx-copy-alt font-size-24"}></i>
                                    </span>
                                  </div>
                                </div>
                                </CardBody>
                                </Card>
                                </Col>
                                <Col xs="4">
                                <Card className="mini-stats-wid">
                                  <CardBody style={{padding: '10px', background: '#90ee9080'}}>
                                  <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">
                                      Total Attempted
                                    </p>
                                    <h4 className="mb-0">1</h4>
                                  </div>
                                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                    <span className="avatar-title rounded-circle bg-primary">
                                      <i className={"bx bx-copy-alt font-size-24"}></i>
                                    </span>
                                  </div>
                                </div>
                                </CardBody>
                                </Card>
                                </Col>
                                <Col xs="4">
                            <Card className="mini-stats-wid">
                              <CardBody style={{padding: '10px', background: '#faebd7'}}>
                              <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Remaining Questions
                                </p>
                                <h4 className="mb-0">19</h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i className={"bx bx-archive-in font-size-24"}></i>
                                </span>
                              </div>
                            </div>
                            </CardBody>
                            </Card>
                            </Col>
                        
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                          <div className="quiz-container">
                          {!showResult ? (
                            <div>
                              <div>
                                <span className="active-question-no">{addLeadingZero(activeQuestion + 1)}</span>
                                <span className="total-question">/{addLeadingZero(questions.length)}</span>
                              </div>
                              <h2>{question}</h2>
                              <ul>
                                {choices.map((answer, index) => (
                                  <li
                                    onClick={() => onAnswerSelected(answer, index)}
                                    key={answer}
                                    className={selectedAnswerIndex === index ? 'selected-answer' : null}>
                                    {answer}
                                  </li>
                                ))}
                              </ul>
                              <div className="flex-right">
                                <button onClick={onClickNext} disabled={selectedAnswerIndex === null}>
                                  {activeQuestion === questions.length - 1 ? 'Finish' : 'Next'}
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="result">
                              <h3>Result</h3>
                              <p>
                                Total Question: <span>{questions.length}</span>
                              </p>
                              <p>
                                Total Score:<span> {result.score}</span>
                              </p>
                              <p>
                                Correct Answers:<span> {result.correctAnswers}</span>
                              </p>
                              <p>
                                Wrong Answers:<span> {result.wrongAnswers}</span>
                              </p>
                            </div>
                          )}
                        </div></Row>                        
                        </Col>
                        <Col xs="1"></Col>
                        <Col xs="3">
                        <Row>
                        <div className="flex-grow-1 align-self-center">
                          <div className="text-muted">
                            <h5>Henry wells</h5>
                            <p className="mb-1">henrywells@abc.com</p>
                            <p className="mb-0">Id no: #SK0234</p>
                          </div>
                        </div></Row>
                        <Row style={{marginTop: '100px'}}>
                        {(quiz?.questions || [])?.map((q, key) => (
                            <div style={{width: '15%', background: '#f0f2fd', margin: '6px', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} key={"card_" + key}>
                                {key + 1}
                            </div>
                        ))}
                        </Row>
                        </Col>
                      </Row>
                    
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>

        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
OnlineTest.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default OnlineTest;