import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {GET_EXAMBOARDSCATEGORY, ADD_NEW_EXAM_BOARDCATEGORY, DELETE_EXAM_BOARDCATEGORY, GET_MASTERLIST,GETQA_MASTERLIST } from "./actionTypes";
import {
    getExamBoardcategorySuccess, addNewExamBoardcategorySuccess,deleteExamBoardcategorySuccess,getmasterlistsuccess,getqamasterlistsuccess
} from "./actions";

//Include Both Helper File with needed methods
import { getExamBoardscategoryList, addExamBoardcategory,deleteExamBoardcategory, getmasterlist, userValidation, getqamaster} from "helpers/fakebackend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchExamBoardscategory({ payload: data}) {
  try {
    const response = yield call(getExamBoardscategoryList, data)
    yield call(userValidation, response)
    yield put(getExamBoardcategorySuccess(response))
  } catch (error) {
    yield put(getExamBoardcategorySuccess({statusFlag: 2}))
  }
}

function* onAddNewExamBoardcategory({ payload: data }) {
    try {
        const response = yield call(addExamBoardcategory, data)
        yield call(userValidation, response)
        yield put(addNewExamBoardcategorySuccess(response))
        console.log(response, 'response')
        if (response && response.statusFlag === 1) {
          toast.success(response.message, { autoClose: 2000 });
        } else {
          toast.error(response.message, { autoClose: 2000 });
        }      
    } catch (error) {
        yield put(addNewExamBoardcategorySuccess({statusFlag: 2}))
        toast.error("Exam Board Category Added Failed", { autoClose: 2000 });
    }
  }
  function* onDeleteExamBoardcategory({ payload: data }) {
    try {
        const response = yield call(deleteExamBoardcategory, data)
        yield call(userValidation, response)
        yield put(deleteExamBoardcategorySuccess(response))
        console.log(response, 'response')
        if (response && response.statusFlag === 1) {
          toast.success(response.message, { autoClose: 2000 });
        } else {
          toast.error(response.message, { autoClose: 2000 });
        }      
    } catch (error) {
        yield put(deleteExamBoardcategorySuccess({statusFlag: 2}))
        toast.error("Exam Board Deleted Failed", { autoClose: 2000 });
    }
  }

  function* fetchmasterlist({ payload: data}) {
    try {
      const response = yield call(getmasterlist, data)
      yield call(userValidation, response)
      yield put(getmasterlistsuccess(response))
    } catch (error) {
      yield put(getmasterlistsuccess({statusFlag: 2}))
    }
  }

  function* fetchqamasterlist({ payload: data}) {
    try {
      const response = yield call(getqamaster, data)
      yield call(userValidation, response)
      yield put(getqamasterlistsuccess(response))
    } catch (error) {
      yield put(getqamasterlistsuccess({statusFlag: 2}))
    }
  }


function* examboardcategorySaga() {
  yield takeEvery(GET_EXAMBOARDSCATEGORY, fetchExamBoardscategory)
  yield takeEvery(ADD_NEW_EXAM_BOARDCATEGORY, onAddNewExamBoardcategory)
  yield takeEvery(DELETE_EXAM_BOARDCATEGORY, onDeleteExamBoardcategory)
  yield takeEvery(GET_MASTERLIST, fetchmasterlist)
  yield takeEvery(GETQA_MASTERLIST, fetchqamasterlist)
  

}

export default examboardcategorySaga;
