import {
    GET_EXAMPAPER,
    GET_EXAMPAPER_SUCCESS,
    ADD_NEW_EXAMPAPERS,
    ADD_NEW_EXAMPAPERS_SUCCESS,
    DELETE_EXAMPAPERS,
    DELETE_EXAMPAPERS_SUCCESS,
    RESET_EXAMPAPERS_BOARDCATEGORY
  } from "./actionTypes"
  
  export const getExamPaper = (data) => ({
    type: GET_EXAMPAPER,
    payload: data
  })
  
  export const getExamPaperSuccess = examboards => ({
    type: GET_EXAMPAPER_SUCCESS,
    payload: examboards,
  })
  export const addNewExamPaper = data => ({
    type: ADD_NEW_EXAMPAPERS,
    payload: data,
  })
  
  export const addNewExamPaperSuccess = data => ({
    type: ADD_NEW_EXAMPAPERS_SUCCESS,
    payload: data,
  })
  
  export const deleteExamPaper = data => ({
    type: DELETE_EXAMPAPERS,
    payload: data,
  })
  
  export const deleteExamPaperSuccess = data => ({
    type: DELETE_EXAMPAPERS_SUCCESS,
    payload: data,
  })
  
  
  export const resetStorePaper = data => ({
    type: RESET_EXAMPAPERS_BOARDCATEGORY,
    payload: data,
  })