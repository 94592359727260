import React, { useMemo, useRef, useState, useEffect } from "react";
import { Card, Button, CardBody, Col, Container, UncontrolledTooltip, Form, FormGroup, Input, InputGroup, Label, Row, FormFeedback, InputGroupText, CardFooter, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalBody, CardHeader, CardText } from "reactstrap";
import { useFormik } from "formik";
import { SketchPicker } from "react-color";
import { ColorPicker } from "@vtaits/react-color-picker";
import "../../../node_modules/@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import Select from "react-select";
import * as Yup from "yup";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getmasterlist } from "../../store/actions";
import { getLoggedInUser, getRefreshToken } from "../../../src/helpers/fakebackend_helper";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../components/Common/TableContainer';
import { EditorURL } from "../../constants/layout"
import classnames from "classnames";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import * as url from "../../../src/helpers/url_helper";
import Spinners from "../../components/Common/Spinner";
import AWS from 'aws-sdk';
import * as constants from "constants/layout"
import ApproveModal from "components/Common/ApproveModal";
// const htmlContent = "http://localhost:3000/ckeditor.html"
// const htmlContent = "http://172.16.1.201:3795/ckeditor.html"
const htmlContent = "https://uatadmin.iiceacademy.in/ckeditor.html"
// const htmlContent = "https://uatapi.iiceacademy.in/ckeditor.html"
// const htmlContent = "https://ice.trio-s.com/ckeditor.html"
// const htmlContent = "https://admin.iiceacademy.in/ckeditor.html"
var GLOBAL_ENG_CONTENT = "";
const AddStudyMaterials = () => {

    const { cid } = useParams();
    const dispatch = useDispatch();
    const user = getLoggedInUser()
    const navigate = useNavigate();
    const masterstore = useSelector(state => state.ExamBoardCategory)
    var isMaterialClone = localStorage.getItem('isMaterialClone');
    const [selectedBoardCategory, setSelectedBoardCategory] = useState([]);
    const [selectedExamPaper, setselectedExamPaper] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState([]);
    const [selectedSubjectsubcat, setselectedSubjectSubcat] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState([]);

    const [initialSubmitState, setInitialSubmitState] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [dataTableUploads, setDataTableUploads] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    const [optionTopic, setOptionTopic] = useState([]);
    const [optionAllTopic, setOptionAllTopic] = useState([]);
    const [optionSubject, setOptionSubject] = useState([]);
    const [optionAllSubject, setOptionAllSubject] = useState([]);
    const [optionSubjectSubcat, setOptionSubjectSubcat] = useState([]);
    const [optionAllSubjectSubcat, setOptionAllSubjectSubcat] = useState([]);
    const [optionExamPaper, setOptionExamPaper] = useState([]);
    const [optionAllExamPaper, setOptionAllExamPaper] = useState([]);
    const [optionBoardCategory, setOptionBoardCategory] = useState([]);

    const [activeTab, setActiveTab] = useState("1");
    const [fileName, setFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState('')
    const [generalsetting, setGeneralSetting] = useState("");

    const [smObj, setsmObj] = useState(null);
    const [langArr, setLangArr] = useState([])
    const [languageActiveTab, setlanguageActiveTab] = useState(null);
    const [saveClone, setSaveClone] = useState(0);

    const smEngIframeRef = useRef(null);
    const smRegIframeRef = useRef(null);
    const [isLoading, setLoading] = useState(false)
    const [contentFilesArr, setContentFilesArr] = useState([])
    const [uploadFilesArr, setUploadFilesArr] = useState([])
    const { stid } = useParams();
    const [statusMaterial, setStatusMaterial] = useState(true);

    const [engContent, setEngContent] = useState('')
    const [test, setTest] = useState('')
    const [regContent, setRegContent] = useState('')
    const [approvebutton, setApprovebutton] = useState(false);

    const [showEngPreview, setShowEngPreview] = useState(true);
    const [showRegPreview, setShowRegPreview] = useState(true);
    const refTopics = useRef("")
    const [approveStudyMaterial, setapproveStudyMaterial] = useState(true);
    const [isDisabledSubCategory, setIsDisabledSubCategory] = useState(false);
    const [isDisabledTopic, setIsDisabledTopic] = useState(false);

    const fileuplaod = useRef(null);
    const regfileuplaod = useRef(null);
    const [regFileName, setRegFileName] = useState('');
    const [regselectedFile, setRegSelectedFile] = useState('')
    const [regdataTable, setRegDataTable] = useState([]);
    const [isRegEditing, setIsRegEditing] = useState(false);
    const [regdataTableUploads, setRegDataTableUploads] = useState([]);
    const [subjectSubCategoryOptions, setSubjectSubCategoryOptions] = useState([]);
    const [TopicOptions, setOptionTopicOptions] = useState([]);
    const [filtersubjectSubCategoryOptions, setFiltersubjectSubCategoryOptions] = useState([]);
    const [selectedSubjectSubCategory, setSelectedSubjectSubCategory] = useState([]);
    const [optionMasterAllTopic, setOptionMasterAllTopic] = useState([]);
    const [filterTopicOptions, setFilterTopicOptions] = useState([]);
    const [engkey, setEngKey] = useState(0);
    const [saveCloneSid, setSaveCloneSid] = useState(0);
    const [regkey, setRegKey] = useState(0);

    const [approveModal, setApproveModal] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [rejectbutton, setRejectbutton] = useState(false);
    const [reason, setReason] = useState('');
    const [inputFocus, setInputFocus] = useState(false);
    const [successFlag, setSuccessFlag] = useState(false);

    var subcatList;
    var topicList;
    useEffect(() => {
        if (masterstore.masterlist != undefined) {

            console.log('masetr', masterstore)
            // if (masterstore?.masterlist && masterstore?.masterlist?.board_categoryList) {
            //     setOptionMasterBoardCategory(masterstore?.masterlist?.board_categoryList)
            // }
            if (masterstore?.masterlist && masterstore?.masterlist?.examPaperList) {
                // setOptionMasterAllExamPaper(masterstore?.masterlist?.examPaperList)
                setOptionExamPaper(masterstore?.masterlist?.examPaperList)
            }
            if (masterstore?.masterlist && masterstore?.masterlist?.subjectList) {
                setOptionSubject(masterstore?.masterlist?.subjectList)
                setOptionAllSubject(masterstore?.masterlist?.subjectList)
            }
            if (masterstore?.masterlist && masterstore?.masterlist?.subCategryList) {
                setOptionSubjectSubcat(masterstore?.masterlist?.subCategryList)
                setOptionAllSubjectSubcat(masterstore?.masterlist?.subCategryList)
                setSubjectSubCategoryOptions(masterstore?.masterlist?.subCategryList)
                subcatList = masterstore?.masterlist?.subCategryList

            }
            if (masterstore?.masterlist && masterstore?.masterlist?.topicList) {
                setOptionAllTopic(masterstore?.masterlist?.topicList)
                setOptionTopic(masterstore?.masterlist?.topicList)
                setOptionTopicOptions(masterstore?.masterlist?.topicList)
                topicList = masterstore?.masterlist?.topicList
            }
            // if (masterstore?.masterlist && masterstore?.masterlist?.examPaperList) {
            //     setOptionMasterAllLanguage(masterstore?.masterlist?.examPaperList)
            //     setOptionMasterLanguage(masterstore?.masterlist?.examPaperList)
            // }
            // if (masterstore?.masterlist && masterstore?.masterlist?.languageList) {
            //     setOptionMasterAllLanguageData(masterstore?.masterlist?.languageList)
            //     setOptionMasterLanguageData(masterstore?.masterlist?.languageList)
            // }
            console.log('masterstore.masterlist.generalsettings', masterstore.masterlist.generalsettings);
            // if (masterstore.masterlist.generalsettings) {
            setGeneralSetting(masterstore.masterlist.generalsettings)
            // }

        }

    }, [masterstore])



    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            selectedExamPaper: (smObj && smObj.subject) || undefined,
            // selectedBoardCategory: (smObj && smObj.subject) || undefined,
            selectedSubject: (smObj && smObj.topicname) || undefined,
            // selectedSubjectSubCategory: (selectedSubjectSubCategory) || undefined,
            // selectedLanguage: (smObj && smObj.subject) || undefined,
            // selectedTopic: (smObj && smObj.examlevel) || undefined,
            title: (smObj && smObj.title) || "",


        },
        validationSchema: Yup.object({

            // selectedBoardCategory: Yup.object().required("Please Select Board"),
            selectedSubject: Yup.object().required("Please Select Subject Name"),
            title: Yup.string().required("Please enter Title"),
            // selectedTopic: Yup.object().required("Please Select Topic Name"),
            selectedExamPaper: Yup.object().required("Please Select Exam Paper"),
            // selectedSubjectSubCategory: Yup.object().required("Please Select Subcategory"),
            // selectedLanguage: Yup.object().required("Please Select Language")
        }),
        onSubmit: async (values) => {
            console.log('gllua', values)
            saveMaterial()

        },
    });
    // const getmasterdatalistapi = () => {
    //     // setLoading(true)
    //     dispatch(getmasterlist({ user_id: Number(user.uid) }));
    // }

    const getmasterdatalistapi = async () => {
        // setLoading(true)
        // dispatch(getmasterlist({ user_id: Number(user.uid) }));
        const data = { user_id: Number(user.uid) }
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'master/getMaster', data, config)
        if (response1 && response1.data && response1.data && response1.data.status === 200) {

            // setMasterData(response1?.data?.body)

            // setOptionBoardName(response1?.data?.body?.board_categoryList)
            setOptionAllSubject(response1?.data?.body?.subjectList)
            setOptionSubject([])
            // setOptionSubjectName(response1?.data?.body?.subjectList)
            setOptionAllSubjectSubcat(response1?.data?.body?.subCategryList)
            // setOptionSubjectSubcatName(response1?.data?.body?.subCategryList)
            setOptionSubjectSubcat([])
            setOptionAllExamPaper(response1?.data?.body?.examPaperList)
            setOptionExamPaper(response1?.data?.body?.examPaperList)
            setOptionAllTopic(response1?.data?.body?.topicList)
            // setOptionTopicName(response1?.data?.body?.topicList)
            setOptionTopic([])

            if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
                const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser()
                    } else {
                        await getmasterdatalistapi() // calling same function again getting refresh token
                    }
                }
            }
        }
    }

    const saveMaterial = async () => {
        setLoading(true)
        console.log('contentFilesArr', contentFilesArr);
        console.log('---', selectedSubject)

        setUploadFilesArr([])
        setContentFilesArr([])
        if (Number(localStorage.getItem('isMaterialClone')) === 1) {
            setDataTableUploads([])
            setRegDataTableUploads([])
        }
        setTimeout(async () => {



            if (smEngIframeRef.current && smEngIframeRef.current.contentWindow.updatedContent) {
                const iframeContent = smEngIframeRef.current.contentWindow.updatedContent;
                const engBlobData = new Blob([iframeContent], { type: 'text/html' });
                console.log(engBlobData, 'engBlobData')
                var contentFileEng = await appendContentFile(engBlobData, 'eng', 'html', 'materials', '1', '')

            }
            if (smRegIframeRef.current && smRegIframeRef.current.contentWindow.updatedContent) {
                const iframeContent = smRegIframeRef.current.contentWindow.updatedContent;
                const regBlobData = new Blob([iframeContent], { type: 'text/html' });
                await appendContentFile(regBlobData, 'reg', 'html', 'materials', '2', '').then((res) => {

                })

            }

            var multi_lang_files_array = [...dataTableUploads, ...regdataTableUploads]
            if (multi_lang_files_array.length > 0) {
                for (var i = 0; i < multi_lang_files_array.length; i++) {
                    if (multi_lang_files_array[i]?.is_aws_file == 1) {
                        if (multi_lang_files_array[i]?.is_deleted == 1) {
                            setUploadFilesArr(uploadFilesArr.push({
                                stf_filename: multi_lang_files_array[i]?.uploadedFileName,
                                stf_description: multi_lang_files_array[i]?.fName,
                                is_deleted: 1,
                                stf_langtype: multi_lang_files_array[i]?.stf_langtype
                            }))
                        }
                        else {
                            setUploadFilesArr(uploadFilesArr.push({
                                stf_filename: multi_lang_files_array[i]?.uploadedFileName,
                                stf_description: multi_lang_files_array[i]?.fName,
                                stf_langtype: multi_lang_files_array[i]?.stf_langtype
                            }))
                        }

                    }
                    else {
                        const [file_name, file_ext] = multi_lang_files_array[i]?.uploadedFileName.split('.')
                        const file = multi_lang_files_array[i]?.fileObject[0];
                        let blobData = new Blob(multi_lang_files_array[i]?.fileObject, { type: file.type })
                        await appendContentFile(blobData, file_name, file_ext, 'uploadedMaterials', multi_lang_files_array[i]?.stf_langtype, multi_lang_files_array[i]?.fName)
                    }

                }
            }
            // alert(contentFilesArr.length) 
            // alert(uploadFilesArr.length) 
            // return false

            var val_multi_lang_files_array = multi_lang_files_array.filter(e => e?.is_deleted != 1)

            if (langArr?.includes("1")) {
                console.log('contentFilesArr', contentFilesArr);
                console.log('val_multi_lang_files_array', val_multi_lang_files_array);
                var eng_content_check = contentFilesArr.findIndex(e => e.stc_langflag == 1)
                var eng_file_check = val_multi_lang_files_array.findIndex(e => e.stf_langtype == 1)
                if (eng_content_check == -1 && eng_file_check == -1) {
                    setUploadFilesArr([])
                    setContentFilesArr([])
                    toast.error('Please upload the english content', { autoClose: 2000 });
                    return false
                }

            } else {
                var reg_content_check = contentFilesArr.findIndex(e => e.stc_langflag == 2)
                var reg_file_check = val_multi_lang_files_array.findIndex(e => e.stf_langtype == 2)
                if (reg_content_check == -1 && reg_file_check == -1) {
                    setUploadFilesArr([])
                    setContentFilesArr([])
                    toast.error('Please upload the regional content', { autoClose: 2000 });
                    return false
                }

            }


            // if (contentFilesArr.length == 0 && val_multi_lang_files_array.length == 0) {
            //     toast.error('Please upload the content', { autoClose: 2000 });
            //     return false
            // }

            var qts;
            var vts;

            // Check if approval toggle is enabled
            if (generalsetting && generalsetting.length > 0 && generalsetting[0].gs_studymaterial_approval == 1) {

                if (stid == 0 || isMaterialClone == '1') { // if it is add

                    if (user.user_previleges.filter(p =>
                        Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Special)).length === 1) {
                        qts = 1
                        vts = 3
                    }
                    else {
                        qts = 3
                        vts = 3
                    }
                    // else if (user.user_previleges.filter(p =>
                    //     Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create && p.urp_type !== constants.menuTypes.Special)).length === 1 && user.user_previleges.filter(p =>
                    //         Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create || p.urp_type !== constants.menuTypes.Special)).length === 2) {
                    //     qts = 3
                    // }
                    // else if (user.user_previleges.filter(p =>
                    //     Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create || p.urp_type !== constants.menuTypes.Special)).length === 4 && user.user_previleges.filter(p =>
                    //         Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create && p.urp_type !== constants.menuTypes.Special)).length === 1) {
                    //     qts = 1
                    // }
                } else { // if it is update


                    if (approveStudyMaterial == 3) {
                        qts = 3
                        vts = 3
                    }
                    else {
                        if (statusMaterial === true) {
                            qts = 1
                            vts = 3
                        } else {
                            qts = 2
                            vts = 3
                        }
                    }

                }


            } else {
                if (stid === 0 || isMaterialClone == '1') { // if it is save 
                    qts = 1;
                    vts = 4
                } else { // if it is update
                    if (statusMaterial === true) {
                        qts = 1 // Active
                        vts = 4
                    } else {
                        qts = 2 // Inactive
                        vts = 4
                    }
                }
            }
            // var st_sid;
            // console.log('said', saveCloneSid)

            // console.log('ssssss', selectedSubject)
            // if (saveClone == 1 && stid == 0 && saveCloneSid !== 0) {
            //     console.log('-------------------------------->')
            var st_sid = Number(selectedSubject?.value)
            // } else {
            //     st_sid = Number(selectedSubject?.subjectid)
            // }
            // const [st_sid, st_sscid] = selectedSubject != null ? selectedSubject.value?.split('-') : [0, 0];
            var data = {
                "user_id": Number(user.uid),
                "process_type": stid == 0 || isMaterialClone == '1' ? 'save' : 'update',
                "stid": isMaterialClone == '1' ? 0 : Number(stid),
                "st_exctid": Number(selectedBoardCategory?.value || 0),
                "st_expid": Number(selectedExamPaper?.value),
                "st_sid": Number(st_sid),
                "st_sscid": Number(selectedSubjectsubcat?.value || 0),
                "st_tid": Number(selectedTopic?.value || 0),
                "st_stsid": qts,
                "st_title": validation.values.title,
                "st_content_files": contentFilesArr,
                "st_upload_files": uploadFilesArr,
                "st_vstatus": vts,
                "st_reason": reason !== '' && reason !== undefined && reason !== null ? reason : ''
            }
            // return false;
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
            const response1 = await axios.post(url.BASE_URL + 'master/savestudymaterial', data, config)

            if (response1 && response1.statusFlag === 3) {

                setLoading(false)
                // user validation
                toast.error(response1.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                sessionStorage.removeItem("SESSION_KEY")
                window.location.reload()
                return
            }
            if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
                setLoading(false)
                const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser()
                    } else {
                        saveMaterial() // calling same function again getting refresh token
                    }
                }
            }
            if (response1 && response1.data && response1.data.body?.statusFlag == 3) {
                setLoading(false)

                // user validation
                // toast.error(response1.message, { autoClose: 2000 });
                toast.error(response1.data.body.message, { autoClose: 2000 });

                return
            }


            if (response1 && response1.data && response1.data.body && response1.data.body?.statusFlag == 1) {

                setLoading(false)
                setContentFilesArr([])
                toast.success(response1.data.body.message, { autoClose: 2000 });
                if (saveClone == 1) {
                    setEngKey(prevKey => prevKey + 1);
                    setRegKey(prevKey => prevKey + 1);
                    localStorage.setItem('isMaterialClone', '1')
                    editLoad(response1.data.body.updated_stid)
                    // qid = 0;
                }
                else {
                    if (Number(localStorage.getItem('isMaterialClone')) === 3) {
                        navigate(
                            `/list-studymaterial-approval`
                        )
                    } else {
                        navigate(
                            `/list-studymaterial`
                        )
                    }

                }
            } else {
                setLoading(false)
                toast.error(response1.data.message, { autoClose: 2000 });
                if (Number(localStorage.getItem('isMaterialClone')) === 3) {
                    navigate(
                        `/list-studymaterial-approval`
                    )
                } else {
                    navigate(
                        `/list-studymaterial`
                    )
                }
            }

        }, 500);
    }
    async function handleApproveClick() {
        setLoading(true)

        setUploadFilesArr([])
        setContentFilesArr([])
        setTimeout(async () => {



            if (smEngIframeRef.current && smEngIframeRef.current.contentWindow.updatedContent) {
                const iframeContent = smEngIframeRef.current.contentWindow.updatedContent;
                const engBlobData = new Blob([iframeContent], { type: 'text/html' });
                console.log(engBlobData, 'engBlobData')
                var contentFileEng = await appendContentFile(engBlobData, 'eng', 'html', 'materials', '1', '')

            }
            if (smRegIframeRef.current && smRegIframeRef.current.contentWindow.updatedContent) {
                const iframeContent = smRegIframeRef.current.contentWindow.updatedContent;
                const regBlobData = new Blob([iframeContent], { type: 'text/html' });
                await appendContentFile(regBlobData, 'reg', 'html', 'materials', '2', '').then((res) => {

                })

            }

            var multi_lang_files_array = [...dataTableUploads, ...regdataTableUploads]
            if (multi_lang_files_array.length > 0) {
                for (var i = 0; i < multi_lang_files_array.length; i++) {
                    if (multi_lang_files_array[i]?.is_aws_file == 1) {
                        if (multi_lang_files_array[i]?.is_deleted == 1) {
                            setUploadFilesArr(uploadFilesArr.push({
                                stf_filename: multi_lang_files_array[i]?.uploadedFileName,
                                stf_description: multi_lang_files_array[i]?.fName,
                                is_deleted: 1,
                                stf_langtype: multi_lang_files_array[i]?.stf_langtype
                            }))
                        }
                        else {
                            setUploadFilesArr(uploadFilesArr.push({
                                stf_filename: multi_lang_files_array[i]?.uploadedFileName,
                                stf_description: multi_lang_files_array[i]?.fName,
                                stf_langtype: multi_lang_files_array[i]?.stf_langtype
                            }))
                        }

                    }
                    else {
                        const [file_name, file_ext] = multi_lang_files_array[i]?.uploadedFileName.split('.')
                        const file = multi_lang_files_array[i]?.fileObject[0];
                        let blobData = new Blob(multi_lang_files_array[i]?.fileObject, { type: file.type })
                        await appendContentFile(blobData, file_name, file_ext, 'uploadedMaterials', multi_lang_files_array[i]?.stf_langtype, multi_lang_files_array[i]?.fName)
                    }

                }
            }
            // alert(contentFilesArr.length) 
            // alert(uploadFilesArr.length) 
            // return false

            var val_multi_lang_files_array = multi_lang_files_array.filter(e => e?.is_deleted != 1)

            if (langArr?.includes("1")) {
                var eng_content_check = contentFilesArr.findIndex(e => e.stc_langflag == 1)
                var eng_file_check = val_multi_lang_files_array.findIndex(e => e.stf_langtype == 1)
                if (eng_content_check == -1 && eng_file_check == -1) {
                    setUploadFilesArr([])
                    setContentFilesArr([])
                    toast.error('Please upload the english content', { autoClose: 2000 });
                    return false
                }

            } else {
                var reg_content_check = contentFilesArr.findIndex(e => e.stc_langflag == 2)
                var reg_file_check = val_multi_lang_files_array.findIndex(e => e.stf_langtype == 2)
                if (reg_content_check == -1 && reg_file_check == -1) {
                    setUploadFilesArr([])
                    setContentFilesArr([])
                    toast.error('Please upload the regional content', { autoClose: 2000 });
                    return false
                }

            }


            // if (contentFilesArr.length == 0 && val_multi_lang_files_array.length == 0) {
            //     toast.error('Please upload the content', { autoClose: 2000 });
            //     return false
            // }

            var qts;
            // Check if approval toggle is enabled
            if (generalsetting && generalsetting.length > 0 && generalsetting[0].gs_studymaterial_approval == 1) {

                if (stid == 0 || isMaterialClone == '1') { // if it is add

                    if (user.user_previleges.filter(p =>
                        Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Special)).length === 1) {
                        qts = 1
                    }
                    else {
                        qts = 3
                    }
                    // else if (user.user_previleges.filter(p =>
                    //     Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create && p.urp_type !== constants.menuTypes.Special)).length === 1 && user.user_previleges.filter(p =>
                    //         Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create || p.urp_type !== constants.menuTypes.Special)).length === 2) {
                    //     qts = 3
                    // }
                    // else if (user.user_previleges.filter(p =>
                    //     Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create || p.urp_type !== constants.menuTypes.Special)).length === 4 && user.user_previleges.filter(p =>
                    //         Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create && p.urp_type !== constants.menuTypes.Special)).length === 1) {
                    //     qts = 1
                    // }
                } else { // if it is update


                    if (approveStudyMaterial == 3) {
                        qts = 3
                    }
                    else {
                        if (statusMaterial === true) {
                            qts = 1
                        } else {
                            qts = 2
                        }
                    }

                }


            } else {
                if (stid === 0 || isMaterialClone == '1') { // if it is save 
                    qts = 1;
                } else { // if it is update
                    if (statusMaterial === true) {
                        qts = 1 // Active
                    } else {
                        qts = 2 // Inactive
                    }
                }
            }
            console.log('----', selectedSubject)
            // const [st_sid, st_sscid] = selectedSubject != null ? selectedSubject.value?.split('-') : [0, 0];
            var data = {
                "user_id": Number(user.uid),
                "process_type": stid == 0 || isMaterialClone == '1' ? 'save' : 'update',
                "stid": isMaterialClone == '1' ? 0 : Number(stid),
                "st_exctid": Number(selectedBoardCategory?.value || 0),
                "st_expid": Number(selectedExamPaper?.value),
                "st_sid": Number(selectedSubject?.value),
                "st_sscid": Number(selectedSubjectsubcat?.value || 0),
                "st_tid": Number(selectedTopic?.value || 0),
                "st_stsid": qts,
                "st_title": validation.values.title,
                "st_content_files": contentFilesArr,
                "st_upload_files": uploadFilesArr,
                "st_vstatus": 4,
                "st_reason": ''
            }
            // return false;
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
            const response1 = await axios.post(url.BASE_URL + 'master/savestudymaterial', data, config)

            if (response1 && response1.statusFlag === 3) {

                setLoading(false)
                // user validation
                toast.error(response1.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                sessionStorage.removeItem("SESSION_KEY")
                window.location.reload()
                return
            }
            if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
                setLoading(false)
                const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser()
                    } else {
                        saveMaterial() // calling same function again getting refresh token
                    }
                }
            }
            if (response1 && response1.data && response1.data.body?.statusFlag == 3) {
                setLoading(false)

                // user validation
                // toast.error(response1.message, { autoClose: 2000 });
                toast.error(response1.data.body.message, { autoClose: 2000 });

                return
            }


            if (response1 && response1.data && response1.data.body && response1.data.body?.statusFlag == 1) {

                setLoading(false)
                toast.success(response1.data.body.message, { autoClose: 2000 });
                if (saveClone == 1) {
                    setEngKey(prevKey => prevKey + 1);
                    setRegKey(prevKey => prevKey + 1);
                    //  editLoadStudyMaterial(response1.data.body.updated_stid)
                    localStorage.setItem('isMaterialClone', '1')
                    // qid = 0;
                }
                else {
                    if (Number(localStorage.getItem('isMaterialClone')) === 3) {
                        navigate(
                            `/list-studymaterial-approval`
                        )
                    } else {
                        navigate(
                            `/list-studymaterial`
                        )
                    }
                }
            } else {
                setLoading(false)
                toast.error(response1.data.message, { autoClose: 2000 });
                if (Number(localStorage.getItem('isMaterialClone')) === 3) {
                    navigate(
                        `/list-studymaterial-approval`
                    )
                } else {
                    navigate(
                        `/list-studymaterial`
                    )
                }
            }

        }, 500);
    }

    async function handleRejectClick() {
        setLoading(true)

        setUploadFilesArr([])
        setContentFilesArr([])
        setTimeout(async () => {



            if (smEngIframeRef.current && smEngIframeRef.current.contentWindow.updatedContent) {
                const iframeContent = smEngIframeRef.current.contentWindow.updatedContent;
                const engBlobData = new Blob([iframeContent], { type: 'text/html' });
                console.log(engBlobData, 'engBlobData')
                var contentFileEng = await appendContentFile(engBlobData, 'eng', 'html', 'materials', '1', '')

            }
            if (smRegIframeRef.current && smRegIframeRef.current.contentWindow.updatedContent) {
                const iframeContent = smRegIframeRef.current.contentWindow.updatedContent;
                const regBlobData = new Blob([iframeContent], { type: 'text/html' });
                await appendContentFile(regBlobData, 'reg', 'html', 'materials', '2', '').then((res) => {

                })

            }

            var multi_lang_files_array = [...dataTableUploads, ...regdataTableUploads]
            if (multi_lang_files_array.length > 0) {
                for (var i = 0; i < multi_lang_files_array.length; i++) {
                    if (multi_lang_files_array[i]?.is_aws_file == 1) {
                        if (multi_lang_files_array[i]?.is_deleted == 1) {
                            setUploadFilesArr(uploadFilesArr.push({
                                stf_filename: multi_lang_files_array[i]?.uploadedFileName,
                                stf_description: multi_lang_files_array[i]?.fName,
                                is_deleted: 1,
                                stf_langtype: multi_lang_files_array[i]?.stf_langtype
                            }))
                        }
                        else {
                            setUploadFilesArr(uploadFilesArr.push({
                                stf_filename: multi_lang_files_array[i]?.uploadedFileName,
                                stf_description: multi_lang_files_array[i]?.fName,
                                stf_langtype: multi_lang_files_array[i]?.stf_langtype
                            }))
                        }

                    }
                    else {
                        const [file_name, file_ext] = multi_lang_files_array[i]?.uploadedFileName.split('.')
                        const file = multi_lang_files_array[i]?.fileObject[0];
                        let blobData = new Blob(multi_lang_files_array[i]?.fileObject, { type: file.type })
                        await appendContentFile(blobData, file_name, file_ext, 'uploadedMaterials', multi_lang_files_array[i]?.stf_langtype, multi_lang_files_array[i]?.fName)
                    }

                }
            }
            // alert(contentFilesArr.length) 
            // alert(uploadFilesArr.length) 
            // return false

            var val_multi_lang_files_array = multi_lang_files_array.filter(e => e?.is_deleted != 1)

            if (langArr?.includes("1")) {
                var eng_content_check = contentFilesArr.findIndex(e => e.stc_langflag == 1)
                var eng_file_check = val_multi_lang_files_array.findIndex(e => e.stf_langtype == 1)
                if (eng_content_check == -1 && eng_file_check == -1) {
                    setUploadFilesArr([])
                    setContentFilesArr([])
                    toast.error('Please upload the english content', { autoClose: 2000 });
                    return false
                }

            } else {
                var reg_content_check = contentFilesArr.findIndex(e => e.stc_langflag == 2)
                var reg_file_check = val_multi_lang_files_array.findIndex(e => e.stf_langtype == 2)
                if (reg_content_check == -1 && reg_file_check == -1) {
                    setUploadFilesArr([])
                    setContentFilesArr([])
                    toast.error('Please upload the regional content', { autoClose: 2000 });
                    return false
                }

            }


            // if (contentFilesArr.length == 0 && val_multi_lang_files_array.length == 0) {
            //     toast.error('Please upload the content', { autoClose: 2000 });
            //     return false
            // }

            var qts;
            // Check if approval toggle is enabled
            if (generalsetting && generalsetting.length > 0 && generalsetting[0].gs_studymaterial_approval == 1) {

                if (stid == 0 || isMaterialClone == '1') { // if it is add

                    if (user.user_previleges.filter(p =>
                        Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Special)).length === 1) {
                        qts = 1
                    }
                    else {
                        qts = 3
                    }
                    // else if (user.user_previleges.filter(p =>
                    //     Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create && p.urp_type !== constants.menuTypes.Special)).length === 1 && user.user_previleges.filter(p =>
                    //         Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create || p.urp_type !== constants.menuTypes.Special)).length === 2) {
                    //     qts = 3
                    // }
                    // else if (user.user_previleges.filter(p =>
                    //     Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create || p.urp_type !== constants.menuTypes.Special)).length === 4 && user.user_previleges.filter(p =>
                    //         Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create && p.urp_type !== constants.menuTypes.Special)).length === 1) {
                    //     qts = 1
                    // }
                } else { // if it is update


                    if (approveStudyMaterial == 3) {
                        qts = 3
                    }
                    else {
                        if (statusMaterial === true) {
                            qts = 1
                        } else {
                            qts = 2
                        }
                    }

                }


            } else {
                if (stid === 0 || isMaterialClone == '1') { // if it is save 
                    qts = 1;
                } else { // if it is update
                    if (statusMaterial === true) {
                        qts = 1 // Active
                    } else {
                        qts = 2 // Inactive
                    }
                }
            }

            // const [st_sid, st_sscid] = selectedSubject != null ? selectedSubject.value?.split('-') : [0, 0];
            var data = {
                "user_id": Number(user.uid),
                "process_type": stid == 0 || isMaterialClone == '1' ? 'save' : 'update',
                "stid": isMaterialClone == '1' ? 0 : Number(stid),
                "st_exctid": Number(selectedBoardCategory?.value || 0),
                "st_expid": Number(selectedExamPaper?.value),
                "st_sid": Number(selectedSubject?.value),
                "st_sscid": Number(selectedSubjectsubcat?.value || 0),
                "st_tid": Number(selectedTopic?.value || 0),
                "st_stsid": qts,
                "st_title": validation.values.title,
                "st_content_files": contentFilesArr,
                "st_upload_files": uploadFilesArr,
                "st_vstatus": 5,
                "st_reason": rejectValidation.values.reason !== '' ? rejectValidation.values.reason : '',
            }
            // return false;
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
            const response1 = await axios.post(url.BASE_URL + 'master/savestudymaterial', data, config)

            if (response1 && response1.statusFlag === 3) {

                setLoading(false)
                // user validation
                toast.error(response1.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                sessionStorage.removeItem("SESSION_KEY")
                window.location.reload()
                return
            }
            if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
                setLoading(false)
                const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser()
                    } else {
                        saveMaterial() // calling same function again getting refresh token
                    }
                }
            }
            if (response1 && response1.data && response1.data.body?.statusFlag == 3) {
                setLoading(false)

                // user validation
                // toast.error(response1.message, { autoClose: 2000 });
                toast.error(response1.data.body.message, { autoClose: 2000 });

                return
            }


            if (response1 && response1.data && response1.data.body && response1.data.body?.statusFlag == 1) {

                setLoading(false)
                toast.success(response1.data.body.message, { autoClose: 2000 });
                if (saveClone == 1) {
                    setEngKey(prevKey => prevKey + 1);
                    setRegKey(prevKey => prevKey + 1);
                    //  editLoadStudyMaterial(response1.data.body.updated_stid)
                    localStorage.setItem('isMaterialClone', '1')
                    // qid = 0;
                }
                else {

                    if (Number(localStorage.getItem('isMaterialClone')) === 3) {
                        navigate(
                            `/list-studymaterial-approval`
                        )
                    } else {
                        navigate(
                            `/list-studymaterial`
                        )
                    }
                }
            } else {
                setLoading(false)
                toast.error(response1.data.message, { autoClose: 2000 });
                if (Number(localStorage.getItem('isMaterialClone')) === 3) {
                    navigate(
                        `/list-studymaterial-approval`
                    )
                } else {
                    navigate(
                        `/list-studymaterial`
                    )
                }
            }

        }, 500);
    }

    useEffect(() => {
        if (smEngIframeRef.current?.contentWindow && smEngIframeRef.current?.contentWindow != undefined && showEngPreview == false) {

            const iframeEngDocument = smEngIframeRef.current?.contentWindow
            iframeEngDocument.initialContent = engContent
            iframeEngDocument.updatedContent = engContent
        }
    }, [engContent, showEngPreview]);
    useEffect(() => {

        if (smRegIframeRef.current?.contentWindow && smRegIframeRef.current?.contentWindow != undefined && showRegPreview == false) {
            const iframeRegDocument = smRegIframeRef.current?.contentWindow
            iframeRegDocument.initialContent = regContent
            iframeRegDocument.updatedContent = regContent
        }
    }, [showRegPreview, regContent]);
    // useEffect(() => {
    //     setlanguageActiveTab('11')
    //     if(smEngIframeRef.current?.contentWindow && smEngIframeRef.current?.contentWindow != undefined )
    //     {
    //         const iframeEngDocument = smEngIframeRef.current?.contentWindow
    //         console.log(engContent,'engcontetn')
    //        // GLOBAL_ENG_CONTENT = "ccfcfxfxf"
    //         iframeEngDocument.initialContent = GLOBAL_ENG_CONTENT
    //         console.log(GLOBAL_ENG_CONTENT,'GLOBAL_ENG_CONTENT')
    //     }
    // }, [engContent]);

    async function editLoad(stid) {


        setSaveCloneSid(1)

        setLoading(true);
        const user = getLoggedInUser()

        const filter_user_id = user?.user_previleges.find(f =>
            Number(f.urp_mid) === constants.mainMenuList.QA && f.urp_type === constants.menuTypes.Permission1);


        var data = {
            user_id: Number(user.uid),
            stid: Number(stid)
        }


        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'master/editLoadMaterial', data, config)
        if (response1 && response1.statusFlag === 3) { // user validation
            toast.error(response1.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            sessionStorage.removeItem("SESSION_KEY")
            window.location.reload()
            return
        }
        if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser()
                } else {
                    await editLoad(stid) // calling same function again getting refresh token
                }
            }
        }
        if (response1 && response1.data && response1.data.body && response1.data.body.MaterialData?.length > 0) {
            // if (!filter_user_id?.uid && response1?.data?.body?.MaterialData[0]?.access_user_id === user?.uid || filter_user_id?.uid) {
            setsmObj(response1.data.body.MaterialData[0])
            setapproveStudyMaterial(response1.data.body.MaterialData[0].st_stsid)
            setReason(response1?.data?.body?.MaterialData[0]?.st_reason)
            setLoading(false);

            var smObj = {
                selectedExamPaper: {
                    label: response1.data.body.MaterialData[0]?.exp_name,
                    value: response1.data.body.MaterialData[0]?.st_expid
                },
                selectedBoardCategory: {
                    label: response1.data.body.MaterialData[0]?.examboardnamecategory,
                    value: response1.data.body.MaterialData[0]?.st_exctid
                },
                selectedTopic: {
                    label: response1.data.body.MaterialData[0]?.t_name,
                    value: response1.data.body.MaterialData[0]?.st_tid
                },
                selectedSubjectsubcat: {
                    label: response1.data.body.MaterialData[0]?.ssc_name,
                    value: response1.data.body.MaterialData[0]?.st_sscid
                },

            }

            if (Number(localStorage.getItem('isMaterialClone')) === 1) {
                validation.setFieldValue('title', '')
            } else {
                validation.setFieldValue('title', response1.data.body.MaterialData[0]?.st_title)
            }
            setsmObj(smObj)
            setSelectedBoardCategory({
                label: response1.data.body.MaterialData[0]?.examboardnamecategory,
                value: response1.data.body.MaterialData[0]?.st_exctid
            })
            setSelectedSubject({
                label: response1.data.body.MaterialData[0]?.subname,
                value: response1.data.body.MaterialData[0]?.st_sid.toString() + "-" + response1.data.body.MaterialData[0]?.st_sscid.toString()
            })
            setselectedSubjectSubcat({
                label: response1.data.body.MaterialData[0]?.ssc_name,
                value: response1.data.body.MaterialData[0]?.st_sscid.toString()
            })
            validation.setFieldValue("selectedBoardCategory", {
                label: response1.data.body.MaterialData[0]?.examboardnamecategory,
                value: response1.data.body.MaterialData[0]?.st_exctid
            })
            validation.setFieldValue("selectedExamPaper", {
                label: response1.data.body.MaterialData[0]?.exp_name,
                value: response1.data.body.MaterialData[0]?.st_expid
            })
            validation.setFieldValue("selectedSubject", {
                label: response1.data.body.MaterialData[0]?.subname,
                value: response1.data.body.MaterialData[0]?.st_sid.toString() + "-" + response1.data.body.MaterialData[0]?.st_sscid.toString()
            })
            validation.setFieldValue("selectedSubject", {
                label: response1.data.body.MaterialData[0]?.ssc_name,
                value: response1.data.body.MaterialData[0]?.st_sscid.toString()
            })

            validation.setFieldValue("selectedTopic", {
                label: response1.data.body.MaterialData[0]?.t_name,
                value: response1.data.body.MaterialData[0]?.st_tid
            })


            setSelectedTopic({
                label: response1.data.body.MaterialData[0]?.t_name,
                value: response1.data.body.MaterialData[0]?.st_tid
            })

            setselectedExamPaper({
                label: response1.data.body.MaterialData[0]?.exp_name,
                value: response1.data.body.MaterialData[0]?.st_expid
            })
            setStatusMaterial(response1.data.body.MaterialData[0]?.st_stsid)
            if (response1.data.body.MaterialData[0]?.st_stsid == "1" || response1.data.body.MaterialData[0]?.st_stsid == "3")
                setStatusMaterial(
                    true
                )
            else if (response1.data.bosy.MaterialData[0]?.st_stsid == '2') setStatusMaterial(
                false
            )
            if (masterstore && masterstore?.masterlist && masterstore?.masterlist?.subCategryList) {

                console.log('optiona', masterstore)
                console.log('optiona', masterstore?.masterlist?.subCategryList)
                var filter_subcat = masterstore?.masterlist?.subCategryList?.filter(e => Number(e.sscid) == Number(response1.data.body.MaterialData[0]?.st_sscid))
                console.log('filtereee', filter_subcat)
                if (filter_subcat?.length > 0) {
                    setIsDisabledSubCategory(false)
                } else {
                    setIsDisabledSubCategory(true)
                }
            }
            if (masterstore && masterstore?.masterlist && masterstore?.masterlist?.topicList) {

                console.log('optiona', masterstore)
                console.log('optiona', masterstore?.masterlist?.topicList)
                var filter_topic = masterstore?.masterlist?.topicList?.filter(e => Number(e.topicid) == Number(response1.data.body.MaterialData[0]?.st_tid))
                console.log('filtereee', filter_topic)
                if (filter_topic?.length > 0) {
                    setIsDisabledTopic(false)
                } else {
                    setIsDisabledTopic(true)
                }
            }
            if (masterstore && masterstore?.masterlist && masterstore?.masterlist) {
                console.log('maste', masterstore?.masterlist?.examPaperList)
                // var filter_paper = masterstore?.masterlist?.examPaperList?.filter(e => Number(e.expid) == Number(response1.data.body.MaterialData[0]?.st_expid))
                // console.log('filter_paper====', filter_paper)
                var suject_lang = masterstore?.masterlist?.examPaperList?.map(e => {
                    if (e.expid == response1.data.body.MaterialData[0]?.st_expid) {
                        var lang_arr = e.lang?.split(',');
                        setLangArr(lang_arr);

                        if (lang_arr?.includes("1")) {
                            setShowEngPreview(false)
                            setlanguageActiveTab("11")
                        }
                        else {
                            setShowRegPreview(false)
                            setlanguageActiveTab("12")
                        }
                    }

                })
            }





            console.log('ereee', response1?.data?.body?.MaterialData[0])
            // console
            // var filter_subcat = optionAllSubjectSubcat.filter(e )

            // handleSelectBoardName_editload({
            //     label: response1.data.body.MaterialData[0]?.examboardnamecategory,
            //     value: response1.data.body.MaterialData[0]?.st_exctid
            // })

            // Check if approval toggle is enabled

            console.log('sfdsfdgfd', generalsetting);
            setGeneralSetting(masterstore.masterlist.generalsettings)

            if (masterstore && masterstore?.masterlist && masterstore?.masterlist?.generalsettings && masterstore?.masterlist?.generalsettings[0]?.gs_studymaterial_approval == 1) {

                if (user.user_previleges.filter(p =>
                    Number(p.urp_smid) === constants.SubmenuList.SMApproval && (p.urp_type === constants.menuTypes.Permission1)).length === 1) {


                    if (response1.data.body.MaterialData[0]?.st_vstatus == "3") {
                        setApprovebutton(true)
                    } else {
                        setApprovebutton(false)
                    }

                    if (response1.data.body.MaterialData[0]?.st_vstatus == "5") {
                        setRejectbutton(true)
                    } else {
                        setRejectbutton(false)
                    }
                }
                else {
                    setApprovebutton(false)
                }
            }
            else {
                setApprovebutton(false)
            }

            if (response1.data.body.MaterialData[0]?.st_vstatus == "4") {
                setSuccessFlag(true)
            } else {
                setSuccessFlag(false)
            }

            // smEngIframeRef.current?.contentWindow.initialContent = '<p>Test</p>'


            if (response1.data.body?.ContentUploads?.length > 0) {
                var content_uploads = response1.data.body?.ContentUploads;
                for (var i = 0; i < content_uploads.length; i++) {
                    if (content_uploads[i]?.stc_filename) {

                        await fetch(content_uploads[i]?.stc_filename)
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error('Network response was not ok');
                                }
                                return response.text();
                            })
                            .then(html_content => {
                                console.log('cons', content_uploads[i])
                                if (content_uploads[i]?.stc_langflag == 1) {
                                    console.log('-------=-')
                                    setEngContent(html_content)
                                    setShowEngPreview(false)

                                    // setTimeout(() => {

                                    // GLOBAL_ENG_CONTENT = "html_content"
                                    //     setTest('ccgfghgfhgfhgf')
                                    // }, 1000);
                                    // smEngIframeRef.current.contentWindow.initialContent = html_content;
                                    const iframeEngDocument = smEngIframeRef.current?.contentWindow
                                    iframeEngDocument.initialContent = html_content
                                    iframeEngDocument.updatedContent = html_content
                                }
                                if (content_uploads[i]?.stc_langflag == 2) {
                                    setRegContent(html_content)
                                    setShowRegPreview(false)
                                    // smRegIframeRef.current.contentWindow.initialContent = html_content;
                                    const iframeRegDocument = smRegIframeRef?.current?.contentWindow
                                    iframeRegDocument.initialContent = html_content
                                    iframeRegDocument.updatedContent = html_content
                                }
                            })
                            .catch(error => {
                                console.error('There was a problem fetching the content:', error);
                            });


                    }


                }
            }

            if (response1.data.body?.UploadedFiles?.length > 0) {
                var uploadFiles = []
                var reguploadFiles = []
                for (var j = 0; j < response1.data.body?.UploadedFiles?.length; j++) {

                    var upload_file_json = {
                        sno: j + 1,
                        fName: response1.data.body?.UploadedFiles[j]?.stf_description,
                        uploadedFileName: response1.data.body?.UploadedFiles[j]?.stf_filename,
                        is_aws_file: 1,
                        stf_langtype: response1.data.body?.UploadedFiles[j]?.stf_langtype
                        // fileObject:selectedFile

                    }


                    if (response1.data.body?.UploadedFiles[j]?.stf_langtype == 1) {
                        uploadFiles.push(upload_file_json)
                    }
                    else {
                        reguploadFiles.push(upload_file_json)
                    }



                }
                setDataTable(uploadFiles);
                setDataTableUploads(uploadFiles)

                setRegDataTable(reguploadFiles);
                setRegDataTableUploads(reguploadFiles)
            }

            var sub_filter = handleSelectExamPaper_editload({
                label: response1.data.body.MaterialData[0]?.exp_name,
                value: response1.data.body.MaterialData[0]?.st_expid
            }, response1.data.body.MaterialData[0]?.st_expid)
            console.log('sub_filter', sub_filter);

            setOptionSubject([{
                label: response1.data.body.MaterialData[0]?.s_name,
                value: response1.data.body.MaterialData[0]?.st_sid.toString()
            }, ...sub_filter])
            setSelectedSubject({
                label: response1.data.body.MaterialData[0]?.s_name,
                value: response1.data.body.MaterialData[0]?.st_sid.toString()
            })
            // handleSelectSubjectName_editload({
            //     label: response1.data.body.MaterialData[0]?.s_name,
            //     value: response1.data.body.MaterialData[0]?.st_sid.toString()
            // }
            //     , {
            //         label: response1.data.body.MaterialData[0]?.exp_name,
            //         value: response1.data.body.MaterialData[0]?.st_expid
            //     }, response1.data.body.MaterialData[0]?.st_expid)
            // let newRow = {
            //     sno: dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
            //     fName: fileName,
            //     uploadedFileName: selectedFile[0]?.name,
            //     fileObject:selectedFile

            // }






            // }
            // else {
            //     navigate("/list-studymaterial")
            // }
        }

    }

    const appendContentFile = async (blobData, fileLang, ext, folderName, langtype, desc) => {

        const current_time = new Date();
        let ms = current_time.getTime();
        // setContentFilesArr([])

        var uploadData = {
            "user_id": Number(user.uid),
            "fileName": folderName + "/sm_" + fileLang + "_" + ms + "." + ext
        }

        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'master/getPreSignedRL', uploadData, config)
        if (response1 && response1.statusFlag === 3) { // user validation
            toast.error(response1.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            sessionStorage.removeItem("SESSION_KEY")
            window.location.reload()
            return
        }
        if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser()
                } else {
                    appendContentFile(blobData, fileLang, ext, folderName, langtype, desc) // calling same function again getting refresh token
                }
            }
        }


        if (response1 && response1.data && response1.data.body) {

            var varuploadURL = response1.data.body?.uploadURL?.uploadURL
            const result = await fetch(varuploadURL, {
                method: 'PUT',
                body: blobData
            }).then(lamdaresp => {
                const uploadUrl = lamdaresp.url.split('?');
                if (uploadUrl[0]) {
                    //   let fileParams = {                   
                    //     imageurl: uploadUrl[0]
                    //   }

                    if (folderName == 'materials') {
                        var temp_contentFiles = []
                        temp_contentFiles = contentFilesArr

                        // temp_contentFiles =
                        temp_contentFiles?.push({
                            stc_filename: uploadUrl[0],
                            stc_langflag: Number(langtype)

                        })

                        // setContentFilesArr(prevArray => [...prevArray, {
                        //     stc_filename: uploadUrl[0],
                        //     stc_langflag: Number(langtype)

                        // }]);
                        setContentFilesArr(temp_contentFiles)
                    }


                    else
                        setUploadFilesArr(uploadFilesArr.push({
                            stf_filename: uploadUrl[0],
                            stf_description: desc,
                            stf_langtype: Number(langtype)
                        }))
                    //   return fileParams                     
                }
            })

        }


        // const url = window.URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = 'material.html';
        // link.click();
        // window.URL.revokeObjectURL(url);

    }


    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const handleClose = () => {
        validation.resetForm();
        setInitialSubmitState(true);
        console.log(approvebutton, approvebutton);
        if (Number(localStorage.getItem('isMaterialClone')) === 3) {
            navigate(
                `/list-studymaterial-approval`
            )
        } else {
            navigate(
                `/list-studymaterial`
            )
        }
        // navigate("/list-studymaterial");
    }


    const handleDeleteRow = (row) => {
        try {


            setDataTable(prevData => {
                // Filter out the row to be deleted based on some identifier (e.g., id)
                const updatedData = prevData.filter(item => item.sno !== row.sno);
                // Return the updated data
                return updatedData;
            });
            if (row?.is_aws_file == 1) {// add indicator for deleting from aws
                setDataTableUploads(prevData => {
                    // Filter out the row to be deleted based on some identifier (e.g., id)
                    const updatedData = prevData.map(item => {

                        if (item.sno == row.sno) {
                            item.is_deleted = 1
                        }
                        return item
                    });
                    // Return the updated data

                    return updatedData;
                });
            }
            else {

                setDataTableUploads(prevData => {
                    // Filter out the row to be deleted based on some identifier (e.g., id)
                    const updatedData = prevData.filter(item => item.sno !== row.sno);
                    // Return the updated data
                    return updatedData;
                });
            }

        } catch (error) {
            console.error("Error deleting row:", error);
        }
    };

    const handleRegDeleteRow = (row) => {
        try {


            setRegDataTable(prevData => {
                // Filter out the row to be deleted based on some identifier (e.g., id)
                const updatedData = prevData.filter(item => item.sno !== row.sno);
                // Return the updated data
                return updatedData;
            });
            if (row?.is_aws_file == 1) {// add indicator for deleting from aws
                setRegDataTableUploads(prevData => {
                    // Filter out the row to be deleted based on some identifier (e.g., id)
                    const updatedData = prevData.map(item => {

                        if (item.sno == row.sno) {
                            item.is_deleted = 1
                        }
                        return item
                    });
                    // Return the updated data

                    return updatedData;
                });
            }
            else {

                setRegDataTableUploads(prevData => {
                    // Filter out the row to be deleted based on some identifier (e.g., id)
                    const updatedData = prevData.filter(item => item.sno !== row.sno);
                    // Return the updated data
                    return updatedData;
                });
            }

        } catch (error) {
            console.error("Error deleting row:", error);
        }
    };

    const handleAddOrUpdateRow = () => {
        const fileDetails = selectedFile;
        if (fileName == "" || fileName == null) {
            toast.error('Please enter the description', { autoClose: 2000 });
            return false
        }
        if (fileDetails && fileDetails.length > 0 && fileDetails != "") {

            let newRow = {
                sno: dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
                fName: fileName,
                uploadedFileName: fileDetails[0]?.name,
                fileObject: fileDetails,
                stf_langtype: 1

            }
            setDataTable([...dataTable, newRow]);
            setDataTableUploads([...dataTableUploads, newRow]);
            setFileName("")
            setSelectedFile([])
            // document.getElementById("fileuplaod").value = '';
        }
        else {
            toast.error('Please upload the file', { autoClose: 2000 });
        }


    };
    const handleRegAddOrUpdateRow = () => {
        const fileDetails = regselectedFile;
        if (regFileName == "" || regFileName == null) {
            toast.error('Please enter the description', { autoClose: 2000 });
        }
        if (fileDetails && fileDetails.length > 0 && fileDetails != "") {

            let newRow = {
                sno: regdataTable.length > 0 ? regdataTable[regdataTable.length - 1]?.sno + 1 : 1,
                fName: regFileName,
                uploadedFileName: fileDetails[0]?.name,
                fileObject: fileDetails,
                stf_langtype: 2

            }
            setRegDataTable([...regdataTable, newRow]);
            setRegDataTableUploads([...regdataTableUploads, newRow]);
            setRegFileName("")
            setRegSelectedFile([])
            // document.getElementById("fileuplaod").value = '';
        }
        else {
            toast.error('Please upload the file', { autoClose: 2000 });
        }


    };

    const getUploadedFile = (e) => {
        let files = e.target.files
        setSelectedFile(files)
    }

    const getRegUploadedFile = (e) => {
        let files = e.target.files
        setRegSelectedFile(files)
    }

    // const getmasterlistapi = async () => {
    //     const user = getLoggedInUser()
    //     var data = { user_id: Number(user.uid) }

    //     const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    //     const response1 = await axios.post(url.BASE_URL + 'master/getQAMaster', data, config)
    //     if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    //         const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    //         if (refresh) {
    //             if (Number(refresh) === constants.invalidRefreshToken) {
    //                 logoutUser()
    //             } else {
    //                 getmasterlistapi() // calling same function again getting refresh token
    //             }
    //         }
    //     }
    //     if (response1 && response1.data && response1.data.body) {
    //         var masterListData = response1.data.body


    //         if (masterListData != undefined) {
    //             // if (masterListData && masterListData.board_categoryList) {
    //             //     var boardList = masterListData.board_categoryList.filter(e => e.c_exctid != 0)

    //             //     setOptionBoardCategory(boardList)

    //             // }
    //             // if (masterListData && masterListData.conf_subject_subcategoryList) {

    //             //     //  setOptionSubject([{ s_name: "Select", sid: "0", sscid: "0", exctid: "0" }, ...masterListData.conf_subject_subcategoryList])
    //             //     setOptionAllSubject([{ s_name: "Select", sid: "0", sscid: "0", exctid: "0" }, ...masterListData.conf_subject_subcategoryList])
    //             // }

    //             // if (masterListData && masterListData.topicList) {
    //             //     setOptionAllTopic(masterListData.topicList)
    //             //     // setOptionTopic(masterListData.topicList)
    //             // }
    //             // if (masterListData && masterListData.examPaperList) {
    //             //     //  setOptionExamPaper(masterListData.examPaperList)
    //             //     setOptionAllExamPaper(masterListData.examPaperList)
    //             // }
    //             if (masterListData && masterListData.generalSettings) {
    //                 setGeneralSetting(masterListData.generalSettings)
    //             }


    //         }


    //     }
    // }

    useEffect(() => {
        // getmasterlistapi()
        GLOBAL_ENG_CONTENT = "FOrm load"
        document.getElementById('headerTitle').innerHTML = 'Study Materials';
        getmasterdatalistapi()
        console.log('=-=-============', ((user.user_previleges.filter(p =>
            Number(p.urp_smid) === constants.SubmenuList.SMApproval && (p.urp_type === constants.menuTypes.Special)).length)
        ))
    }, []);

    useEffect(() => {
        console.log('stid==============', stid)
        if (stid != "0" && stid != null && stid != undefined) editLoad(stid)

    }, []);



    function handleSelectSubjectName(varselectedSubjectName) {
        console.log('-0-0-', varselectedSubjectName)
        setFiltersubjectSubCategoryOptions([])
        setFilterTopicOptions([])

        const sid = varselectedSubjectName.value;

        console.log('optionAllSubjectSubcat', optionAllSubjectSubcat);


        var filter_subcat = optionAllSubjectSubcat.filter(e => Number(e.ssc_sid) === Number(varselectedSubjectName?.subjectid))

        console.log('filter_subcat', filter_subcat)
        var topic_data = optionAllTopic.filter(e => Number(e.subjectid) == Number(varselectedSubjectName?.subjectid) && Number(e.subcategoryid) == Number(0))
        console.log('=-0=--=--', topic_data)
        setOptionTopic(topic_data)
        setFilterTopicOptions(topic_data)

        if (filter_subcat && filter_subcat.length == 0) {
            setIsDisabledSubCategory(true)
        } else {
            setIsDisabledSubCategory(false)
        }
        if (topic_data && topic_data.length == 0) {
            setIsDisabledTopic(true)
        } else {
            setIsDisabledTopic(false)
        }


        // setOptionAllSubjectSubcat(filter_subcat)
        setOptionSubjectSubcat(filter_subcat)
        // setOptionTopic(filter_subcat)

        setSelectedTopic(null)
        setSelectedSubject(varselectedSubjectName)
        validation.setFieldValue("selectedTopic", null);



    }
    function handleSelectSubjectSubcatName(varselectedSubjectName) {
        console.log('-0-0-', varselectedSubjectName)
        setFilterTopicOptions([])

        setselectedSubjectSubcat(varselectedSubjectName)
        var topic_data = optionAllTopic.filter(e => Number(e.subjectid) == Number(varselectedSubjectName?.ssc_sid) && Number(e.subcategoryid) == Number(varselectedSubjectName?.sscid))

        console.log('topic', topic_data);

        var data_count = topic_data.length;
        if (data_count === 0) {
            setFilterTopicOptions([])
            setIsDisabledTopic(true)
        } else {
            setIsDisabledTopic(false)
            setFilterTopicOptions(topic_data)
        }
        // setSelectedSubjectSubCategory(varselectedSubjectSubCategoryName)
        // if (varselectedSubjectName?.label != 'Select' && varselectedSubjectName != null && varselectedSubjectName != undefined) { // if select all option for board, need to checko only exam paper 
        // handleSelectExamPaper(selectedExamPaper, selectedBoardCategory?.value)
        const sscid = varselectedSubjectName.value;
        console.log('sele', optionAllTopic)


        // console.log('----', optionAllSubjectSubcat)
        // var filter_subcat = optionAllSubjectSubcat.filter(e => Number(e.sid) == varselectedSubjectName?.value)
        // console.log('filte', filter_subcat)
        // const expid = varselectedSubjectName.s_expid
        var filtered_topic_option = optionAllTopic.filter(e => e.subjectid == varselectedSubjectName?.ssc_sid && e.subcategoryid == varselectedSubjectName?.sscid);
        console.log('pppp', filtered_topic_option)
        setOptionTopic(filtered_topic_option)
        // filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
        // setOptionTopic(filtered_topic_option)


        // }
        // else {
        //     handleSelectExamPaper(selectedExamPaper, selectedBoardCategory?.value)
        // }

        setSelectedTopic(null)
        // setSelectedSubject(varselectedSubjectName)
        validation.setFieldValue("selectedTopic", null);



    }

    // const [sid, sscid] = varselectedSubjectName.value.split('-');
    // const expid = varselectedSubjectName.s_expid
    // var filtered_topic_option = optionAllTopic.filter(e => e.subjectid == sid && e.subcategoryid == sscid && e.cs_expid == expid);

    // filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
    // setOptionTopic(filtered_topic_option)


    function handleSelectSubjectName_editload(varselectedSubjectName, varselectedExamPaper, varBoardID) {
        console.log('-----', varselectedSubjectName)
        setSelectedSubject(varselectedSubjectName)
        // console.log('---', )
        // subcatList = masterstore?.masterlist?.subCategryList;
        // var topic_data = subcatList.filter(e => Number(e.ssc_sid) == NUm)

        // var topic_data = subjectSubCategoryOptions.filter(e => Number(e.ssc_sid) === Number(varselectedSubjectName?.value))
        // setOptionMasterAllTopic(topic_data)
        // var data_count = topic_data.length;
        // console.log('data_count', data_count)

        // if (data_count === 0) {
        //     setFiltersubjectSubCategoryOptions([])
        //     setSelectedSubjectSubCategory([])
        //     setIsDisabledSubCategory(true)
        //     var topic = optionAllTopic.filter(e => Number(e.subjectid) == Number(varselectedSubjectName?.subjectid))

        //     console.log('topic', topic, topic.length);

        //     if (topic.length === 0) {
        //         setFilterTopicOptions([])
        //         setIsDisabledTopic(true)
        //     } else {
        //         setIsDisabledTopic(false)
        //         setFilterTopicOptions(topic)
        //     }
        // } else {
        //     setIsDisabledSubCategory(false)
        //     setFiltersubjectSubCategoryOptions(topic_data)
        // }

        // if (varselectedSubjectName?.label != null && varselectedSubjectName?.label != 'Select' && varselectedSubjectName != null && varselectedSubjectName != undefined) { // if select all option for board, need to checko only exam paper 
        //     //   handleSelectExamPaper_editload(varselectedExamPaper, varBoardID)
        //     const [sid, sscid] = varselectedSubjectName.value.split('-');
        //     // var filtered_topic_option = optionAllTopic.filter(e => e.subjectid == sid && e.subcategoryid == sscid && e.cs_expid == varselectedExamPaper?.value) ;
        //     var filtered_topic_option = optionAllTopic.filter(e => e.subjectid == sid && e.subcategoryid == sscid); // Added For the impact of removed topic from configuration
        //     filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
        //     setOptionTopic(filtered_topic_option)


        // }
        // else {
        //     // handleSelectExamPaper_editload(varselectedExamPaper,varBoardID)
        // }






    }
    function handleSelectTopicName(selectedTopicName) {
        setSelectedTopic(selectedTopicName);
    }
    const changeLangugeTab = tab => {
        if (languageActiveTab !== tab) {
            setlanguageActiveTab(tab);
        }
    };

    // useEffect(() => {

    //     if(masterstore.masterlist != undefined)
    //     {
    //     if (masterstore.masterlist && masterstore.masterlist.board_categoryList) {
    //         setOptionBoardCategory(masterstore.masterlist.board_categoryList)

    //     }
    //     if (masterstore.masterlist && masterstore.masterlist.subject_subcategoryList) {

    //         setOptionSubject([{ s_name: "Select", sid: "0", sscid: "0", exctid: "0" }, ...masterstore.masterlist.subject_subcategoryList])
    //         setOptionAllSubject([{ s_name: "Select", sid: "0", sscid: "0", exctid: "0" }, ...masterstore.masterlist.subject_subcategoryList])
    //     }

    //     if (masterstore.masterlist && masterstore.masterlist.topicList) {
    //         setOptionAllTopic(masterstore.masterlist.topicList)
    //         setOptionTopic(masterstore.masterlist.topicList)
    //     }
    //     if (masterstore.masterlist && masterstore.masterlist.examPaperList) {
    //         setOptionExamPaper(masterstore.masterlist.examPaperList)
    //     }


    //     if (stid != "0" && stid != null && stid != undefined) editLoad(stid)
    // }
    // }, [masterstore.masterlist])

    const [columns, setColumns] = useState(
        () => [
            {
                header: 'Actions',
                // width: '8%',
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">

                            <div
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    //console.log("Deleting row:", cellProps.row.original);
                                    handleDeleteRow(cellProps.row.original);
                                }}
                            >
                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    );
                }
            },
            {
                header: 'S.No.',
                accessorKey: 'sno',
                // width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
            },
            {
                header: 'Description',
                accessorKey: 'fName',
                enableColumnFilter: false,
                enableSorting: true,

            },

            {
                header: 'Files',
                accessorKey: 'uploadedFileName',
                enableColumnFilter: false,
                enableSorting: true,
            }
        ],
        []
    );
    ;
    const [regcolumns, setRegColumns] = useState(
        () => [
            {
                header: 'Actions',
                // width: '8%',
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">

                            <div
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    //console.log("Deleting row:", cellProps.row.original);
                                    handleRegDeleteRow(cellProps.row.original);
                                }}
                            >
                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    );
                }
            },
            {
                header: 'S.No.',
                accessorKey: 'sno',
                // width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
            },
            {
                header: 'Description',
                accessorKey: 'fName',
                enableColumnFilter: false,
                enableSorting: true,

            },

            {
                header: 'Files',
                accessorKey: 'uploadedFileName',
                enableColumnFilter: false,
                enableSorting: true,
            }
        ],
        []
    );
    // function handleSelectBoardName(varselectedBoardName) {
    //     setSelectedBoardCategory(varselectedBoardName);

    //     var filtered_exampaper_option = optionAllExamPaper.map(e => {
    //         var exctid_arr = e?.exctid?.split(',');
    //         if (exctid_arr?.length > 0) {
    //             if (exctid_arr?.includes(varselectedBoardName.value)) {
    //                 return e;
    //             }
    //         }

    //     })
    //     filtered_exampaper_option = filtered_exampaper_option.filter(e => e != undefined);
    //     setOptionExamPaper(filtered_exampaper_option)

    //     setOptionSubject([])
    //     setOptionTopic([])
    //     setselectedExamPaper(null)
    //     validation.setFieldValue("selectedExamPaper", null);

    //     setSelectedSubject(null)
    //     validation.setFieldValue("selectedSubject", null);
    //     setSelectedTopic(null)
    //     validation.setFieldValue("selectedTopic", null);



    // }

    // function handleSelectBoardName_editload(varselectedBoardName) {


    //     var filtered_exampaper_option = optionAllExamPaper.map(e => {
    //         var exctid_arr = e?.exctid?.split(',');
    //         if (exctid_arr?.length > 0) {
    //             if (exctid_arr?.includes(varselectedBoardName.value)) {
    //                 return e;
    //             }
    //         }

    //     })
    //     filtered_exampaper_option = filtered_exampaper_option.filter(e => e != undefined);
    //     setOptionExamPaper(filtered_exampaper_option)



    // }

    function handleSelectExamPaper(varselectedPaper, boardID) {
        console.log('------', varselectedPaper)
        console.log('--=-==-=', boardID)
        setIsDisabledSubCategory(false)

        setselectedExamPaper(varselectedPaper);
        filter_exampaper_subject(varselectedPaper, boardID)
        filter_exampaper_topic(varselectedPaper, boardID)
        setSelectedSubject(null)
        validation.setFieldValue("selectedSubject", null);
        setSelectedTopic(null)
        setselectedSubjectSubcat(null)
        validation.setFieldValue("selectedTopic", null);
        validation.setFieldValue("selectedSubjectSubcat", null);
        console.log('=-=-=-=-', optionAllSubject)
        var filter_subject = optionAllSubject.filter(e => e.expid == varselectedPaper?.value)
        setOptionSubject(filter_subject)


        // set language tab visibility
        var suject_lang = optionExamPaper.map(e => {
            if (e.expid == varselectedPaper?.value) {
                var lang_arr = e.lang?.split(',');
                setLangArr(lang_arr);

                if (lang_arr?.includes("1")) {
                    setShowEngPreview(false)
                    setlanguageActiveTab("11")
                }
                else {
                    setShowRegPreview(false)
                    setlanguageActiveTab("12")
                }
            }

        })
    }
    function handleSelectExamPaper_editload(varselectedPaper, varBoardID) {
        console.log('var', varselectedPaper)

        filter_exampaper_subject(varselectedPaper, varBoardID)
        filter_exampaper_topic(varselectedPaper, varBoardID)

        console.log('var==================', varselectedPaper)
        // console.log('op', optionAllExamPaper)
        //   set language tab visibility
        var suject_lang = optionAllExamPaper.map(e => {
            if (e.expid == varselectedPaper?.value) {
                var lang_arr = e.lang?.split(',');
                setLangArr(lang_arr);

                if (lang_arr?.includes("1")) {
                    setShowEngPreview(false)
                    setlanguageActiveTab("11")
                }
                else {
                    setShowRegPreview(false)
                    setlanguageActiveTab("12")
                }
            }

        })

        var filter_subject = optionAllSubject.filter(e => Number(e.expid) === Number(varselectedPaper?.value))
        console.log('optionAllSubject', optionAllSubject);

        return filter_subject;
    }

    function filter_exampaper_subject(varselectedPaper, varBoardID) {
        // var filtered_subject_option = optionAllSubject.map(e => {
        //     if (e.expid == varselectedPaper?.value) {
        //         return e;
        //     }

        // })

        var filtered_subject_option = optionAllSubject.map(e => {
            var exctid_arr = e?.exctid?.split(',');
            if (exctid_arr?.length > 0) {
                if (exctid_arr?.includes(varBoardID) && e.cs_expid == varselectedPaper?.value) {
                    return e;
                }
            }

        })

        filtered_subject_option = filtered_subject_option.filter(e => e != undefined);
        setOptionSubject([{ s_name: "Select", sid: "0", sscid: "0", exctid: "0" }, ...filtered_subject_option])

    }
    function filter_exampaper_topic(varselectedPaper, varBoardID) {



        var filtered_topic_option = optionAllTopic.map(e => {

            var exctid_arr = e?.exctid?.split(',');
            if (exctid_arr?.length > 0) {
                if (exctid_arr?.includes(varBoardID) && e.cs_expid == varselectedPaper?.value && e.subjectid == '0' && e.subcategoryid == '0') {
                    return e;
                }
            }


        })
        filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
        setOptionTopic(filtered_topic_option)
        refTopics.current = filtered_topic_option

    }


    const customStyles = {
        option: (styles, state, data) => { // fixed
            return {
                ...styles,
                backgroundColor: state.isDisabled
                    ? undefined
                    : state.isSelected
                        ? '#a6b0cf57'
                        : state.isFocused
                            ? '#556ee6'
                            : 'white',

                color: state.isDisabled
                    ? '#ccc'
                    : state.isSelected
                        ? 'black'
                        : state.isFocused
                            ? 'white'
                            : 'black',

                cursor: state.isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled
                        ? state.isSelected
                            ? '#556ee6'
                            : '#556ee6'
                        : undefined,
                },
                fontWeight: state.value && Number(state.value.split('-')[1]) !== 0 ? '' : '600'
            };
        }
    };

    const rejectToggle = () => {
        setRejectModal(prev => !prev);
    };
    const inputRef = useRef(null);
    const rejectValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            reason: (reason) || ''
        },
        validationSchema: Yup.object({
            reason: Yup.string().required("Please Enter Reason")
        }),
        onSubmit: (values) => {
            setLoading(true);
            handleRejectClick()
        },
    });

    useEffect(() => {
        if (rejectModal && inputRef.current && inputFocus) {
            inputRef.current.focus();
            setInputFocus(false);
        }
    }, [rejectModal, inputFocus]);

    useEffect(() => {
        if (rejectModal) {
            setInputFocus(true);
        }
    }, [rejectModal]);

    useEffect(() => {
        if (!rejectModal && rejectbutton === false) {
            reserForm()
        }
    }, [rejectModal]);

    const reserForm = () => {
        // setRejectModal(false)
        rejectValidation.setFieldValue('reason', '')
        rejectValidation.setFieldTouched('reason', false)
    }



    //meta title
    // document.title = "Study Materials | Add Study Material"
    return (
        <React.Fragment>
            <ToastContainer />
            <ApproveModal
                show={approveModal}
                onDeleteClick={handleApproveClick}
                onCloseClick={() => setApproveModal(false)}
            />
            <div className="page-content">
                <Container fluid={true}>
                    {/* <Breadcrumbs title="Study Materials" breadcrumbItem="Add Study Material" /> */}

                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            : null}

                    <Row>
                        {/* <div>{engContent}</div> */}
                        <Col lg="12">
                            <Form
                                onSubmit={(e) => {
                                    if (e.nativeEvent.submitter.id == 'save') {

                                        setSaveClone(0)
                                        setSaveCloneSid(0)
                                    }
                                    else {

                                        setSaveClone(1)
                                        setSaveCloneSid(0)
                                    }
                                    e.preventDefault();
                                    validation.handleSubmit(e.nativeEvent.submitter.id);
                                    return false;



                                }}
                            >
                                <Card className="crd-min-ht">
                                    <CardBody>
                                        <Row>

                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label>Exam Paper<span style={{ color: 'red' }}>*</span></Label>
                                                    <Select
                                                        value={selectedExamPaper}
                                                        onChange={(selectedOption) => {
                                                            validation.setFieldValue("selectedExamPaper", selectedOption);
                                                            handleSelectExamPaper(selectedOption, selectedBoardCategory?.value);
                                                        }}
                                                        options={optionExamPaper.map((option) => ({
                                                            label: option.exp_name,
                                                            value: option.expid,
                                                        }))}

                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        className={`select2-selection ${validation.touched.selectedExamPaper && validation.errors.selectedExamPaper ? 'is-invalid' : ''}`}
                                                        isDisabled={stid > 0 && localStorage.getItem('isMaterialClone') === '0' || localStorage.getItem('isMaterialClone') === '3'}
                                                    />
                                                    {validation.touched.selectedExamPaper && validation.errors.selectedExamPaper ? (
                                                        <div className="invalid-feedback">{validation.errors.selectedExamPaper}</div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col lg="3">
                                                <div className="mb-3">
                                                    <Label>Subject<span style={{ color: 'red' }}>*</span></Label>
                                                    <Select
                                                        value={selectedSubject}
                                                        onChange={(selectedOption) => {
                                                            validation.setFieldValue("selectedSubject", selectedOption);
                                                            handleSelectSubjectName(selectedOption);
                                                        }}
                                                        options={optionSubject.map((option) => ({
                                                            ...option,
                                                            label: option.subjectname,
                                                            value: option.subjectid,
                                                        }))}

                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        styles={customStyles}
                                                        className={`select2-selection ${validation.touched.selectedSubject && validation.errors.selectedSubject ? 'is-invalid' : ''}`}
                                                    />
                                                    {validation.touched.selectedSubject && validation.errors.selectedSubject ? (
                                                        <div className="invalid-feedback">{validation.errors.selectedSubject}</div>
                                                    ) : null}

                                                </div>
                                            </Col>
                                            <Col lg="3">
                                                <div className="mb-3">
                                                    <Label>Subcategory
                                                    </Label>
                                                    <Select
                                                        isDisabled={isDisabledSubCategory}

                                                        value={selectedSubjectsubcat}
                                                        onChange={(selectedOption) => {
                                                            validation.setFieldValue("selectedSubject", selectedOption);
                                                            handleSelectSubjectSubcatName(selectedOption);
                                                        }}
                                                        options={optionSubjectSubcat.map((option) => ({
                                                            ...option,
                                                            label: option.ssc_name,
                                                            value: option.sscid,
                                                        }))}

                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        styles={customStyles}
                                                        className={`select2-selection ${validation.touched.selectedSubject && validation.errors.selectedSubject ? 'is-invalid' : ''}`}
                                                    />
                                                    {validation.touched.selectedSubject && validation.errors.selectedSubject ? (
                                                        <div className="invalid-feedback">{validation.errors.selectedSubject}</div>
                                                    ) : null}

                                                </div>
                                            </Col>



                                            <Col lg="3">
                                                <div className="mb-3">
                                                    <Label>Topic
                                                    </Label>
                                                    <Select
                                                        ref={refTopics}
                                                        value={selectedTopic}
                                                        onChange={(selectedOption) => {
                                                            validation.setFieldValue("selectedTopic", selectedOption);
                                                            handleSelectTopicName(selectedOption)
                                                        }}
                                                        isDisabled={isDisabledTopic}

                                                        options={optionTopic.map((option) => ({
                                                            label: option.topicname,
                                                            value: option.topicid,
                                                        }))}
                                                        validate={{
                                                            required: { value: true },
                                                        }}

                                                        className={`select2-selection ${validation.touched.selectedTopic && validation.errors.selectedTopic ? 'is-invalid' : ''}`}
                                                    />
                                                    {validation.touched.selectedTopic && validation.errors.selectedTopic ? (
                                                        <div className="invalid-feedback">{validation.errors.selectedTopic}</div>
                                                    ) : null}

                                                </div>

                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col lg="3">
                                                <div className="mb-3">
                                                    <Label>Title<span style={{ color: 'red' }}>*</span></Label>
                                                    <Input
                                                        name="title"
                                                        type="text"
                                                        // maxLength={constants.textboxLength.c_name}
                                                        placeholder="Title"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.title}
                                                        invalid={
                                                            validation.touched.title && validation.errors.title ? true : false
                                                        }
                                                    // innerRef={confref}

                                                    />
                                                    {validation.touched.title && validation.errors.title ? (
                                                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                                    ) : null}
                                                </div>

                                            </Col>
                                            {reason && Number(localStorage.getItem('isMaterialClone')) !== 3 ?
                                                <Col lg="5">
                                                    <Label>Rejected Reason</Label>

                                                    <textarea
                                                        className="form-control"
                                                        id="commentmessage-input"
                                                        placeholder="Type here..."
                                                        rows="3"
                                                        value={reason}
                                                        disabled
                                                    ></textarea>
                                                    {/* <Card className="mb-3 card-custom">
                                                        <CardBody className="p-2" style={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', background: '#f9f9f9' }}>
                                                            <p className="text-danger p-2" style={{ fontSize: '14px', fontWeight: 'bold', borderBottom: '2px solid #ddd' }}>
                                                                Rejected Reason
                                                            </p>
                                                            <CardText className="card-custom-text px-2" style={{ marginTop: '1%', fontSize: '1rem', color: '#333' }}>
                                                                {reason || 'No reason provided'}
                                                            </CardText>
                                                        </CardBody>
                                                    </Card> */}

                                                    {/* <Card className="mb-3 card-custom">
                                                        <CardBody>
                                                            <CardHeader className="text-danger card-custom-title">Rejected Reason</CardHeader>
                                                            <CardText className="card-custom-text" style={{ marginTop: '1%' }}>
                                                                {reason || 'No reason provided'}
                                                            </CardText>
                                                        </CardBody>
                                                    </Card> */}

                                                    {/* <span className="text-danger">Rejected Reason</span>
                                                    <p style={{ marginTop: '1%' }}>{reason}</p> */}
                                                </Col> : rejectValidation && rejectValidation?.initialValues && rejectValidation?.initialValues?.reason !== "" ?
                                                    <Col lg="5">
                                                        <Label>Rejected Reason</Label>

                                                        <textarea
                                                            className="form-control"
                                                            id="commentmessage-input"
                                                            placeholder="Type here..."
                                                            rows="3"
                                                            value={rejectValidation?.initialValues?.reason}
                                                            disabled
                                                        ></textarea>
                                                        {/* <Card className="mb-3 card-custom">
                                                      <CardBody className="p-2" style={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', background: '#f9f9f9' }}>
                                                          <p className="text-danger p-2" style={{ fontSize: '14px', fontWeight: 'bold', borderBottom: '2px solid #ddd' }}>
                                                              Rejected Reason
                                                          </p>
                                                          <CardText className="card-custom-text px-2" style={{ marginTop: '1%', fontSize: '1rem', color: '#333' }}>
                                                              {reason || 'No reason provided'}
                                                          </CardText>
                                                      </CardBody>
                                                  </Card> */}

                                                        {/* <Card className="mb-3 card-custom">
                                                      <CardBody>
                                                          <CardHeader className="text-danger card-custom-title">Rejected Reason</CardHeader>
                                                          <CardText className="card-custom-text" style={{ marginTop: '1%' }}>
                                                              {reason || 'No reason provided'}
                                                          </CardText>
                                                      </CardBody>
                                                  </Card> */}

                                                        {/* <span className="text-danger">Rejected Reason</span>
                                                  <p style={{ marginTop: '1%' }}>{reason}</p> */}
                                                    </Col> : ""

                                            }
                                        </Row>
                                        <div className="crypto-buy-sell-nav">
                                            <Nav tabs className="nav-tabs-custom" role="tablist">
                                                <NavItem>
                                                    <NavLink className={classnames({ active: activeTab === "1", })}
                                                        onClick={() => { toggleTab("1") }}>
                                                        Content Upload
                                                    </NavLink>
                                                </NavItem>
                                                {/* <NavItem>
                                                    <NavLink className={classnames({ active: activeTab === "2", })}
                                                        onClick={() => { toggleTab("2") }}>
                                                        File Upload
                                                    </NavLink>
                                                </NavItem> */}
                                            </Nav>

                                            <TabContent
                                                activeTab={activeTab}
                                                className="crypto-buy-sell-nav-content p-4"
                                            >
                                                <TabPane tabId="1" id="buy">

                                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                                        {langArr?.includes("1") ?
                                                            <NavItem>
                                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: languageActiveTab === "11", })} onClick={() => { changeLangugeTab("11"); setShowEngPreview(false) }}>
                                                                    <span className="d-block d-sm-none">
                                                                        <i className="fas fa-home"></i>
                                                                    </span>
                                                                    <span className="d-none d-sm-block">English</span>

                                                                </NavLink>
                                                            </NavItem>
                                                            : null}
                                                        {langArr?.includes("2") ?

                                                            <NavItem>
                                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: languageActiveTab === "12", })} onClick={() => { changeLangugeTab("12"); setShowRegPreview(false) }}>
                                                                    <span className="d-block d-sm-none">
                                                                        <i className="far fa-user"></i>
                                                                    </span>
                                                                    <span className="d-none d-sm-block">Regional</span>
                                                                </NavLink>
                                                            </NavItem>
                                                            : null}
                                                        <Col lg="12" style={{
                                                            backgroundColor: 'rgb(244 245 249)',

                                                        }}>
                                                            <TabContent
                                                                activeTab={languageActiveTab}
                                                                className="text-muted"
                                                            >
                                                                <TabPane tabId="11">
                                                                    <div className="card-body">

                                                                        {showEngPreview == false ?
                                                                            <Row>
                                                                                <iframe
                                                                                    key={engkey}
                                                                                    ref={smEngIframeRef}
                                                                                    title="Embedded HTML"
                                                                                    src={htmlContent}
                                                                                    width="100%"
                                                                                    height="400px"
                                                                                />
                                                                            </Row>
                                                                            :
                                                                            null}

                                                                    </div>
                                                                    {/* English file upload starts */}

                                                                    <Col lg="12" style={{
                                                                        backgroundColor: 'rgb(244 245 249)',
                                                                        padding: 16
                                                                    }}>

                                                                        <Row>

                                                                            <Col lg="3">
                                                                                <Label>Description<span style={{ color: 'red' }}>*</span></Label>
                                                                                <Input
                                                                                    name="filename"
                                                                                    placeholder="Enter Description"
                                                                                    type="text"
                                                                                    value={fileName}
                                                                                    onChange={(e) =>
                                                                                        setFileName(e.target.value)}
                                                                                />

                                                                            </Col>
                                                                            <Col lg="4">
                                                                                <Label>Upload
                                                                                    <span>(jpg,jpeg,png,pdf only)</span>
                                                                                    <span style={{ color: 'red' }}>*</span>
                                                                                </Label>
                                                                                <Input ref={fileuplaod} type="file" id="fileuplaod" name="fileuplaod" accept=".pdf, .jpg, .jpeg, .png, image/*"
                                                                                    onChange={(e) =>
                                                                                        getUploadedFile(e)} />




                                                                            </Col>
                                                                            <Col lg="1" style={{ padding: '0', margin: '2%' }}>

                                                                                <Button
                                                                                    type="button"
                                                                                    style={{
                                                                                        color: "white",
                                                                                        background: "transparent",
                                                                                        border: "transparent",
                                                                                        padding: "0px",
                                                                                        fontSize: "25px",
                                                                                        height: "25px"
                                                                                    }}
                                                                                    onClick={handleAddOrUpdateRow}
                                                                                >
                                                                                    {isEditing ? <i className="mdi mdi-check-circle text-success me-2"></i> : <i className="mdi mdi-plus-circle text-success me-2"></i>}
                                                                                </Button>
                                                                                {isEditing && (
                                                                                    <Button
                                                                                        type="button"
                                                                                        style={{
                                                                                            color: "white",
                                                                                            background: "transparent",
                                                                                            border: "transparent",
                                                                                            padding: "0px",
                                                                                            fontSize: "25px",
                                                                                            height: "25px"
                                                                                        }}
                                                                                        onClick={handleAddOrUpdateRow}
                                                                                    >
                                                                                        <i className="mdi mdi-close-circle text-danger"></i>

                                                                                    </Button>)}
                                                                            </Col>


                                                                        </Row>
                                                                        <Row>

                                                                            <TableContainer
                                                                                columns={columns}
                                                                                data={dataTable || []}
                                                                                pageSize={dataTable.length}
                                                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                                            />
                                                                        </Row>

                                                                    </Col>{/* English file upload ends */}

                                                                </TabPane>
                                                                <TabPane tabId="12">
                                                                    <div className="card-body">
                                                                        {showRegPreview == false ?
                                                                            <Row>
                                                                                <iframe
                                                                                    key={regkey}
                                                                                    ref={smRegIframeRef}
                                                                                    title="Embedded HTML"
                                                                                    src={htmlContent}
                                                                                    width="100%"
                                                                                    height="400px"
                                                                                />
                                                                            </Row> : null}
                                                                    </div>

                                                                    {/* Regional file upload starts */}

                                                                    <Col lg="12" style={{
                                                                        backgroundColor: 'rgb(244 245 249)',
                                                                        padding: 16
                                                                    }}>

                                                                        <Row>

                                                                            <Col lg="3">
                                                                                <Label>Description<span style={{ color: 'red' }}>*</span></Label>
                                                                                <Input
                                                                                    name="filename"
                                                                                    placeholder="Enter Description"
                                                                                    type="text"
                                                                                    value={regFileName}
                                                                                    onChange={(e) =>
                                                                                        setRegFileName(e.target.value)}
                                                                                />

                                                                            </Col>
                                                                            <Col lg="4">
                                                                                <Label>Upload <span>(jpg,jpeg,png,pdf only)</span><span style={{ color: 'red' }}>*</span></Label>
                                                                                <Input ref={regfileuplaod} type="file" id="regfileuplaod" name="regfileuplaod" accept=".pdf, .jpg, .jpeg, .png, image/*"
                                                                                    onChange={(e) =>
                                                                                        getRegUploadedFile(e)} />
                                                                            </Col>
                                                                            <Col lg="1" style={{ padding: '0', margin: '2%' }}>

                                                                                <Button
                                                                                    type="button"
                                                                                    style={{
                                                                                        color: "white",
                                                                                        background: "transparent",
                                                                                        border: "transparent",
                                                                                        padding: "0px",
                                                                                        fontSize: "25px",
                                                                                        height: "25px"
                                                                                    }}
                                                                                    onClick={handleRegAddOrUpdateRow}
                                                                                >
                                                                                    {isRegEditing ? <i className="mdi mdi-check-circle text-success me-2"></i> : <i className="mdi mdi-plus-circle text-success me-2"></i>}
                                                                                </Button>
                                                                                {isRegEditing && (
                                                                                    <Button
                                                                                        type="button"
                                                                                        style={{
                                                                                            color: "white",
                                                                                            background: "transparent",
                                                                                            border: "transparent",
                                                                                            padding: "0px",
                                                                                            fontSize: "25px",
                                                                                            height: "25px"
                                                                                        }}
                                                                                        onClick={handleRegAddOrUpdateRow}
                                                                                    >
                                                                                        <i className="mdi mdi-close-circle text-danger"></i>

                                                                                    </Button>)}
                                                                            </Col>


                                                                        </Row>
                                                                        <Row>

                                                                            <TableContainer
                                                                                columns={regcolumns}
                                                                                data={regdataTable || []}
                                                                                pageSize={regdataTable.length}
                                                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                                            />
                                                                        </Row>
                                                                    </Col>{/* Regional file upload ends */}
                                                                </TabPane>
                                                            </TabContent>
                                                        </Col>

                                                    </Nav>

                                                </TabPane>

                                                {/* <TabPane tabId="2">
                                                    <Col lg="12" style={{
                                                        backgroundColor: 'rgb(244 245 249)',
                                                        padding: 16
                                                    }}>

                                                        <Row>

                                                            <Col lg="3">
                                                                <Label>Description</Label>
                                                                <Input
                                                                    name="filename"
                                                                    placeholder="Enter Description"
                                                                    type="text"
                                                                    value={fileName}
                                                                    onChange={(e) =>
                                                                        setFileName(e.target.value)}
                                                                />

                                                            </Col>
                                                            <Col lg="4">
                                                                <Label>Upload<span style={{ color: 'red' }}>*</span></Label>
                                                                <Input ref={fileuplaod} type="file" id="fileuplaod" name="fileuplaod" accept="pdf, pdf"
                                                                    onChange={(e) =>
                                                                        getUploadedFile(e)} />
                                                            </Col>
                                                            <Col lg="1" style={{ padding: '0', margin: '2%' }}>

                                                                <Button
                                                                    type="button"
                                                                    style={{
                                                                        color: "white",
                                                                        background: "transparent",
                                                                        border: "transparent",
                                                                        padding: "0px",
                                                                        fontSize: "25px",
                                                                        height: "25px"
                                                                    }}
                                                                    onClick={handleAddOrUpdateRow}
                                                                >
                                                                    {isEditing ? <i className="mdi mdi-check-circle text-success me-2"></i> : <i className="mdi mdi-plus-circle text-success me-2"></i>}
                                                                </Button>
                                                                {isEditing && (
                                                                    <Button
                                                                        type="button"
                                                                        style={{
                                                                            color: "white",
                                                                            background: "transparent",
                                                                            border: "transparent",
                                                                            padding: "0px",
                                                                            fontSize: "25px",
                                                                            height: "25px"
                                                                        }}
                                                                        onClick={handleAddOrUpdateRow}
                                                                    >
                                                                        <i className="mdi mdi-close-circle text-danger"></i>

                                                                    </Button>)}
                                                            </Col>


                                                        </Row>
                                                        <Row>

                                                            <TableContainer
                                                                columns={columns}
                                                                data={dataTable || []}
                                                                pageSize={dataTable.length}
                                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                            />
                                                        </Row>

                                                    </Col>
                                                </TabPane> */}
                                            </TabContent>
                                        </div>
                                    </CardBody>
                                    <CardFooter>
                                        <Row lg="12">
                                            {stid > 0
                                                ? (
                                                    <div className="mb-3">
                                                        <Label>Status</Label>
                                                        <div className="square-switch" key={'status'}>

                                                            <input
                                                                type="checkbox"
                                                                id="statusMaterial"
                                                                className="switch switch-bool"
                                                                checked={statusMaterial}
                                                                onClick={() =>
                                                                    setStatusMaterial(!statusMaterial)
                                                                }
                                                            />
                                                            <label
                                                                htmlFor="statusMaterial"
                                                                data-on-label="Active"
                                                                data-off-label="Inactive"
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}
                                        </Row>
                                        {!isLoading ?

                                            <div className="text-end">


                                                {

                                                    //   ( user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_type) === constants.menuTypes.Special
                                                    //   && (generalsetting && generalsetting.length > 0 && generalsetting[0].gs_studymaterial_approval == 1 && approvestudymaterial == 3)                                             
                                                    //   ) && (stid != 0) )

                                                    // (generalsetting && generalsetting.length > 0 && generalsetting[0].gs_studymaterial_approval == 1) &&
                                                    // (user.user_previleges.filter(p =>
                                                    //     Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type == constants.menuTypes.Modify || p.urp_type != constants.menuTypes.Create)).length == 2)

                                                    //     (generalsetting && generalsetting.length > 0 && generalsetting[0].gs_studymaterial_approval == 1) && 
                                                    //   ((user.user_previleges.filter(p =>
                                                    //        Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create || p.urp_type === constants.menuTypes.Special)).length !== 2)|| (user.user_previleges.filter(p =>
                                                    //            Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type !== constants.menuTypes.Create || p.urp_type === constants.menuTypes.Special)).length === 2
                                                    //        )
                                                    //    ||(user.user_previleges.filter(p =>
                                                    //        Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Modify)).length === 1)
                                                    //         || (user.user_previleges.filter(p =>
                                                    //            Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type !== constants.menuTypes.Modify || p.urp_type === constants.menuTypes.Special)).length !== 2)
                                                    //    ) 

                                                    // &&





                                                    (((stid != 0 && approvebutton == true && isMaterialClone == "3") || (stid != 0 && rejectbutton == true && isMaterialClone == "3")) &&
                                                        (user.user_previleges.filter(p =>
                                                            Number(p.urp_smid) === constants.SubmenuList.SMApproval && (p.urp_type === constants.menuTypes.Permission1)).length === 1)
                                                    )

                                                        ? <>
                                                            <Button color="success"
                                                                type="button"
                                                                id="save"
                                                                style={{ marginRight: "10px" }}
                                                                className="save-user"
                                                                onClick={() => setApproveModal(true)}
                                                            >
                                                                Approve
                                                            </Button>
                                                            <Button color="warning"
                                                                type="button"
                                                                id="save"
                                                                className="save-user"
                                                                onClick={() => {
                                                                    setRejectModal(true)
                                                                    rejectModal ? inputRef.current.focus() : '';
                                                                }}
                                                            >
                                                                Reject
                                                            </Button></>
                                                        : ""}

                                                {


                                                    ((stid != null && stid != undefined && Number(stid) === 0) &&

                                                        (
                                                            (user.user_previleges.filter(p => Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create && p.urp_type === constants.menuTypes.Special).length === 2))
                                                            || (user.user_previleges.filter(p =>
                                                                Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create)).length === 1))


                                                        // (user.user_previleges.filter(p =>
                                                        //     Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Create || p.urp_type === constants.menuTypes.Special)).length === 2)|| (user.user_previleges.filter(p =>
                                                        //         Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type !== constants.menuTypes.Create || p.urp_type === constants.menuTypes.Special)).length === 2
                                                        //     ) 
                                                    ) ?
                                                        // <div className="text-end">

                                                        <Button color="success"
                                                            type="submit"
                                                            id="save"
                                                            className="save-user"
                                                        // onClick={handleSaveClick}
                                                        >
                                                            Save
                                                        </Button>
                                                        // {/* </div>  */}
                                                        :
                                                        ((stid != "0" && stid != null && stid != undefined && isMaterialClone == "0") && successFlag === false &&
                                                            ((user.user_previleges.filter(p =>
                                                                Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Modify)).length === 1))
                                                            // ((user.user_previleges.filter(p =>
                                                            //     Number(p.urp_mid) === constants.mainMenuList.StudyMaterial && (p.urp_type === constants.menuTypes.Modify)).length === 1)
                                                            //      || (user.user_previleges.filter(p =>
                                                            //         Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type !== constants.menuTypes.Modify || p.urp_type === constants.menuTypes.Special)).length === 2))

                                                        ) ?
                                                            // <div className="text-end">

                                                            <Button color="success"
                                                                type="submit"
                                                                id="save"
                                                                className="save-user"
                                                            // onClick={handleSaveClick}
                                                            >
                                                                Update
                                                            </Button>
                                                            // </div>
                                                            : ""}




                                                {/* <Button color="success"
                                                    id="save"
                                                    type="submit"
                                                    className="save-user"
                                                    disabled={initialSubmitState}
                                                >


                                                    {stid > 0 && isMaterialClone != '1' ? 'Update' : 'Save'}
                                                </Button> */}
                                                {stid == 0 || isMaterialClone == '1' ?
                                                    <Button color="primary"
                                                        type="submit"
                                                        id="clone"
                                                        style={{ marginLeft: "10px" }}
                                                        className="save-user"
                                                    // onClick={handleSaveClick}
                                                    >
                                                        Save & Continue
                                                    </Button> : null}
                                                <button type="button" style={{ marginLeft: "10px" }}
                                                    onClick={handleClose}
                                                    className="btn btn-danger">Close</button>
                                            </div> : null}

                                    </CardFooter>

                                </Card>
                            </Form>
                        </Col>
                    </Row>


                </Container>
            </div>
            <Modal isOpen={rejectModal} toggle={rejectToggle} backdrop="static">
                <ModalBody toggle={rejectToggle}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            rejectValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Label>Reason<span style={{ color: 'red' }}>*</span></Label>
                                    <Input
                                        name="reason"
                                        type="text"
                                        className="form-control"
                                        placeholder="Type here..."
                                        rows="3"
                                        autoComplete="off"
                                        maxLength={constants.textboxLength.reason}
                                        onChange={rejectValidation.handleChange}
                                        onBlur={rejectValidation.handleBlur}
                                        value={rejectValidation.values.reason || ""}
                                        innerRef={inputRef}
                                        validate={{ required: { value: true } }}
                                        invalid={rejectValidation.touched.reason && rejectValidation.errors.reason}
                                    />
                                    {rejectValidation.touched.reason && rejectValidation.errors.reason ? (
                                        <FormFeedback type="invalid">{rejectValidation.errors.reason}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <div className="hstack gap-2 justify-content-center mb-0">
                        <button type="button" className="btn btn-danger" onClick={rejectValidation.handleSubmit}>Reject</button>
                        <button type="button" className="btn btn-secondary" onClick={() =>
                            setRejectModal(false)
                        }>No</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
};

export default AddStudyMaterials;