import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changepasswordmaster, logoutUser } from "../../store/actions";
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import * as constants from "../../constants/layout";
import { ToastContainer } from "react-toastify";

const ChangePasswordModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const user = getLoggedInUser();
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [oldpasswordShow, setOldPasswordShow] = useState(false);
  const [newpasswordShow, setNewPasswordShow] = useState(false);
  const [confmpasswordShow, setConfmPasswordShow] = useState(false);

  const validatePasswordComplexity = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;
    return regex.test(password);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'oldPassword':
        setOldPassword(value);
        setOldPasswordError(value === '');
        break;
      case 'newPassword':
        setNewPassword(value);
        setNewPasswordError(value === '' || !validatePasswordComplexity(value));
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        setConfirmPasswordError(value === '' || value !== newPassword);
        setConfirmPasswordErrorMessage(value !== newPassword ? "Confirm password must be the same as the new password." : '');
        break;
      default:
        break;
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({

    }),
    onSubmit: (values) => {
      if (!oldPassword || !newPassword || !confirmPassword || newPassword !== confirmPassword || !validatePasswordComplexity(newPassword)) {
        return;
      }

      dispatch(
        changepasswordmaster({
          user_id: Number(user.uid),
          old_password: oldPassword,
          new_password: newPassword,
        })
      );
      toggle();
    },
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle}>Change Password</ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <Label>Old Password<span style={{ color: 'red' }}>*</span></Label>
          <div className="input-group auth-pass-inputgroup">

          <Input
            name="oldPassword"
            // type="password"
            type={oldpasswordShow ? "text" : "password"}
            maxLength={constants.textboxLength.u_password}
            placeholder="Old Password"
            onChange={handleInputChange}
            value={oldPassword}
            invalid={oldPasswordError}
          />
           <button onClick={() => setOldPasswordShow(!oldpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i>
                                </button>
          {oldPasswordError && <FormFeedback type="invalid">Please enter old password</FormFeedback>}
          </div>
        </div>

        <div className="mb-3">
          <Label>New Password<span style={{ color: 'red' }}>*</span></Label>
          <div className="input-group auth-pass-inputgroup">
          <Input
            name="newPassword"
            type={newpasswordShow ? "text" : "password"}
            // type="password"
            maxLength={constants.textboxLength.u_password}
            placeholder="New Password"
            onChange={handleInputChange}
            value={newPassword}
            invalid={newPasswordError}
          />
                <button onClick={() => setNewPasswordShow(!newpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i>
                                </button>
          {newPasswordError && <FormFeedback type="invalid">Please enter a valid new password with at least 8 characters, including one letter and one number</FormFeedback>}
          </div>
        </div>

        <div className="mb-3">
          <Label>Confirm New Password<span style={{ color: 'red' }}>*</span></Label>
          <div className="input-group auth-pass-inputgroup">

          <Input
            name="confirmPassword"
            // type="password"
            type={confmpasswordShow ? "text" : "password"}
            maxLength={constants.textboxLength.u_password}
            placeholder="Confirm New Password"
            onChange={handleInputChange}
            value={confirmPassword}
            invalid={confirmPasswordError}
          />
          
          <button onClick={() => setConfmPasswordShow(!confmpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i>
                                </button>
          {confirmPasswordError && <FormFeedback type="invalid">{confirmPasswordErrorMessage}</FormFeedback>}
       </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-danger" onClick={toggle}>Close</button>
        <Button color="success" onClick={validation.handleSubmit}>Update</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChangePasswordModal;