import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";
import * as constants from "../constants/layout"
// import { logoutUser } from "../store/auth/login/actions";
import { logoutUser } from "store/actions";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = sessionStorage.getItem('SESSION_KEY');
  if (!user) {
    const user = localStorage.getItem('authUser');
    sessionStorage.setItem('SESSION_KEY', user)
    return JSON.parse(user)
  } else {
    return JSON.parse(user)
  }
};

export const userValidation = async (response) => {
  if (response && response.statusFlag === 3) { // user validation
    toast.error(response.message, { autoClose: 2000 });
    logoutUser()
    // localStorage.removeItem("authUser");
    // sessionStorage.removeItem("SESSION_KEY")
    // window.location.reload()
    return
  }
}


//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

//getRefreshToken 
export const getRefreshToken = async () => {
  const user = getLoggedInUser()
  const params = { token: user.refreshToken }
  const response1 = await axios.post(url.BASE_URL + `user-master/refreshToken`, params)
  if (response1?.data?.body?.tokenReult) {
    if (response1?.data?.body?.tokenReult?.status === constants.invalidRefreshToken) {
      localStorage.removeItem("authUser");
      sessionStorage.removeItem("SESSION_KEY");
      window.location.reload()
      return constants.invalidRefreshToken
    }
    const accessToken = response1?.data?.body?.tokenReult?.accessToken
    const refreshToken = response1?.data?.body?.tokenReult?.refreshToken
    const data = Object.assign({}, user)
    data.accessToken = accessToken ? accessToken : data.accessToken
    data.refreshToken = refreshToken ? refreshToken : data.refreshToken
    localStorage.setItem('authUser', JSON.stringify(data))
    sessionStorage.setItem('SESSION_KEY', JSON.stringify(data))
    return accessToken
  } else {
    return ''
  }
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};


// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

/** Exam Board APIS **/
// getExamBoardsList Method
export const getExamBoardsList = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/getExamBoardList', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getExamBoardsList(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ examBoards: response1.data.body.examBoardList } }
  } else {
    return { statusFlag: 2, examBoards: [] }
  }
};

// addExamBoard
export const addNewExamBoard = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/saveExamBoard', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        addNewExamBoard(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// deleteExamBoard
export const deleteExamBoard = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/deleteExamBoard', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        deleteExamBoard(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

/** Exam Board APIS **/


/** studymaterial APIS **/

// getstudymaterialList Method
export const getstudymaterialList = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/getstudymaterialList', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getstudymaterialList(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ studymaterialList: response1.data.body.studymaterialList } }
  } else {
    return { statusFlag: 2, studymaterialList: [] }
  }
};

// deleteblueprint
export const deletestudymaterial = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/deletestudymaterial', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        deletestudymaterial(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// getblueprintList Method
export const getblueprintList = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'bluePrint/getBluePrintList', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getblueprintList(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ bluePrintList: response1.data.body.bluePrintList } }
  } else {
    return { statusFlag: 2, bluePrintList: [] }
  }
};

// addblueprint
export const addblueprint = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'bluePrint/saveBluePrint', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        addblueprint(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// editloadblueprint
export const editloadblueprint = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'bluePrint/editLoadBluePrint', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        editloadblueprint(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ bluePrintData: response1.data.body } }
  } else {
    return { statusFlag: 2, bluePrintData: [] }
  }
};

// deleteblueprint
export const deleteblueprint = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'bluePrint/deleteBluePrint', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        deleteblueprint(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

/** Exam Board Category APIS **/


// getExamBoardscategoryList Method
export const getExamBoardscategoryList = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/getExamBoardCategoryList', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getExamBoardscategoryList(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ examBoardscategory: response1.data.body.examBoardcategoryList } }
  } else {
    return { statusFlag: 2, examBoardscategory: [] }
  }
};


// addExamBoardcategory
export const addExamBoardcategory = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/saveExamBoardCategory', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        addExamBoardcategory(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// deleteExamBoard

export const deleteExamBoardcategory = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/deleteExamBoardCategory', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        deleteExamBoardcategory(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};
// Mockup Test API
// getMockupList Method
export const getMockupTestList = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'mockuptest/getMockupTest', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getMockupTestList(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ mockupList: response1.data.body.mockupTestList } }
  } else {
    return { statusFlag: 2, mockupList: [] }
  }
};
// addMockup
export const addMockup = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'mockuptest/saveMockupTest', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        addMockup(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};
// editloadmockuptest
export const editloadMockupTest = async (data) => {
  try {

    const user = getLoggedInUser()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(url.BASE_URL + 'mockuptest/editLoadMockupTest', data, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
      if (refresh) {
        if (Number(refresh) === constants.invalidRefreshToken) {
          logoutUser()
        } else {
          editloadMockupTest(data) // calling same function again getting refresh token
        }
      }
    }
    if (response1 && response1.data && response1.data.body) {
      return { ...response1.data.body, ...{ mockupTestData: response1.data.body } }
    } else {
      return { statusFlag: 2, mockupTestData: [] }
    }
  } catch (err) {
    console.error('err', err)
  }
};


// deleteMockupTest

export const deleteMockupTest = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'mockuptest/deleteMockupTest', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        deleteMockupTest(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};


// addGeneralSettings
export const addGeneralSettings = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'generalsettings/saveGeneralSettings', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        addGeneralSettings(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};
// editloadgeneralsettings
export const editloadgeneralSettings = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'generalsettings/editLoadGeneralSettings', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        editloadgeneralSettings(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ generalSettingsData: response1.data.body } }
  } else {
    return { statusFlag: 2, generalSettingsData: [] }
  }
};

/** Exam Paper APIS **/


// getExamPaperList Method
export const getExamPaperList = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/getExaminationpapersList', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getExamPaperList(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ examPapercategory: response1.data.body.examinationPapersList } }
  } else {
    return { statusFlag: 2, examPapercategory: [] }
  }
};


// addExamPaper
export const addExamPaper = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/saveExaminationpapers', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        addExamPaper(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// deleteExamPaper

export const deleteExamPaper = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/deleteExaminationpapers', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        deleteExamPaper(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// getsubjectList Method
export const getsubjectList = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/getSubjectList', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getsubjectList(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ subjectList: response1.data.body.subjectList } }
  } else {
    return { statusFlag: 2, subjectList: [] }
  }
};

// addsubject
export const addsubjectlist = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/saveSubject', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        addsubjectlist(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// deletesubject

export const deletesubjectlist = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/deleteSubject', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        deletesubjectlist(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// getsubjectsubcategoryList Method
export const getsubjectsubcategoryList = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/getSubjectSubCatList', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getsubjectsubcategoryList(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ subjectSubCatList: response1.data.body.subjectSubCatList } }
  } else {
    return { statusFlag: 2, subjectSubCatList: [] }
  }
};


// addsubjectsubcategory
export const addsubjectsubcategorylist = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/saveSubjectSubCategory', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        addsubjectsubcategorylist(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// deletesubjectsubcategory

export const deletesubjectsubcategory = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/deleteSubjectSubCategory', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        deletesubjectsubcategory(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// gettopicList Method
export const gettopicList = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/getTopicList', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        gettopicList(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ topicList: response1.data.body.topicList } }
  } else {
    return { statusFlag: 2, topicList: [] }
  }
};


// addtopic
export const addtopiclist = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/saveTopic', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        addtopiclist(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// deletetopiclist

export const deletetopiclist = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/deleteTopic', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        deletetopiclist(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// getusermasterList Method
export const getusermasterList = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'user-master/getUserList', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getusermasterList(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ userList: response1.data.body.userList } }
  } else {
    return { statusFlag: 2, userList: [] }
  }
};

// addusermasterlist
export const addusermasterlist = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'user-master/saveUser', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        addusermasterlist(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// adduserlog
export const adduserlog = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'user-master/adminUserLog', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        adduserlog(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// deleteusermasterlist

export const deleteusermasterlist = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'user-master/deleteUser', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        deleteusermasterlist(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

//resetpassword api
export const resetpasswordapi = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'user-master/resetPassword', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        resetpasswordapi(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

//changepassword api
export const changepasswordapi = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'user-master/changePassword', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        changepasswordapi(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {


    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }

  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

//masterlist api
export const getmasterlist = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/getMaster', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getmasterlist(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ masterlist: response1.data.body } }
  } else {
    return { statusFlag: 2, masterlist: [] }
  }
};

//masterlist api
export const getqamaster = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'master/getQAMaster', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getqamaster(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ masterlist: response1.data.body } }
  } else {
    return { statusFlag: 2, masterlist: [] }
  }
};

/** Exam Board Category APIS **/

/**  USER ROLE APIS **/
// getUserroleList Method
export const getUserroleList = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'user-master/getUserRoleList', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getUserroleList(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ userRoleList: response1.data.body.userRoleList } }
  } else {
    return { statusFlag: 2, userRoleList: [] }
  }
};

// addNewUserrole
export const addNewUserrole = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'user-master/saveUserRole', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        addNewUserrole(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return response1.data.body
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// deleteUserRole
export const deleteUserRole = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'user-master/deleteUserRole', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        deleteUserRole(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
  } else {
    return { statusFlag: 2, message: 'Something went wrong' }
  }
};

// getUserroleDetails Method
export const getUserroleDetails = async (data) => {
  const user = getLoggedInUser()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(url.BASE_URL + 'user-master/getUserRoleDetails', data, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === constants.invalidRefreshToken) {
        logoutUser()
      } else {
        getUserroleDetails(data) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return { ...response1.data.body, ...{ userRoleDetails: response1.data.body.userRoleDetails } }
  } else {
    return { statusFlag: 2, userRoleDetails: [] }
  }
};

/** USER ROLE APIS **/

//Login API
export const postLogin = async (data) => {
  const response1 = await axios.post(url.BASE_URL + 'user-master/login', data)
  console.log(response1, 'response33')
  return response1
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtForgetPwd,
  postJwtProfile,
};
