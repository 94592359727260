import PropTypes from 'prop-types'
import React, { useMemo } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import TableContainer from '../../components/Common/TableContainer';

const QuestionModal = ({ show, from, onCloseClick, questionData }) => {
    // const columns = useMemo(
    //     () => [
    //         {
    //             header: 'S.No.',
    //             accessorKey: 'sno',
    //             width: '8%',
    //             enableColumnFilter: false,
    //             enableSorting: true,
    //             className: 'pl-25',
    //             cell: ({ row, table }) =>
    //                 (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
    //         },
    //         {
    //             header: (
    //                 <span style={{ cursor: 'pointer' }}>
    //                     Topic Name ▼
    //                 </span>
    //             ),

    //             accessorKey: 'topicname',
    //             enableColumnFilter: false,
    //             enableSorting: true,
    //         },

    //     ],
    //     []
    // );
    return (
        <Modal size="md" isOpen={show} toggle={onCloseClick}>
            <ModalHeader toggle={onCloseClick} tag="h4" style={{ background: '#556ee630' }}>
                {"Question Details"}
                {/* Dynamic title content can go here */}
            </ModalHeader>
            <ModalBody style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                <p>{questionData}</p>
                <div className="hstack gap-2 mb-0" style={{ float: 'right', marginTop: '4%' }}>
                    <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Close</button>
                </div>
            </ModalBody>
        </Modal>
    )
}

QuestionModal.propTypes = {
    onCloseClick: PropTypes.func,
    // topicList: PropTypes.any,
    show: PropTypes.any
}

export default QuestionModal
