import {
    GET_STUDYMATERIAL_SUCCESS,
    DELETE_STUDYMATERIAL_SUCCESS,
    RESET_STORE_STUDYMATERIAL,
} from "./actionTypes"

  const INIT_STATE = {
    studymaterialList: [],
    studymaterialList_statusFlag: 0
  }
  
  const Studymaterial= (state = INIT_STATE, action) => {
    console.log(action.type, action.payload, 'reducer')
    switch (action.type) {
      case  GET_STUDYMATERIAL_SUCCESS:
        return {
          ...state,
          studymaterialList: action.payload.studymaterialList,
          studymaterialList_statusFlag: action.payload.statusFlag
        }
       case   DELETE_STUDYMATERIAL_SUCCESS:
        return {
            ...state,
            studymaterialList_statusFlag: action.payload.statusFlag,
        };
       case  RESET_STORE_STUDYMATERIAL:
            return {
              ...state,
              studymaterialList_statusFlag: action.payload.statusFlag,
            };
        default:
            return state
        }
      }
      
      export default Studymaterial