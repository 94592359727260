import {
  GET_USERROLE_LIST,
  GET_USERROLE_LIST_SUCCESS,
  ADD_NEW_USER_ROLE,
  ADD_NEW_USER_ROLE_SUCCESS,
  DELETE_USER_ROLE,
  DELETE_USER_ROLE_SUCCESS,
  RESET_STORE_USER_ROLE,
  EDIT_USER_ROLE,
  GET_USER_ROLE_DETAILS,
  GET_USER_ROLE_DETAILS_SUCCESS
} from "./actionTypes"

export const getUserroleList = (data) => ({
  type: GET_USERROLE_LIST,
  payload: data
})

export const getUserroleListuccess = userRoleList => ({
  type: GET_USERROLE_LIST_SUCCESS,
  payload: userRoleList,
})
export const addNewUserrole = data => ({
  type: ADD_NEW_USER_ROLE,
  payload: data,
})

export const addNewUserroleSuccess = data => ({
  type: ADD_NEW_USER_ROLE_SUCCESS,
  payload: data,
})

export const deleteUserrole = data => ({
  type: DELETE_USER_ROLE,
  payload: data,
})

export const deleteUserroleSuccess = data => ({
  type: DELETE_USER_ROLE_SUCCESS,
  payload: data,
})


export const resetUserRoleStore = data => ({
  type: RESET_STORE_USER_ROLE,
  payload: data,
})

export const setEditUserRole = data => ({
  type: EDIT_USER_ROLE,
  payload: data,
})

export const getUserRoleDetails = (data) => ({
  type: GET_USER_ROLE_DETAILS,
  payload: data
})

export const getUserRoleDetailsSuccess = userRoleDetails => ({
  type: GET_USER_ROLE_DETAILS_SUCCESS,
  payload: userRoleDetails,
})