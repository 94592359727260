import {
    ADD_GENERALSETTINGS_SUCCESS,
    EDITLOAD_GENERALSETTINGS_SUCCESS,
  } from "./actionTypes"
  
  const INIT_STATE = {
    generalSettingsData :[],
    generalsettings_statusFlag: 0
  }
  
  const GeneralSettings = (state = INIT_STATE, action) => {
    switch (action.type) {
   
      case ADD_GENERALSETTINGS_SUCCESS:
        return {
            ...state,
            generalsettings_statusFlag: action.payload.statusFlag,
        };

          case EDITLOAD_GENERALSETTINGS_SUCCESS:
            return {
                ...state,
                generalSettingsData : action.payload,
                generalsettings_statusFlag: action.payload.statusFlag,
            };
  
      default:
        return state
    }
  }
  
  export default GeneralSettings
  