import React, { Fragment, useEffect, useState } from "react";
import { Row, Table, Button, Col } from "reactstrap";
import { Link } from "react-router-dom";

import {
  // Column,
  // Table as ReactTable,
  // ColumnFiltersState,
  // FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';
import JobListGlobalFilter from "./GlobalSearchFilter";
import { Input } from "reactstrap";
// Column Filter
const Filter = ({
  column
}) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '')}
        onChange={value => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  );
};

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <React.Fragment>
      <Col sm={4}>
        <input {...props} value={value} onChange={e => setValue(e.target.value)} />
      </Col>
    </React.Fragment>
  );
};

const TableContainer = ({
  columns,
  data,
  tableClass,
  theadClass,
  divClassName,
  isBordered,
  isPagination,
  isGlobalFilter,
  paginationWrapper,
  SearchPlaceholder,
  pagination,
  buttonClass,
  buttonName,
  isAddButton,
  isCustomPageSize,
  pageSize,
  handleUserClick,
  isJobListGlobalFilter,
  totalLength

}) => {
  const [pagesToShow, setPagesToShow] = useState(5);
  const [currentPage, setCurrentPage] = useState(0); // Current page index
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(9)
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank
    });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState
  } = table;

  // useEffect(() => {
  //   Number(customPageSize) && setPageSize(Number(customPageSize));
  // }, [customPageSize, setPageSize]);
  useEffect(() => {
    Number(pageSize) && setPageSize(Number(pageSize));
  }, [setPageSize]);

  const totalPages = getPageOptions().length;

  const getVisiblePageOptions = () => {
    const startPage = Math.floor(currentPage / pagesToShow) * pagesToShow;
    const endPage = Math.min(startPage + pagesToShow - 1, totalPages - 1);

    // Generate the page numbers to display
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const handleNext = () => {
    if (currentPage + pagesToShow < totalPages) {
      setCurrentPage(prevPage => prevPage + pagesToShow);
    } else {
      setCurrentPage(Math.min(getState().pagination.pageIndex + pagesToShow, totalPages - 1));
    }
    const newPageIndex = Math.min(getState().pagination.pageIndex + pagesToShow, totalPages - 1);
    setPageIndex(newPageIndex);
  };

  const handlePrevious = () => {
    if (currentPage - pagesToShow >= 0) {
      setCurrentPage(prevPage => prevPage - pagesToShow);
    }
    const newPageIndex = Math.max(getState().pagination.pageIndex - pagesToShow, 0);
    setPageIndex(newPageIndex);
  };

  const Gotopage = (event) => {
    if (Math.sign(event.target.value) === 1 || Math.sign(event.target.value) === 0) {
      console.log('event', event.target.value);
      if (Math.sign(event.target.value) !== 0) {
        setCurrentPage(event.target.value - 1)
      } else {
        setCurrentPage(1)
      }
      setPageIndex(event.target.value - 1);
    }
  }

  return (
    <Fragment>

      <Row className="mb-2">
        {isCustomPageSize && (
          <Col sm={2}>
            <select
              className="form-select pageSize mb-2"
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}

        {isGlobalFilter && <DebouncedInput
          value={globalFilter ?? ''}
          onChange={value => setGlobalFilter(String(value))}
          className="form-control search-box me-2 mb-2 d-inline-block"
          placeholder={SearchPlaceholder}
        />}

        {isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />}

        {isAddButton && <Col sm={8}>
          <div className="text-sm-end">
            <Button type="button" className={buttonClass} onClick={handleUserClick}>
              <i className="mdi mdi-plus me-1"></i> {buttonName}</Button>
          </div>
        </Col>}
      </Row>

      <div className={divClassName ? divClassName : "table-responsive"} style={{ maxHeight: '50vh', overflow: 'auto' }}>
        <Table hover className={tableClass} bordered={isBordered}>
          <thead className={theadClass} style={{ position: 'sticky', top: 0 }}>
            {getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th width={`${header.column.columnDef.width}`} key={header.id} colSpan={header.colSpan} className={`${header.column.columnDef.enableSorting ? "sorting sorting_desc" : ""}`}>
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {
                              {
                                asc: '',
                                desc: '',
                              }
                              [header.column.getIsSorted()] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </React.Fragment>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody>
            {getRowModel().rows.map((row, index) => {
              return (
                <tr key={index}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td className={`${cell.column.columnDef.className}`} width={`${cell.column.columnDef.width}`} key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}

          </tbody>
        </Table>
      </div>


      {
        isPagination && (
          <Row>
            <Col sm={12} md={5}>
              <div className="dataTables_info">
                Showing {Math.min(getRowModel().rows.length, getState().pagination.pageSize)} of {data?.length} Results
              </div>
            </Col>
            <Col sm={12} md={7}>
              <div className={paginationWrapper}>
                <ul className={pagination}>
                  <span className="me-2" style={{ marginTop: '2%' }}>Go to page</span> <Input
                    name="title"
                    type="number"
                    // maxLength={constants.textboxLength.c_name}
                    placeholder="No."
                    onChange={(e) => Gotopage(e)}
                    style={{ width: '12%' }}
                  />
                  <li className={`paginate_button page-item previous ${!getCanPreviousPage() ? 'disabled' : ''}`}>
                    <Link to="#" className="page-link" onClick={handlePrevious}>
                      <i className="mdi mdi-chevron-left"></i>
                    </Link>
                  </li>
                  {getVisiblePageOptions().map((item, key) => (
                    <li key={key} className={`paginate_button page-item ${getState().pagination.pageIndex === item ? 'active' : ''}`}>
                      <Link to="#" className="page-link" onClick={() => { setPageIndex(item), setCurrentPage(item) }}>
                        {item + 1}
                      </Link>
                    </li>
                  ))}
                  <li className={`paginate_button page-item next ${!getCanNextPage() ? 'disabled' : ''}`}>
                    <Link to="#" className="page-link" onClick={handleNext}>
                      <i className="mdi mdi-chevron-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        )
      }
    </Fragment>
  );
};

export default TableContainer;