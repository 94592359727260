import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_BLUEPRINT, ADD_BLUEPRINT, DELETE_BLUEPRINT, EDITLOAD_BLUEPRINT } from "./actionTypes";
import {
    getblueprintSuccess, addblueprintSuccess, deleteblueprintSuccess, editloadSuccess
} from "./actions";

//Include Both Helper File with needed methods
import { getblueprintList, deleteblueprint, addblueprint, editloadblueprint, userValidation} from "helpers/fakebackend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchblueprint({ payload: data}) {
  try {
    const response = yield call(getblueprintList, data)
    yield call(userValidation, response)
    yield put(getblueprintSuccess(response))
  } catch (error) {
    yield put(getblueprintSuccess({statusFlag: 2}))
  }
}

function* onAddblueprint({ payload: data }) {
  try {
      const response = yield call(addblueprint, data)
      yield call(userValidation, response)
      yield put(addblueprintSuccess(response))
      if (response && response.statusFlag === 1) {
        toast.success(response.message, { autoClose: 2000 });
      } else {
        toast.error(response.message, { autoClose: 2000 });
      }      
  } catch (error) {
      yield put(addblueprintSuccess({statusFlag: 2}))
      toast.error("Blueprint Added Failed", { autoClose: 2000 });
  }
}

function* onDeleteblueprint({ payload: data }) {
  try {
      const response = yield call(deleteblueprint, data)
      yield call(userValidation, response)
      yield put(deleteblueprintSuccess(response))
      if (response && response.statusFlag === 1) {
        toast.success(response.message, { autoClose: 2000 });
      } else {
        toast.error(response.message, { autoClose: 2000 });
      }      
  } catch (error) {
      yield put(deleteblueprintSuccess({statusFlag: 2}))
      toast.error("Exam Board Deleted Failed", { autoClose: 2000 });
  }
}

function* oneditblueprint({ payload: data }) {
  try {
      const response = yield call(editloadblueprint, data)
      yield call(userValidation, response)
      yield put(editloadSuccess(response))
      if (response && response.statusFlag === 1) {
        toast.success(response.message, { autoClose: 2000 });
      } else {
        toast.error(response.message, { autoClose: 2000 });
      }      
  } catch (error) {
      yield put(editloadSuccess({statusFlag: 2}))
      toast.error("Exam Board Deleted Failed", { autoClose: 2000 });
  }
}

function* examboardSaga() {
  yield takeEvery(GET_BLUEPRINT, fetchblueprint)
  yield takeEvery(ADD_BLUEPRINT, onAddblueprint)
  yield takeEvery(DELETE_BLUEPRINT, onDeleteblueprint)
  yield takeEvery(EDITLOAD_BLUEPRINT, oneditblueprint)

}

export default examboardSaga;
